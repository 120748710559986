import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";

// MUI
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

export function CategoryForm({ mode, trainer_category_id }) {
  const [name, setName] = useState("");
  const [saved, setSaved] = useState(false);
  console.log("newTEST:", mode, trainer_category_id);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState("");
  useEffect(() => {
    let mounted = true;
    if (trainer_category_id > 0) {
      HandyGolfAPI.sendRequest("trainer/getCategoryDetails", {
        trainer_category_id: trainer_category_id,
      }).then((data) => {
        if (mounted) {
          setName(data.name);
        }
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
    return () => {
      mounted = false;
    };
  }, [mode, trainer_category_id]);

  const saveCategoryDetails = () => {
    let method = "addCategory";
    if (trainer_category_id > 0) {
      method = "editCategory";
    }
    HandyGolfAPI.sendRequest("trainer/" + method, {
      trainer_category_id: trainer_category_id,
      name: name,
    }).then((data) => {
      //   setRedirect("/admin/swing_trainer/category_mgmt/view/" + trainer_category_id);
      //   setRedirect("/admin/swing_trainer/category_mgmt");
      setSaved(true);
      //   console.log(data);
    });
  };
  return (
    <div>
      {redirect !== "" && <Navigate to={redirect} />}
      {!saved && (
        <>
          {" "}
          <Typography variant="h4" gutterBottom>
            Category Management
          </Typography>
          {mode === "edit" ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/admin/swing_trainer/category_mgmt/">
                <Button>Category List</Button>
              </Link>
              <Link to={"/admin/swing_trainer/category_mgmt/view/" + trainer_category_id}>
                <Button>Category Details</Button>
              </Link>
              <Button disabled>Edit Category</Button>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/admin/swing_trainer/category_mgmt/">
                <Button>Category List</Button>
              </Link>
              <Button disabled>Add Category</Button>
            </Breadcrumbs>
          )}
          <Card sx={{ maxWidth: 550 }}>
            <CardHeader title="Category Details" />
            {loaded ? (
              <>
                <CardContent>
                  <FormControl>
                    <TextField
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      sx={{ width: 320 }}
                    />
                  </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    className="blue"
                    onClick={saveCategoryDetails}
                  >
                    Save
                  </Button>
                  <Link to="/admin/swing_trainer/category_mgmt/">
                    <Button variant="contained" className="blue">
                      Cancel
                    </Button>
                  </Link>
                </CardActions>
              </>
            ) : (
              <CardContent>
                <CircularProgress />
              </CardContent>
            )}
          </Card>
        </>
      )}
      {saved && (
        <>
          <Typography variant="h4" gutterBottom>
            Category Management
          </Typography>

          <Card sx={{ maxWidth: 550 }}>
            <CardHeader title="Changes Added" />
            <CardContent>
              <strong>Category has been saved.</strong> <br />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Link to={"/admin/swing_trainer/category_mgmt"}>
                <Button variant="contained" className="blue">
                  Go Back
                </Button>
              </Link>

              <br />
            </CardActions>
          </Card>
        </>
      )}
    </div>
  );
}
