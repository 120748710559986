import React from "react";

// CSS
import "./LessonsPage.css";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import HandyGolfAPI from "../lib/HandyGolfAPI";

// Functions
function convertSecondsToTimeText(seconds, include_seconds = false) {
  let hours = "";
  let minutes = "";
  if (seconds >= 3600) {
    hours =
      Math.floor(seconds / 3600).toString() +
      " Hour" +
      (Math.floor(seconds / 3600) > 1 ? "s" : "");
    seconds = seconds - Math.floor(seconds / 3600) * 3600;
  }

  if (seconds >= 60) {
    minutes =
      Math.floor(seconds / 60).toString() +
      " Minute" +
      (Math.floor(seconds / 60) > 1 ? "s" : "");
    seconds = seconds - Math.floor(seconds / 60) * 60;
  } else {
    minutes = "00:";
  }

  if (seconds > 0 && include_seconds) {
    return (
      hours +
      " " +
      minutes +
      " " +
      seconds.toString() +
      " Second" +
      (seconds > 1 ? "s" : "")
    ).trim();
  } else {
    return (hours + " " + minutes).trim();
  }
}

// Page Components
function LinearProgressWithLabel(props) {
  const value = props.value;
  return (
    <>
      <Typography
        variant="body2"
        className="txt-dark-pastel-green"
        color="text.secondary"
      >{`${Math.round(value)}%`}</Typography>
      <LinearProgress variant="determinate" {...props} />
    </>
  );
}
let old_lessons = [
  {
    name: "Simple Swing Sequence",
    tagline: "Amazing golf drill that will truly help you hit...",
    thumbnail: "/img/sample-course-vid-thumb.jpg",
    percent_complete: 32,
    lesson_count: 12,
    total_length: 7944,
    description:
      "This is the description of the lesson with lots of additional information going here.",
    lesson_units: [
      {
        number: "1",
        name: "Grip",
        percent_complete: 100,
        sub_units: [
          {
            number: "1.1",
            name: "Grip Overview",
          },
          {
            number: "1.2",
            name: "Wrist Hinge",
          },
          {
            number: "1.3",
            name: "Drill - Grip club in different methods",
          },
          {
            number: "1.4",
            name: "Checklist",
          },
          {
            number: "1.5",
            name: "Drill - Push down with left side to create hinge...",
          },
        ],
      },
      {
        number: "2",
        name: "Setup",
        percent_complete: 100,
        sub_units: [
          {
            number: "2.1",
            name: "Grip Overview",
          },
          {
            number: "2.2",
            name: "Wrist Hinge",
          },
          {
            number: "2.3",
            name: "Drill - Grip club in different methods",
          },
          {
            number: "2.4",
            name: "Checklist",
          },
          {
            number: "2.5",
            name: "Drill - Push down with left side to create hinge...",
          },
        ],
      },
      {
        number: "3",
        name: "Rotation/Coil",
        percent_complete: 56,
        sub_units: [
          {
            number: "1.1",
            name: "Grip Overview",
          },
          {
            number: "1.2",
            name: "Wrist Hinge",
          },
          {
            number: "1.3",
            name: "Drill - Grip club in different methods",
          },
          {
            number: "1.4",
            name: "Checklist",
          },
          {
            number: "1.5",
            name: "Drill - Push down with left side to create hinge...",
          },
        ],
      },
      {
        number: "4",
        name: "Backswing",
        percent_complete: 0,
        sub_units: [
          {
            number: "4.1",
            name: "Grip Overview",
          },
          {
            number: "4.2",
            name: "Wrist Hinge",
          },
          {
            number: "4.3",
            name: "Drill - Grip club in different methods",
          },
          {
            number: "4.4",
            name: "Checklist",
          },
          {
            number: "4.5",
            name: "Drill - Push down with left side to create hinge...",
          },
        ],
      },
    ],
  },
];
function LessonDisplay({ lesson }) {
  const [expanded_lesson_unit, setExpandedLessonUnit] = React.useState("");
  return (
    <div className="lesson">
      <div className="lesson_bg" />
      <div className="lesson_top_section">
        <div className="lesson_video">
          <div className="lesson_video_text">
            <div className="name">{lesson.name}</div>
            <hr />
            <div className="callout">{lesson.tagline}</div>
          </div>
          <img src={lesson.thumbnail} />
          <LinearProgressWithLabel
            className="greenbg"
            value={lesson.percent_complete}
          />
          <Typography variant="subtitle2" gutterBottom>
            Class Length:{" "}
            <strong>
              {lesson.lesson_count} Video Lessons (
              {convertSecondsToTimeText(lesson.total_length)})
            </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {lesson.description}
          </Typography>
          <br />
          <div className="txt_center">
            {/* @TODO I should have some sort of if statement to determine what button appears here. */}
            {/* @TODO Should I use some sort of SEO friendly name for the course? */}
            <Link
              className="button1"
              to={"/lesson_details/" + lesson.lesson_id}
            >
              Continue Lesson
            </Link>
          </div>
        </div>
        <div className="lesson_units">
          {lesson.lesson_units.map((lesson_unit, i) => {
            return (
              <div className="lesson_unit" key={i}>
                <Accordion
                  expanded={expanded_lesson_unit === "panel" + i.toString()}
                  className={
                    lesson_unit.percent_complete === 100
                      ? "completed_unit"
                      : "partial"
                  }
                  onChange={(event, newExpanded) => {
                    setExpandedLessonUnit(
                      newExpanded ? "panel" + i.toString() : ""
                    );
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"panel" + i.toString() + "-content"}
                    id={"panel" + i.toString() + "-header"}
                  >
                    {lesson_unit.percent_complete === 100 && (
                      <>
                        <CheckIcon className="completed_check" />{" "}
                        {lesson_unit.number}. {lesson_unit.name}
                      </>
                    )}
                    {lesson_unit.percent_complete < 100 &&
                      lesson_unit.percent_complete > 0 && (
                        <>
                          {lesson_unit.number}. {lesson_unit.name}
                          <div
                            className="unit_progress_border_complete"
                            style={{ width: "33%" }}
                          ></div>
                        </>
                      )}
                    {lesson_unit.percent_complete === 0 && (
                      <>
                        {lesson_unit.number}. {lesson_unit.name}
                      </>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {lesson_unit.sub_units.map((sub_unit, i) => {
                      return (
                        <div key={i}>
                          {sub_unit.number}: {sub_unit.name}
                          <br />
                        </div>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
      <div className="lesson_bottom_section"></div>
    </div>
  );
}
function LessonsDisplay() {
  const [lessons, setLessons] = React.useState([]);
  // getLessonsForList
  React.useEffect(() => {
    let mounted = true;

    HandyGolfAPI.sendRequest("lesson/getLessonsForList", {}).then((data) => {
      if (mounted) {
        setLessons(data);
        console.log(data);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return lessons.map((lesson, idx) => {
    return <LessonDisplay lesson={lesson} key={idx} />;
  });
}

function LessonsPage() {
  return (
    <div className="body_content">
      <img
        src="/img/course-list-top-background.jpg"
        className="lesson_list_top"
      />
      <div
        style={{
          color: "#ffffff",
          textAlign: "center",
          marginTop: 30,
          marginBottom: 100,
        }}
      >
        <Typography variant="subtitle1" className="blackshadow" gutterBottom>
          Pro Skills Simplified for Every Golfer
        </Typography>
        <Typography variant="h3" className="blackshadow" gutterBottom>
          Golf Made Simple
        </Typography>
        <Link className="button1" to="/">
          Subscribe Now
        </Link>
      </div>
      <div className="gray_border">
        <div className="lesson_list">
          <LessonsDisplay />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default LessonsPage;
