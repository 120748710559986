import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";

import { FileUploader } from "react-drag-drop-files";

import LinearProgress from "@mui/material/LinearProgress";

// const s3Bucket = "handygolfwebsite-images";

// const s3Region = "us-east-2";

// const sessionAccess = sessionStorage.getItem("accessKeyId");
// const sessionSecret = sessionStorage.getItem("secretAccessKey");

export function FileUpload({
  bucketInfo,
  s3Keys,
  setError,
  fileList,
  setFileList,
  setCounter,
  counter,

  filePath,
  setFilePath,
}) {
  const sessionAccess = sessionStorage.getItem("accessKeyId");
  const sessionSecret = sessionStorage.getItem("secretAccessKey");
  AWS.config.update({
    region: bucketInfo.region,
    credentials: {
      accessKeyId: sessionAccess,
      secretAccessKey: sessionSecret,
    },
  });
  const s3 = new AWS.S3({
    params: { Bucket: bucketInfo.name },
    region: bucketInfo.region,
  });

  const [file, setFile] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [duplicateLink, setDuplicateLink] = useState();

  const Upload = async () => {
    console.log(file);
    const fileExtension = file.name.split(".")[file.name.split(".").length - 1];

    const newPath = filePath.folder !== "" ? `${filePath.folder}/` : "";
    const newName =
      filePath.name !== ""
        ? `${filePath.name + "." + fileExtension}`
        : file.name;

    const newFilePath = `${newPath}${newName}`;
    console.log(newFilePath);

    for (let i = 0; i < fileList.length; i++) {
      console.log("LOOP", newFilePath);
      console.log("LOOP", fileList[i].Key);
      if (newFilePath === fileList[i].Key) {
        setDuplicateLink(
          `https://handygolfwebsite-images.s3.us-east-2.amazonaws.com/${fileList[i].Key}`
        );

        console.log(
          "TODO: show matched file below for user to review before they decide if they want to overwrite"
        );
        if (window.confirm("this will overwrite a file already in storage")) {
        } else {
          return;
        }
      }
    }
    //this will prob be a modal, but for now confirm works^^

    const params = {
      Bucket: bucketInfo.name,
      Key: newFilePath,
      Body: file,
    };

    let upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(parseInt((evt.loaded * 100) / evt.total));
        setLoading(true);
      })
      .promise();

    await upload
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.code);
        setError(err.code);
        setLoading(false);
      });
    setCounter(counter + 1);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };
  const handleChange = (newFile) => {
    console.log(newFile);
    setFile([...file, newFile[0]]);
  };
  const uploadForEach = () => {
    file.forEach((el) => {
      Upload(el);
    });
  };
  return (
    <div className="uploader__subform">
      <input type="file" onChange={handleFileChange} />

      <FileUploader handleChange={handleChange} name="file" />
      {/* add multiple in the future ^^ */}
      {file ? (
        <button onClick={Upload}>Upload</button>
      ) : (
        <button disabled>Upload</button>
      )}
      {duplicateLink && (
        <a href={duplicateLink} target="_blank">
          Will overwrite file, see original
        </a>
      )}
      {loading && <LinearProgress variant="determinate" value={progress} />}
    </div>
  );
}
