import React, { useContext, useState, useEffect } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import HandyGolfAuth from "../../lib/HandyGolfAuth";
import { useUser } from "../user_provider";
const ThemeContext = React.createContext();

export function useTheme() {
  return useContext(ThemeContext);
}
export function ThemeProvider({ children }) {
  const [user] = useUser();
  const [theme, setTheme] = useState();
  useEffect(() => {
    HandyGolfAuth.appCallback = () => {
      setDefaultTheme();
    };
    setDefaultTheme();
  }, []);

  const setDefaultTheme = () => {
    HandyGolfAuth.getAuthenticatedUser().then((data) => {
      setTheme(data.theme_preference);
    });
  };
  const setContextTheme = () => {
    setTheme(theme === 1 ? 0 : 1);
  };

  return (
    <ThemeContext.Provider value={[theme, setContextTheme]}>
      {children}
    </ThemeContext.Provider>
  );
}
