import React from "react";

export const Button = (props) => {
  let buttonClass = "button";

  // if (props.breadcrumb) {
  //   return (
  //     <button
  //       className={"button-breadcrumb"}
  //       style={props.style}
  //       type={props.type}
  //       disabled={props.disabled}
  //       onClick={props.onClick}
  //       id={props.id}
  //     >
  //       {props.iconLeading && (
  //         <span className="icon-leading material-icons">
  //           {props.iconLeading}
  //         </span>
  //       )}
  //       {props.children}
  //       {props.iconTrailing && (
  //         <span className="icon-trailing material-icons">
  //           {props.iconTrailing}
  //         </span>
  //       )}
  //     </button>
  //   );
  // }

  if (props.primary) {
    buttonClass = "button-primary";
  } else if (props.secondary) {
    buttonClass = "button-secondary";
  } else if (props.breadcrumb) {
    buttonClass = "button-breadcrumb";
  }

  if (props.fluid) {
    buttonClass += " fluid";
  }
  if (props.iconLeading) {
    buttonClass += " ss-icon-leading";
  }
  if (props.iconTrailing) {
    buttonClass += " ss-icon-trailing";
  }
  return (
    <button
      className={buttonClass}
      style={props.style}
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      id={props.id}
    >
      {props.iconLeading && (
        <span className="icon-leading material-icons">{props.iconLeading}</span>
      )}
      {props.children}
      {props.iconTrailing && (
        <span className="icon-trailing material-icons">
          {props.iconTrailing}
        </span>
      )}
    </button>
  );
};
