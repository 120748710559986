import * as React from "react";
// Materials
import { Button, Drawer, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import HandyGolf from "../lib/HandyGolf";
// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

// Google Libraries
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export function Footer() {
  return (
    <div className="footer">
      <div className="footer_content">
        <div className="footer_content_left">
          &copy; 2023 Handy Golf, All Rights Reserved.
        </div>
        <div className="footer_content_right">
          {HandyGolf.getValue("is_dev_env") ? (
            <>
              <Link to="/dev/style_guide" className="footer">
                Style Guide
              </Link>
              <Link to="/dev/mysql" className="footer">
                MySQL
              </Link>
              <Link to="/dev/debug" className="footer">
                Debug
              </Link>
            </>
          ) : (
            <></>
          )}
          <Link to="/privacy_policy" className="footer">
            Privacy Policy
          </Link>
          <Link to="/terms_of_service" className="footer">
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
}
