import React, { useState, useEffect, Children } from "react";

//mui
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

const widthSpan = 100;

export function Carousel(props) {
  const { children } = props;
  const [sliderPosition, setSliderPosition] = useState(0);
  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      nextSlideHandler();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });
  const manageInterval = () => {
    clearInterval(interval);
  };

  const prevSlideHandler = () => {
    manageInterval();
    let newPosition = sliderPosition;
    if (newPosition > 0) {
      newPosition -= 1;
    } else {
      newPosition = children.length - 1;
    }
    translateFullSlides(newPosition);
    setSliderPosition(newPosition);
  };
  const nextSlideHandler = () => {
    manageInterval();
    let newPosition = sliderPosition;
    if (newPosition < children.length - 1) {
      newPosition += 1;
    } else {
      newPosition = 0;
    }
    translateFullSlides(newPosition);
    setSliderPosition(newPosition);
  };

  const translateFullSlides = (newPosition) => {
    let toTranslate = -widthSpan * newPosition;
    for (let i = 0; i < children.length; i++) {
      let elem = document.getElementById(`carousel__element__num` + i);
      elem.style.transform = `translateX(` + toTranslate + `%)`;
    }
  };
  const feedbackDisplay = Children.map(children, (child, index) => {
    //add id as well

    return (
      <div
        className={`carousel__element
        ${index === sliderPosition ? "active" : ""}
      ${index < sliderPosition ? "prev" : ""}
      ${index > sliderPosition ? "next" : ""}
      `}
        id={`carousel__element__num` + index}
      >
        {child}
      </div>
    );
  });
  return (
    <div className="carousel">
      <div className="carousel__left">
        <WestIcon onClick={prevSlideHandler} />
      </div>
      <div className="carousel__display">{feedbackDisplay}</div>
      <div className="carousel__right">
        <EastIcon onClick={nextSlideHandler} />
      </div>
    </div>
  );
}
