import React, { createContext, useContext, useState, useCallback } from "react";
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import AWS from "aws-sdk";
const imageBucket = {
  name: HandyGolf.getValue("s3_image_bucket_name"),
  region: HandyGolf.getValue("s3_image_bucket_region"),
};
const sourceBucket = {
  name: HandyGolf.getValue("s3_source_video_bucket_name"),
  region: HandyGolf.getValue("s3_bucket_region"),
};
const transcodedBucket = {
  name: HandyGolf.getValue("s3_transcoded_video_bucket_name"),
  region: HandyGolf.getValue("s3_bucket_region"),
};
const defaultKeys = {
  accessKeyId: null,
  secretAccessKey: null,
};

const UploadContext = createContext();
export const useUpload = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [transcodeList, setTranscodeList] = useState();

  const verifyAdmin = async () => {
    try {
      const response = await HandyGolfAPI.sendRequest(
        "user/getS3ImageBucketCreds"
      );
      console.log(response);
      let accessKeyId = response.access;
      let secretAccessKey = response.secret;
      AWS.config.update({
        region: imageBucket.region,
        credentials: { accessKeyId, secretAccessKey },
      });

      const s3 = new AWS.S3({
        params: { Bucket: imageBucket.name },
        region: imageBucket.region,
      });

      const params = { Bucket: imageBucket.name };

      s3.listObjectsV2(params, (err, data) => {
        if (err) {
          console.error(err);
          //   setError(err.message || "Error fetching objects");
          //   setKeyCheckResult(false);
        } else {
          console.log(data.Contents);
          sessionStorage.setItem("accessKeyId", accessKeyId);
          sessionStorage.setItem("secretAccessKey", secretAccessKey);
          setIsVerified(true);
          //   console.log("set keys in storage 2, 62");
          //   setFileList(data.Contents);
          //   setKeyCheckResult(true);
        }
        // setIsLoading(false);
      });
    } catch (error) {
      console.error("Verification failed:", error);
    }
  };

  const createVideoRecord = async (file, type, object) => {
    let name = "tempname";
    //  type.includes("quick_fix") ? object.name : "tempname";
    // type.includes("trainer_video")
    //   ? `trainer_video_${object.trainer_video_id}`
    //   : "noname";
    let fileExtension = file.name.split(".").pop();

    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
      video.addEventListener("loadedmetadata", async () => {
        try {
          const data = await HandyGolfAPI.sendRequest("video/create", {
            video: { name: name, video_length: video.duration },
          });

          objectUpdateConverter(type, object, data.data.video_id);
          let newName = videoNameCreator(type, data.data.video_id, object);
          await HandyGolfAPI.sendRequest("video/update", {
            video_id: data.data.video_id,
            video: {
              name: newName,
              transcode_source_filename: `video_${data.data.video_id}.${fileExtension}`,
            },
          });

          resolve(data.data.video_id);
        } catch (error) {
          console.error("Failed to create video record:", error);
          reject(error);
        }
      });
    });
  };

  const videoNameCreator = (objectType, videoId, object) => {
    if (objectType === "trainer_video") {
      return `v${videoId}_tv${object.trainer_video_id}`;
    }
    if (objectType === "lesson_unit_video") {
      return `v${videoId}_lu${object.lesson_unit_id}`;
    }
    if (objectType === "trainer_program_video") {
      return `v${videoId}_p${object.trainer_program_id}`;
    }
    if (objectType === "fix_fault_video") {
      return `v${videoId}_ff${object.fix_fault_id}`;
    }
    if (objectType === "fix_cause_video") {
      return `v${videoId}_fc${object.fix_cause_id}`;
    }
  };
  const updateUploadProgress = (id, progress) => {
    setUploads((prev) =>
      prev.map((upload) =>
        upload.id === id ? { ...upload, progress } : upload
      )
    );
  };

  const markUploadComplete = (id) => {
    setUploads((prev) =>
      prev.map((upload) =>
        upload.id === id ? { ...upload, completed: true } : upload
      )
    );
  };

  const uploadSingle = async (
    file,
    videoId,
    uploadId,
    accessKeyId,
    secretAccessKey
  ) => {
    let fileExtension = file.name.split(".")[file.name.split(".").length - 1];
    console.log(videoId);
    let fileKey = `video_${videoId}.${fileExtension}`;
    AWS.config.update({
      region: sourceBucket.region,
      credentials: { accessKeyId, secretAccessKey },
    });
    const s3 = new AWS.S3({
      params: { Bucket: sourceBucket.name },
      region: sourceBucket.region,
    });
    const params = {
      Bucket: sourceBucket.name,
      Key: fileKey,
      Body: file,
    };

    try {
      await s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          const progress = Math.round((evt.loaded / evt.total) * 100);
          updateUploadProgress(uploadId, progress);
        })
        .promise();

      markUploadComplete(uploadId);
    } catch (error) {
      console.error("Error in single-part upload:", error);
    }
  };

  const uploadParts = async (
    file,
    videoId,
    uploadId,
    accessKeyId,
    secretAccessKey
  ) => {
    let fileExtension = file.name.split(".")[file.name.split(".").length - 1];
    let fileKey = `video_${videoId}.${fileExtension}`;
    AWS.config.update({
      region: sourceBucket.region,
      credentials: { accessKeyId, secretAccessKey },
    });
    const s3 = new AWS.S3({
      params: { Bucket: sourceBucket.name },
      region: sourceBucket.region,
    });
    const partSize = 5 * 1024 * 1024;
    const numParts = Math.ceil(file.size / partSize);
    let uploadedBytes = 0;
    const params = {
      Bucket: sourceBucket.name,
      Key: fileKey,
    };
    let multipart;
    try {
      multipart = await s3.createMultipartUpload(params).promise();
      const uploadPromises = Array.from(
        { length: numParts },
        async (_, index) => {
          const start = index * partSize;
          const end = Math.min(start + partSize, file.size);
          const partParams = {
            Bucket: params.Bucket,
            Key: params.Key,
            PartNumber: index + 1,
            UploadId: multipart.UploadId,
            Body: file.slice(start, end),
          };

          const part = await s3.uploadPart(partParams).promise();
          uploadedBytes += end - start;

          const progress = Math.round((uploadedBytes / file.size) * 100);
          updateUploadProgress(uploadId, progress);

          return { PartNumber: index + 1, ETag: part.ETag };
        }
      );

      const completedParts = await Promise.all(uploadPromises);

      await s3
        .completeMultipartUpload({
          Bucket: params.Bucket,
          Key: params.Key,
          UploadId: multipart.UploadId,
          MultipartUpload: { Parts: completedParts },
        })
        .promise();

      updateUploadProgress(uploadId, 100);
      markUploadComplete(uploadId);
    } catch (error) {
      console.error("Error in multipart upload:", error);

      if (multipart?.UploadId) {
        await s3
          .abortMultipartUpload({
            Bucket: params.Bucket,
            Key: params.Key,
            UploadId: multipart.UploadId,
          })
          .promise();
      }
    }
  };

  const uploadVideo = async (file, type, object) => {
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    if (!accessKeyId || !secretAccessKey) {
      // setError("NO CREDENTIALS");
      // setKeyCheckResult(false);
      console.log("hey there are no keys and nothing is being done about it");
      // setIsLoading(false);
      return;
    }
    // if (type.includes("trainer_video")) {
    const videoId = await createVideoRecord(file, type, object);
    const uploadId = Date.now();
    const newUpload = {
      id: uploadId,
      videoId,
      file,
      type: type,
      object: object,
      progress: 0,
      completed: false,
    };

    setUploads((prev) => [...prev, newUpload]);

    if (file.size < 100 * 1024 * 1024) {
      console.log("going to single");
      await uploadSingle(file, videoId, uploadId, accessKeyId, secretAccessKey);
    } else {
      console.log("going to parts");
      await uploadParts(file, videoId, uploadId, accessKeyId, secretAccessKey);
    }
    // }
  };

  const objectUpdateConverter = (type, object, videoId) => {
    if (type.includes("trainer_video")) {
      HandyGolfAPI.sendRequest("trainer/video/update", {
        trainer_video_id: object.trainer_video_id,
        trainer_video: { video_id: videoId },
      }).then((dataDos) => {
        console.log("updatedvideo", dataDos);
      });
    }
    if (type.includes("lesson_unit_video")) {
      HandyGolfAPI.sendRequest("lesson/lesson_unit/update", {
        lesson_unit_id: object.lesson_unit_id,
        lesson_unit: { video_id: videoId },
      }).then((dataDos) => {
        console.log("updatedvideo", dataDos);
      });
    }
    if (type.includes("trainer_program_video")) {
      HandyGolfAPI.sendRequest("trainer/program/update", {
        trainer_program_id: object.trainer_program_id,
        trainer_program: { video_id: videoId },
      }).then((dataDos) => {
        console.log("updatedvideo", dataDos);
      });
    }
    if (type.includes("fix_fault_video")) {
      HandyGolfAPI.sendRequest("fix/fault/update", {
        fix_fault_id: object.fix_fault_id,
        fix_fault: { video_id: videoId },
      }).then((dataDos) => {
        console.log("updatedvideo", dataDos);
      });
    }
    if (type.includes("fix_cause_video")) {
      HandyGolfAPI.sendRequest("fix/cause/update", {
        fix_cause_id: object.fix_cause_id,
        fix_cause: { video_id: videoId },
      }).then((dataDos) => {
        console.log("updatedvideo", dataDos);
      });
    }
    if (type.includes("quick_fix_video")) {
      HandyGolfAPI.sendRequest("fix/fault/addQuickfixVideoId", {
        fix_fault_id: object.fix_fault_id,
        video_id: videoId,
      });
    }
    //still to do quick fixes
  };
  const getTranscodeList = async () => {
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    AWS.config.update({
      region: transcodedBucket.region,
      credentials: { accessKeyId, secretAccessKey },
    });

    const params = {
      Bucket: transcodedBucket.name,
      MaxKeys: 1000,
    };

    const s3 = new AWS.S3({
      params,
      region: transcodedBucket.region,
    });

    let isTruncated = true;
    let continuationToken = null;
    const allObjects = [];

    try {
      while (isTruncated) {
        const response = await s3
          .listObjectsV2({
            ...params,
            ContinuationToken: continuationToken,
          })
          .promise();

        allObjects.push(...response.Contents);

        isTruncated = response.IsTruncated;
        continuationToken = response.NextContinuationToken;
      }

      console.log(allObjects);
      setTranscodeList(allObjects);
    } catch (err) {
      console.error("Error retrieving object list:", err);
    }
  };
  const cleanupAll = (files, triggerLoad) => {
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    AWS.config.update({
      region: sourceBucket.region,
      credentials: { accessKeyId, secretAccessKey },
    });

    files.forEach((file) => {
      const params = {
        Bucket: sourceBucket.name,
        Key: file.transcode_source_filename,
        // ,Prefix: s3Directory // we dont need directory because we upload source straight to the main dir.
      };

      const s3 = new AWS.S3({
        params: params,
        region: sourceBucket.region,
      });
      if (file.video_url !== "") {
        try {
          s3.deleteObject(params).promise();
          HandyGolfAPI.sendRequest("video/update", {
            video_id: file.video_id,
            video: { transcode_source_filename: "" },
          });
          console.log("Object deleted successfully");
          triggerLoad();
        } catch (error) {
          console.error("Error deleting object:", error);
        }
      }
    });
  };

  return (
    <UploadContext.Provider
      value={{
        isVerified,
        verifyAdmin,
        uploads,
        uploadVideo,
        getTranscodeList,
        transcodeList,
        cleanupAll,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};
