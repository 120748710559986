import React from 'react';

// Components
import PageMetaData from '../components/PageMetaData'

// MUI
import Typography from "@mui/material/Typography";
import HandyGolf from "../lib/HandyGolf";

function StorePage() {
    React.useEffect(() => {

    }, [])
    return (
        <PageMetaData
            title="Store"
            seo="keywords for seo stuff"
        >
            <div className="body_content">
                <Typography variant="h3" gutterBottom>Store</Typography>
            </div>
        </PageMetaData>
    );
}

export default StorePage;
