import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// CSS
import "../../LessonsPage.css";

// Libraries
import HandyGolfAPI from "../../../lib/HandyGolfAPI";

// Components
import LessonMgmt from "../../../components/LessonMgmt";
import PageMetaData from "../../../components/PageMetaData";
import { UnitSection, VideoSection, NoteSection } from "./sub";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Rating from "@mui/material/Rating";
import StickyNote2SharpIcon from "@mui/icons-material/StickyNote2Sharp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Video Library
import ReactPlayer from "react-player";

// Navigation
import { useParams, Navigate, Link, json } from "react-router-dom";

// Other
import nl2br from "react-nl2br";
//POINT OF NO RETURN
export function LessonDisplay({ lesson_id }) {
  const [lesson, setLesson] = useState({});
  const [current_lesson_unit, setCurrentLessonUnit] = useState({});
  const [expanded_lesson_unit, setExpandedLessonUnit] = useState();
  const [feedback_display, setFeedbackDisplay] = useState(false);
  const [feedback_rating, setFeedbackRating] = useState(0);
  const [feedback_text, setFeedbackText] = useState("");
  const [feedback_timeout, setFeedbackTimeout] = useState(null);
  const [loaded_lesson_unit_id, setLoadedLessonUnitId] = useState(0);
  const [note_text, setNoteText] = useState("");
  const [note_time_checked, setNoteTimeChecked] = useState(true);
  const [selected_tab, setSelectedTab] = useState(0);
  const [user_lesson_data, setUserLessonData] = useState({});
  const [video_levels, setVideoLevels] = useState([]);
  const [video_playing, setVideoPlaying] = useState(false);
  const [video_time, setVideoTime] = useState(0);

  // Refs
  const videoRef = React.useRef(null);
  let navigate = useNavigate();

  async function stall(stallTime = 3000) {
    await new Promise((resolve) => setTimeout(resolve, stallTime));
  }
  const loadLessonUnitData = async (lesson_unit_id = null) => {
    let payload = {
      lesson_id: lesson_id,
    };
    console.log("payload", payload);
    if (lesson_unit_id !== null) {
      payload.lesson_unit_id = lesson_unit_id;
    }

    let localLesson = localStorage.getItem("lesson");
    let localCurrentLessonUnit = localStorage.getItem("current_lesson_unit");
    let localUserLessonData = localStorage.getItem("user_lesson_data");
    if (localLesson && localCurrentLessonUnit && localUserLessonData) {
      console.log("the gangs all here");
    }

    await stall();
    console.log("payload", payload);
    await HandyGolfAPI.sendRequest(
      "lesson/getLessonUnitDetailsForUser",
      payload
    ).then((data) => {
      console.log(data);
      localStorage.setItem(
        "current_lesson_unit",
        JSON.stringify(data.current_lesson_unit)
      );
      localStorage.setItem(
        "user_lesson_data",
        JSON.stringify(data.user_lesson_data)
      );

      console.log("checked", data);
      if (data.current_lesson_unit) {
        if (loaded_lesson_unit_id !== data.current_lesson_unit.lesson_unit_id) {
          // Lesson Unit Changed... Reset Page
          if (data.current_lesson_unit.parent_lesson_unit_number) {
            setExpandedLessonUnit(
              "panel" + data.current_lesson_unit.parent_lesson_unit_number
            );
          }
          setNoteText("");
          setFeedbackRating(data.current_lesson_unit.rating);
          setFeedbackText(data.current_lesson_unit.feedback_text);
          setFeedbackDisplay(false);
          // Reset Video
          setVideoPlaying(false);
          if (videoRef && videoRef.current) {
            videoRef.current.seekTo(0, "seconds");
          }
          setLoadedLessonUnitId(data.current_lesson_unit.lesson_unit_id);
        }
        setCurrentLessonUnit(data.current_lesson_unit);
        setUserLessonData(data.user_lesson_data);
      }
    });
  };

  //check local storage
  const isLocalStorageEnabled = () => {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, null);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };
  // getLessonDetailsForUser
  // const [lessons, setLessons] = useState([]);
  React.useEffect(() => {
    let mounted = true;

    HandyGolfAPI.sendRequest("lesson/getLastUpdatedOn", {
      lesson_id: lesson_id,
    }).then((data) => {
      console.log(data);
      console.log("right now!", Math.floor(Date.now() / 1000));
      console.log("last refresh!", data[0].last_updated_on);
      console.log(
        "difference!",
        Math.floor(Date.now() / 1000) - data[0].last_updated_on
      );
      console.log(
        Math.floor(Date.now() / 1000) - 86400 < data[0].last_updated_on
      );
      let nowNow = data[0].last_updated_on;
      if (Math.floor(Date.now() / 1000) - 86400 > data[0].last_updated_on) {
        localStorage.removeItem("lesson");
        localStorage.removeItem("current_lesson_unit");
        localStorage.removeItem("user_lesson_data");
        console.log("hey its been 24 hours since you were here");
      } else {
        console.log(
          "last refresh was",
          (Math.floor(Date.now() / 1000) - data[0].last_updated_on) / 60,
          "minutes ago"
        );
      }
    });

    let localLesson = JSON.parse(localStorage.getItem("lesson"));
    let localCurrentLessonUnit = JSON.parse(
      localStorage.getItem("current_lesson_unit")
    );
    let localUserLessonData = JSON.parse(
      localStorage.getItem("user_lesson_data")
    );

    if (
      localLesson &&
      localCurrentLessonUnit &&
      localUserLessonData &&
      JSON.stringify(localLesson.lesson_id) === lesson_id
    ) {
      console.log("the gangs all here");

      setLesson(localLesson);
      console.log("Lesson check", localLesson);
      setCurrentLessonUnit(localCurrentLessonUnit);
      setUserLessonData(localUserLessonData);
      setExpandedLessonUnit(
        "panel" + localCurrentLessonUnit.parent_lesson_unit_number
      );
      setNoteText("");
      setFeedbackText(localCurrentLessonUnit.feedback_text);
      setFeedbackDisplay(false);
      setVideoPlaying(false);
      if (videoRef && videoRef.current) {
        videoRef.current.seekTo(0, "seconds");
      }
      setLoadedLessonUnitId(localCurrentLessonUnit.lesson_unit_id);
    } else {
      console.log("YOU WENT TO THE ELSE");
      HandyGolfAPI.sendRequest("lesson/getLessonDetailsForUser", {
        lesson_id: lesson_id,
      }).then((data) => {
        if (mounted) {
          setLesson(data);

          localStorage.setItem("lesson", JSON.stringify(data));
          loadLessonUnitData();
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  const LinearProgressWithLabel = (props) => {
    const value = props.value;
    return (
      <>
        <Typography
          variant="body2"
          className="txt-dark-pastel-green"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
        <LinearProgress variant="determinate" {...props} />
      </>
    );
  };

  const saveFeedback = (new_rating, new_text) => {
    // Build Payload
    let payload = {
      lesson_id: lesson.lesson_id,
      lesson_unit_id: current_lesson_unit.lesson_unit_id,
      rating: new_rating !== null ? new_rating : feedback_rating,
      feedback_text:
        new_text !== null && new_text !== undefined
          ? new_text.trim()
          : feedback_text.trim(),
    };

    // Set Timeout
    clearTimeout(feedback_timeout);
    setFeedbackTimeout(
      setTimeout(() => {
        HandyGolfAPI.sendRequest(
          "lesson/saveUserLessonUnitFeedback",
          payload
        ).then((data) => {
          // Do Nothing on Success
        });
      }, 1000)
    );

    // Set States
    if (new_rating !== null) {
      setFeedbackRating(new_rating);
    }
    if (new_text !== null && new_text !== undefined) {
      setFeedbackText(new_text);
    }
  };

  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };

  console.log("lesson", lesson);
  console.log("lessonid", lesson.lesson_id);
  console.log("current l unit", current_lesson_unit);
  console.log("current l unit id", current_lesson_unit.lesson_unit_id);
  return (
    <>
      {!(
        lesson &&
        lesson.lesson_id > 0 &&
        current_lesson_unit &&
        current_lesson_unit.lesson_unit_id
      ) && <CircularProgress />}
      {lesson &&
        lesson.lesson_id > 0 &&
        current_lesson_unit &&
        current_lesson_unit.lesson_unit_id && (
          <div className="lesson">
            <div className="lesson_progress_title">
              <Button
                className="lesson_title"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  navigate("/lessons");
                }}
              >
                {lesson.name}
              </Button>
              <LinearProgress
                variant="determinate"
                className="whitebg"
                value={parseInt(user_lesson_data.percent_complete)}
              />
            </div>
            <div className="lesson_top_section">
              <div className="lesson_video">
                <VideoSection
                  lesson={lesson}
                  current_lesson_unit={current_lesson_unit}
                  loadLessonUnitData={loadLessonUnitData}
                  videoRef={videoRef}
                  video_playing={video_playing}
                  setVideoPlaying={setVideoPlaying}
                  video_levels={video_levels}
                  setVideoLevels={setVideoLevels}
                  feedback_display={feedback_display}
                  setFeedbackDisplay={setFeedbackDisplay}
                  setVideoTime={setVideoTime}
                  saveFeedback={saveFeedback}
                  feedback_rating={feedback_rating}
                  feedback_text={feedback_text}
                />

                {/* @TODO Remove this code once you confirm it is not needed. */}
                {/*{ user_lesson_data && user_lesson_data.sub_unit_notes_and_completion && user_lesson_data.sub_unit_notes_and_completion[current_lesson_unit.lesson_unit_id] && user_lesson_data.sub_unit_notes_and_completion[current_lesson_unit.lesson_unit_id].unit_completed ? (*/}
                {/*    <Button*/}
                {/*        variant="contained"*/}
                {/*        onClick={() => {*/}
                {/*            HandyGolfAPI.sendRequest(*/}
                {/*                "lesson/markLessonUnitAsIncomplete",*/}
                {/*                { lesson_id: lesson.lesson_id, lesson_unit_id: current_lesson_unit.lesson_unit_id }*/}
                {/*            )*/}
                {/*                .then(data => {*/}
                {/*                    loadLessonUnitData(current_lesson_unit.lesson_unit_id)*/}
                {/*                });*/}
                {/*        }}*/}
                {/*    >Mark as Incomplete</Button>*/}
                {/*) : (*/}
                {/*    <Button*/}
                {/*        variant="contained"*/}
                {/*        onClick={() => {*/}
                {/*            HandyGolfAPI.sendRequest(*/}
                {/*                "lesson/markLessonUnitAsComplete",*/}
                {/*                { lesson_id: lesson.lesson_id, lesson_unit_id: current_lesson_unit.lesson_unit_id }*/}
                {/*            )*/}
                {/*                .then(data => {*/}
                {/*                    loadLessonUnitData(current_lesson_unit.lesson_unit_id)*/}
                {/*                });*/}
                {/*        }}*/}
                {/*    >Mark as Complete</Button>*/}
                {/*) }*/}
                <NoteSection
                  lesson={lesson}
                  current_lesson_unit={current_lesson_unit}
                  loadLessonUnitData={loadLessonUnitData}
                  selected_tab={selected_tab}
                  setSelectedTab={setSelectedTab}
                  note_time_checked={note_time_checked}
                  setNoteTimeChecked={setNoteTimeChecked}
                  convertSecondsToTimeText={convertSecondsToTimeText}
                  video_time={video_time}
                  note_text={note_text}
                  setNoteText={setNoteText}
                  videoRef={videoRef}
                  video_playing={video_playing}
                />
              </div>
              <UnitSection
                lesson={lesson}
                expanded_lesson_unit={expanded_lesson_unit}
                user_lesson_data={user_lesson_data}
                current_lesson_unit={current_lesson_unit}
                setExpandedLessonUnit={setExpandedLessonUnit}
                loadLessonUnitData={loadLessonUnitData}
              />
            </div>
            <div className="lesson_bottom_section"></div>
          </div>
        )}
    </>
  );
}
