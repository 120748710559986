import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export function ContinueWatching({ videos, type, favorites }) {
  let navigate = useNavigate();
  // console.log(favorites);
  // console.log("videosrecent:", videos);
  let favoriteIds = [];
  if (!videos) {
    return <h4>loading...</h4>;
  }
  for (let i = 0; i < favorites.length; i++) {
    favoriteIds.push(favorites[i].trainer_video_id);
  }
  // console.log(favoriteIds);
  function LinearProgressWithLabel(props) {
    const value = props.value;
    return (
      <>
        <Typography
          variant="body2"
          className="txt-dark-pastel-green"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
        <LinearProgress variant="determinate" {...props} />
      </>
    );
  }

  return (
    <div className="continue-watching">
      <h1>Continue Where You Left Off</h1>
      <div className="continue-watching__scroll">
        {videos.map((video, idx) => {
          return (
            <div
              className="continue-watching__video card2"
              key={idx}
              onClick={() => {
                navigate("/swing_trainer/" + video.trainer_video_id);
              }}
            >
              {favoriteIds.includes(video.trainer_video_id) ? (
                <button>fav</button>
              ) : (
                <></>
              )}
              <div>
                <img src={video.thumbnail_url}></img>
                {/* <LinearProgressWithLabel
                className="greenbg"
                value={video.video_viewed_until_seconds}
              /> */}
                <LinearProgress
                  variant="determinate"
                  value={video.video_viewed_until_seconds}
                  className="greenbg"
                />
              </div>

              <div className="continue-watching__video continue-watching__video--info">
                <h5>{video.name}</h5>
                <p>{video.description}</p>
                {/* <Link
                  className="button1"
                  to={"/swing_trainer/" + video.trainer_video_id}
                >
                  Watch Training
                </Link> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
