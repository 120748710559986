import React, { useEffect, useState, useContext } from "react";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  AppTopBar,
  BottomNav,
  ResultsSlider,
  Button,
} from "../components";
import {
  StateContext,
  StateDispatchContext,
  CheckCacheContext,
} from "../context/AppContext";

export function TrainerVideoSearch() {
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkCache = useContext(CheckCacheContext);

  let navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const currentType = currentPath === "/trainer/drills" ? 1 : 2;
  const typeString = currentType === 1 ? "DRILL" : "GUIDED_TRAINING";
  //   const [currentType, setCurrentType] = useState();
  const [categorySelected, setCategorySelected] = useState();
  // const [categories, setCategories] = useState();
  const [childCategories, setChildCategories] = useState();
  const [videoListold, setVideoList] = useState();
  // const [recommendedIds, setRecommendedIds] = useState();
  // const [favoriteIds, setFavoriteIds] = useState();
  // const [watchlistIds, setWatchlistIds] = useState();
  // const [recentIds, setRecentIds] = useState();
  const [searchString, setSearchString] = useState("");
  const [searchStringArray, setSearchStringArray] = useState([]);
  const [searchFilterVideos, setSearchFilterVideos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const isLocalStorageEnabled = () => {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, null);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    // console.log("1st console");
    checkCache();
    console.log("2st console");
    if (!isLoading) {
      // console.log("3rdlog");
      childCategoryHandler();

      filterUpdate();
    }
  }, [searchResults, categorySelected, typeString]);
  const isLoading =
    state.loading.DRILL_trainer_videos ||
    state.loading.DRILL_user_video_ids ||
    state.loading.DRILL_categories ||
    state.loading.GUIDED_TRAINING_trainer_videos ||
    state.loading.GUIDED_TRAINING_user_video_ids ||
    state.loading.GUIDED_TRAINING_categories;

  if (isLoading) {
    return <>loading.....</>;
  }

  let videoList =
    typeString === "DRILL"
      ? state.DRILL_trainer_videos.trainer_videos
      : state.GUIDED_TRAINING_trainer_videos.trainer_videos;

  let categories =
    typeString === "DRILL"
      ? state.DRILL_categories.categories
      : state.DRILL_categories.categories;
  let recommendedIds =
    typeString === "DRILL"
      ? state.DRILL_user_video_ids.recommended_video_ids
      : state.GUIDED_TRAINING_user_video_ids.recommended_video_ids;
  let favoriteIds =
    typeString === "DRILL"
      ? state.DRILL_user_video_ids.favorite_video_ids
      : state.GUIDED_TRAINING_user_video_ids.favorite_video_ids;
  let watchlistIds =
    typeString === "DRILL"
      ? state.DRILL_user_video_ids.watchlist_video_ids
      : state.GUIDED_TRAINING_user_video_ids.watchlist_video_ids;
  let recentIds =
    typeString === "DRILL"
      ? state.DRILL_user_video_ids.recent_video_ids
      : state.GUIDED_TRAINING_user_video_ids.recent_video_ids;

  const searchStringCheck = (title, catNames, equipNames, searchTerm) => {
    let titleSplit = title.split(" ");
    // let allSearchTerms = titleSplit.concat(catNames, equipNames);
    let allSearchTerms = titleSplit;
    for (let i = 0; i < allSearchTerms.length; i++) {
      if (allSearchTerms[i].includes(searchTerm)) {
        return true;
      }
    }
    return false;
  };

  const searchResultSetter = () => {
    // console.log(searchResults);

    let splitStringArray = searchString.split(" ");
    let properCapsStringArray = splitStringArray.map(
      (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    );

    if (properCapsStringArray.length > 0) {
      properCapsStringArray = properCapsStringArray.filter(
        (term) => term.length > 1
      );
      let mergedArray = [];

      properCapsStringArray.forEach((term) => {
        const singleSearchTermVideoArray = videoList.filter((video) =>
          // video.trainer_category_names.includes(term)
          searchStringCheck(
            video.name,
            video.trainer_category_names,
            video.trainer_equipment_names,
            term
          )
        );

        mergedArray = [...mergedArray, ...singleSearchTermVideoArray];
        // setSearchResults(...searchResults, ...singleSearchTermVideoArray);
      });
      const uniqueArrayNoDuplicates = [...new Set(mergedArray)];

      setSearchResults(uniqueArrayNoDuplicates);
    }
  };
  const filterUpdate = (idx, array) => {
    console.log(searchFilterVideos);
    setSearchFilterVideos([]);
    // console.log(categorySelected);
    if (categorySelected || categorySelected === 0) {
      let newSubCategories = categories[categorySelected].child_categories;

      if (searchResults.length === 0) {
        newSubCategories.forEach((category) => {
          let newArray = videoList.filter((video) =>
            video.trainer_category_ids.includes(category.trainer_category_id)
          );
          setSearchFilterVideos((prev) => [...prev, newArray]);
        });
      } else {
        newSubCategories.forEach((category) => {
          let newArray = searchResults.filter((video) =>
            video.trainer_category_ids.includes(category.trainer_category_id)
          );
          setSearchFilterVideos((prev) => [...prev, newArray]);
        });
      }
    }
    // setLoading(false);
  };
  // console.log("RECOMMENDED IDS CHECK", recommendedIds);
  const userVideoIdFilter = (idArray) => {
    // if (categorySelected) {
    // console.log(categories[categorySelected]);
    // }
    if (searchResults.length === 0 && videoList) {
      let newArray = videoList.filter(
        (video) => idArray.includes(video.trainer_video_id)
        // videoIdFilterCheck(idArray, video)
      );
      // console.log(newArray);
      return newArray;
    } else {
      let newArray = searchResults.filter((video) =>
        idArray.includes(video.trainer_video_id)
      );
      return newArray;
    }
  };

  const childCategoryHandler = (idx) => {
    // console.log(idx);
    // console.log(categories);
    // console.log("category selected:", categorySelected);

    if (categorySelected || categorySelected === 0) {
      setChildCategories(categories[categorySelected].child_categories);
    } else {
      setChildCategories();
    }
  };

  // if (loading) {
  //   return <></>;
  // }
  // console.log(categories);

  let searchTimer = null;

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
    // if (searchString.length > 1) {
    if (searchTimer == null) {
      searchTimer = setTimeout(() => {
        searchResultSetter();
        // console.log("searched times");
      }, 300);
    }
    // }
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    clearTimeout(searchTimer);
    if (searchString === "") {
      setSearchResults(videoList);
    } else {
      searchResultSetter();
    }
  };

  return (
    <>
      <AppTopBar
        search
        filter
        breadcrumb
        handleSearchChange={handleSearchChange}
        onSearchSubmit={onSearchSubmit}
      />
      <div className="container">
        <div className="container__content">
          <div className="pad-box scroll-content-mobile">
            {/* {!loading && (
              <> */}
            {categories && (
              <div className="button-slider">
                {categories.map((category, idx) => {
                  return (
                    <Button
                      key={idx}
                      primary
                      style={
                        categorySelected !== idx
                          ? {
                              background: "transparent",
                              border: "1px solid grey",
                            }
                          : {}
                      }
                      // onClick={() => {
                      //   setNoteText("");

                      //   setIsNoteFocused(false);
                      // }}
                      onClick={() => {
                        // setSearchFilterVideos([]);
                        if (idx === categorySelected) {
                          setCategorySelected();
                          // childCategoryHandler(-1);
                        } else {
                          // console.log(idx);
                          setCategorySelected(idx);
                          // childCategoryHandler(idx);
                          filterUpdate(idx, category.child_categories);
                        }
                      }}
                    >
                      {category.name}
                    </Button>
                  );
                })}
              </div>
            )}

            {searchResults && searchString.length > 0 && (
              <div className="results-slider">
                <strong>
                  <p>
                    {searchResults.length +
                      " Search Results for: " +
                      searchString}
                  </p>
                </strong>
                <ResultsSlider
                  videos={searchResults}
                  // sectionName={
                  //   searchResults.length + " Search Results for: " + searchString
                  // }
                />
              </div>
            )}
            {!categorySelected && categorySelected !== 0 && (
              <>
                {recommendedIds &&
                  userVideoIdFilter(recommendedIds).length > 0 && (
                    <div className="results-slider">
                      <strong>
                        <p>
                          {/* {searchResults.length +
                      " Search Results for: " +
                      searchString} */}
                          Recommended
                        </p>
                      </strong>
                      <ResultsSlider
                        videos={userVideoIdFilter(recommendedIds)}
                        // sectionName={
                        //   searchResults.length + " Search Results for: " + searchString
                        // }
                      />
                    </div>
                  )}
                {favoriteIds && userVideoIdFilter(favoriteIds).length > 0 && (
                  <div className="results-slider">
                    <strong>
                      <p>
                        {/* {searchResults.length +
                      " Search Results for: " +
                      searchString} */}
                        Favorites
                      </p>
                    </strong>
                    <ResultsSlider
                      videos={userVideoIdFilter(favoriteIds)}
                      // sectionName={
                      //   searchResults.length + " Search Results for: " + searchString
                      // }
                    />
                  </div>
                )}
                {watchlistIds && userVideoIdFilter(watchlistIds).length > 0 && (
                  <div className="results-slider">
                    <strong>
                      <p>
                        {/* {searchResults.length +
                      " Search Results for: " +
                      searchString} */}
                        Your Watch List
                      </p>
                    </strong>
                    <ResultsSlider
                      videos={userVideoIdFilter(watchlistIds)}
                      // sectionName={
                      //   searchResults.length + " Search Results for: " + searchString
                      // }
                    />
                  </div>
                )}
                {recentIds && userVideoIdFilter(recentIds).length > 0 && (
                  <div className="results-slider">
                    <strong>
                      <p>
                        {/* {searchResults.length +
                      " Search Results for: " +
                      searchString} */}
                        Recently Watched
                      </p>
                    </strong>
                    <ResultsSlider
                      videos={userVideoIdFilter(recentIds)}
                      // sectionName={
                      //   searchResults.length + " Search Results for: " + searchString
                      // }
                    />
                  </div>
                )}
              </>
            )}

            {
              // categorySelected > -1 &&
              //   categorySelected < 7 &&
              childCategories && (
                // !loading &&
                // searchFilterVideos.length !== 0
                //  &&
                <>
                  {childCategories.map((childCategory, idx) => {
                    // console.log(searchFilterVideos);
                    if (searchFilterVideos[idx].length !== 0) {
                      return (
                        <div className="results-slider" key={idx}>
                          <strong>
                            <p>{childCategory.name}</p>
                          </strong>
                          <ResultsSlider
                            // key={idx}
                            videos={searchFilterVideos[idx]}
                            // sectionName={
                            //   childCategory.name

                            // }
                          />
                        </div>
                      );
                    }
                  })}
                </>
              )
            }
          </div>
        </div>
        <div className="container__collapse"></div>
      </div>
      <BottomNav />
    </>
  );
}
