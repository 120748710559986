import React, { useState, useEffect } from "react";

// Handy Golf Libs
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAuth from "../lib/HandyGolfAuth";

// Materials
// import { Button, Drawer, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Popover from "@mui/material/Popover";

import { Button, Search } from "../components";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";

// Google Libraries
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export function AppTopBar({
  path,
  pageTitle,
  search,
  addTo,
  favorite,
  share,
  breadcrumb,
  filter,
  back,
  // searchString,
  // setSearchString,
  handleSearchChange,
  onSearchSubmit,
  trainer_video_id,
  favoriteIds,
  watchlistIds,
  handleFavorite,
  handleWatchlist,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // console.log(favoriteIds, trainer_video_id);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let navigate = useNavigate();

  return (
    <div className="navigation__top-app__wrapper">
      <div className="navigation__top-app">
        <div className="navigation__top-app__middle">
          <div></div>
          {breadcrumb && (
            <span
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                navigate(path);
              }}
            >
              <img src="/img/chevron-left.svg" />
            </span>
          )}
          {pageTitle && (
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
              }}
            >
              <strong>{pageTitle}</strong>
            </div>
          )}

          {search && (
            <div className="navigation__top-app__middle--search">
              {search && (
                <Search
                  // searchString={searchString}
                  // setSearchString={setSearchString}
                  handleSearchChange={handleSearchChange}
                  onSearchSubmit={onSearchSubmit}
                />
              )}
              {/* {filter && (
                <span>
                  <img src="/img/fluent_filter_green_icon.svg" />
                </span>
              )} */}
            </div>
          )}

          <div
            className="top-nav-temp-middle-right"
            // className="navigation__top-app__middle__right"
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            {favorite && (
              <span
                onClick={() => {
                  handleFavorite();
                }}
              >
                {favoriteIds.includes(Number(trainer_video_id)) ? (
                  <img src="/img/star-gold-icon.svg" />
                ) : (
                  <img src="/img/star-outline-icon.svg" />
                )}
              </span>
            )}
            {addTo && (
              <span
                onClick={() => {
                  handleWatchlist();
                }}
              >
                {watchlistIds.includes(Number(trainer_video_id)) ? (
                  <img src="/img/added_icon.svg" />
                ) : (
                  <img src="/img/add_to_icon.svg" />
                )}
              </span>
            )}

            <span>
              <i className="material-icons">help_outline</i>
            </span>
          </div>
        </div>

        <div className="navigation__top-app__right">
          <div
            style={{ display: "flex", gap: "7px", cursor: "pointer" }}
            onClick={handleClick}
          >
            <span>
              <img src="/img/user-circle-icon.svg" />
            </span>
            <span>username</span>

            <span>
              <i className="material-icons">arrow_drop_down</i>
            </span>
          </div>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          // sx={{"& .MuiPopover-paper": {borderRadius: '5px'}}
          sx={{
            "& .MuiPopover-paper": {
              borderRadius: "5px",
              backgroundColor: "#000509",
              marginTop: "10px",
            },
          }}
        >
          {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
          <div className="navigation__top-app__popover-content">
            <div style={{ borderBottom: "1px solid rgb(36 36 38)" }}>
              <div
                style={{
                  padding: "18px 22px",
                  cursor: "pointer",
                  dipslay: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/user/profile");
                }}
              >
                <span style={{ paddingRight: "23px" }}>
                  <img src="/img/manage_account_icon.svg" />
                </span>
                <span>Manage Account</span>
              </div>
              <div
                style={{
                  padding: "18px 22px",
                  cursor: "pointer",
                  dipslay: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/user/profile");
                }}
              >
                <span style={{ paddingRight: "23px" }}>
                  <img src="/img/logout_icon.svg" />
                </span>
                <span>Logout</span>
              </div>
            </div>
            <div>
              <div
                style={{
                  padding: "18px 22px",
                  cursor: "pointer",
                  dipslay: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/admin/dashboard");
                }}
              >
                <span style={{ paddingRight: "23px" }}>
                  <img src="/img/admin_area_icon.svg" />
                </span>
                <span>Admin Area</span>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}
