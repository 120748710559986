import React, { useEffect, useState, useContext } from "react";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { Card, AppTopBar, BottomNav } from "../components";
import { useNavigate } from "react-router-dom";

import {
  StateContext,
  StateDispatchContext,
  CheckCacheContext,
} from "../context/AppContext";

export function Trainer() {
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkCache = useContext(CheckCacheContext);
  // console.log(state);
  let navigate = useNavigate();
  // console.log(state, "isloading:");
  const isLoading =
    state.loading.DRILL_trainer_videos ||
    state.loading.DRILL_user_video_ids ||
    state.loading.DRILL_categories ||
    state.loading.GUIDED_TRAINING_trainer_videos ||
    state.loading.GUIDED_TRAINING_user_video_ids ||
    state.loading.GUIDED_TRAINING_categories;
  // const [loading, setLoading] = useState();
  // console.log(state, "isloading:", isLoading);
  useEffect(() => {
    checkCache();
    // setLoading(true);
    // // let mounted = true;
    // let localTrainerDrillVideos = JSON.parse(
    //   localStorage.getItem("DRILL_trainer_videos")
    // );
    // let localTrainerGTVideos = JSON.parse(
    //   localStorage.getItem("GUIDED_TRAINING_trainer_videos")
    // );
    // if (!localTrainerDrillVideos || !localTrainerGTVideos) {
    //   GrabAllVideoData();
    // }
    // if (!UserIdCacheChecker()) {
    //   GrabAllUserVideoIds();
    // }
    // if (
    //   UserIdCacheChecker() &&
    //   localTrainerDrillVideos &&
    //   localTrainerGTVideos
    // ) {
    //   setLoading(false);
    // }
    // return () => {
    //   mounted = false;
    // };
  }, []);

  const image =
    "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg";
  const loremIpsum =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.";

  if (isLoading) {
    console.log("LOADING TIMMEE");
    return <>loading.....</>;
  }

  return (
    <>
      <AppTopBar />
      {!isLoading && (
        <div className="container">
          <div className="container__content">
            <div className="pad-box">
              <div className="card-container">
                <Card
                  borderless
                  title="Programs"
                  description="Amazing golf drill that will truly help you hit driver straight"
                  image={image}
                  onClick={() => {
                    navigate("/trainer/programs");
                  }}
                />
                <Card
                  borderless
                  title="At Home Guided Training"
                  description="Amazing golf drill that will truly help you hit driver straight"
                  image={image}
                  onClick={() => {
                    navigate("/trainer/guided_trainings");
                  }}
                />
                <Card
                  borderless
                  title="Drills"
                  description="Amazing golf drill that will truly help you hit driver straight"
                  image={image}
                  onClick={() => {
                    navigate("/trainer/drills");
                  }}
                />
              </div>
              <div className="container__triple-wide-content">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "381px", height: "250px" }}>
                    <Card
                      thumbnail
                      image={"/img/sample-course-vid-thumb.jpg"}

                      // onClick={() => {
                      //   navigate(
                      //     "/trainer/guided_trainings/" + video.trainer_video_id
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <p style={{ color: "#3ab549", margin: "0" }}>
                      How it Works
                    </p>
                    <h1
                      style={{ fontSize: "28px", color: "white", margin: "0" }}
                    >
                      Swing Trainer
                    </h1>
                    <p style={{ fontSize: "14px", margin: "0" }}>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container__collapse"></div>
        </div>
      )}

      <BottomNav />
    </>
  );
}
