import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import HandyGolfAPI from "../../../../lib/HandyGolfAPI";
// Video Library
import ReactPlayer from "react-player";
const videoConstraints = {
  // width: 1280,
  // height: 720,
  facingMode: "environment",
};

export function SwingTrainerVideoPage() {
  const [video_levels, setVideoLevels] = useState([]);
  const [video_playing, setVideoPlaying] = useState(false);
  const [video_time, setVideoTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState();
  const [feedback, setFeedback] = useState();
  const [rating, setRating] = useState();
  const [newFeedback, setNewFeedback] = useState("");
  const [addFeedbackOpen, setAddFeedbackOpen] = useState(false);
  const [mirror, setMirror] = useState(false);

  // Refs
  const videoRef = useRef(null);
  const webcamRef = useRef(null);
  //   console.log(trainer_video_id);
  const { trainer_video_id } = useParams();
  // console.log(trainer_video_id);

  useEffect(() => {
    HandyGolfAPI.sendRequest("trainer/getVideoDetails", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      setVideo(data);
      setLoading(false);
    });
    HandyGolfAPI.sendRequest("trainer/getUserVideoFeedback", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      setFeedback(data);
    });
  }, []);

  function setFavorite() {
    HandyGolfAPI.sendRequest("trainer/toggleUserFavorite", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      // console.log(data);
    });
  }
  const onRatingChange = (e) => {
    setRating(e.target.value);
  };
  const handleChange = (e) => {
    setNewFeedback(e.target.value);
  };
  const sendFeedback = (e) => {
    e.preventDefault();
    // setNewFeedback(newFeedback);
    HandyGolfAPI.sendRequest("trainer/saveUserVideoFeedback", {
      trainer_video_id: trainer_video_id,
      rating: Number(rating),
      feedback_text: newFeedback,
    }).then((data) => {
      console.log(data);
    });
  };
  const onUserMedia = (e) => {
    console.log(e);
  };

  // console.log(rating);
  if (loading) {
    return <></>;
  }
  return (
    <div className="body_content">
      <h1>videopage</h1>
      <h1>video number {trainer_video_id}</h1>
      <h1>{video.name}</h1>
      <button
        onClick={() => {
          setMirror(!mirror);
        }}
      >
        MIRROR
      </button>
      <div style={{ aspectRatio: "16/9" }}>
        <button onClick={setFavorite}>fav</button>
        <ReactPlayer
          ref={videoRef}
          url={video.video_url}
          light={video.thumbnail_url}
          preload="auto"
          width="100%"
          height="100%"
          controls={true}
          controlsList="nodownload"
          playing={video_playing}
        ></ReactPlayer>
      </div>
      <br />
      {mirror && (
        <Webcam
          ref={webcamRef}
          audio={true}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{ width: "100%", height: "100%" }}
          onUserMedia={onUserMedia}
          mirrored={true}
        />
      )}

      <div>
        <div>
          <h1>Feedback</h1>
        </div>
        <div>{/* <p>{feedback.feedback_text}</p> */}</div>
        <div>
          <form onSubmit={sendFeedback}>
            <div style={{ display: "flex" }}>
              <div>
                <input
                  type="radio"
                  name="rating"
                  value={"1"}
                  // id="regular"
                  checked={rating === "1" || rating === 0}
                  onChange={onRatingChange}
                />
                <label>1</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="rating"
                  value={"2"}
                  checked={rating === "2"}
                  onChange={onRatingChange}
                />
                <label>2</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="rating"
                  value={"3"}
                  checked={rating === "3"}
                  onChange={onRatingChange}
                />
                <label>3</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="rating"
                  value={"4"}
                  checked={rating === "4"}
                  onChange={onRatingChange}
                />
                <label>4</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="rating"
                  value={"5"}
                  checked={rating === "5"}
                  onChange={onRatingChange}
                />
                <label>5</label>
              </div>
            </div>
            <div>
              <label>Provide feedback</label>
              <input
                type="textarea"
                name="feedback"
                value={newFeedback}
                onChange={handleChange}
              />
            </div>
            <button>Submit Feedback</button>
          </form>
        </div>
      </div>
    </div>
  );
}
