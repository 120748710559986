import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AdminTable,
  Uploader,
  setFormDefaults,
  AdminContainer,
  VideoDragDrop,
} from "./index";
import { Button, AdminNav } from "../components";
import { useUser } from "../providers";
import { useUpload } from "../context/UploadContext";
import ReactPlayer from "react-player";
const initialLessonForm = {
  name: "",
  tagline: "",
  type: 1,
  // sort_order: 0,
  description: "",
};
const initialUnitForm = {
  name: "",
  description: "",
  text: "",
  unit_type: 1,
  // sort_order:0
};
export function AdminLessons() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const { isVerified, verifyAdmin } = useUpload();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    child,
    grand_child,
    id_param: parent_id,
    id_param2: child_id,
    id_param3: grandchild_id,
  } = useParams();
  const [loading, setLoading] = useState(true);
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();

  const [uploadingThumb, setUploadingThumb] = useState();
  const [uploadingVideo, setUploadingVideo] = useState();
  const [videoUrl, setVideoUrl] = useState();

  //lessons
  const [lessonList, setLessonList] = useState();
  const [lessonListIds, setLessonListIds] = useState();
  const [selectedLesson, setSelectedLesson] = useState();
  const [editingLesson, setEditingLesson] = useState();
  //lesson units
  const [lessonUnitList, setLessonUnitList] = useState();
  const [lessonUnitListIds, setLessonUnitListIds] = useState();
  const [selectedLessonUnit, setSelectedLessonUnit] = useState();
  const [editingLessonUnit, setEditingLessonUnit] = useState();
  // const [lessonUnitUrl, setLessonUnitUrl] = useState();
  //lesson sub units
  const [lessonSubUnitList, setLessonSubUnitList] = useState();
  const [lessonSubUnitListIds, setLessonSubUnitListIds] = useState();
  const [selectedSubUnit, setSelectedSubUnit] = useState();
  const [editingSubUnit, setEditingSubUnit] = useState();
  // const [subUnitUrl, setSubUnitUrl] = useState();

  //updating / editing states
  const [lessonForm, setLessonForm] = useState(initialLessonForm);
  const [unitForm, setUnitForm] = useState(initialUnitForm);
  //maybe separate subUnit form if we need
  const updateURL = (newPath) => {
    const currentPath = location.pathname;
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath, { replace: true });
    }
  };

  const getLessons = () => {
    HandyGolfAPI.sendRequest("lesson/lesson/list").then((data) => {
      initialLessonIdsArray(data);
      setLessonList(data);
      setLoading(false);
    });
  };
  const initialLessonIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.lesson_id));
    setLessonListIds(idsArray);
  };

  const getLessonUnits = () => {
    HandyGolfAPI.sendRequest("lesson/lesson_unit/list").then((data) => {
      let currentLessonUnits = data.filter(
        (unit) =>
          unit.lesson_id === selectedLesson.lesson_id &&
          unit.parent_lesson_unit_id === 0
      );
      console.log(currentLessonUnits);
      initialLessonUnitIdsArray(currentLessonUnits);
      setLessonUnitList(currentLessonUnits);
      // setAllNonParentSubUnits(allSubUnits);
    });
  };
  const getLessonSubUnits = () => {
    HandyGolfAPI.sendRequest("lesson/lesson_unit/list").then((data) => {
      let allSubUnits = data.filter(
        (unit) =>
          unit.lesson_id === selectedLesson.lesson_id &&
          unit.parent_lesson_unit_id !== 0
      );
      let currentSubUnits = allSubUnits.filter(
        (sub) => sub.parent_lesson_unit_id === selectedLessonUnit.lesson_unit_id
      );
      initialSubUnitIdsArray(currentSubUnits);
      setLessonSubUnitList(currentSubUnits);
    });
  };

  const initialLessonUnitIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.lesson_unit_id));
    setLessonUnitListIds(idsArray);
  };

  const initialSubUnitIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.lesson_unit_id));
    setLessonSubUnitListIds(idsArray);
  };

  const getSelectedLessonUnitUrl = () => {
    HandyGolfAPI.sendRequest("video/find", {
      video_id: selectedLessonUnit.video_id,
    }).then((data) => {
      console.log(data);
      setVideoUrl(data.video_url);
    });
    // setVideoUrl("");
  };
  const getSelectedSubUnitUrl = () => {
    HandyGolfAPI.sendRequest("video/find", {
      video_id: selectedSubUnit.video_id,
    }).then((data) => {
      console.log(data);
      setVideoUrl(data.video_url);
    });
    // setVideoUrl("");
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);

  useEffect(() => {
    if (!selectedLesson) {
      getLessons();
    }
  }, [selectedLesson]);

  useEffect(() => {
    if (selectedLesson && !selectedLessonUnit) {
      getLessonUnits();
    }
  }, [selectedLesson]);

  useEffect(() => {
    if (selectedLessonUnit) {
      getLessonSubUnits();
      if (selectedLessonUnit.video_id !== 0) {
        getSelectedLessonUnitUrl();
      }
    }
  }, [selectedLessonUnit]);
  useEffect(() => {
    if (selectedSubUnit) {
      if (selectedSubUnit.video_id !== 0) {
        getSelectedSubUnitUrl();
      }
    }
  }, [selectedSubUnit]);
  useEffect(() => {
    if (lessonList) {
      if (parent_id) {
        let currentParent = lessonList.find(
          (el) => el.lesson_id === parseInt(parent_id)
        );
        if (currentParent) {
          setSelectedLesson(currentParent);
          setEditingLesson(true);
        }
      }
      if (lessonUnitList) {
        if (child_id) {
          let currentChild = lessonUnitList.find(
            (el) => el.lesson_unit_id === parseInt(child_id)
          );
          if (currentChild) {
            setSelectedLessonUnit(currentChild);
            setEditingLessonUnit(true);
          }
        }
        if (lessonSubUnitList) {
          if (grandchild_id) {
            let currentGrandChild = lessonSubUnitList.find(
              (el) => el.lesson_unit_id === parseInt(grandchild_id)
            );

            if (currentGrandChild) {
              setSelectedSubUnit(currentGrandChild);
              setEditingSubUnit(true);
            }
          }
        }
      }
    }
  }, [lessonList, lessonUnitList, lessonSubUnitList]);

  const handleLessonOrderChange = (direction, lessonId) => {
    let tempIdsArray = lessonListIds;
    let tempLessons = lessonList;
    let currentIndex = lessonListIds.findIndex((id) => id === lessonId);
    let idElement = lessonListIds[currentIndex];
    let lessonElement = lessonList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempLessons.splice(currentIndex, 1);
      tempLessons.splice(currentIndex - 1, 0, lessonElement);
      setLessonList(tempLessons);
      setLessonListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempLessons.splice(currentIndex, 1);
      tempLessons.splice(currentIndex + 1, 0, lessonElement);
      setLessonList(tempLessons);
      setLessonListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleLessonUnitOrderChange = (direction, lessonUnitId) => {
    let tempIdsArray = lessonUnitListIds;
    let tempLessonUnits = lessonUnitList;
    let currentIndex = lessonUnitListIds.findIndex((id) => id === lessonUnitId);
    let idElement = lessonUnitListIds[currentIndex];
    let lessonUnitElement = lessonUnitList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex - 1, 0, lessonUnitElement);
      setLessonUnitList(tempLessonUnits);
      setLessonUnitListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex + 1, 0, lessonUnitElement);
      setLessonUnitList(tempLessonUnits);
      setLessonUnitListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleSubUnitOrderChange = (direction, lessonSubUnitId) => {
    let tempIdsArray = lessonSubUnitListIds;
    let tempLessonUnits = lessonSubUnitList;
    let currentIndex = lessonSubUnitListIds.findIndex(
      (id) => id === lessonSubUnitId
    );
    let idElement = lessonSubUnitListIds[currentIndex];
    let lessonSubUnitElement = lessonSubUnitList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex - 1, 0, lessonSubUnitElement);
      setLessonSubUnitList(tempLessonUnits);
      setLessonSubUnitListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex + 1, 0, lessonSubUnitElement);
      setLessonSubUnitList(tempLessonUnits);
      setLessonSubUnitListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };

  const handleLessonFormChange = (e) => {
    setLessonForm({
      ...lessonForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleLessonEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("lesson/lesson/update", {
      lesson_id: selectedLesson.lesson_id,
      lesson: lessonForm,
    }).then((data) => {
      console.log(data);
      setSelectedLesson(data.data);
      // setEditingLesson(false);
      setEditing(false);
      setLessonForm(initialLessonForm);
    });
  };

  const handleLessonCreateSubmit = (e) => {
    e.preventDefault();
    let newSortOrder = lessonList.length + 1;
    let lessonFormWithSort = lessonForm;
    lessonFormWithSort = { ...lessonFormWithSort, sort_order: newSortOrder };

    HandyGolfAPI.sendRequest("lesson/lesson/create", {
      lesson: lessonFormWithSort,
    }).then((data) => {
      console.log(data);
      //take data.data as var
      //add it to end of list
      //add its id to end of listIds
      let newLesson = data.data;
      let lessons = lessonList;
      let lessonIds = lessonListIds;
      lessons.push(newLesson);
      lessonIds.push(newLesson.lesson_id);
      setLessonList(lessons);
      setLessonListIds(lessonIds);
      setCreating(false);
      setLessonForm(initialLessonForm);
    });
  };

  const handleUnitFormChange = (e) => {
    setUnitForm({
      ...unitForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleUnitEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("lesson/lesson_unit/update", {
      lesson_unit_id: selectedSubUnit
        ? selectedSubUnit.lesson_unit_id
        : selectedLessonUnit.lesson_unit_id,
      lesson_unit: unitForm,
    }).then((data) => {
      console.log(data);
      if (selectedSubUnit) {
        setSelectedSubUnit(data.data);
        // setEditingSubUnit(false);
      } else {
        setSelectedLessonUnit(data.data);
        // setEditingLessonUnit(false);
      }

      setEditing(false);
      setUnitForm(initialUnitForm);
    });
  };
  const handleUnitCreateSubmit = (e) => {
    e.preventDefault();
    let newSortOrder = selectedLessonUnit
      ? lessonSubUnitList.length + 1
      : lessonUnitList.length + 1;
    let parentId = selectedLessonUnit ? selectedLessonUnit.lesson_unit_id : 0;
    let unitFormWithSortAndId = unitForm;
    unitFormWithSortAndId = {
      ...unitFormWithSortAndId,
      sort_order: newSortOrder,
      lesson_id: selectedLesson.lesson_id,
      parent_lesson_unit_id: parentId,
    };
    console.log(unitFormWithSortAndId);
    HandyGolfAPI.sendRequest("lesson/lesson_unit/create", {
      lesson_unit: unitFormWithSortAndId,
    })
      .then((data) => {
        console.log(data);
        if (selectedSubUnit) {
          console.log("i dont think its possible to be here but just in case");
        }
        if (selectedLesson && !selectedLessonUnit) {
          let newParentUnit = data.data;
          let parentUnits = lessonUnitList;
          let parentUnitIds = lessonUnitListIds;
          parentUnits.push(newParentUnit);
          parentUnitIds.push(newParentUnit.lesson_unit_id);
          setLessonUnitList(parentUnits);
          setLessonUnitListIds(parentUnitIds);
        }
        if (selectedLessonUnit) {
          let newSubUnit = data.data;
          let subUnits = lessonSubUnitList;
          let subUnitIds = lessonSubUnitListIds;
          subUnits.push(newSubUnit);
          subUnitIds.push(newSubUnit.lesson_unit_id);
          setLessonSubUnitList(subUnits);
          setLessonSubUnitListIds(subUnitIds);
        }

        setCreating(false);
        setUnitForm(initialUnitForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUnitNumberColumn = (units) => {
    return units.map((unit, idx) => ({
      ...unit,
      Unit: idx + 1,
    }));
  };
  const addSubUnitColumn = (subUnits) => {
    return subUnits.map((sub, idx) => ({
      ...sub,
      Unit: `${selectedLessonUnit.sort_order}.${idx + 1}`,
    }));
  };
  const handleSelectLesson = (item) => {
    updateURL(`lesson/${item.lesson_id}`);
    setSelectedLesson(item);
  };
  const handleSelectLessonUnit = (item) => {
    updateURL(`lesson_unit/${item.lesson_unit_id}`);
    setSelectedLessonUnit(item);
  };
  const handleSelectSubUnit = (item) => {
    updateURL(`lesson_sub_unit/${item.lesson_unit_id}`);
    setSelectedSubUnit(item);
  };
  const removeLastTwoSegments = () => {
    const currentPath = location.pathname;
    const segments = currentPath.split("/");

    if (segments.length > 3) {
      const newPath = segments.slice(0, -2).join("/");

      navigate(newPath, { replace: true });
    }
  };
  console.log("sub", selectedSubUnit);
  return (
    <>
      <AdminContainer>
        {lessonList && (
          <div className="admin-table-half">
            <div className="admin-table-half__title">
              Lessons
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm();
                  setCreating(true);
                }}
              >
                <i className="material-icons">add</i>
                <strong>New Lesson</strong>
              </div>
            </div>
            <AdminTable
              tableData={lessonList}
              tableIds={lessonListIds}
              handleOrderChange={handleLessonOrderChange}
              headers={
                openSorting
                  ? ["", "sort_order", "name"]
                  : ["name", "description"]
              }
              setSortChanging={setSortChanging}
              idType={"lesson_id"}
              // setSelectedItem={setSelectedLesson}
              setSelectedItem={handleSelectLesson}
              setEditingItem={setEditingLesson}
            />
            {openSorting ? (
              <>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  <div
                    className="admin-edit-item-button"
                    onClick={() => {
                      setOpenSorting(false);
                    }}
                  >
                    <strong>Cancel</strong>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  <div
                    className="admin-edit-item-button"
                    onClick={() => {
                      HandyGolfAPI.sendRequest("lesson/lesson/reorderLessons", {
                        lesson_ids: lessonListIds,
                      }).then((data) => {
                        getLessons();
                      });
                    }}
                  >
                    <i className="material-icons">swap_vert</i>
                    <strong>Save Sort Order</strong>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  <div
                    className="admin-edit-item-button"
                    onClick={() => {
                      setOpenSorting(true);
                    }}
                  >
                    <i className="material-icons">swap_vert</i>
                    <strong>Set Sort Order</strong>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div
          className={
            !creating
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                setCreating(false);
                setLessonForm(initialLessonForm);
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h2>Make a New Lesson</h2>
              <form onSubmit={handleLessonCreateSubmit} className="admin-form">
                <div>
                  <label>Lesson Name</label>
                  <input
                    type="text"
                    name="name"
                    maxLength={40}
                    placeholder="name for new lesson"
                    value={lessonForm.name}
                    onChange={handleLessonFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {lessonForm.name.length > 0 ? (
                      <div>{lessonForm.name.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Tagline</label>
                  <input
                    type="text"
                    name="tagline"
                    maxLength={50}
                    placeholder="tagline for new lesson"
                    value={lessonForm.tagline}
                    onChange={handleLessonFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {lessonForm.tagline.length > 0 ? (
                      <div>{lessonForm.tagline.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Select Type for Lesson</label>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                    }}
                  >
                    <div className="admin-radio-button">
                      <input
                        type="radio"
                        name="type"
                        id="free"
                        value="free"
                        checked={lessonForm.type === 1}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const numericValue =
                            value === "free"
                              ? 1
                              : value === "subscription"
                              ? 2
                              : value;
                          setLessonForm({
                            ...lessonForm,
                            [name]: numericValue,
                          });
                        }}
                      />
                      <label>Free</label>
                    </div>
                    <div className="admin-radio-button">
                      <input
                        type="radio"
                        name="type"
                        id="subscription"
                        value="subscription"
                        checked={lessonForm.type === 2}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const numericValue =
                            value === "free"
                              ? 1
                              : value === "subscription"
                              ? 2
                              : value;
                          setLessonForm({
                            ...lessonForm,
                            [name]: numericValue,
                          });
                        }}
                      />
                      <label>Subscription</label>
                    </div>
                  </div>
                </div>
                <div>
                  <label>Description</label>
                  <textarea
                    rows="4"
                    name="description"
                    maxLength={150}
                    placeholder="description for new lesson"
                    value={lessonForm.description}
                    onChange={handleLessonFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {lessonForm.description.length > 0 ? (
                      <div>{lessonForm.description.length}/150</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>To Edit Sort Order Go back to the lesson list</label>
                  {/* <input
                                    type="number"
                                    name="sort_order"
                                    disabled
                                    placeholder="sort order"
                                   
                                  /> */}
                </div>
                <Button fluid primary>
                  Submit Changes
                </Button>
              </form>
            </div>
          </div>
        </div>
        <div
          className={
            !editingLesson
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingLesson(false);
                setSelectedLesson();
              }}
            >
              Close
            </Button>
          </div>
          {selectedLesson && (
            <>
              <div className="admin-overflow-scroll">
                <div className="admin-info-half">
                  <div className="admin-info-half__title">
                    {selectedLesson.name}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Thumbnail
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingThumb(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>

                    {!uploadingThumb && (
                      <img
                        src={
                          selectedLesson.thumbnail_url !== ""
                            ? selectedLesson.thumbnail_url
                            : "/img/thumbnail-absent.png"
                        }
                      />
                    )}
                  </div>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__section-title">
                      Details
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          // setLessonForm(selectedLesson);
                          setFormDefaults(
                            selectedLesson,
                            lessonForm,
                            setLessonForm
                          );
                          setEditing(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Name:</label>
                      <strong>{selectedLesson.name}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Tagline:</label>
                      <strong>{selectedLesson.tagline}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Type:</label>
                      <strong>
                        {selectedLesson.type === 1 ? "Free" : "Subscription"}
                      </strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Sort Order:</label>
                      <strong>{selectedLesson.sort_order}</strong>
                    </div>

                    <div className="admin-info-half__description">
                      <label>Description:</label>
                      <div>
                        {selectedLesson.description !== ""
                          ? selectedLesson.description
                          : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you SURE you want to PERMANENTLY DELETE this lesson and all of its contents?"
                            )
                          ) {
                            HandyGolfAPI.sendRequest("lesson/lesson/delete", {
                              lesson_id: selectedLesson.lesson_id,
                            }).then((data) => {
                              console.log(data);
                              setEditingLesson(false);
                              setSelectedLesson();
                            });
                          }
                        }}
                      >
                        <i className="material-icons">delete</i>
                        <strong>Delete</strong>
                      </div>
                    </div>
                  </div>
                </div>
                {lessonUnitList && (
                  <div className="admin-table-half">
                    <div className="admin-table-half__title">
                      Units in Selected Lesson
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          // setLessonForm(selectedLesson);
                          // setEditing(true);
                          setCreating(true);
                        }}
                      >
                        <i className="material-icons">add</i>
                        <strong>New Unit</strong>
                      </div>
                    </div>
                    <AdminTable
                      tableData={addUnitNumberColumn(lessonUnitList)}
                      tableIds={lessonUnitListIds}
                      handleOrderChange={handleLessonUnitOrderChange}
                      // TODO probably a better way to do the 1.1,1.2 2.1 thing
                      headers={
                        openSorting
                          ? ["", "sort_order", "name"]
                          : ["Unit", "name", "description"]
                      }
                      setSortChanging={setSortChanging}
                      idType={"lesson_unit_id"}
                      // setSelectedItem={setSelectedLessonUnit}
                      setSelectedItem={handleSelectLessonUnit}
                      setEditingItem={setEditingLessonUnit}
                    />

                    {openSorting ? (
                      <>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              setOpenSorting(false);
                            }}
                          >
                            <strong>Cancel</strong>
                          </div>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              HandyGolfAPI.sendRequest(
                                "lesson/lesson_unit/reorderLessonUnits",
                                {
                                  lesson_id: selectedLesson.lesson_id,
                                  parent_lesson_unit_id: 0,
                                  lesson_unit_ids: lessonUnitListIds,
                                }
                              ).then((data) => {
                                getLessonUnits();
                              });
                            }}
                          >
                            <i className="material-icons">swap_vert</i>
                            <strong>Save Sort Order</strong>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              setOpenSorting(true);
                            }}
                          >
                            <i className="material-icons">swap_vert</i>
                            <strong>Set Sort Order</strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div
                className={
                  !creating
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setCreating(false);
                      setUnitForm(initialUnitForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      New Unit For:<br></br>'{selectedLesson.name}' Lesson
                    </h2>
                    <form
                      onSubmit={handleUnitCreateSubmit}
                      className="admin-form"
                    >
                      <div>
                        <label>Unit Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={40}
                          placeholder="name for a new unit"
                          value={unitForm.name}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.name.length > 0 ? (
                            <div>{unitForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Description</label>
                        <textarea
                          rows="4"
                          name="description"
                          maxLength={150}
                          placeholder="description of new unit"
                          value={unitForm.description}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.description.length > 0 ? (
                            <div>{unitForm.description.length}/150</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Unit Text</label>
                        <textarea
                          rows="4"
                          name="text"
                          maxLength={500}
                          placeholder="Text info of new unit"
                          value={unitForm.text}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.text.length > 0 ? (
                            <div>{unitForm.text.length}/500</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>Select Unit Type</label>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="normal lesson unit"
                              value="normal lesson unit"
                              checked={unitForm.unit_type === 1}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Normal Lesson Unit</label>
                          </div>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="drill lesson unit"
                              value="drill lesson unit"
                              checked={unitForm.unit_type === 2}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Drill Lesson Unit</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>
                          To edit unit sort order go back to previous page
                        </label>
                      </div>
                      <Button fluid primary>
                        Submit
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !editing
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEditing(false);
                      setLessonForm(initialLessonForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>Edit Lesson Form</h2>
                    <form
                      onSubmit={handleLessonEditSubmit}
                      className="admin-form"
                    >
                      <div>
                        <label>Lesson Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={40}
                          placeholder={selectedLesson.name}
                          value={lessonForm.name}
                          onChange={handleLessonFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {lessonForm.name.length > 0 ? (
                            <div>{lessonForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Tagline</label>
                        <input
                          type="text"
                          name="tagline"
                          maxLength={50}
                          placeholder={selectedLesson.tagline}
                          value={lessonForm.tagline}
                          onChange={handleLessonFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {lessonForm.tagline.length > 0 ? (
                            <div>{lessonForm.tagline.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>Select Type for Lesson</label>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                          }}
                        >
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="type"
                              id="free"
                              value="free"
                              checked={lessonForm.type === 1}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "free"
                                    ? 1
                                    : value === "subscription"
                                    ? 2
                                    : value;
                                setLessonForm({
                                  ...lessonForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Free</label>
                          </div>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="type"
                              id="subscription"
                              // placeholder="type number for new lesson"
                              value="subscription"
                              checked={lessonForm.type === 2}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "free"
                                    ? 1
                                    : value === "subscription"
                                    ? 2
                                    : value;
                                setLessonForm({
                                  ...lessonForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Subscription</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>Description</label>
                        <textarea
                          rows="4"
                          name="description"
                          maxLength={150}
                          placeholder={selectedLesson.description}
                          value={lessonForm.description}
                          onChange={handleLessonFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {lessonForm.description.length > 0 ? (
                            <div>{lessonForm.description.length}/150</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>
                          To Edit Sort Order Go back to the lesson list
                        </label>
                        {/* <input
                                    type="number"
                                    name="sort_order"
                                    disabled
                                    placeholder="sort order"
                                   
                                  /> */}
                      </div>
                      <Button fluid primary>
                        Submit Changes
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !uploadingThumb
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingThumb(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingThumb && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {selectedLesson.thumbnail_url === "" ? (
                          <>
                            <h2>Add Thumbnail</h2>

                            <Uploader
                              folder={"lesson_thumbnails/"}
                              fileName={`lesson_thumbnails/lesson_${selectedLesson.lesson_id}`}
                              background={"/img/thumbnail-absent.png"}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedLesson}
                              id={selectedLesson.lesson_id}
                              type={"lesson_thumbnail"}
                            />
                          </>
                        ) : (
                          <>
                            <h2>Change Thumbnail</h2>

                            <Uploader
                              folder={"lesson_thumbnails/"}
                              fileName={`lesson_thumbnails/lesson_${selectedLesson.lesson_id}`}
                              background={selectedLesson.thumbnail_url}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedLesson}
                              id={selectedLesson.lesson_id}
                              type={"lesson_thumbnail"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div
          className={
            !editingLessonUnit
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingLessonUnit(false);
                setSelectedLessonUnit();
              }}
            >
              Close
            </Button>
          </div>
          {selectedLessonUnit && (
            <>
              <div className="admin-overflow-scroll">
                <div className="admin-info-half">
                  <div className="admin-info-half__title">
                    {selectedLessonUnit.name}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Video
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingVideo(true);
                          verifyAdmin();
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    {selectedLessonUnit.video_id !== 0 && videoUrl ? (
                      <div style={{ position: "relative" }}>
                        <div className="admin-player-wrapper">
                          <ReactPlayer
                            className="admin-react-player"
                            width="100%"
                            height="100%"
                            // light={
                            //   selectedLessonUnit.thumbnail_url !== ""
                            //     ? selectedLessonUnit.thumbnail_url
                            //     : "/img/thumbnail-absent.png"
                            // }
                            url={videoUrl}
                            controls={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <>There is no video file</>
                    )}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Thumbnail
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingThumb(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>

                    {!uploadingThumb && (
                      <img
                        src={
                          selectedLessonUnit.thumbnail_url !== ""
                            ? selectedLessonUnit.thumbnail_url
                            : "/img/thumbnail-absent.png"
                        }
                      />
                    )}
                  </div>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__section-title">
                      Details
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          // setLessonForm(selectedLesson);
                          setFormDefaults(
                            selectedLessonUnit,
                            unitForm,
                            setUnitForm
                          );
                          setEditing(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Name:</label>
                      <strong>{selectedLessonUnit.name}</strong>
                    </div>

                    <div className="admin-info-half__key-value">
                      <label>Unit Type:</label>
                      <strong>
                        {selectedLessonUnit.unit_type === 1
                          ? "Normal Lesson Unit"
                          : "Drill Lesson Unit"}
                      </strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Sort Order:</label>
                      <strong>{selectedLessonUnit.sort_order}</strong>
                    </div>

                    <div className="admin-info-half__description">
                      <label>Description:</label>
                      <div>
                        {selectedLessonUnit.description !== ""
                          ? selectedLessonUnit.description
                          : "Lorum ipsum lorum ipsum flo"}
                      </div>
                    </div>
                    <div className="admin-info-half__description">
                      <label>Text:</label>
                      <div>
                        {selectedLessonUnit.text !== ""
                          ? selectedLessonUnit.text
                          : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you SURE you want to PERMANENTLY DELETE this unit and all of its contents?"
                            )
                          ) {
                            HandyGolfAPI.sendRequest(
                              "lesson/lesson_unit/delete",
                              {
                                lesson_unit_id:
                                  selectedLessonUnit.lesson_unit_id,
                              }
                            ).then((data) => {
                              console.log(data);
                              setEditingLessonUnit(false);
                              setSelectedLessonUnit();
                            });
                          }
                        }}
                      >
                        <i className="material-icons">delete</i>
                        <strong>Delete</strong>
                      </div>
                    </div>
                  </div>
                </div>

                {lessonSubUnitList && (
                  <div className="admin-table-half">
                    <div className="admin-table-half__title">
                      Sub Units in Selected Unit
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          // setLessonForm(selectedLesson);
                          // setEditing(true);
                          setCreating(true);
                        }}
                      >
                        <i className="material-icons">add</i>
                        <strong>New Sub Unit</strong>
                      </div>
                    </div>
                    <AdminTable
                      tableData={addSubUnitColumn(lessonSubUnitList)}
                      tableIds={lessonSubUnitListIds}
                      handleOrderChange={handleSubUnitOrderChange}
                      headers={
                        openSorting
                          ? ["", "sort_order", "name"]
                          : ["Unit", "name", "description"]
                      }
                      setSortChanging={setSortChanging}
                      idType={"lesson_unit_id"}
                      // setSelectedItem={setSelectedSubUnit}
                      setSelectedItem={handleSelectSubUnit}
                      setEditingItem={setEditingSubUnit}
                    />
                    {openSorting ? (
                      <>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              setOpenSorting(false);
                            }}
                          >
                            <strong>Cancel</strong>
                          </div>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              HandyGolfAPI.sendRequest(
                                "lesson/lesson_unit/reorderLessonUnits",
                                {
                                  lesson_id: selectedLesson.lesson_id,
                                  parent_lesson_unit_id:
                                    selectedLessonUnit.lesson_unit_id,
                                  lesson_unit_ids: lessonSubUnitListIds,
                                }
                              ).then((data) => {
                                HandyGolfAPI.sendRequest(
                                  "lesson/lesson_unit/list"
                                ).then((data) => {
                                  getLessonSubUnits();
                                });
                              });
                            }}
                          >
                            <i className="material-icons">swap_vert</i>
                            <strong>Save Sort Order</strong>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              setOpenSorting(true);
                            }}
                          >
                            <i className="material-icons">swap_vert</i>
                            <strong>Set Sort Order</strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div
                className={
                  !creating
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setCreating(false);
                      setUnitForm(initialUnitForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      New Sub Unit For the:<br></br>'{selectedLessonUnit.name}'
                      Unit
                    </h2>
                    <form
                      onSubmit={handleUnitCreateSubmit}
                      className="admin-form"
                    >
                      <div>
                        <label>Sub Unit Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={40}
                          placeholder="name for a new unit"
                          value={unitForm.name}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.name.length > 0 ? (
                            <div>{unitForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Description</label>
                        <textarea
                          rows="4"
                          name="description"
                          maxLength={150}
                          placeholder="description of new unit"
                          value={unitForm.description}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.description.length > 0 ? (
                            <div>{unitForm.description.length}/150</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Sub Unit Text</label>
                        <textarea
                          rows="4"
                          name="text"
                          maxLength={500}
                          placeholder="Text info of new unit"
                          value={unitForm.text}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.text.length > 0 ? (
                            <div>{unitForm.text.length}/500</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>Select Sub Unit Type</label>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="normal lesson unit"
                              value="normal lesson unit"
                              checked={unitForm.unit_type === 1}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Normal Lesson Unit</label>
                          </div>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="drill lesson unit"
                              value="drill lesson unit"
                              checked={unitForm.unit_type === 2}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Drill Lesson Unit</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>
                          To edit unit sort order go back to previous page
                        </label>
                      </div>
                      <Button fluid primary>
                        Submit Changes
                      </Button>
                    </form>
                  </div>
                </div>
              </div>

              <div
                className={
                  !editing
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEditing(false);
                      setUnitForm(initialUnitForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      Edit the Unit: <br></br> '{selectedLessonUnit.name}'
                    </h2>
                    <form
                      onSubmit={handleUnitEditSubmit}
                      className="admin-form"
                    >
                      <div>
                        <label>Unit Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={40}
                          placeholder={selectedLessonUnit.name}
                          value={unitForm.name}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.name.length > 0 ? (
                            <div>{unitForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Description</label>
                        <textarea
                          rows="4"
                          name="description"
                          maxLength={150}
                          placeholder={selectedLessonUnit.description}
                          value={unitForm.description}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.description.length > 0 ? (
                            <div>{unitForm.description.length}/150</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Unit Text</label>
                        <textarea
                          rows="4"
                          name="text"
                          maxLength={500}
                          placeholder={selectedLessonUnit.text}
                          value={unitForm.text}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.text.length > 0 ? (
                            <div>{unitForm.text.length}/500</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>Select Unit Type</label>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="normal lesson unit"
                              value="normal lesson unit"
                              checked={unitForm.unit_type === 1}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Normal Lesson Unit</label>
                          </div>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="drill lesson unit"
                              value="drill lesson unit"
                              checked={unitForm.unit_type === 2}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Drill Lesson Unit</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>
                          To edit unit sort order go back to previous page
                        </label>
                      </div>
                      <Button fluid primary>
                        Submit Changes
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !uploadingThumb
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingThumb(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingThumb && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {selectedLessonUnit.thumbnail_url === "" ? (
                          <>
                            <h2>Add Thumbnail</h2>

                            <Uploader
                              folder={"lesson_unit_thumbnails/"}
                              fileName={`lesson_unit_thumbnails/lesson_unit_${selectedLessonUnit.lesson_unit_id}`}
                              background={"/img/thumbnail-absent.png"}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedLessonUnit}
                              id={selectedLessonUnit.lesson_unit_id}
                              type={"lesson_unit_thumb"}
                            />
                          </>
                        ) : (
                          <>
                            <h2>Change Thumbnail</h2>

                            <Uploader
                              folder={"lesson_unit_thumbnails/"}
                              fileName={`lesson_unit_thumbnails/lesson_unit_${selectedLessonUnit.lesson_unit_id}`}
                              background={selectedLessonUnit.thumbnail_url}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedLessonUnit}
                              id={selectedLessonUnit.lesson_unit_id}
                              type={"lesson_unit_thumb"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className={
                  !uploadingVideo
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingVideo(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingVideo && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {videoUrl === "" ? (
                          <>
                            <h2>Add Video</h2>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="/img/thumbnail-absent.png"
                                className="dragdrop-under-content"
                              />
                              <div className="dragdrop-overlay">
                                <VideoDragDrop
                                  type={"lesson_unit_video"}
                                  object={selectedLessonUnit}
                                  setUploading={setUploadingVideo}
                                  // handleFileChange={handleFileChange}
                                  // hasDropped={hasDropped}
                                  // duplicateFound={duplicateFound}
                                  // uppingToBucket={uppingToBucket}
                                  // progress={progress}
                                  // uploadFile={uploadFile}
                                  // setDuplicateFound={setDuplicateFound}
                                  // setUploading={setUploading}
                                  // type={type}
                                />
                              </div>
                            </div>
                            {/* <Uploader
                              folder={"lesson_unit_videos/"}
                              fileName={`lesson_unit_videos/lesson_unit_${selectedLessonUnit.lesson_unit_id}`}
                              // background={"/img/thumbnail-absent.png"}
                              background={
                                selectedLessonUnit.thumbnail_url !== ""
                                  ? selectedLessonUnit.thumbnail.url
                                  : "/img/thumbnail-absent.png"
                              }
                              setUploading={setUploadingVideo}
                              setNew={setSelectedLessonUnit}
                              relatedObject={selectedLessonUnit}
                              id={selectedLessonUnit.lesson_unit_id}
                              type={"lesson_unit_video"}
                            /> */}
                          </>
                        ) : (
                          <>
                            <h2>Change Video</h2>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="/img/thumbnail-absent.png"
                                className="dragdrop-under-content"
                              />
                              <div className="dragdrop-overlay">
                                <VideoDragDrop
                                  type={"lesson_unit_video"}
                                  object={selectedLessonUnit}
                                  setUploading={setUploadingVideo}
                                  // handleFileChange={handleFileChange}
                                  // hasDropped={hasDropped}
                                  // duplicateFound={duplicateFound}
                                  // uppingToBucket={uppingToBucket}
                                  // progress={progress}
                                  // uploadFile={uploadFile}
                                  // setDuplicateFound={setDuplicateFound}
                                  // setUploading={setUploading}
                                  // type={type}
                                />
                              </div>
                            </div>
                            {/* <Uploader
                              folder={"lesson_unit_videos/"}
                              fileName={`lesson_unit_videos/lesson_unit_${selectedLessonUnit.lesson_unit_id}`}
                              // background={videoUrl}
                              background={
                                selectedLessonUnit.thumbnail_url !== ""
                                  ? selectedLessonUnit.thumbnail.url
                                  : "/img/thumbnail-absent.png"
                              }
                              setUploading={setUploadingVideo}
                              setNew={setSelectedLessonUnit}
                              relatedObject={selectedLessonUnit}
                              id={selectedLessonUnit.lesson_unit_id}
                              type={"lesson_unit_video"}
                            /> */}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div
          className={
            !editingSubUnit
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingSubUnit(false);
                setSelectedSubUnit();
              }}
            >
              Close
            </Button>
          </div>
          {selectedSubUnit && (
            <>
              <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                <div className="admin-info-half" style={{ maxWidth: "460px" }}>
                  <div className="admin-info-half__title">
                    {selectedSubUnit.name}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Video
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingVideo(true);
                          verifyAdmin();
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    {selectedSubUnit.video_id !== 0 && videoUrl ? (
                      <div style={{ position: "relative" }}>
                        <div className="admin-player-wrapper">
                          <ReactPlayer
                            className="admin-react-player"
                            width="100%"
                            height="100%"
                            // light={
                            //   selectedSubUnit.thumbnail_url !== ""
                            //     ? selectedSubUnit.thumbnail_url
                            //     : "/img/thumbnail-absent.png"
                            // }
                            url={videoUrl}
                            controls={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <>There is no video file</>
                    )}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Thumbnail
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingThumb(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>

                    {!uploadingThumb && (
                      <img
                        src={
                          selectedSubUnit.thumbnail_url !== ""
                            ? selectedSubUnit.thumbnail_url
                            : "/img/thumbnail-absent.png"
                        }
                      />
                    )}
                  </div>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__section-title">
                      Details
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          // setLessonForm(selectedLesson);
                          setFormDefaults(
                            selectedSubUnit,
                            unitForm,
                            setUnitForm
                          );
                          setEditing(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Name:</label>
                      <strong>{selectedSubUnit.name}</strong>
                    </div>

                    <div className="admin-info-half__key-value">
                      <label>Unit Type:</label>
                      <strong>
                        {selectedSubUnit.unit_type === 1
                          ? "Normal Lesson Unit"
                          : "Drill Lesson Unit"}
                      </strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Sort Order:</label>
                      <strong>{selectedSubUnit.sort_order}</strong>
                    </div>

                    <div className="admin-info-half__description">
                      <label>Description:</label>
                      <div>
                        {selectedSubUnit.description !== ""
                          ? selectedSubUnit.description
                          : "Lorum ipsum lorum ipsum flo"}
                      </div>
                    </div>
                    <div className="admin-info-half__description">
                      <label>Text:</label>
                      <div>
                        {selectedSubUnit.text !== ""
                          ? selectedSubUnit.text
                          : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you SURE you want to PERMANENTLY DELETE this Sub Unit and all of its contents?"
                            )
                          ) {
                            HandyGolfAPI.sendRequest(
                              "lesson/lesson_unit/delete",
                              {
                                lesson_unit_id: selectedSubUnit.lesson_unit_id,
                              }
                            ).then((data) => {
                              console.log(data);
                              setEditingSubUnit(false);
                              setSelectedSubUnit();
                            });
                          }
                        }}
                      >
                        <i className="material-icons">delete</i>
                        <strong>Delete</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  !editing
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEditing(false);
                      setUnitForm(initialUnitForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      Edit the Sub Unit: <br></br> '{selectedSubUnit.name}'
                    </h2>
                    <form
                      onSubmit={handleUnitEditSubmit}
                      className="admin-form"
                    >
                      <div>
                        <label>Sub Unit Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={40}
                          placeholder={selectedSubUnit.name}
                          value={unitForm.name}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.name.length > 0 ? (
                            <div>{unitForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Description</label>
                        <textarea
                          rows="4"
                          name="description"
                          maxLength={150}
                          placeholder={selectedSubUnit.description}
                          value={unitForm.description}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.name.length > 0 ? (
                            <div>{unitForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Sub Unit Text</label>
                        <textarea
                          rows="4"
                          name="text"
                          maxLength={500}
                          placeholder={selectedSubUnit.text}
                          value={unitForm.text}
                          onChange={handleUnitFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {unitForm.name.length > 0 ? (
                            <div>{unitForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>Select Sub Unit Type</label>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="normal lesson unit"
                              value="normal lesson unit"
                              checked={unitForm.unit_type === 1}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Normal Lesson Unit</label>
                          </div>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="unit_type"
                              id="drill lesson unit"
                              value="drill lesson unit"
                              checked={unitForm.unit_type === 2}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "normal lesson unit"
                                    ? 1
                                    : value === "drill lesson unit"
                                    ? 2
                                    : value;
                                setUnitForm({
                                  ...unitForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Drill Lesson Unit</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>
                          To edit unit sort order go back to previous page
                        </label>
                      </div>
                      <Button fluid primary>
                        Submit Changes
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !uploadingThumb
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingThumb(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingThumb && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {selectedSubUnit.thumbnail_url === "" ? (
                          <>
                            <h2>Add Thumbnail</h2>

                            <Uploader
                              folder={"lesson_unit_thumbnails/"}
                              fileName={`lesson_unit_thumbnails/lesson_unit_${selectedSubUnit.lesson_unit_id}`}
                              background={"/img/thumbnail-absent.png"}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedSubUnit}
                              id={selectedSubUnit.lesson_unit_id}
                              type={"lesson_unit_thumb"}
                            />
                          </>
                        ) : (
                          <>
                            <h2>Change Thumbnail</h2>

                            <Uploader
                              folder={"lesson_unit_thumbnails/"}
                              fileName={`lesson_unit_thumbnails/lesson_unit_${selectedSubUnit.lesson_unit_id}`}
                              background={selectedSubUnit.thumbnail_url}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedSubUnit}
                              id={selectedSubUnit.lesson_unit_id}
                              type={"lesson_unit_thumb"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className={
                  !uploadingVideo
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingVideo(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingVideo && isVerified && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {videoUrl === "" ? (
                          <>
                            <h2>Add Video</h2>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="/img/thumbnail-absent.png"
                                className="dragdrop-under-content"
                              />
                              <div className="dragdrop-overlay">
                                <VideoDragDrop
                                  type={"lesson_unit_video"}
                                  object={selectedSubUnit}
                                  setUploading={setUploadingVideo}
                                  // handleFileChange={handleFileChange}
                                  // hasDropped={hasDropped}
                                  // duplicateFound={duplicateFound}
                                  // uppingToBucket={uppingToBucket}
                                  // progress={progress}
                                  // uploadFile={uploadFile}
                                  // setDuplicateFound={setDuplicateFound}
                                  // setUploading={setUploading}
                                  // type={type}
                                />
                              </div>
                            </div>
                            {/* <Uploader
                              folder={"lesson_unit_videos/"}
                              fileName={`lesson_unit_videos/lesson_unit_${selectedSubUnit.lesson_unit_id}`}
                              background={
                                selectedSubUnit.thumbnail_url !== ""
                                  ? selectedSubUnit.thumbnail.url
                                  : "/img/thumbnail-absent.png"
                              }
                              // background={"/vid/adamscott.mp4"}
                              setUploading={setUploadingVideo}
                              setNew={setSelectedSubUnit}
                              relatedObject={selectedSubUnit}
                              id={selectedSubUnit.lesson_unit_id}
                              type={"lesson_unit_video"}
                            /> */}
                          </>
                        ) : (
                          <>
                            <h2>Change Video</h2>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="/img/thumbnail-absent.png"
                                className="dragdrop-under-content"
                              />
                              <div className="dragdrop-overlay">
                                <VideoDragDrop
                                  type={"lesson_unit_video"}
                                  object={selectedSubUnit}
                                  setUploading={setUploadingVideo}
                                  // handleFileChange={handleFileChange}
                                  // hasDropped={hasDropped}
                                  // duplicateFound={duplicateFound}
                                  // uppingToBucket={uppingToBucket}
                                  // progress={progress}
                                  // uploadFile={uploadFile}
                                  // setDuplicateFound={setDuplicateFound}
                                  // setUploading={setUploading}
                                  // type={type}
                                />
                              </div>
                            </div>
                            {/* <Uploader
                              folder={"lesson_unit_videos/"}
                              fileName={`lesson_unit_videos/lesson_unit_${selectedSubUnit.lesson_unit_id}`}
                              // background={videoUrl}
                              background={
                                selectedSubUnit.thumbnail_url !== ""
                                  ? selectedSubUnit.thumbnail.url
                                  : "/img/thumbnail-absent.png"
                              }
                              // background={"/vid/adamscott.mp4"}
                              setUploading={setUploadingVideo}
                              setNew={setSelectedSubUnit}
                              relatedObject={selectedSubUnit}
                              id={selectedSubUnit.lesson_unit_id}
                              type={"lesson_unit_video"}
                            /> */}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </AdminContainer>
    </>
  );
}
