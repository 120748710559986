import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List } from "../list";

export function VideoListCategories({ categories }) {
  let navigate = useNavigate();
  // console.log("category:", categories);
  return (
    <div className="categories">
      {categories.map((cat, idx) => {
        let videos = cat.videos;
        // console.log(videos);
        return (
          <div
            key={idx}
            className="categories__sections"
            onClick={() => {
              navigate("/swing_trainer/" + videos[idx].trainer_video_id);
            }}
          >
            <h4>{cat.name}</h4>
            <List videos={videos} />
          </div>
        );
      })}
    </div>
  );
}
