import * as React from "react";
// Handy Golf Libs
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAuth from "../lib/HandyGolfAuth";

// Materials
import { Button, Drawer, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

// Google Libraries
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export function SiteNav() {
  const [state, setState] = React.useState({
    sidemenu: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="header">
      <div className="header_content">
        <div className="logo">
          <Link to="/">
            <img
              src="/img/handy-golf-logo.svg"
              className="handy-golf-logo"
              alt="Handy Golf Logo"
            />
          </Link>
        </div>
        <div className="header_menu">
          <div>
            <NavLink to="/" className="header_menu">
              Home
            </NavLink>
          </div>
          <div>
            <NavLink to="/lessons" className="header_menu">
              Lessons
            </NavLink>
          </div>
          <div>
            <NavLink to="/about" className="header_menu">
              About
            </NavLink>
          </div>
          <div>
            <NavLink to="/support" className="header_menu">
              Support
            </NavLink>
          </div>
          <div>
            <NavLink to="/tools" className="header_menu">
              Tools
            </NavLink>
          </div>
          <div>
            <NavLink to="/store" className="header_menu">
              Store
            </NavLink>
          </div>
          <div>
            <NavLink to="/admin" className="header_menu">
              Admin
            </NavLink>
          </div>
        </div>
        <div className="account">
          <Link to="/user/profile">
            <Button>
              <AccountCircleIcon sx={{ color: "#0071bd", fontSize: 40 }} />
            </Button>
          </Link>
        </div>
        <div className="hamburger">
          <React.Fragment key="sidemenu">
            <Button onClick={toggleDrawer("sidemenu", true)}>
              <MenuIcon sx={{ color: "#0071bd", fontSize: 30 }} />
            </Button>
            <Drawer
              anchor="left"
              open={state["sidemenu"]}
              onClose={toggleDrawer("sidemenu", false)}
            >
              <div className="sidemenu">
                <div>
                  <NavLink to="/" onClick={toggleDrawer("sidemenu", false)}>
                    Home
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/lessons"
                    onClick={toggleDrawer("sidemenu", false)}
                  >
                    Lessons
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/about"
                    onClick={toggleDrawer("sidemenu", false)}
                  >
                    About
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/contact"
                    onClick={toggleDrawer("sidemenu", false)}
                  >
                    Contact
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/admin"
                    onClick={toggleDrawer("sidemenu", false)}
                  >
                    Admin
                  </NavLink>
                </div>
                <Divider sx={{ width: 250 }} />
                <div>
                  <NavLink
                    to="/user/profile"
                    onClick={toggleDrawer("sidemenu", false)}
                  >
                    Account
                  </NavLink>
                </div>
              </div>
            </Drawer>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}
