import React, { useEffect, useState } from "react";

// Handy Golf Libs
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAuth from "../lib/HandyGolfAuth";
import { useTheme } from "../providers";
import { useUpload } from "../context/UploadContext";
// Materials
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "./shared";
import MButton from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import { useUser } from "../providers";

export function AdminNavItem({ title, subNav, soloUrl, selectedNav }) {
  // const [currentPath, setCurrentPath] = useState()
  const { isVerified, verifyAdmin, uploads } = useUpload();
  // useEffect(()=>{

  // },[])

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const uploadsCompleted = () => {
    let count = 1;
    uploads.forEach((upload) => {
      if (upload.completed === true) {
        count += 1;
      }
    });
    return count;
  };
  const currentUploadProgress = () => {
    let current = uploads.find((upload) => upload.completed === false);
    return current.progress;
  };
  return (
    <>
      {subNav ? (
        <>
          <MButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={
              title === selectedNav
                ? { color: "#3ab549" }
                : { color: "#707070", fontWeight: "normal" }
            }
          >
            <div className="admin-navigation__navlink">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                {title}{" "}
                {open ? (
                  <i className="material-icons open">chevron_right</i>
                ) : (
                  <i className="material-icons">chevron_right</i>
                )}
              </div>
            </div>

            {/* {title} */}
            {/* ▼ */}
          </MButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {subNav.map((nav, idx) => {
              return (
                <MenuItem
                  onClick={handleClose}
                  key={idx}
                  style={{ padding: "0", width: "100%" }}
                >
                  <div className="admin-navigation__navlink">
                    <NavLink to={nav.url}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          padding: "8px 16px",
                        }}
                      >
                        {nav.name}
                      </div>
                    </NavLink>
                  </div>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        <>
          <MButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ padding: "0" }}
          >
            <div className="admin-navigation__navlink">
              <NavLink to={soloUrl}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    padding: "8px 16px",
                  }}
                >
                  {title}
                  {title === "Uploads" && (
                    <>
                      {uploads.length > 0 && (
                        <div style={{ paddingLeft: "14px" }}>
                          {/* <CircularProgress variant="determinate" value={66.67}> */}
                          <div style={{ fontSize: "10px" }}>
                            {uploadsCompleted() - 1 === uploads.length ? (
                              <>✔</>
                            ) : (
                              <div>
                                {uploadsCompleted()}/{uploads.length}
                                <div>
                                  <LinearProgress
                                    variant="determinate"
                                    value={currentUploadProgress()}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* </CircularProgress> */}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </NavLink>
            </div>
          </MButton>
        </>
      )}
    </>
  );
}
