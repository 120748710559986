import React, { useEffect, useState, useContext } from "react";
import { Button, Search } from "../../components";

import HandyGolfAPI from "../../lib/HandyGolfAPI";

export function PickDrillOrGT({
  videoType,
  setOpenPicker,
  setActivityToSubmit,
  activityType,
}) {
  const [videoList, setVideoList] = useState();
  const [selectedVideo, setSelectedVideo] = useState();

  //search fields
  const [searchString, setSearchString] = useState("");
  const [searchStringArray, setSearchStringArray] = useState([]);
  const [searchFilterVideos, setSearchFilterVideos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const getVideoList = () => {
    HandyGolfAPI.sendRequest("trainer/video/list", {
      query: { where: [{ name: "type", value: videoType }] },
    }).then((data) => {
      //   console.log(data);
      setVideoList(data);
      setSearchResults(data);
    });
  };

  useEffect(() => {
    if (!videoList) {
      getVideoList();
    }
  }, []);

  const searchStringCheck = (title, searchTerm) => {
    let titleSplit = title.split(" ");
    // let allSearchTerms = titleSplit.concat(catNames, equipNames);
    let allSearchTerms = titleSplit;
    for (let i = 0; i < allSearchTerms.length; i++) {
      if (allSearchTerms[i].includes(searchTerm)) {
        return true;
      }
    }
    return false;
  };
  const searchResultSetter = () => {
    // console.log(searchResults);
    console.log(searchString);

    let splitStringArray = searchString.split(" ");
    let properCapsStringArray = splitStringArray.map(
      (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    );

    if (properCapsStringArray.length > 0) {
      properCapsStringArray = properCapsStringArray.filter(
        (term) => term.length > 1
      );
      let mergedArray = [];

      properCapsStringArray.forEach((term) => {
        const singleSearchTermVideoArray = videoList.filter((video) =>
          // video.trainer_category_names.includes(term)
          searchStringCheck(
            video.name,
            // video.trainer_category_names,
            // video.trainer_equipment_names,
            term
          )
        );

        mergedArray = [...mergedArray, ...singleSearchTermVideoArray];
        // setSearchResults(...searchResults, ...singleSearchTermVideoArray);
      });
      const uniqueArrayNoDuplicates = [...new Set(mergedArray)];

      setSearchResults(uniqueArrayNoDuplicates);
    }
  };
  let searchTimer = null;

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
    // if (searchString.length > 1) {

    if (searchTimer == null) {
      searchTimer = setTimeout(() => {
        if (e.target.value === "") {
          setSearchResults(videoList);
        } else {
          searchResultSetter();
        }

        // console.log("searched times");
      }, 300);
    }
    // if (e.target.value === "") {
    //   setSearchResults(videoList);
    // }
    // }
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    // console.log(searchStringArray)
    clearTimeout(searchTimer);
    if (searchString === "") {
      //   console.log("empty string");
      setSearchResults(videoList);
    } else {
      searchResultSetter();
    }
  };
  const activitySetter = (video) => {
    let vidObject = {
      name: video.name,
      description: video.description,
      type: activityType,
      trainer_video_id: video.trainer_video_id,
      activity_length_in_seconds: video.video_length,
    };
    // setActivityVideo(vidObject);
    setActivityToSubmit(vidObject);
  };
  return (
    <div style={{ maxWidth: "500px", position: "relative" }}>
      <div className="admin-action-view__edit-button">
        <Button
          primary
          //   iconLeading="edit"
          onClick={() => {
            setOpenPicker(false);
          }}
        >
          Cancel
        </Button>
      </div>
      <h2>Choose New Activity</h2>
      <Search
        handleSearchChange={handleSearchChange}
        onSearchSubmit={onSearchSubmit}
      />
      <div>
        {searchResults && (
          <div className="picker-list">
            {searchResults.map((video, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    selectedVideo
                      ? selectedVideo.trainer_video_id ===
                        video.trainer_video_id
                        ? "picker-list__element picker-list__element--selected"
                        : "picker-list__element"
                      : "picker-list__element"
                  }
                  onClick={() => {
                    console.log(video);
                    if (selectedVideo) {
                      if (
                        selectedVideo.trainer_video_id ===
                        video.trainer_video_id
                      ) {
                        setSelectedVideo();
                      } else {
                        setSelectedVideo(video);
                      }
                    } else {
                      setSelectedVideo(video);
                    }
                  }}
                >
                  <div>{video.name}</div>
                  <div className="picker-list__thumbnail">
                    <img
                      src={
                        video.thumbnail_url !== ""
                          ? video.thumbnail_url
                          : "/img/thumbnail-absent.png"
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {selectedVideo && (
          <Button
            fluid
            primary
            onClick={() => {
              activitySetter(selectedVideo);
              // setDrillOrGTActivity(selectedVideo);
              //   setPicking(false);
              setOpenPicker(false);
            }}
          >
            Set Video
          </Button>
        )}
      </div>
    </div>
  );
}
