import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Webcam from "react-webcam";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { useUser } from "../providers";
import { useUpload } from "../context/UploadContext";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Card,
  Container,
  AppTopBar,
  BottomNav,
  Player,
  AdminNav,
  Button,
} from "../components";

import {
  StateContext,
  StateDispatchContext,
  CheckCacheContext,
} from "../context/AppContext";
import { AdminContainer } from "./index";
import HandyGolf from "../lib/HandyGolf";

export function AdminUploadDash() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const {
    isVerified,
    verifyAdmin,
    uploads,
    getTranscodeList,
    transcodeList,
    cleanupAll,
  } = useUpload();

  const [awaitCleanup, setAwaitCleanup] = useState();
  const [awaitTranscode, setAwaitTranscode] = useState();
  const [awaitUpload, setAwaitUpload] = useState();
  const [currentTranscode, setCurrentTranscode] = useState();
  // const [transcodeList, setTranscodeList] = useState();
  const [completedUploads, setCompletedUploads] = useState(0);

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    // console.log("hi, uploads changed");
    if (uploads === []) {
      setCompletedUploads(0);
    } else {
      let tempNum = 0;
      uploads.forEach((upload) => {
        if (upload.completed === true) {
          tempNum += 1;
        }
      });
      setCompletedUploads(tempNum);
    }
  }, [uploads]);
  useEffect(() => {
    console.log("completedUploads triggered!", completedUploads);
    HandyGolfAPI.sendRequest("video/getTranscodingVideoList").then((data) => {
      console.log(data);
      setAwaitTranscode(data.awaiting_transcoding);
      setAwaitCleanup(data.awaiting_cleanup);
      setAwaitUpload(data.awaiting_upload);
    });
  }, [completedUploads]);
  useEffect(() => {
    if (awaitTranscode) {
      console.log(awaitTranscode);
      getTranscodeList();
    }
  }, [awaitTranscode]);

  useEffect(() => {
    if (transcodeList) {
      if (awaitTranscode) {
        handleCurrentTranscode();
      }
    }
  }, [transcodeList]);
  // useEffect(()=>{
  //   if(awaitCleanup){

  //   }

  // },[awaitCleanup])

  const handleCurrentTranscode = () => {
    let newArray = [];
    let tempAwaitArray = [...awaitTranscode];
    tempAwaitArray.forEach((awaitingItem) => {
      let foundFile = getFileFromTranscodeList(awaitingItem.video_id);
      if (!foundFile) {
        let unfoundFile = { ...awaitingItem, transcoded: false, newUrl: "" };
        newArray.push(unfoundFile);
      } else {
        // "https://handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx.s3.us-east-2.amazonaws.com" +
        let url =
          HandyGolf.getValue("s3_transcoded_video_bucket_name") +
          "." +
          HandyGolf.getValue("s3_bucket_region") +
          "." +
          "amazonaws.com" +
          "/" +
          `${foundFile.Key}`;
        let newFoundFile = { ...awaitingItem, transcoded: true, newUrl: url };
        newArray.push(newFoundFile);
      }
    });
    console.log(newArray);
    setCurrentTranscode(newArray);
  };
  const getFileFromTranscodeList = (id) => {
    let possibleFile = transcodeList.find((file) =>
      file.Key.includes(`video_${id}.m3u8`)
    );
    if (possibleFile) {
      console.log("possibleFile found!", possibleFile);
      return possibleFile;
    }
    return false;
  };
  const deleteDeadRecords = (records) => {
    records.forEach((vid) =>
      HandyGolfAPI.sendRequest("video/delete", { video_id: vid.video_id }).then(
        (data) => {
          console.log(data);
          setCompletedUploads(completedUploads + 1);
        }
      )
    );
  };

  // let navigate = useNavigate();

  const finishTranscodable = (file) => {
    HandyGolfAPI.sendRequest("video/update", {
      video_id: file.video_id,
      video: { video_url: file.newUrl },
    }).then((data) => {
      console.log("transcodeupdated", data);
      let tempArr = [...currentTranscode];
      let idx = tempArr.findIndex((el) => el.video_id === file.video_id);
      tempArr.splice(idx, 1);
      setCurrentTranscode(tempArr);
      triggerLoad();
      setCompletedUploads(completedUploads + 1);
    });
  };
  const currentUploadProgress = () => {
    let current = uploads.find((upload) => upload.completed === false);
    return current.progress;
  };

  const triggerLoad = () => {
    setAwaitCleanup();
    setAwaitUpload();
    setAwaitTranscode();
  };
  return (
    <>
      <AdminContainer>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "2rem 0",
            // overflowY: "scroll",
          }}
        >
          <div
            // style={{
            //   width: "80%",

            // }}
            className="admin-action-edit-screen admin-action-edit-screen--open"
          >
            <div className="admin-overflow-scroll admin-overflow-scroll--centered">
              <div className="admin-info-half">
                {currentTranscode && awaitCleanup && awaitUpload && uploads && (
                  <>
                    {currentTranscode.length === 0 &&
                      awaitCleanup.length === 0 &&
                      awaitUpload.length === 0 &&
                      uploads.length === 0 && (
                        <div className="admin-info-half__title">
                          Nothing in Uploads!
                        </div>
                      )}
                  </>
                )}

                {uploads && uploads.length > 0 && (
                  <>
                    <div className="admin-info-half__title">
                      Currently Uploading
                    </div>
                    <div className="card-container">
                      {uploads.map((upload, idx) => {
                        return (
                          <div key={idx} className="admin-dash-card">
                            <>
                              <div>{upload.type}</div>
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {upload.object.name}
                              </div>

                              {upload.completed ? (
                                <>
                                  <strong>Finished</strong>
                                  <>
                                    <LinearProgress
                                      variant="determinate"
                                      value={100}
                                    />
                                  </>
                                </>
                              ) : (
                                <>
                                  {upload.progress === 0 ? (
                                    <>
                                      <strong>waiting...</strong>
                                      <>
                                        <LinearProgress variant="indeterminate" />
                                      </>
                                    </>
                                  ) : (
                                    <>
                                      <strong>
                                        Progress: {upload.progress}%
                                      </strong>
                                      <>
                                        <LinearProgress
                                          variant="determinate"
                                          value={currentUploadProgress()}
                                        />
                                      </>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {awaitCleanup && awaitCleanup.length > 0 && (
                  <>
                    <div className="admin-info-half__title">
                      Finalize & Delete HQ Source
                    </div>
                    <div
                      className="admin-info-half__infobox"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div>
                        <Button
                          secondary
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure? Finalizing will remove the high quality source file from storage!"
                              )
                            ) {
                              cleanupAll(awaitCleanup, triggerLoad);
                            }
                          }}
                        >
                          Finalize All
                        </Button>
                      </div>
                    </div>
                    <div className="card-container">
                      {awaitCleanup.map((file, idx) => {
                        return (
                          <div key={idx} className="admin-dash-card">
                            {file.name}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {currentTranscode && currentTranscode.length > 0 && (
                  <>
                    <div className="admin-info-half__title">Transcoding</div>
                    <div
                      className="admin-info-half__infobox"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div>
                        <Button
                          secondary
                          onClick={() => {
                            getTranscodeList();
                          }}
                        >
                          Check Transcode Status
                        </Button>
                        {uploads.length === 0 && awaitTranscode.length > 0 && (
                          <Button
                            secondary
                            onClick={() => {
                              // getTranscodeList();
                              deleteDeadRecords(awaitTranscode);
                            }}
                          >
                            Delete Records
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="card-container">
                      {currentTranscode.map((file, idx) => {
                        return (
                          <>
                            {file.transcoded ? (
                              <div key={idx} className="admin-dash-card">
                                {file.name}
                                <strong
                                  style={{ textAlign: "center" }}
                                  // onClick={() => {
                                  //   finishTranscodable(file);
                                  // }}
                                >
                                  Ready
                                </strong>
                                <Button
                                  secondary
                                  onClick={() => {
                                    finishTranscodable(file);
                                  }}
                                >
                                  Set Transcoded URL
                                </Button>
                              </div>
                            ) : (
                              <div key={idx} className="admin-dash-card">
                                {file.name}
                                <strong style={{ textAlign: "center" }}>
                                  Waiting...
                                </strong>

                                <Button
                                  secondary
                                  disabled
                                  onClick={() => {
                                    finishTranscodable(file);
                                  }}
                                >
                                  Set Transcoded URL
                                </Button>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </>
                )}

                {awaitUpload && awaitUpload.length > 0 && (
                  <>
                    <div className="admin-info-half__title">Uploading</div>
                    <div
                      className="admin-info-half__infobox"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div>
                        {uploads.length === 0 && awaitUpload.length > 0 && (
                          <Button
                            secondary
                            onClick={() => {
                              // getTranscodeList();
                              deleteDeadRecords(awaitUpload);
                            }}
                          >
                            Delete Records
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="card-container">
                      {awaitUpload.map((file, idx) => {
                        return (
                          <div key={idx} className="admin-dash-card">
                            {file.name}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </AdminContainer>
    </>
  );
}
