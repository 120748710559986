import * as React from "react";
import { useToast } from "../../../providers";
// import './Toaster.scss';
import CloseIcon from "@mui/icons-material/Close";
export function Toaster() {
  const { toasts, clickToast } = useToast();

  return (
    <div className="toasts">
      {toasts.map((toast, index) => {
        let toastClass = `toast toast--${toast.type}`;
        console.log(toast.type, "ye");
        return (
          <div
            className={toastClass}
            key={toast.id}
            onClick={() => {
              clickToast(index);
            }}
          >
            {toast.message}
            {/* prob dont need close icon but i figured styles
            for messages would be decided later */}
            <CloseIcon></CloseIcon>
          </div>
        );
      })}
    </div>
  );
}
