import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import { Button } from "./Button";
import { Height } from "@mui/icons-material";

export const Card = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  if (props.borderless) {
    return (
      <>
        <div
          className="borderless"
          style={{ backgroundImage: `url(${props.image})` }}
          onClick={props.onClick}
          id={props.id}
        >
          <div className="borderless__title-box">
            {/* <div className="card__title"> */}
            <div>{props.title}</div>
            <p>{props.description}</p>
          </div>
        </div>
      </>
    );
  }
  if (props.thumbnail) {
    return (
      <>
        <div
          className="thumbnail"
          style={{ backgroundImage: `url(${props.image})` }}
          onClick={props.onClick}
          id={props.id}
        >
          <strong>
            <p>{props.title}</p>
          </strong>
        </div>
      </>
    );
  }
  if (props.squareThumbInfo) {
    return (
      <>
        <div
          className="square-thumbnail-card"
          onClick={props.onClick}
          id={props.id}
        >
          <div
            className="square-thumbnail-card__thumb"
            style={{ backgroundImage: `url(${props.image})` }}
          ></div>
          <div className="square-thumbnail-card__textbox">
            <div>
              <strong>{props.title}</strong>
            </div>

            <div>{props.description}</div>
          </div>
        </div>
      </>
    );
  }
  if (props.wide) {
    return (
      <>
        <div className="wide">
          {/* <div style={{ padding: "20px" }}>
            <Card
              thumbnail
              image={
                "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg"
              }
            />
          </div>
          <div style={{ padding: "20px" }}>
            <>How it works</>
            <h2>This is completely temporary</h2>
            <p>
              asdf saf jkljwfilejlaskd jawfoeij fjiej jk jkj awfj jfeijoa j
              ifjel{" "}
            </p>
          </div> */}
          {props.children}
        </div>
      </>
    );
  }

  //card class builder
  let cardClass = "card";

  if (props.bordered) {
    cardClass += " card--bordered";
  }
  if (props.split) {
    cardClass += " card--split";
  }
  if (props.continue) {
    cardClass += " card--continue";
  }
  if (props.start) {
    cardClass += " card--start";
  }
  if (props.purchase) {
    cardClass += " card--purchase";
  }
  if (showInfo) {
    cardClass += " card__info";
  }

  const handleShowInfo = () => {
    setShowInfo((prev) => !prev);
    console.log();
  };

  return (
    <>
      <div className={cardClass} id={props.id}>
        {props.bordered && <p>{props.smallTitle}</p>}

        {/* {props.split && (
          <div style={{ textAlign: "right", width: "100%" }}>
            <i className="material-icons" onClick={handleShowInfo}>
              info_outline
            </i>
          </div>
        )} */}

        <img src={props.image} />

        {props.split && props.continue && !showInfo && (
          <LinearProgress
            color="success"
            variant="determinate"
            value={30}
            size={10}
          />
        )}
        {props.split && props.start && <div style={{ height: "4px" }}></div>}
        <div className="card__title">{props.title}</div>
        <p>{props.description}</p>

        {showInfo && (
          <>
            <p>{props.backInfo}</p>
            <br></br>
            <p>
              <strong>12 video lessons</strong>
            </p>
            <p>
              <strong>2 Hours 44 minutes</strong>
            </p>
          </>
        )}
        {props.bordered && props.continue && (
          <>
            <strong>
              {props.progressFraction[0].toString() +
                "/" +
                props.progressFraction[1].toString()}
            </strong>

            <LinearProgress
              color="success"
              variant="determinate"
              value={30}
              size={10}
            />
          </>
        )}
        {props.bordered && props.start && (
          <>
            <strong style={{ textAlign: "right" }}>
              {props.progressMinutes.toString() + " minutes left"}
            </strong>

            <LinearProgress
              color="success"
              variant="determinate"
              value={30}
              size={10}
            />
          </>
        )}

        {props.purchase && (
          <Button primary iconLeading="lock">
            Subscribe Now
          </Button>
        )}
        {props.split && props.start && (
          <Button
            primary
            style={{ backgroundColor: "white", color: "#091540" }}
            onClick={props.onClick}
          >
            Start Lesson
          </Button>
        )}
        {props.split && props.continue && (
          <Button
            primary
            style={{ backgroundColor: "white", color: "#091540" }}
            onClick={props.onClick}
          >
            Continue Lesson
          </Button>
        )}
      </div>
    </>
  );
};
