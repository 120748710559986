import React, { useState, useEffect } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { PickDrillOrGT, PickLesson } from "./index";
import { Button } from "../../components";

const initialMirrorForm = {
  name: "",
  description: "",
  activity_length_in_seconds: 50,
};
export function ActivityPicker({
  creating,
  sessionId,
  activityToSubmit,
  setActivityToSubmit,
  prevActivity,
}) {
  const [activityType, setActivityType] = useState();
  const [typeSelected, setTypeSelected] = useState();
  const [openPicker, setOpenPicker] = useState();
  const [mirrorForm, setMirrorForm] = useState(initialMirrorForm);
  //   const [activityToSubmit, setActivityToSubmit] = useState();

  //   useEffect(() => {
  //     if (creating) {
  //       setActivityToSubmit();
  //     }
  //   }, [creating]);

  const activityTypeConverter = (type) => {
    if (type === 0) {
      return "No Type Selected";
    }
    if (type === 1) {
      return "Drill Explanation";
    }
    if (type === 2) {
      return "Drill Practice";
    }
    if (type === 3) {
      return "Guided Training";
    }
    if (type === 4) {
      return "Lesson";
    }
    if (type === 5) {
      return "Mirror Mode";
    }
    if (type === "No Type Selected") {
      return 0;
    }
    if (type === "Drill Explanation") {
      return 1;
    }
    if (type === "Drill Practice") {
      return 2;
    }
    if (type === "Guided Training") {
      return 3;
    }
    if (type === "Lesson") {
      return 4;
    }
    if (type === "Mirror Mode") {
      return 5;
    }
  };
  //   console.log(activityType);
  const activityCreate = () => {
    let creationObj = {
      ...activityToSubmit,
      trainer_session_id: sessionId,
    };
    HandyGolfAPI.sendRequest("trainer/activity/create", {
      //   trainer_activity_id: datum.trainer_activity_id,
      trainer_activity: creationObj,
    })
      .then((data) => {
        console.log(data);
        // secondTable(datum.trainer_session_id, "activities");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMirrorFormChange = (e) => {
    setMirrorForm({
      ...mirrorForm,
      [e.target.name]: e.target.value,
    });
  };
  const mirrorActivitySetter = (e) => {
    e.preventDefault();
    let mirrorObj = {
      name: mirrorForm.name,
      description: mirrorForm.description,
      type: activityType,
      activity_length_in_seconds: mirrorForm.activity_length_in_seconds,
    };
    setActivityToSubmit(mirrorObj);
  };
  return (
    <>
      {creating ? (
        <>
          {/* <div> */}
          {!openPicker && (
            <>
              <h2>New Activity</h2>
              <select
                name="type"
                onChange={(e) => {
                  let convertedType = activityTypeConverter(e.target.value);
                  // dispatch({
                  //   type: "UPDATE_FIELD",
                  //   form: "activities",
                  //   field: "type",
                  //   value: convertedType,
                  // });

                  setActivityType(convertedType);
                  if (convertedType !== 0) {
                    setTypeSelected(true);
                  }
                }}
                style={{ height: "50px", fontSize: "20px" }}
              >
                <option selected={activityType === 0} value="No Type Selected">
                  No Type Selected
                </option>
                <option selected={activityType === 1} value="Drill Explanation">
                  Drill Explanation
                </option>
                <option selected={activityType === 2} value="Drill Practice">
                  Drill Practice
                </option>
                <option selected={activityType === 3} value="Guided Training">
                  Guided Training
                </option>
                <option selected={activityType === 4} value="Lesson">
                  Lesson
                </option>
                <option selected={activityType === 5} value="Mirror Mode">
                  Mirror Mode
                </option>
              </select>
              {activityToSubmit && (
                <>
                  <div className="admin-info-half">
                    <div className="admin-info-half__title">
                      Current Selected Activity
                    </div>
                    <div className="admin-info-half__infobox">
                      <div className="admin-info-half__key-value">
                        <label>Name:</label>
                        <strong>{activityToSubmit.name}</strong>
                      </div>
                      <div className="admin-info-half__key-value">
                        <label>Type:</label>
                        <strong>
                          {activityTypeConverter(activityToSubmit.type)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* </div> */}

          {(activityType === 1 || activityType === 2) && (
            <>
              {!openPicker && (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenPicker(true);
                  }}
                >
                  Select Drill
                </Button>
              )}

              {openPicker && (
                <PickDrillOrGT
                  videoType={1}
                  setOpenPicker={setOpenPicker}
                  setActivityToSubmit={setActivityToSubmit}
                  activityType={activityType}
                />
              )}
            </>
          )}

          {activityType === 3 && (
            <>
              {!openPicker && (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenPicker(true);
                  }}
                >
                  Select Guided Training
                </Button>
              )}
              {openPicker && (
                <PickDrillOrGT
                  videoType={2}
                  setOpenPicker={setOpenPicker}
                  setActivityToSubmit={setActivityToSubmit}
                  activityType={activityType}
                />
              )}
            </>
          )}
          {activityType === 4 && (
            <>
              {!openPicker && (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenPicker(true);
                  }}
                >
                  Select Lesson
                </Button>
              )}
              {openPicker && (
                <PickLesson
                  setOpenPicker={setOpenPicker}
                  setActivityToSubmit={setActivityToSubmit}
                  activityType={activityType}
                />
              )}
            </>
          )}
          {activityType === 5 && (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h2>Mirror Activity Form</h2>
                  <form className="admin-form" onSubmit={mirrorActivitySetter}>
                    <div>
                      <label>Activity Name</label>
                      <input
                        type="text"
                        name="name"
                        maxLength={50}
                        placeholder="Name for mirror activity"
                        value={mirrorForm.name}
                        onChange={handleMirrorFormChange}
                      />
                      <div className="admin-form__character-counter">
                        {mirrorForm.name.length > 0 ? (
                          <div>{mirrorForm.name.length}/50</div>
                        ) : (
                          <div style={{ opacity: "0" }}>0/0</div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Activity Description</label>
                      <input
                        type="text"
                        name="description"
                        maxLength={400}
                        placeholder="description of activity"
                        value={mirrorForm.description}
                        onChange={handleMirrorFormChange}
                      />
                      <div className="admin-form__character-counter">
                        {mirrorForm.description.length > 0 ? (
                          <div>{mirrorForm.description.length}/50</div>
                        ) : (
                          <div style={{ opacity: "0" }}>0/0</div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Activity Length</label>
                      <input
                        type="number"
                        name="activity_length_in_seconds"
                        value={mirrorForm.activity_length_in_seconds}
                        onChange={handleMirrorFormChange}
                      />
                    </div>
                    <Button fluid primary>
                      Set Activity
                    </Button>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {!openPicker && (
            <>
              <h2>Edit Activity</h2>
              <select
                name="type"
                onChange={(e) => {
                  let convertedType = activityTypeConverter(e.target.value);
                  // dispatch({
                  //   type: "UPDATE_FIELD",
                  //   form: "activities",
                  //   field: "type",
                  //   value: convertedType,
                  // });

                  setActivityType(convertedType);
                  if (convertedType !== 0) {
                    setTypeSelected(true);
                  }
                }}
                style={{ height: "50px", fontSize: "20px" }}
              >
                <option selected={activityType === 0} value="No Type Selected">
                  No Type Selected
                </option>
                <option selected={activityType === 1} value="Drill Explanation">
                  Drill Explanation
                </option>
                <option selected={activityType === 2} value="Drill Practice">
                  Drill Practice
                </option>
                <option selected={activityType === 3} value="Guided Training">
                  Guided Training
                </option>
                <option selected={activityType === 4} value="Lesson">
                  Lesson
                </option>
                <option selected={activityType === 5} value="Mirror Mode">
                  Mirror Mode
                </option>
              </select>
              {activityToSubmit && (
                <>
                  <div className="admin-info-half">
                    <div className="admin-info-half__title">
                      Current Selected Activity
                    </div>
                    <div className="admin-info-half__infobox">
                      <div className="admin-info-half__key-value">
                        <label>Name:</label>
                        <strong>{activityToSubmit.name}</strong>
                      </div>
                      <div className="admin-info-half__key-value">
                        <label>Type:</label>
                        <strong>
                          {activityTypeConverter(activityToSubmit.type)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {(activityType === 1 || activityType === 2) && (
            <>
              {!openPicker && (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenPicker(true);
                  }}
                >
                  Select Drill
                </Button>
              )}

              {openPicker && (
                <PickDrillOrGT
                  videoType={1}
                  setOpenPicker={setOpenPicker}
                  setActivityToSubmit={setActivityToSubmit}
                  activityType={activityType}
                />
              )}
            </>
          )}

          {activityType === 3 && (
            <>
              {!openPicker && (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenPicker(true);
                  }}
                >
                  Select Guided Training
                </Button>
              )}
              {openPicker && (
                <PickDrillOrGT
                  videoType={2}
                  setOpenPicker={setOpenPicker}
                  setActivityToSubmit={setActivityToSubmit}
                  activityType={activityType}
                />
              )}
            </>
          )}
          {activityType === 4 && (
            <>
              {!openPicker && (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenPicker(true);
                  }}
                >
                  Select Lesson
                </Button>
              )}
              {openPicker && (
                <PickLesson
                  setOpenPicker={setOpenPicker}
                  setActivityToSubmit={setActivityToSubmit}
                  activityType={activityType}
                />
              )}
            </>
          )}
          {activityType === 5 && (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h2>Mirror Activity Form</h2>
                  <form className="admin-form" onSubmit={mirrorActivitySetter}>
                    <div>
                      <label>Activity Name</label>
                      <input
                        type="text"
                        name="name"
                        maxLength={50}
                        placeholder="Name for mirror activity"
                        value={mirrorForm.name}
                        onChange={handleMirrorFormChange}
                      />
                      <div className="admin-form__character-counter">
                        {mirrorForm.name.length > 0 ? (
                          <div>{mirrorForm.name.length}/50</div>
                        ) : (
                          <div style={{ opacity: "0" }}>0/0</div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Activity Description</label>
                      <input
                        type="text"
                        name="description"
                        maxLength={400}
                        placeholder="description of activity"
                        value={mirrorForm.description}
                        onChange={handleMirrorFormChange}
                      />
                      <div className="admin-form__character-counter">
                        {mirrorForm.description.length > 0 ? (
                          <div>{mirrorForm.description.length}/50</div>
                        ) : (
                          <div style={{ opacity: "0" }}>0/0</div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Activity Length</label>
                      <input
                        type="number"
                        name="activity_length_in_seconds"
                        value={mirrorForm.activity_length_in_seconds}
                        onChange={handleMirrorFormChange}
                      />
                    </div>
                    <Button fluid primary>
                      Set Activity
                    </Button>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
