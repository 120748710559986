import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AdminTable, Uploader, setFormDefaults, AdminContainer } from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";

const initialCategoryForm = {
  name: "",
  parent_trainer_category_id: 0,
};

export function AdminCategories() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    child,
    // grand_child,
    id_param: parent_id,
    id_param2: child_id,
    // id_param3: grandchild_id,
  } = useParams();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //categories
  const [parentCategoryList, setParentCategoryList] = useState();
  const [selectedParent, setSelectedParent] = useState();
  const [editingParent, setEditingParent] = useState();
  const [childCategoryList, setChildCategoryList] = useState();

  const [selectedChild, setSelectedChild] = useState();
  const [editingChild, setEditingChild] = useState();
  //form
  const [categoryForm, setCategoryForm] = useState(initialCategoryForm);
  const updateURL = (newPath) => {
    const currentPath = location.pathname;

    // Avoid duplicating paths
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath, { replace: true });
    }
  };

  const getCategories = () => {
    HandyGolfAPI.sendRequest("trainer/category/list")
      .then((data) => {
        // console.log(data);
        // setParentCategoryList(data);
        let parentCats = data.filter(
          (cat) => cat.parent_trainer_category_id === 0
        );
        let childCats = data.filter(
          (cat) => cat.parent_trainer_category_id !== 0
        );
        setParentCategoryList(parentCats);
        setChildCategoryList(childCats);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);

  useEffect(() => {
    if (!selectedParent) {
      getCategories();
    }
  }, [selectedParent]);
  useEffect(() => {
    if (parentCategoryList) {
      if (parent_id) {
        let currentParent = parentCategoryList.find(
          (el) => el.trainer_category_id === parseInt(parent_id)
        );
        console.log(currentParent);
        if (currentParent) {
          setSelectedParent(currentParent);
          setEditingParent(true);
        }
      }
      if (childCategoryList) {
        if (child_id) {
          let currentChild = childCategoryList.find(
            (el) => el.trainer_category_id === parseInt(child_id)
          );
          console.log(currentChild);
          if (currentChild) {
            setSelectedChild(currentChild);
            setEditingChild(true);
          }
        }
      }
    }
  }, [parentCategoryList, childCategoryList]);

  const filterChildCategories = (data) => {
    let childCats = data.filter(
      (cat) =>
        cat.parent_trainer_category_id === selectedParent.trainer_category_id
    );
    return childCats;
  };

  const handleCategoryFormChange = (e) => {
    setCategoryForm({
      ...categoryForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleParentCreateSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/category/create", {
      trainer_category: categoryForm,
    })
      .then((data) => {
        let parentList = parentCategoryList;
        let newParent = data.data;
        parentList.push(newParent);
        setParentCategoryList(parentList);
        setCategoryForm(initialCategoryForm);
        setCreating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChildCreateSubmit = (e) => {
    e.preventDefault();
    let childForm = {
      ...categoryForm,
      parent_trainer_category_id: selectedParent.trainer_category_id,
    };
    HandyGolfAPI.sendRequest("trainer/category/create", {
      trainer_category: childForm,
    })
      .then((data) => {
        let childList = childCategoryList;
        let newChild = data.data;
        childList.push(newChild);
        setChildCategoryList(childList);
        setCategoryForm(initialCategoryForm);
        setCreating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleParentEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/category/update", {
      trainer_category_id: selectedParent.trainer_category_id,
      trainer_category: categoryForm,
    })
      .then((data) => {
        setSelectedParent(data.data);
        setCategoryForm(initialCategoryForm);
        setEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChildEditSubmit = (e) => {
    e.preventDefault();
    let childForm = {
      ...categoryForm,
      parent_trainer_category_id: selectedParent.trainer_category_id,
    };
    HandyGolfAPI.sendRequest("trainer/category/update", {
      trainer_category_id: selectedChild.trainer_category_id,
      trainer_category: childForm,
    }).then((data) => {
      setSelectedChild(data.data);
      setCategoryForm(initialCategoryForm);
      setEditing(false);
    });
  };
  const handleSelectParent = (item) => {
    updateURL(`category/${item.trainer_category_id}`);
    setSelectedParent(item);
  };
  const handleSelectChild = (item) => {
    updateURL(`sub_category/${item.trainer_category_id}`);
    setSelectedChild(item);
  };
  const removeLastTwoSegments = () => {
    const currentPath = location.pathname;
    const segments = currentPath.split("/");

    if (segments.length > 3) {
      const newPath = segments.slice(0, -2).join("/");

      navigate(newPath, { replace: true });
    }
  };
  return (
    <>
      <AdminContainer>
        {parentCategoryList && (
          <div className="admin-table-half">
            <div className="admin-table-half__title">
              Parent Categories
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm();
                  setCreating(true);
                }}
              >
                <i className="material-icons">add</i>
                <strong>New Category</strong>
              </div>
            </div>
            <AdminTable
              tableData={parentCategoryList}
              headers={["name"]}
              // setSelectedItem={setSelectedParent}
              setSelectedItem={handleSelectParent}
              setEditingItem={setEditingParent}
              // typeConverter={videoTypeConverter}
            />
          </div>
        )}
        <div
          className={
            !creating
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                setCreating(false);
                //   setLessonForm(initialLessonForm);
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h2>New Parent Category</h2>
              <form onSubmit={handleParentCreateSubmit} className="admin-form">
                <div>
                  <input
                    type="text"
                    maxLength={50}
                    name="name"
                    placeholder="name for new category"
                    value={categoryForm.name}
                    onChange={handleCategoryFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {categoryForm.name.length > 0 ? (
                      <div>{categoryForm.name.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <Button fluid primary>
                  Submit New Category
                </Button>
              </form>
            </div>
          </div>
        </div>
        <div
          className={
            !editingParent
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingParent(false);
                setSelectedParent();
              }}
            >
              Close
            </Button>
          </div>
          {selectedParent && (
            <>
              <div className="admin-overflow-scroll">
                <div className="admin-info-half">
                  <div className="admin-info-half__title">
                    Category Details
                    <div
                      className="admin-edit-item-button"
                      onClick={() => {
                        // setLessonForm(selectedLesson);
                        // setCategoryForm({ name: selectedParent.name });
                        setFormDefaults(
                          selectedParent,
                          categoryForm,
                          setCategoryForm
                        );
                        setEditing(true);
                      }}
                    >
                      <i className="material-icons">edit</i>
                      <strong>Edit</strong>
                    </div>
                  </div>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__key-value">
                      <label>Name:</label>
                      <strong>{selectedParent.name}</strong>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "40px",
                    }}
                  >
                    <div
                      className="admin-edit-item-button"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you SURE you want to PERMANENTLY DELETE this PARENT CATEGORY and all of its contents?"
                          )
                        ) {
                          HandyGolfAPI.sendRequest("trainer/category/delete", {
                            trainer_category_id:
                              selectedParent.trainer_category_id,
                          }).then((data) => {
                            //   console.log(data);
                            setEditingParent(false);
                            setSelectedParent();
                          });
                        }
                      }}
                    >
                      <i className="material-icons">delete</i>
                      <strong>Delete</strong>
                    </div>
                  </div>
                </div>
                {childCategoryList && (
                  <div className="admin-table-half">
                    <div className="admin-table-half__title">
                      Child Categories
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setCreating(true);
                        }}
                      >
                        <i className="material-icons">add</i>
                        <strong>New Child</strong>
                      </div>
                    </div>
                    <AdminTable
                      tableData={filterChildCategories(childCategoryList)}
                      headers={["name"]}
                      // setSelectedItem={setSelectedChild}
                      setSelectedItem={handleSelectChild}
                      setEditingItem={setEditingChild}
                    />
                  </div>
                )}
              </div>
              <div
                className={
                  !creating
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setCreating(false);
                      //   setLessonForm(initialLessonForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>New Child Category</h2>
                    <form
                      onSubmit={handleChildCreateSubmit}
                      className="admin-form"
                    >
                      <div>
                        <input
                          type="text"
                          maxLength={50}
                          name="name"
                          placeholder="name for new category"
                          value={categoryForm.name}
                          onChange={handleCategoryFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {categoryForm.name.length > 0 ? (
                            <div>{categoryForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <Button fluid primary>
                        Submit New Category
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !editing
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEditing(false);
                      setCategoryForm(initialCategoryForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      Edit <br></br>'{selectedParent.name}' Category
                    </h2>
                    <form
                      onSubmit={handleParentEditSubmit}
                      className="admin-form"
                    >
                      <div>
                        <input
                          type="text"
                          maxLength={50}
                          name="name"
                          placeholder={selectedParent.name}
                          value={categoryForm.name}
                          onChange={handleCategoryFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {categoryForm.name.length > 0 ? (
                            <div>{categoryForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <Button fluid primary>
                        Submit Edit
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !editingChild
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      removeLastTwoSegments();
                      setEditingChild(false);
                      setSelectedChild();
                    }}
                  >
                    Close
                  </Button>
                </div>
                {selectedChild && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        <div className="admin-info-half__title">
                          Child Category Details
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              // setLessonForm(selectedLesson);
                              // setCategoryForm({
                              //   name: selectedChild.name,
                              // });
                              setFormDefaults(
                                selectedChild,
                                categoryForm,
                                setCategoryForm
                              );
                              setEditing(true);
                            }}
                          >
                            <i className="material-icons">edit</i>
                            <strong>Edit</strong>
                          </div>
                        </div>
                        <div className="admin-info-half__infobox">
                          <div className="admin-info-half__key-value">
                            <label>Name:</label>
                            <strong>{selectedChild.name}</strong>
                          </div>
                        </div>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you SURE you want to PERMANENTLY DELETE this CHILD CATEGORY and all of its contents?"
                                )
                              ) {
                                HandyGolfAPI.sendRequest(
                                  "trainer/category/delete",
                                  {
                                    trainer_category_id:
                                      selectedChild.trainer_category_id,
                                  }
                                ).then((data) => {
                                  console.log(data);
                                  setEditingChild(false);
                                  setSelectedChild();
                                });
                              }
                            }}
                          >
                            <i className="material-icons">delete</i>
                            <strong>Delete</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        !editing
                          ? "admin-action-edit-screen"
                          : "admin-action-edit-screen admin-action-edit-screen--open"
                      }
                    >
                      <div className="admin-action-view__edit-button">
                        <Button
                          primary
                          iconLeading="expand_more"
                          onClick={() => {
                            setEditing(false);
                            setCategoryForm(initialCategoryForm);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <h2>
                            Edit <br></br>'{selectedChild.name}' Category
                          </h2>
                          <form
                            onSubmit={handleChildEditSubmit}
                            className="admin-form"
                          >
                            <div>
                              <input
                                type="text"
                                maxLength={50}
                                name="name"
                                placeholder={selectedChild.name}
                                value={categoryForm.name}
                                onChange={handleCategoryFormChange}
                              />
                              <div className="admin-form__character-counter">
                                {categoryForm.name.length > 0 ? (
                                  <div>{categoryForm.name.length}/50</div>
                                ) : (
                                  <div style={{ opacity: "0" }}>0/0</div>
                                )}
                              </div>
                            </div>
                            <Button fluid primary>
                              Submit Edit
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </AdminContainer>
    </>
  );
}
