import React, { useState, useEffect } from "react";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { Card, Button } from "../components";
import { LinearProgress } from "@mui/material";
import { AppTopBar, Footer, BottomNav } from "../components";

export function Dashboard() {
  const [continueVid, setContinueVid] = useState();
  const [recommendedVid, setRecommendedVid] = useState();
  useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("trainer/getUserContinueWatching", {
      type: 1,
    }).then((data) => {
      console.log(data[0]);
      if (data[0]) {
        setContinueVid(data[0]);
      }

      // setUnFinishedVideos(data);
    });
    HandyGolfAPI.sendRequest("trainer/getUserRecommendations", {
      type: 1,
    }).then((data) => {
      console.log(data);
      if (data[0]) {
        setRecommendedVid(data[0]);
      }
      // setRecommendedVideos(data);
    });
  }, []);

  const image =
    "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg";
  const loremIpsum =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.";
  if (!continueVid) {
    return <></>;
  }
  return (
    <>
      <AppTopBar />
      <div className="container">
        <div className="container__content">
          <div className="pad-box scroll-content-mobile">
            <div className="card-container">
              <Card
                bordered
                continue
                smallTitle="Keep watching"
                title={continueVid.name}
                image={continueVid.thumbnail_url}
                description={continueVid.description}
                progressFraction={[continueVid.video_viewed_until_seconds, 80]}
              />
              <Card
                bordered
                start
                smallTitle="Next to practice"
                title="todoTODO"
                image={image}
                description="But this will let you do thumbs, animations, etc way easier!"
                // progressFraction={[3, 12]}
                progressMinutes={10}
              />

              <Card
                bordered
                purchase
                smallTitle="Recommended for you"
                title="Drill Name"
                image={image}
                description="There was a todo here, not anymore"
              />
            </div>
            {/* <Card wide /> */}
          </div>
        </div>
        <div className="container__collapse"></div>
      </div>
      {/* <Footer /> */}
      <BottomNav />
    </>
  );
}
