import React, { useEffect, useState } from "react";

// import HandyGolfAPI from "../../lib/HandyGolfAPI";
import SportsGolfIcon from "@mui/icons-material/SportsGolf";

const today = Date.now();
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
function GenerateCalendar(pastActivityDays) {
  let tiles = [];
  // console.log(pastActivityDays);
  // the key=i thing was just to get rid of an annoying warning, its not essential.
  for (let i = 6; i >= 0; i--) {
    if (i === 0) {
      if (pastActivityDays.includes(i)) {
        tiles.push(
          <div key={i} className="calendar__tile calendar__tile--last">
            {getWeekdays(i)}
            <SportsGolfIcon />
          </div>
        );
      } else {
        tiles.push(
          <div key={i} className="calendar__tile calendar__tile--last">
            {getWeekdays(i)}
            <p>
              continue
              <br /> training
            </p>
          </div>
        );
      }
    } else {
      if (pastActivityDays.includes(i)) {
        tiles.push(
          <div key={i} className="calendar__tile">
            {getWeekdays(i)}
            <SportsGolfIcon />
          </div>
        );
      } else {
        tiles.push(
          <div key={i} className="calendar__tile">
            {getWeekdays(i)}
          </div>
        );
      }
    }
  }

  return tiles;
}

function getWeekdays(daysAgo) {
  let day = new Date(today - daysAgo * 86400000);
  //   let weekday = dayTranslator(day.getDay());
  let weekday = dayNames[day.getDay()];

  let date = day.getDate();
  let month = day.getMonth() + 1;
  if (daysAgo === 0) {
    return (
      <div className="calendar__dayAndDate">
        <p>Today</p>
        {/* <p>{`${month}/${date}`}</p> */}
      </div>
    );
  }
  return (
    <div className="calendar__dayAndDate">
      <p>{weekday}</p>
      {/* <p>{`${month}/${date}`}</p> */}
    </div>
  );
}

export function Calendar({ userActivity }) {
  // console.log(userActivity);
  let daysWithActivity = userActivity.recent.activity_timestamps;
  let pastActivityDays = [];
  let overallMinutes = userActivity.overall.watched_seconds / 60;

  daysWithActivity.forEach((day) => {
    let daySinceActivity =
      Math.floor(today / 86400000) - Math.floor(day / 86400000);

    pastActivityDays.push(daySinceActivity);
  });

  return (
    <div className="calendar-wrapper">
      <h1>Great Job! You trained hard this week!</h1>
      <h4>You've watched {overallMinutes} minutes of training this week!</h4>
      <div className="calendar">{GenerateCalendar(pastActivityDays)}</div>
      <h4>Studies show daily practice is essential for mastery!</h4>
    </div>
  );
}
