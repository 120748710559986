import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AdminTable, Uploader, setFormDefaults, AdminContainer } from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";

const initialForm = {};

export function AdminRequests() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    // child,
    // grand_child,
    id_param: parent_id,
    // id_param2: child_id,
    // id_param3: grandchild_id,
  } = useParams();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //requests
  const [requestList, setRequestList] = useState();
  const [selectedRequest, setSelectedRequest] = useState();
  const [editingRequest, setEditingRequest] = useState();
  //form
  //no forms yet
  const updateURL = (newPath) => {
    const currentPath = location.pathname;
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath);
    }
  };
  const getRequests = () => {
    HandyGolfAPI.sendRequest("user/request/list")
      .then((data) => {
        setRequestList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    if (!selectedRequest) {
      getRequests();
    }
  }, [selectedRequest]);
  useEffect(() => {
    if (requestList) {
      if (parent_id) {
        let currentParent = requestList.find(
          (el) => el.user_request_id === parseInt(parent_id)
        );
        if (currentParent) {
          setSelectedRequest(currentParent);
          setEditingRequest(true);
        }
      }
    }
  }, [requestList]);
  useEffect(() => {
    if (!parent_id) {
      setEditingRequest();
      setSelectedRequest();
    } else {
      HandyGolfAPI.sendRequest("user/request/find", {
        user_request_id: parseInt(parent_id),
      }).then((data) => {
        setSelectedRequest(data);
        setEditingRequest(true);
      });
    }
  }, [parent_id]);

  const requestTypeConverter = (type) => {
    if (type === 1) {
      return "Bug";
    }
    if (type === 2) {
      return "Suggestion";
    }
  };

  const handleSelectRequest = (item) => {
    updateURL(`request/${item.user_request_id}`);
    setSelectedRequest(item);
  };
  const removeLastTwoSegments = () => {
    const currentPath = location.pathname;
    const segments = currentPath.split("/");

    if (segments.length > 3) {
      const newPath = segments.slice(0, -2).join("/");

      navigate(newPath);
    }
  };
  return (
    <>
      <AdminContainer>
        {requestList && (
          <div className="admin-table-half">
            <div className="admin-table-half__title">
              User Requests
              {/* <div
                className="admin-edit-item-button"
                onClick={() => {
                  HandyGolfAPI.sendRequest("user/request/create", {
                    user_request: {
                      user_id: user.user_id,
                      request_type: 2,
                      message:
                        "So my problem is how bad all the admin pages are, they just look terrible.lore magna aliqua. Ut enim ad minim veniam.",
                    },
                  }).then((data) => {
                    console.log(data);
                  });
                }}
              >
                <i className="material-icons">add</i>
                <strong>New Request</strong>
              </div> */}
            </div>
            <AdminTable
              tableData={requestList}
              headers={[
                "sent_on",
                "request_type",
                "user_full_name",

                // "screen_details",
                "message",
              ]}
              idType={"user_request_id"}
              setSelectedItem={handleSelectRequest}
              setEditingItem={setEditingRequest}
              typeConverter={requestTypeConverter}
            />
          </div>
        )}
        {/* where creating would go */}
        <div
          className={
            !editingRequest
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingRequest(false);
                setSelectedRequest();
              }}
            >
              Close
            </Button>
          </div>
          {selectedRequest && (
            <>
              <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                <div className="admin-info-half" style={{ maxWidth: "560px" }}>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__section-title">
                      {/* {selectedRequest.name} Category */}Request
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          //   setFormDefaults(
                          //     selectedCategory,
                          //     categoryForm,
                          //     setCategoryForm
                          //   );
                          //   setEditing(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Name:</label>
                      <strong>{selectedRequest.user_full_name}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Email:</label>
                      <strong>{selectedRequest.user_email}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Request Type:</label>
                      <strong>
                        {requestTypeConverter(selectedRequest.request_type)}
                      </strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Date Submitted:</label>
                      <strong>{selectedRequest.sent_on}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Screen Details:</label>
                      <strong>{selectedRequest.screen_details}</strong>
                    </div>
                    <div className="admin-info-half__description">
                      <label>Request Text:</label>
                      <div>{selectedRequest.message}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "24px",
                    }}
                  >
                    <div
                      className="admin-edit-item-button"
                      style={{ paddingBottom: "0" }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you SURE you want to PERMANENTLY DELETE this user request?"
                          )
                        ) {
                          HandyGolfAPI.sendRequest("user/request/delete", {
                            user_request_id: selectedRequest.user_request_id,
                          }).then((data) => {
                            console.log(data);
                            setEditingRequest(false);
                            setSelectedRequest();
                          });
                        }
                      }}
                    >
                      <i className="material-icons">delete</i>
                      <strong>Delete</strong>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </AdminContainer>
    </>
  );
}
