import React, { useEffect } from "react";

import { Footer, AppNav, AppTopBar, AdminNav } from "../components";

import { AppRoutes } from "../routes";
import { Toaster } from "../components";
import { useTheme, useUser } from "../providers";

export const AdminLayout = () => {
  const [theme] = useTheme();
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  // if (theme === 1) {
  //   document.documentElement.classList.add("dark");
  // }
  useEffect(() => {
    if (theme === 0) {
      document.documentElement.classList.remove("dark");
    }
    if (theme === 1) {
      document.documentElement.classList.add("dark");
    }
  }, [theme]);
  return (
    <div className="admin-app-wrapper">
      <div className="admin-app">
        <Toaster />
        {adminMode ? (
          // <div className="admin-app__sidebar">
          //   <AdminNav />
          // </div>
          <></>
        ) : (
          <div className="admin-app__sidebar">
            <AppNav />
          </div>
        )}

        <div className="admin-app__body">
          {/* <Nav /> */}
          {/* <AppTopBar /> */}

          <AppRoutes />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
