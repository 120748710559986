import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import screenfull from "screenfull";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import {
  Card,
  Container,
  AppTopBar,
  BottomNav,
  Controls,
  Button,
  MobileControls,
  PracticeControls,
} from "..";

// Video Library
import ReactPlayer from "react-player";

//mui
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const videoConstraints = {
  // width: 1280,
  // height: 720,
  facingMode: "user",
};

const practiceVideoDefaultSettings = {
  repRange: 5,
  setRange: 5,
  playbackRate: 1,
  endRepDelay: 0,
  endSetDelay: 3,
  cameraPosition: "alternating",
};
let count = 0;

let repCount = 0;
let setCount = 1;
let setTimer = 0;
let totalTimer = 0;

const practiceIntroVid = "/vid/handy-golf-practice.m4v";
const downTheLine = "/vid/downtheline.mp4";
const faceOn = "/vid/faceon.mp4";
let practiceUrl = downTheLine;
export function Player({
  videoRef,
  url,
  light,
  saveFeedback,
  lessonDetails,
  updateLessonDetails,
  dispatchHandler,
  // currentSubUnit,
  // loadLessonUnitData,
  // lesson,
  feedback_display,
  setFeedbackDisplay,
  feedback_rating,
  feedback_text,
  prevSubUnitId,
  nextSubUnitId,
  // userLessonData,
  // setVideoTime,
  isTrainer,
  practiceMode,
  openingPracticeMode,
  setOpeningPracticeMode,
  defaultCompareMode,
}) {
  // console.log(feedback_display);
  //   const [videoState, setVideoState] = useState({
  //     playing: true,
  //     muted: false,
  //     volume: 0.5,
  //     playbackRate: 1.0,
  //     played: 0,
  //     seeking: false,
  //     buffer: true,
  //   });

  //   //Destructuring the properties from the videoState
  //   const { playing, muted, volume, playbackRate, played, seeking, buffer } =
  //     videoState;

  const [video_levels, setVideoLevels] = useState([]);
  const [video_playing, setVideoPlaying] = useState(false);
  // const [video_time, setVideoTime] = useState(0);
  const [played, setPlayed] = useState(0);
  const [video_muted, setVideoMuted] = useState(false);
  const [video_volume, setVideoVolume] = useState(0.5);
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState();
  const [compareMode, setCompareMode] = useState(
    defaultCompareMode ? true : false
  );
  const [mirror, setMirror] = useState(false);
  const [cameraMirror, setCameraMirror] = useState(true);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isBetweenSets, setIsBetweenSets] = useState();
  const [practiceEnded, setPracticeEnded] = useState(false);

  const [practiceVideoSettings, setPracticeVideoSettings] = useState(
    practiceVideoDefaultSettings
  );
  // const [repDelayTimerOpen, setRepDelayTimerOpen] = useState(false)
  const [delayTimerOpen, setDelayTimerOpen] = useState(false);
  // const [introUp, setIntroUp] = useState();

  const { trainer_video_id } = useParams();

  // const videoRef = useRef(null);
  const introVidRef = useRef(null);
  const webcamRef = useRef(null);
  const controlRef = useRef(null);
  const mobileControlRef = useRef(null);
  const playerContainerRef = useRef(null);
  // const {
  //   lesson,
  //   completedSubUnits,
  //   numUnits,
  //   sub,
  //   subNotes,
  //   unitCompletion,
  //   unitNotes,
  //   prevSub,
  //   nextSub,
  // } = lessonDetails;

  const currentTime = videoRef.current ? videoRef.current.getCurrentTime() : 0;
  const duration = videoRef.current ? videoRef.current.getDuration() : 0;

  const readyHandler = (e) => {
    controlRef.current.className = "video-controls";
    mobileControlRef.current.className = "video-controls-mobile";
    // practiceUrl = downTheLine;
  };

  const progressHandler = (e) => {
    // console.log(currentTime);
    if (count > 3) {
      // console.log("close");
      controlRef.current.className = "video-controls";
      mobileControlRef.current.className = "video-controls-mobile";
      document.body.style.cursor = "none";
      setPopoverOpen(false);
    } else if (
      controlRef.current.className === "video-controls  video-controls--open" ||
      mobileControlRef.current.className ===
        "video-controls-mobile video-controls-mobile--open"
    ) {
      count += 1;
      document.body.style.cursor = "default";
    }

    if (practiceMode) {
      if (repCount <= practiceVideoSettings.repRange && video_playing) {
        setTimer += 1;
      }
      if (e.played === 1) {
        // setPlayed(0);
        console.log("here after the countdown");
        handleRepComplete();
        // setVideoPlaying(false);
        // repCount += 1;
        // videoRef.current.seekTo(0);

        // if (practiceVideoSettings.endRepDelay > 0) {
        // handleRepDelay();
        // setVideoPlaying(false);
        // setTimeout(
        //   setVideoPlaying(true),
        //   practiceVideoSettings.endRepDelay * 1000
        // );
        // }
        // if(setCount -1 !==practiceVideoSettings.setRange){}

        // console.log(`reps of set ${setCount}`, repCount);
        // console.log("set", setCount);
        // setPlayed(0);
      }
      // if (repCount >= practiceVideoSettings.repRange) {
      //   if (practiceVideoSettings.cameraPosition === "alternating") {
      //     practiceUrl = practiceUrl === faceOn ? downTheLine : faceOn;
      //   }

      //   repCount = 0;
      //   setCount += 1;
      //   setTimer = 0;
      //   if (e.played === 1) {
      //     if (setCount - 1 >= practiceVideoSettings.setRange) {
      //       setVideoPlaying(false);
      //       setPracticeEnded(true);
      //       repCount = 0;
      //       setCount = 1;
      //       setTimer = 0;
      //     } else {
      //       handleSetDelay();
      //     }
      //   }
      // }
    }

    setPlayed(e.played);

    // setVideoTime(Math.floor(e.playedSeconds));
    // if (video_playing && feedback_display) {
    //   setFeedbackDisplay(false);
    // }
  };
  const handleRepComplete = async () => {
    repCount += 1;
    if (
      practiceVideoSettings.endRepDelay > 0 ||
      repCount >= practiceVideoSettings.repRange
    ) {
      await handleRepDelay();
    }

    videoRef.current.seekTo(0);
    if (repCount >= practiceVideoSettings.repRange) {
      //setcomp
      handleSetComplete();
    }
  };
  const handleSetComplete = async () => {
    setCount += 1;
    repCount = 0;
    setTimer = 0;
    if (practiceVideoSettings.cameraPosition === "alternating") {
      practiceUrl = practiceUrl === faceOn ? downTheLine : faceOn;
    }
    if (setCount - 1 >= practiceVideoSettings.setRange) {
      setVideoPlaying(false);
      setPracticeEnded(true);
      repCount = 0;
      setCount = 1;
      setTimer = 0;
    } else {
      await handleSetDelay();
    }
  };

  const handleRepDelay = async () => {
    setVideoPlaying(false);
    await stallRep();
    // if (
    //   setCount <= practiceVideoSettings.setRange &&
    //   repCount <= practiceVideoSettings.repRange
    // ) {
    if (repCount < practiceVideoSettings.repRange) {
      console.log("REPC:", repCount, "RepR:", practiceVideoSettings.repRange);
      console.log("setC:", setCount, "setR:", practiceVideoSettings.setRange);

      setVideoPlaying(true);
    }

    // }
  };
  const handleSetDelay = async () => {
    // setVideoPlaying(false);
    setVideoPlaying(false);
    setIsBetweenSets(true);
    await stallSet();
    setIsBetweenSets(false);
    // setVideoPlaying(true);
    handleSetCountDown();
  };
  const handleSetCountDown = async () => {
    setDelayTimerOpen(true);
    // await stallSet();
    // setDelayTimerOpen(false);
    // setVideoPlaying(true);
  };

  async function stallRep(
    stallTime = practiceVideoSettings.endRepDelay * 1000
  ) {
    if (
      practiceVideoSettings.endRepDelay === 0 &&
      repCount >= practiceVideoSettings.repRange
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } else {
      await new Promise((resolve) => setTimeout(resolve, stallTime));
    }
  }
  async function stallSet(
    stallTime = practiceVideoSettings.endSetDelay * 1000
  ) {
    if (isBetweenSets) {
      // (as of july 24,24, we dont know what goes inbetween sets, but this
      // will keep everything stationary for x time until whatver is inbetween
      // them goes away)
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } else {
      console.log("you shouldnt see this log");
      await new Promise((resolve) => setTimeout(resolve, stallTime));
    }
  }

  const playPauseHandler = () => {
    setVideoPlaying((prev) => !prev);
  };
  const muteHandler = () => {
    let muteStatusPreClick = video_muted;
    setVideoMuted((prev) => !prev);
    setVideoVolume(muteStatusPreClick === true ? 0.5 : 0);
  };

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;
    setVideoVolume(newVolume);
    setVideoMuted(Number(newVolume) === 0 ? true : false);
  };

  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;
    setVideoVolume(newVolume);
    setVideoMuted(Number(newVolume) === 0 ? true : false);
  };

  const seekHandler = (e, value) => {
    setPlayed(parseFloat(value / 100));
    // setVideoState({ ...videoState, played: parseFloat(value / 100) });
    videoRef.current.seekTo(parseFloat(value / 100));
    // setVideoTime(value);
    // videoRef.current.seekTo(value);
  };

  const handleMouseMove = () => {
    controlRef.current.className = "video-controls  video-controls--open";
    // mobileControlRef.current.className =
    //   "video-controls-mobile video-controls-mobile--open";
    document.body.style.cursor = "default";

    count = 0;
  };

  const hanldeMouseLeave = () => {
    controlRef.current.className = "video-controls";
    // mobileControlRef.current.className = "video-controls-mobile";
    setPopoverOpen(false);
    count = 0;
  };
  const doubleClickBackward = () => {
    //todo
    videoRef.current.seekTo(Math.floor(currentTime - 10));
    // mobileControlRef.current.className =
    //   "video-controls-mobile video-controls-mobile--open";
    // count = 0;
  };
  const doubleClickForward = () => {
    //todo
    videoRef.current.seekTo(Math.floor(currentTime + 10));
    // mobileControlRef.current.className =
    //   "video-controls-mobile video-controls-mobile--open";
    // count = 0;
  };
  // console.log(Math.floor(currentTime) - 10);
  const toggleFullScreen = () => {
    // if (!screenfull.isFullscreen) {
    //   playerContainerRef.current.style.paddingTop = "0";
    // } else {
    //   playerContainerRef.current.style.paddingTop = "56.25%";
    // }
    screenfull.toggle(playerContainerRef.current);
    // console.log(playerContainerRef.current.style);
  };
  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };
  //   videoRef.current.className = "react-player";
  const toggleMirrorVideo = () => {
    setMirror((prev) => !prev);
  };
  const toggleMirrorCamera = () => {
    setCameraMirror((prev) => !prev);
  };

  if (loading) {
    return <></>;
  }
  const handleKeyDown = (e) => {
    // console.log(e.code);
    if (e.code === "Escape") {
      // console.log(e.code);
      toggleFullScreen();
      // if (!screenfull.isFullscreen) {
      //   console.log(screenfull.isFullscreen);
      // }
      //   playerContainerRef.current.style.paddingTop = "0";
      // } else {
      //   playerContainerRef.current.style.paddingTop = "56.25%";
      // }
      // screenfull.toggle(playerContainerRef.current);
    }
    // if (e.code === 'Escape' || e.key === 'Escape') {
    //     if (screenfull.isFullscreen) {
    //         screenfull.exit();
    //     }
    // }
  };
  // window.addEventListener("keydown", handleKeyDown);,
  // console.log(played);
  //   console.log(video_playing, video_time);
  // console.log(mobileControlRef.className);
  const onUserMedia = (e) => {
    // console.log(e);
  };
  // console.log(mirror, compareMode);
  ///INTRO STUFF
  const introReadyHandler = () => {
    if (introVidRef.current !== null) {
      // console.log(introVidRef.current.style);
      // console.log(introVidRef.current);
    }
  };
  // console.log(compareMode);
  // console.log(practiceMode);
  //PRACTICE VID PROPERTY HANDLERS
  const onPracticeSettingsChange = (e) => {
    if (e.target.name === "cameraPosition") {
      if (e.target.name !== "alternating") {
        practiceUrl = e.target.value === "face-on" ? faceOn : downTheLine;
      }
    }
    //following if statement to keep ints as ints
    if (
      e.target.name === "endSetDelay" ||
      e.target.name === "endRepDelay" ||
      e.target.name === "setRange" ||
      e.target.name === "repRange"
    ) {
      setPracticeVideoSettings({
        ...practiceVideoSettings,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setPracticeVideoSettings({
        ...practiceVideoSettings,
        [e.target.name]: e.target.value,
      });
    }
  };

  // console.log(practiceVideoSettings);
  return (
    <>
      <div
        className="player-wrapper"
        ref={playerContainerRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={hanldeMouseLeave}
        // onKeyDown={(e) => {
        //   // console.log(e.code);
        //   handleKeyDown(e);
        // }}
        // onKeyDown={handleKeyDown()}
      >
        {isTrainer ? (
          <>
            {practiceMode ? (
              <>
                <>
                  {openingPracticeMode && (
                    <ReactPlayer
                      className="react-player"
                      // className={
                      //   introUp
                      //     ? "react-player react-player-intro-up"
                      //     : "react-player react-player-intro-down"
                      // }
                      style={
                        openingPracticeMode
                          ? { zIndex: "9", objectFit: "contain" }
                          : { zIndex: "-9" }
                      }
                      // onReady={() => {
                      //   toggleFullScreen();
                      // }}
                      // style={{ objectFit: "cover" }}
                      // style={
                      //   mirror && compareMode
                      //     ? {
                      //         transform: "rotateY(180deg)",
                      //         WebkitTransform: "rotateY(180deg)",
                      //         objectFit: "cover",
                      //       }
                      //     : mirror && !compareMode
                      //     ? {
                      //         transform: "rotateY(180deg)",
                      //         WebkitTransform: "rotateY(180deg)",
                      //         objectFit: "contain",
                      //       }
                      //     : !mirror && compareMode
                      //     ? { objectFit: "cover" }
                      //     : !mirror && !compareMode
                      //     ? {
                      //         // transform: "rotateY(180deg)",
                      //         // WebkitTransform: "rotateY(180deg)",
                      //         objectFit: "contain",
                      //       }
                      //     : {}
                      // }
                      ref={introVidRef}
                      //   url={video.video_url}
                      url={practiceIntroVid}
                      preload="auto"
                      width="100%"
                      // width={compareMode ? "50%" : "100%"}
                      height="100%"
                      // playing={video_playing}
                      muted={video_muted}
                      volume={video_volume}
                      // onSeek={() => {
                      //   setFeedbackDisplay(false);
                      // }}
                      // onReady={readyHandler}
                      // onReady={introReadyHandler()}
                      // onProgress={progressHandler}
                      playing={true}
                      // onEnded={() => {
                      //   setFeedbackDisplay(true);
                      //   HandyGolfAPI.sendRequest("lesson/markLessonUnitAsComplete", {
                      //     lesson_id: lesson.lesson_id,
                      //     lesson_unit_id: sub.lesson_unit_id,
                      //   }).then((data) => {
                      //     loadLessonUnitData(sub.lesson_unit_id);
                      //   });
                      // }}

                      onEnded={() => {
                        // console.log(videoRef.current);
                        setOpeningPracticeMode(false);
                        setCompareMode(true);
                        // videoRef.current.style.zIndex = "-1";
                      }}
                    ></ReactPlayer>
                  )}
                  {isBetweenSets &&
                    practiceVideoSettings.cameraPosition === "alternating" && (
                      <div className="video-next-set-box">
                        {practiceUrl === downTheLine ? (
                          <h1>video-transition "turn to down the line"</h1>
                        ) : (
                          <h1>video-transition "turn to face-on"</h1>
                        )}
                      </div>
                    )}
                  {isBetweenSets &&
                    practiceVideoSettings.cameraPosition !== "alternating" && (
                      <div className="video-next-set-box">
                        {practiceUrl === downTheLine ? (
                          <h1>Good Set, keep it up!</h1>
                        ) : (
                          <h1>Good Set, keep it up!</h1>
                        )}
                      </div>
                    )}

                  <ReactPlayer
                    className="react-player"
                    style={
                      mirror && compareMode
                        ? {
                            transform: "rotateY(180deg)",
                            WebkitTransform: "rotateY(180deg)",
                            objectFit: "cover",
                          }
                        : mirror && !compareMode
                        ? {
                            transform: "rotateY(180deg)",
                            WebkitTransform: "rotateY(180deg)",
                            objectFit: "contain",
                          }
                        : !mirror && compareMode
                        ? { objectFit: "cover" }
                        : !mirror && !compareMode
                        ? {
                            // transform: "rotateY(180deg)",
                            // WebkitTransform: "rotateY(180deg)",
                            objectFit: "contain",
                          }
                        : {}
                    }
                    ref={videoRef}
                    //   url={video.video_url}
                    url={practiceUrl}
                    preload="auto"
                    width={compareMode ? "50%" : "100%"}
                    height="100%"
                    playing={video_playing}
                    muted={video_muted}
                    volume={video_volume}
                    playbackRate={practiceVideoSettings.playbackRate}
                    // onSeek={() => {
                    //   setFeedbackDisplay(false);
                    // }}
                    onReady={readyHandler}
                    onProgress={progressHandler}

                    // onEnded={() => {
                    //   setFeedbackDisplay(true);
                    //   HandyGolfAPI.sendRequest("lesson/markLessonUnitAsComplete", {
                    //     lesson_id: lesson.lesson_id,
                    //     lesson_unit_id: sub.lesson_unit_id,
                    //   }).then((data) => {
                    //     loadLessonUnitData(sub.lesson_unit_id);
                    //   });
                    // }}
                  ></ReactPlayer>
                  {compareMode && (
                    <div className="webcam-player">
                      <Webcam
                        ref={webcamRef}
                        // audio={true}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "100%" }}
                        onUserMedia={onUserMedia}
                        mirrored={cameraMirror}
                      />
                    </div>
                  )}
                  <PracticeControls
                    controlRef={controlRef}
                    playing={video_playing}
                    onPlayPause={playPauseHandler}
                    video_playing={video_playing}
                    //   time={video_time}
                    muted={video_muted}
                    volume={video_volume}
                    onVolumeChangeHandler={volumeChangeHandler}
                    onVolumeSeekUp={volumeSeekUpHandler}
                    onMute={muteHandler}
                    duration={duration}
                    onSeek={seekHandler}
                    played={played}
                    currentTime={currentTime}
                    toggleFullScreen={toggleFullScreen}
                    popoverOpen={popoverOpen}
                    togglePopover={togglePopover}
                    toggleMirrorVideo={toggleMirrorVideo}
                    toggleMirrorCamera={toggleMirrorCamera}
                    mirror={mirror}
                    // loadLessonUnitData={loadLessonUnitData}
                    // sub={sub}
                    // lesson={lesson}
                    // // userLessonData={userLessonData}
                    isTrainer
                    setCompareMode={setCompareMode}
                    repCount={repCount}
                    setCount={setCount}
                    setTimer={setTimer}
                    setRange={practiceVideoSettings.setRange}
                    repRange={practiceVideoSettings.repRange}
                    compareMode={compareMode}
                    onPracticeSettingsChange={onPracticeSettingsChange}
                    playbackRate={practiceVideoSettings.playbackRate}
                    practiceEnded={practiceEnded}
                    setPracticeEnded={setPracticeEnded}
                    delayTimerOpen={delayTimerOpen}
                    endSetDelay={practiceVideoSettings.endSetDelay}
                    setDelayTimerOpen={setDelayTimerOpen}
                    setVideoPlaying={setVideoPlaying}
                  />
                  <PracticeControls
                    controlRef={mobileControlRef}
                    playing={video_playing}
                    onPlayPause={playPauseHandler}
                    video_playing={video_playing}
                    //   time={video_time}
                    muted={video_muted}
                    volume={video_volume}
                    onVolumeChangeHandler={volumeChangeHandler}
                    onVolumeSeekUp={volumeSeekUpHandler}
                    onMute={muteHandler}
                    duration={duration}
                    onSeek={seekHandler}
                    played={played}
                    currentTime={currentTime}
                    toggleFullScreen={toggleFullScreen}
                    popoverOpen={popoverOpen}
                    togglePopover={togglePopover}
                    toggleMirrorVideo={toggleMirrorVideo}
                    mirror={mirror}
                    // loadLessonUnitData={loadLessonUnitData}
                    // sub={sub}
                    // lesson={lesson}
                    // // userLessonData={userLessonData}
                    isTrainer
                    setCompareMode={setCompareMode}
                    repCount={repCount}
                    setCount={setCount}
                    setTimer={setTimer}
                    setRange={practiceVideoSettings.setRange}
                    repRange={practiceVideoSettings.repRange}
                    compareMode={compareMode}
                    onPracticeSettingsChange={onPracticeSettingsChange}
                    playbackRate={practiceVideoSettings.playbackRate}
                    practiceEnded={practiceEnded}
                    setPracticeEnded={setPracticeEnded}
                    delayTimerOpen={delayTimerOpen}
                    endSetDelay={practiceVideoSettings.endSetDelay}
                    setDelayTimerOpen={setDelayTimerOpen}
                    setVideoPlaying={setVideoPlaying}
                  />
                  {/* <MobileControls
                    mobileControlRef={mobileControlRef}
                    playing={video_playing}
                    onPlayPause={playPauseHandler}
                    //   time={video_time}
                    muted={video_muted}
                    volume={video_volume}
                    onVolumeChangeHandler={volumeChangeHandler}
                    onVolumeSeekUp={volumeSeekUpHandler}
                    onMute={muteHandler}
                    duration={duration}
                    onSeek={seekHandler}
                    played={played}
                    currentTime={currentTime}
                    toggleFullScreen={toggleFullScreen}
                    popoverOpen={popoverOpen}
                    togglePopover={togglePopover}
                    toggleMirrorVideo={toggleMirrorVideo}
                    mirror={mirror}
                    loadLessonUnitData={loadLessonUnitData}
                    // sub={sub}
                    // lesson={lesson}
                    // // userLessonData={userLessonData}
                    doubleClickBackward={doubleClickBackward}
                    doubleClickForward={doubleClickForward}
                    isTrainer
                    setCompareMode={setCompareMode}
                  /> */}
                </>
              </>
            ) : (
              <>
                {/* TRAINER EXPLANATION */}
                <ReactPlayer
                  className="react-player"
                  style={
                    mirror && compareMode
                      ? {
                          transform: "rotateY(180deg)",
                          WebkitTransform: "rotateY(180deg)",
                          objectFit: "cover",
                        }
                      : mirror && !compareMode
                      ? {
                          transform: "rotateY(180deg)",
                          WebkitTransform: "rotateY(180deg)",
                          objectFit: "contain",
                        }
                      : !mirror && compareMode
                      ? { objectFit: "cover" }
                      : !mirror && !compareMode
                      ? {
                          // transform: "rotateY(180deg)",
                          // WebkitTransform: "rotateY(180deg)",
                          objectFit: "contain",
                        }
                      : {}
                  }
                  ref={videoRef}
                  //   url={video.video_url}
                  url={url}
                  preload="auto"
                  width={compareMode ? "50%" : "100%"}
                  height="100%"
                  playing={video_playing}
                  muted={video_muted}
                  volume={video_volume}
                  // onSeek={() => {
                  //   setFeedbackDisplay(false);
                  // }}
                  onReady={readyHandler}
                  onProgress={progressHandler}
                  // onEnded={() => {
                  //   setFeedbackDisplay(true);
                  //   HandyGolfAPI.sendRequest("lesson/markLessonUnitAsComplete", {
                  //     lesson_id: lesson.lesson_id,
                  //     lesson_unit_id: sub.lesson_unit_id,
                  //   }).then((data) => {
                  //     loadLessonUnitData(sub.lesson_unit_id);
                  //   });
                  // }}
                ></ReactPlayer>
                {compareMode && (
                  <div className="webcam-player">
                    <Webcam
                      ref={webcamRef}
                      // audio={true}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      style={{ width: "100%", height: "100%" }}
                      onUserMedia={onUserMedia}
                      mirrored={true}
                    />
                  </div>
                )}
                <Controls
                  controlRef={controlRef}
                  playing={video_playing}
                  onPlayPause={playPauseHandler}
                  //   time={video_time}
                  muted={video_muted}
                  volume={video_volume}
                  onVolumeChangeHandler={volumeChangeHandler}
                  onVolumeSeekUp={volumeSeekUpHandler}
                  onMute={muteHandler}
                  duration={duration}
                  onSeek={seekHandler}
                  played={played}
                  currentTime={currentTime}
                  toggleFullScreen={toggleFullScreen}
                  popoverOpen={popoverOpen}
                  togglePopover={togglePopover}
                  toggleMirrorVideo={toggleMirrorVideo}
                  mirror={mirror}
                  // loadLessonUnitData={loadLessonUnitData}
                  // sub={sub}
                  // lesson={lesson}
                  // // userLessonData={userLessonData}
                  isTrainer
                  setCompareMode={setCompareMode}
                  lessonDetails={lessonDetails}
                />
                <MobileControls
                  mobileControlRef={mobileControlRef}
                  playing={video_playing}
                  onPlayPause={playPauseHandler}
                  //   time={video_time}
                  muted={video_muted}
                  volume={video_volume}
                  onVolumeChangeHandler={volumeChangeHandler}
                  onVolumeSeekUp={volumeSeekUpHandler}
                  onMute={muteHandler}
                  duration={duration}
                  onSeek={seekHandler}
                  played={played}
                  currentTime={currentTime}
                  toggleFullScreen={toggleFullScreen}
                  popoverOpen={popoverOpen}
                  togglePopover={togglePopover}
                  toggleMirrorVideo={toggleMirrorVideo}
                  mirror={mirror}
                  // loadLessonUnitData={loadLessonUnitData}
                  // sub={sub}
                  // lesson={lesson}
                  // // userLessonData={userLessonData}
                  doubleClickBackward={doubleClickBackward}
                  doubleClickForward={doubleClickForward}
                  isTrainer
                  setCompareMode={setCompareMode}
                  lessonDetails={lessonDetails}
                />
              </>
            )}
          </>
        ) : (
          <>
            <ReactPlayer
              className={"react-player"}
              //   className={videoFlippedRef.current}
              //   className={reactPlayerClass}
              //   className={
              //     !mirror ? "react-player" : "react-player react-player--mirrored"
              //   }
              style={
                mirror && compareMode
                  ? {
                      transform: "rotateY(180deg)",
                      WebkitTransform: "rotateY(180deg)",
                      objectFit: "cover",
                    }
                  : mirror && !compareMode
                  ? {
                      transform: "rotateY(180deg)",
                      WebkitTransform: "rotateY(180deg)",
                      objectFit: "contain",
                    }
                  : !mirror && compareMode
                  ? { objectFit: "cover" }
                  : !mirror && !compareMode
                  ? {
                      // transform: "rotateY(180deg)",
                      // WebkitTransform: "rotateY(180deg)",
                      objectFit: "contain",
                    }
                  : {}
              }
              ref={videoRef}
              //   url={video.video_url}
              url={url}
              // light={light}
              // playIcon={<img src="/img/play-arrow-1.svg" />}
              //   light={video.thumbnail_url}
              preload="auto"
              width="100%"
              height="100%"
              //   controls={true}
              //   controlsList="nodownload"
              playing={video_playing}
              muted={video_muted}
              volume={video_volume}
              onSeek={() => {
                setFeedbackDisplay(false);
              }}
              onReady={readyHandler}
              onProgress={progressHandler}
              onEnded={() => {
                setFeedbackDisplay(true);
                // HandyGolfAPI.sendRequest("lesson/lesson_unit/markAsWatched", {
                //   lesson_unit_id: sub.lesson_unit_id,
                // }).then((data) => {
                //   dispatchHandler(data);
                // });
              }}
            ></ReactPlayer>
            <Controls
              controlRef={controlRef}
              playing={video_playing}
              onPlayPause={playPauseHandler}
              //   time={video_time}
              muted={video_muted}
              volume={video_volume}
              onVolumeChangeHandler={volumeChangeHandler}
              onVolumeSeekUp={volumeSeekUpHandler}
              onMute={muteHandler}
              duration={duration}
              onSeek={seekHandler}
              played={played}
              currentTime={currentTime}
              toggleFullScreen={toggleFullScreen}
              popoverOpen={popoverOpen}
              togglePopover={togglePopover}
              toggleMirrorVideo={toggleMirrorVideo}
              mirror={mirror}
              // loadLessonUnitData={loadLessonUnitData}
              // sub={sub}
              // lesson={lesson}
              // // userLessonData={userLessonData}
              // prevSubUnitId={prevSubUnitId}
              // nextSubUnitId={nextSubUnitId}
              lessonDetails={lessonDetails}
              updateLessonDetails={updateLessonDetails}
              dispatchHandler={dispatchHandler}
            />
            <MobileControls
              mobileControlRef={mobileControlRef}
              playing={video_playing}
              onPlayPause={playPauseHandler}
              //   time={video_time}
              muted={video_muted}
              volume={video_volume}
              onVolumeChangeHandler={volumeChangeHandler}
              onVolumeSeekUp={volumeSeekUpHandler}
              onMute={muteHandler}
              duration={duration}
              onSeek={seekHandler}
              played={played}
              currentTime={currentTime}
              toggleFullScreen={toggleFullScreen}
              popoverOpen={popoverOpen}
              togglePopover={togglePopover}
              toggleMirrorVideo={toggleMirrorVideo}
              mirror={mirror}
              // loadLessonUnitData={loadLessonUnitData}
              // sub={sub}
              // lesson={lesson}
              // // userLessonData={userLessonData}
              doubleClickBackward={doubleClickBackward}
              doubleClickForward={doubleClickForward}
              setCompareMode={setCompareMode}
              // prevSubUnitId={prevSubUnitId}
              // nextSubUnitId={nextSubUnitId}
              lessonDetails={lessonDetails}
              updateLessonDetails={updateLessonDetails}
              dispatchHandler={dispatchHandler}
            />
          </>
        )}

        {!isTrainer && (
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              display: feedback_display ? "flex" : "none",
              justifyContent: "center",
              backgroundColor: "#000000cc",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              color: "#ffffff",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                position: "relative",
                zIndex: "1",
              }}
            >
              Please tell us how you would rate this lesson:
              <br />
              <Rating
                className="lesson_feedback_stars"
                value={feedback_rating}
                onChange={(e, new_value) => {
                  saveFeedback(new_value, null);
                }}
              />
              <br />
              <div>
                {feedback_rating > 0 && feedback_rating <= 3 ? (
                  <>
                    What could we do better?
                    <br />
                  </>
                ) : (
                  <>
                    Do you have any additional comments?
                    <br />
                  </>
                )}

                <TextField
                  multiline
                  rows={3}
                  style={{
                    width: 300,
                    marginBottom: 20,
                    backgroundColor: "#ffffff",
                  }}
                  inputProps={{ maxLength: 300 }}
                  value={feedback_text}
                  onChange={(e) => {
                    saveFeedback(null, e.target.value);
                  }}
                />
              </div>
              <Button
                primary
                // className="green"
                // startIcon={<ArrowRightIcon />}
                onClick={(e) => {
                  setFeedbackDisplay(false);

                  updateLessonDetails(lessonDetails.nextSub);
                  HandyGolfAPI.sendRequest("lesson/lesson_unit/markAsWatched", {
                    lesson_unit_id: lessonDetails.sub.lesson_unit_id,
                  }).then((data) => {
                    dispatchHandler(data);
                  });
                }}
              >
                Continue to Next Lesson
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
