import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import HandyGolf from "../../lib/HandyGolf";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { DragDrop } from "./index";
import { Button } from "../../components";
import LinearProgress from "@mui/material/LinearProgress";

const defaultBucket = {
  name: HandyGolf.getValue("s3_image_bucket_name"),
  region: HandyGolf.getValue("s3_image_bucket_region"),
};
const imageBucket = {
  name: HandyGolf.getValue("s3_image_bucket_name"),
  region: HandyGolf.getValue("s3_image_bucket_region"),
};
const sourceBucket = {
  name: HandyGolf.getValue("s3_source_video_bucket_name"),
  region: HandyGolf.getValue("s3_bucket_region"),
};
const transcodedBucket = {
  name: HandyGolf.getValue("s3_transcoded_video_bucket_name"),
  region: HandyGolf.getValue("s3_bucket_region"),
};
export function FileUploadForm({
  fileName,
  fileList,
  id,
  type,
  setUploading,
  setNew,
  relatedObject,
  formFields,
  background,
}) {
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState();
  const [bucketInfo, setBucketInfo] = useState();
  const [duration, setDuration] = useState();
  const [hasDropped, setHasDropped] = useState();
  const [duplicateFound, setDuplicateFound] = useState();
  const [checkedForDupes, setCheckedForDupes] = useState();
  const [uppingToBucket, setUppingToBucket] = useState();
  const [progress, setProgress] = useState();
  const [videoId, setVideoId] = useState();
  const [transcoding, setTranscoding] = useState();
  useEffect(() => {
    console.log("top use");
    if (type.includes("video")) {
      setBucketInfo(sourceBucket);
    } else {
      setBucketInfo(imageBucket);
    }
  }, [type]);
  useEffect(() => {
    setDuplicateFound(false);
  }, [fileName]);
  useEffect(() => {
    if (duplicateFound === false) {
      uploadFile();
    }
  }, [checkedForDupes]);
  //   useEffect(()=>{
  // if(file){

  // }
  //   },[file])
  const uploadFile = async () => {
    console.log(file.size);
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    if (!accessKeyId || !secretAccessKey) {
      // setError("NO CREDENTIALS");
      // setKeyCheckResult(false);
      console.log("hey there are no keys and nothing is being done about it");
      // setIsLoading(false);
      return;
    }
    setUppingToBucket(true);
    AWS.config.update({
      region: bucketInfo.region,
      credentials: { accessKeyId, secretAccessKey },
    });
    const s3 = new AWS.S3({
      params: { Bucket: bucketInfo.name },
      region: bucketInfo.region,
    });
    const params = {
      Bucket: bucketInfo.name,
      Key: filePath,
      Body: file,
    };
    console.log(params);
    if (file.size > 100 * 1024 * 1024) {
      // File is larger than 100MB, use multipart upload
      try {
        await multipartUpload(file, bucketInfo.name, filePath, s3);
        console.log("Multipart upload successful");
        // console.log(err);
        // console.log(data);
        afterUploadRequests();
      } catch (error) {
        console.error("Multipart upload failed:", error);
      }
    } else {
      // File is smaller, use regular putObject
      console.log("file:", file, "params:", params, "s3:", s3);
      let upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(parseInt((evt.loaded * 100) / evt.total));
        })
        .promise();

      await upload
        .then((data) => {
          console.log("Upload successful", data);
          // console.log(err);
          // console.log(data);
          afterUploadRequests();
        })
        .catch((err) => {
          console.error("Error during upload:", err);
        });
    }
  };
  async function multipartUpload(file, bucket, key, s3) {
    const fileSize = file.size;
    const partSize = 5 * 1024 * 1024;
    const numParts = Math.ceil(fileSize / partSize);

    const createParams = {
      Bucket: bucket,
      Key: key,
      ContentType: file.type,
    };

    const { UploadId } = await s3.createMultipartUpload(createParams).promise();

    const multipartMap = {
      Parts: [],
    };

    for (let partNumber = 1; partNumber <= numParts; partNumber++) {
      const start = (partNumber - 1) * partSize;
      const end = Math.min(start + partSize, fileSize);

      const partData = file.slice(start, end);

      console.log(`Part ${partNumber} data size: ${partData.size}`);

      const partParams = {
        Bucket: bucket,
        Key: key,
        Body: partData,
        UploadId: UploadId,
        PartNumber: partNumber,
      };

      try {
        const partResult = await s3.uploadPart(partParams).promise();

        if (partResult.ETag) {
          multipartMap.Parts.push({
            ETag: partResult.ETag,
            PartNumber: partNumber,
          });

          setProgress(parseInt((partNumber * 100) / numParts));
        } else {
          console.error(
            `Error: Part ${partNumber} did not return a valid ETag.`
          );
          throw new Error("Missing ETag in uploadPart response");
        }
      } catch (error) {
        console.error(`Error uploading part ${partNumber}:`, error);
        throw error;
      }
    }

    multipartMap.Parts.sort((a, b) => a.PartNumber - b.PartNumber);

    const completeParams = {
      Bucket: bucket,
      Key: key,
      MultipartUpload: multipartMap,
      UploadId: UploadId,
    };

    // console.log("Complete upload parameters:", completeParams);

    return s3.completeMultipartUpload(completeParams).promise();
  }

  const handleFileChange = (file) => {
    setHasDropped(true);
    let fileExtension = file.name.split(".")[file.name.split(".").length - 1];

    if (file.type.includes("video")) {
      // let fullVideoFileName =
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        setDuration(Math.round(video.duration));
      };
      HandyGolfAPI.sendRequest("video/create", {
        video: { name: "TEST_OCT24_1" },
      }).then((data) => {
        console.log(data);
        let newId = data.data.video_id;
        setVideoId(newId);
        let videoName = `video_${newId}.${fileExtension}`;
        HandyGolfAPI.sendRequest("video/update", {
          video_id: newId,
          video: {
            transcode_source_filename: videoName,
          },
        }).then((data) => {
          // console.log("fullname", nameAndExt);
          console.log(data);
          setFilePath(videoName);
          setFile(file);
          duplicateChecker(videoName);
        });
      });
    } else {
      let fullFileName = HandyGolf.getValue("s3_image_folder") + "/" + fileName;
      let nameAndExt = fullFileName + "." + fileExtension;

      console.log("fullname", nameAndExt);
      setFilePath(nameAndExt);
      setFile(file);
      duplicateChecker(nameAndExt);
    }
  };
  const duplicateChecker = (newFilePath) => {
    for (let i = 0; i < fileList.length; i++) {
      if (newFilePath === fileList[i].Key) {
        setDuplicateFound(true);
        return;
      }
    }
    setCheckedForDupes(true);
    // if (duplicateFound === true) {
    //   return;
    // } else {
    //   uploadFile();
    // }
  };
  // console.log(fileName);
  const afterUploadRequests = () => {
    if (type === "lesson_thumb") {
      HandyGolfAPI.sendRequest("lesson/lesson/update", {
        lesson_id: id,
        lesson: {
          thumbnail_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log(data);
        setUploading(false);
        setNew(data.data);
      });
    }
    if (type === "lesson_unit_thumb") {
      HandyGolfAPI.sendRequest("lesson/lesson_unit/update", {
        lesson_unit_id: id,
        lesson_unit: {
          thumbnail_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log(data);
        setUploading(false);
        setNew(data.data);
      });
    }
    if (type === "lesson_unit_video") {
      HandyGolfAPI.sendRequest("video/create", {
        video: {
          name: `lesson_unit_${id}`,
          description: relatedObject.description,
          thumbnail_url: relatedObject.thumbnail_url,
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        HandyGolfAPI.sendRequest("lesson/lesson_unit/update", {
          lesson_unit_id: id,
          lesson_unit: { video_id: data.data.video_id },
        }).then((dataDos) => {
          console.log(dataDos);
          setUploading(false);
          setNew(dataDos.data);
        });
      });
    }
    if (type === "trainer_program_video") {
      HandyGolfAPI.sendRequest("video/create", {
        video: {
          name: `trainer_program_${id}`,
          description: relatedObject.description,
          // thumbnail_url: relatedObject.thumbnail_url,
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        HandyGolfAPI.sendRequest("trainer/program/update", {
          trainer_program_id: id,
          trainer_program: { video_id: data.data.video_id },
        }).then((dataDos) => {
          console.log(dataDos);
          setUploading(false);
          setNew(dataDos.data);
        });
      });
    }

    if (type === "trainer_thumbnail") {
      HandyGolfAPI.sendRequest("trainer/video/update", {
        trainer_video_id: id,
        trainer_video: {
          thumbnail_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log(data);
        setUploading(false);
        setNew(data.data);
      });
    }
    if (type === "analyzer_position_image") {
      HandyGolfAPI.sendRequest("analyzer/position_image/update", {
        analyzer_position_image_id: id,
        analyzer_position_image: {
          image_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log(data);
        setUploading(false);
        setNew(data.data);
      });
    }
    if (type === "trainer_videono") {
      // HandyGolfAPI.sendRequest("video/create", {
      //   video: {
      //     name: `trainer_video_${id}`,
      //     description: relatedObject.description,
      //     thumbnail_url: relatedObject.thumbnail_url,
      //     video_length: duration,
      //     video_url: HandyGolf.getValue("s3_image_url") + filePath,
      //   },
      // }).then((data) => {
      //   HandyGolfAPI.sendRequest("trainer/video/update", {
      //     trainer_video_id: id,
      //     trainer_video: { video_id: data.data.video_id },
      //   }).then((dataDos) => {
      //     console.log(dataDos);
      //     setUploading(false);
      //     setNew(dataDos.data);
      //   });
      // });
      HandyGolfAPI.sendRequest("trainer/video/update", {
        trainer_video_id: id,
        trainer_video: { video_id: videoId },
      }).then((dataDos) => {
        console.log(dataDos);
        // setUploading(false);
        setNew(dataDos.data);
      });
    }
    if (type === "create_trainer_practice_video") {
      HandyGolfAPI.sendRequest("video/create", {
        video: {
          name: `${formFields.view}_practice_${relatedObject.trainer_video_id}`,
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log(data);
        HandyGolfAPI.sendRequest("trainer/video/createPracticeVideo", {
          practice_video: {
            trainer_video_id: relatedObject.trainer_video_id,
            video_id: data.data.video_id,
            view: formFields.view,
          },
        }).then((dataDos) => {
          let url = HandyGolf.getValue("s3_image_url") + filePath;
          console.log(dataDos);
          setUploading(false);
          setNew(dataDos.data, url);
        });
      });
    }
    if (type === "update_trainer_practice_video") {
      HandyGolfAPI.sendRequest("video/update", {
        video_id: relatedObject.video_id,
        video: {
          name: `${formFields.view}_practice_${relatedObject.trainer_video_id}`,
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log(data);
        HandyGolfAPI.sendRequest("trainer/video/updatePracticeVideo", {
          trainer_video_id: relatedObject.trainer_video_id,
          video_id: relatedObject.video_id,
          practice_video: {
            view: formFields.view,
          },
        }).then((dataDos) => {
          console.log(dataDos);
          setUploading(false);
          setNew(dataDos.data);
        });
      });
    }
    if (type === "create_fix_fault_video") {
      HandyGolfAPI.sendRequest("video/create", {
        video: {
          name: `${relatedObject.name}_fault_${relatedObject.fix_fault_id}`,
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        HandyGolfAPI.sendRequest("fix/fault/update", {
          fix_fault_id: relatedObject.fix_fault_id,
          fix_fault: { video_id: data.data.video_id },
        }).then((dataDos) => {
          console.log(dataDos);
          setUploading(false);
          setNew(dataDos.data);
        });
      });
    }
    if (type === "update_fix_fault_video") {
      HandyGolfAPI.sendRequest("video/update", {
        video_id: relatedObject.video_id,
        video: {
          name: `${relatedObject.name}_fault_${relatedObject.fix_fault_id}`,
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        HandyGolfAPI.sendRequest("fix/fault/update", {
          fix_fault_id: relatedObject.fix_fault_id,
          fix_fault: { video_id: data.data.video_id },
        }).then((dataDos) => {
          console.log(dataDos);
          setUploading(false);
          setNew(dataDos.data);
        });
      });
    }
    if (type === "create_quick_fix_video") {
      console.log(relatedObject);
      HandyGolfAPI.sendRequest("video/update", {
        video_id: relatedObject.video_id,
        video: {
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log("createquickfix", data);
      });
    }
    if (type === "update_quick_fix_video") {
      HandyGolfAPI.sendRequest("video/update", {
        video_id: relatedObject.video_id,
        video: {
          video_length: duration,
          video_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        console.log("updatequickfix", data);
      });
    }
    if (type === "coach") {
      HandyGolfAPI.sendRequest("coach/update", {
        coach_id: id,
        coach: {
          profile_picture_url: HandyGolf.getValue("s3_image_url") + filePath,
        },
      }).then((data) => {
        // console.log(data);
        setUploading(false);
        setNew(data.data);
      });
    }
    if (type.includes("video")) {
      setTranscoding(true);
    }
    setFile(null);
  };
  const checkTranscodingStatus = () => {
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    AWS.config.update({
      region: transcodedBucket.region,
      credentials: { accessKeyId, secretAccessKey },
    });

    const params = {
      Bucket: transcodedBucket.name,
      // ,Prefix: s3Directory // we dont need directory because we upload source straight to the main dir.
    };

    console.log(params);
    const s3 = new AWS.S3({
      params: params,
      region: transcodedBucket.region,
    });
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error(err);
        // setError(err.message || "Error fetching objects");
        // setKeyCheckResult(false);
      } else {
        console.log(data.Contents);

        let thing = data.Contents.find((file) =>
          file.Key.includes(`video_${videoId}.m3u8`)
        );
        console.log(thing);
        console.log(videoId);
        if (thing) {
          let url =
            "https://handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx.s3.us-east-2.amazonaws.com" +
            "/" +
            `${thing.Key}`;
          HandyGolfAPI.sendRequest("video/update", {
            video_id: videoId,
            video: {
              video_url: url,
            },
          }).then((data) => {
            console.log(data);
            if (type === "trainer_video") {
              HandyGolfAPI.sendRequest("trainer/video/update", {
                trainer_video_id: id,
                trainer_video: { video_id: videoId },
              }).then((dataDos) => {
                console.log(dataDos);
                // setUploading(false);
                setNew(dataDos.data);
              });
            }

            setTranscoding(false);
          });
        }

        // setFileList(data.Contents);
        // setKeyCheckResult(true);
      }
      // setIsLoading(false);
    });
  };
  const deleteObject = async () => {
    console.log(filePath);
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    AWS.config.update({
      region: sourceBucket.region,
      credentials: { accessKeyId, secretAccessKey },
    });

    const params = {
      Bucket: sourceBucket.name,
      Key: filePath,
      // ,Prefix: s3Directory // we dont need directory because we upload source straight to the main dir.
    };

    // console.log(params);
    const s3 = new AWS.S3({
      params: params,
      region: sourceBucket.region,
    });

    try {
      await s3.deleteObject(params).promise();
      console.log("Object deleted successfully");
    } catch (error) {
      console.error("Error deleting object:", error);
    }
  };

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <img src={background} className="dragdrop-under-content" />
        <div className="dragdrop-overlay">
          <DragDrop
            handleFileChange={handleFileChange}
            hasDropped={hasDropped}
            duplicateFound={duplicateFound}
            uppingToBucket={uppingToBucket}
            progress={progress}
            uploadFile={uploadFile}
            setDuplicateFound={setDuplicateFound}
            setUploading={setUploading}
            type={type}
          />
        </div>
      </div>
      {/* </div> */}
      {duplicateFound === true && (
        <div>
          <h2>uploading this will overwrite!</h2>
        </div>
      )}
      {transcoding && (
        <div>
          <h2>transcoding!</h2>
          <Button
            fluid
            primary
            onClick={() => {
              checkTranscodingStatus();
            }}
          >
            Check Status
          </Button>
          {/* TODO
          <button> onclick= checkingTranscode status: true
          ///then when true triggers useEffect which checks the transcode bucket for the video_<videoid>.m3u8
          //when it finds it, then update the record video/update {video id:videoid} with the new url being that ^^ videos path
          */}
        </div>
      )}
      {transcoding === false && (
        <Button
          primary
          fluid
          onClick={() => {
            if (
              window.confirm(
                "Are you SURE you want to PERMANENTLY DELETE the large source for this video?"
              )
            ) {
              deleteObject();
            }
          }}
        >
          DELETE SOURCE
        </Button>
      )}
      {/* {progress && <LinearProgress variant="determinate" value={progress} />} */}
      {/* {file !== null && (
        <div
          className="admin-file-upload-button"
          onClick={() => {
            uploadFile();
          }}
        >
          <i className="material-icons">upload</i>
          <strong>Upload File</strong>
        </div>
      )} */}
    </>
  );
}
