import React, { useState } from "react";

export function AdminTableItem({
  header,
  item,
  setSortChanging,
  handleOrderChange,
  tableIds,
  idType,
  setSelectedItem,
  setEditingItem,
  typeConverter,
  formatDate,
}) {
  if (header === "") {
    return (
      <td style={{ paddingLeft: "0" }}>
        <div>
          <div className="admin-arrows">
            {tableIds[0] === item[idType] ? (
              <i className="material-icons" style={{ opacity: ".5" }}>
                expand_less
              </i>
            ) : (
              <i
                className="material-icons can-click"
                onClick={() => {
                  setSortChanging(true);
                  handleOrderChange(
                    "up",
                    item[idType]
                    //   item.session_number
                  );
                }}
              >
                expand_less
              </i>
            )}
            {tableIds[tableIds.length - 1] === item[idType] ? (
              <i className="material-icons" style={{ opacity: ".5" }}>
                keyboard_arrow_down
              </i>
            ) : (
              <i
                className="material-icons can-click"
                onClick={() => {
                  setSortChanging(true);
                  handleOrderChange(
                    "down",
                    item[idType]
                    //   item.session_number
                  );
                }}
              >
                keyboard_arrow_down
              </i>
            )}
          </div>
        </div>
      </td>
    );
  } else if (header === "profile_picture_url") {
    return (
      <td
        onClick={() => {
          setSelectedItem(item);
          setEditingItem(true);
        }}
      >
        <div
          className="table-profile-pic"
          style={{
            paddingLeft: "2rem",
          }}
        >
          <img
            src={item[header] !== "" ? item[header] : "/img/no-profile-pic.png"}
          />
        </div>
      </td>
    );
  } else if (header === "image_url") {
    return (
      <td
        onClick={() => {
          setSelectedItem(item);
          setEditingItem(true);
        }}
      >
        <div
          className="table-position-image"
          // className="table-profile-pic"
          style={{
            paddingLeft: "2rem",
          }}
        >
          <img
            src={
              item[header] !== "" ? item[header] : "/img/tmp-swing-analyzer.jpg"
            }
          />
        </div>
      </td>
    );
  } else if (header.includes("type")) {
    return (
      <td
        style={{
          // height: "100%",
          // display: "flex",
          // alignItems: "center",
          paddingInline: "1rem",
        }}
        onClick={() => {
          setSelectedItem(item);
          setEditingItem(true);
        }}
      >
        <div>{typeConverter(item[header])}</div>
      </td>
    );
  } else if (header === "sent_on") {
    return (
      <td
        style={{
          // height: "100%",
          // display: "flex",
          // alignItems: "center",
          paddingInline: "1rem",
        }}
        onClick={() => {
          setSelectedItem(item);
          setEditingItem(true);
        }}
      >
        <div>{formatDate(item[header])}</div>
      </td>
    );
  } else {
    return (
      <td
        style={{
          // height: "100%",
          // display: "flex",
          // alignItems: "center",
          paddingInline: "1rem",
        }}
        onClick={() => {
          setSelectedItem(item);
          setEditingItem(true);
        }}
      >
        <div
          style={{
            maxWidth: "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item[header]}
        </div>
      </td>
    );
  }
}
