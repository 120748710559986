import React from "react";

// Navigation
import { Link } from "react-router-dom";

// MUI
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

function AdminPage() {
  return (
    <div className="body_content">
      <Typography variant="h3" gutterBottom>
        Admin
      </Typography>
      <Link to="/admin/lesson_mgmt">
        <Button variant="contained">Go to Lesson Management</Button>
      </Link>
      <br />
      <br />
      <Link to="/admin/swing_trainer/category_mgmt">
        <Button variant="contained">
          Go to Swing Trainer Category Management
        </Button>
      </Link>
      <br />
      <br />
      <Link to="/admin/swing_trainer/tag_mgmt">
        <Button variant="contained">Go to Swing Trainer Tag Management</Button>
      </Link>
      <br />
      <br />
      <Link to="/admin/swing_trainer/video_mgmt">
        <Button variant="contained">
          Go to Swing Trainer Video Management
        </Button>
      </Link>
    </div>
  );
}

export default AdminPage;
