import * as React from 'react';
import { createContext, useContext, useRef, useState } from 'react';


const defaultToastContext = {
    toasts: [],
    clickToast: () => {},
    success: () => {},
    error: () => {},
    warning: () => {},
    info: () => {},
  };

export const ToastContext = createContext(defaultToastContext);

export function ToastProvider({children}){
    const [toasts, setToasts] =useState([])
    const toastsRef = useRef(toasts);
    toastsRef.current = toasts;

    const toast = (type) => {
        return (message)=>{
            const toastId = Date.now();
            // setToasts(...prev,{
            //     type,
            //     message,
            //     id: toastId
            // });
            setToasts((prevToasts)=>[
                ...prevToasts,
                {
                    type,
                    message,
                    id: toastId
                }
            ])
            setTimeout(()=>{
                removeToast(toastId)
            },5000)
        }
    };
    const removeToast = (id) => {
        setToasts(
            toastsRef.current.filter((toast) => {
                return toast.id !== id;
              })
        );
    };
    const clickToast = (index) => {
        let newArr = [...toasts];
        newArr.splice(index, 1);
        setToasts(newArr);
      };

    return(
        <ToastContext.Provider
        value={{
            toasts,
            clickToast,
            success: toast('success'),
            error: toast('error'),
            warning: toast('warning'),
            info: toast('info'),
        }}
        >
            {children}
        </ToastContext.Provider>
    )
}
export function useToast(){
    const context = useContext(ToastContext);
    
    if(context === undefined){
        throw new Error('useToast must be used within a ToastProvider')
    }
    return context;
}