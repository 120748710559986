import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

const TYPE_DRILL = 1;
const TYPE_TRAINING_SESSION = 2;

export function VideoForm({ mode, trainer_video_id }) {
  const [name, setName] = useState("");
  const [type, setType] = useState(TYPE_DRILL);
  const [description, setDescription] = useState("");
  const [saved, setSaved] = useState(false);
  console.log("newTEST:", mode, trainer_video_id);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [category_list, setCategoryList] = useState([]);
  const [video_categories, setVideoCategories] = useState([]);
  const [tag_list, setTagList] = useState([]);
  const [video_tags, setVideoTags] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (trainer_video_id > 0) {
      HandyGolfAPI.sendRequest("trainer/getVideoDetails", {
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        if (mounted) {
          setName(data.name);
          setType(data.type);
          setDescription(data.description);
        }
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
    HandyGolfAPI.sendRequest("trainer/getCategoryList", {})
      .then((data) => {
        if (mounted) {
          setCategoryList(data);
        }
      });
    HandyGolfAPI.sendRequest("trainer/tag/list", {})
        .then((data) => {
          if (mounted) {
            setTagList(data);
          }
        });
    HandyGolfAPI.sendRequest("trainer/video/getCategoryIds", { trainer_video_id: trainer_video_id })
        .then((data) => {
          if (mounted) {
            setTagList(data);
          }
        });
    return () => {
      mounted = false;
    };
  }, [mode, trainer_video_id]);

  const saveVideoDetails = () => {
    let method = "addVideo";
    if (trainer_video_id > 0) {
      method = "editVideo";
    }
    HandyGolfAPI.sendRequest("trainer/" + method, {
      trainer_video_id: trainer_video_id,
      name: name,
      type: type,
      description: description,
    }).then((data) => {
      // @todo How do I confirm success or failure... check for data for now
      if(data) {
        setSaved(true);
      }
      //   setRedirect("/admin/swing_trainer/video_mgmt/view/" + trainer_video_id);
      //   setRedirect("/admin/swing_trainer/video_mgmt");
      //   console.log(data);
    });
  };
  return (
    <div>
      {redirect !== "" && <Navigate to={redirect} />}
      {!saved && (
        <>
          {" "}
          <Typography variant="h4" gutterBottom>
            Video Management
          </Typography>
          {mode === "edit" ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/admin/swing_trainer/video_mgmt/">
                <Button>Video List</Button>
              </Link>
              <Link to={"/admin/swing_trainer/video_mgmt/view/" + trainer_video_id}>
                <Button>Video Details</Button>
              </Link>
              <Button disabled>Edit Video</Button>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/admin/swing_trainer/video_mgmt/">
                <Button>Video List</Button>
              </Link>
              <Button disabled>Add Video</Button>
            </Breadcrumbs>
          )}
          <Card sx={{ maxWidth: 550 }}>
            <CardHeader title="Video Details" />
            {loaded ? (
              <>
                <CardContent>
                  <FormControl>
                    <TextField
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      sx={{ width: 320 }}
                    />
                    <br />

                    <FormLabel id="show-on-dashboard-label">
                      Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="type"
                      defaultValue={TYPE_DRILL}
                      name="type"
                      value={type}
                    >
                      <FormControlLabel
                        value={TYPE_DRILL}
                        control={
                          <Radio
                            onChange={(e, value) => {
                              if (e.target.checked) {
                                setType(e.target.value);
                              }
                            }}
                          />
                        }
                        label="Drill"
                      />
                      <FormControlLabel
                        value={TYPE_TRAINING_SESSION}
                        control={
                          <Radio
                            onChange={(e, value) => {
                              if (e.target.checked) {
                                setType(e.target.value);
                              }
                            }}
                          />
                        }
                        label="Training Session"
                      />
                    </RadioGroup>
                    <TextField
                        label="Description"
                        variant="outlined"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{ width: 320 }}
                    /><br />
                    <Autocomplete
                        multiple
                        id="video_categories"
                        options={category_list}
                        getOptionLabel={(option) => option.name}
                        value={video_categories}
                        onChange={(e, new_video_categories) => { setVideoCategories(new_video_categories); }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Video Categories"
                                placeholder="Video Categories"
                            />
                        )}
                    /><br />
                    <Autocomplete
                        multiple
                        id="video_tags"
                        options={tag_list}
                        getOptionLabel={(option) => option.name}
                        value={video_tags}
                        onChange={(e, new_video_tags) => { console.log('TESTER RUSTY'); console.log(new_video_tags); setVideoTags(new_video_tags); }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Video Tags"
                                placeholder="Video Tags"
                            />
                        )}
                    />
                  </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    className="blue"
                    onClick={saveVideoDetails}
                  >
                    Save
                  </Button>
                  <Link to="/admin/swing_trainer/video_mgmt/">
                    <Button variant="contained" className="blue">
                      Cancel
                    </Button>
                  </Link>
                </CardActions>
              </>
            ) : (
              <CardContent>
                <CircularProgress />
              </CardContent>
            )}
          </Card>
        </>
      )}
      {saved && (
        <>
          <Typography variant="h4" gutterBottom>
            Video Management
          </Typography>

          <Card sx={{ maxWidth: 550 }}>
            <CardHeader title="Changes Added" />
            <CardContent>
              <strong>Video has been saved.</strong> <br />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Link to={"/admin/swing_trainer/video_mgmt"}>
                <Button variant="contained" className="blue">
                  Go Back
                </Button>
              </Link>

              <br />
            </CardActions>
          </Card>
        </>
      )}
    </div>
  );
}
