import React from "react";

import { SiteNav, Footer } from "../components";

import { AppRoutes } from "../routes";
import { Toaster } from "../components";

export const SiteLayout = () => {
  console.log("youre still seeing me for .001 picoseconds");
  return (
    <div className="site">
      <SiteNav />
      <div className="site__body">
        <Toaster />
        <AppRoutes />
      </div>

      <Footer />
    </div>
  );
};
