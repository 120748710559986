export function setFormDefaults(apiObject, formDefaults, setFormState) {
  // Filter apiObject to include only the keys that exist in formDefaults
  const filteredObject = Object.keys(formDefaults).reduce((acc, key) => {
    if (apiObject.hasOwnProperty(key)) {
      acc[key] = apiObject[key]; // Add matching keys to the result
    } else {
      acc[key] = formDefaults[key]; // Use default value if key not in API object
    }
    return acc;
  }, {});

  // Use React's setState to update the form state
  setFormState((prevState) => ({
    ...prevState,
    ...filteredObject,
  }));
}
