import React, { useState } from "react";

const ProgramCalendar = ({ getDaysWithActivity }) => {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );

  const daysWithActivity = getDaysWithActivity();

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const isCurrentDate = (day) => {
    return (
      day === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    );
  };

  const isSpecialDate = (day) => {
    const dateStr = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    return daysWithActivity.includes(dateStr);
  };

  const renderDates = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);

    const dates = [];

    // Fill in the dates for the previous month
    const daysInPreviousMonth = getDaysInMonth(year, month - 1);
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      dates.push(
        <div key={`prev-${i}`} className="calendar-date calendar-date-disabled">
          {daysInPreviousMonth - i}
        </div>
      );
    }

    // Fill in the dates for the current month
    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(
        <div
          key={`current-${i}`}
          className={`calendar-date ${
            isCurrentDate(i) ? "calendar-date-current" : ""
          }`}
        >
          {i}
          {isSpecialDate(i) && <div className="calendar-date-dot"></div>}
        </div>
      );
    }

    // Fill in the dates for the next month
    const totalDays = dates.length;
    const nextDays = 7 - (totalDays % 7);
    for (let i = 1; i <= nextDays && nextDays < 7; i++) {
      dates.push(
        <div key={`next-${i}`} className="calendar-date calendar-date-disabled">
          {i}
        </div>
      );
    }

    return dates;
  };

  return (
    <div className="program-calendar">
      <div className="calendar-header">
        <button onClick={handlePreviousMonth}>
          <i className="material-icons">chevron_left</i>
        </button>
        <span>{`${
          months[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`}</span>
        <button onClick={handleNextMonth}>
          <i className="material-icons">chevron_right</i>
        </button>
      </div>
      <div className="calendar-days">
        {daysOfWeek.map((day) => (
          <div key={day} className="calendar-day-label">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar-dates">{renderDates()}</div>
    </div>
  );
};

export default ProgramCalendar;
