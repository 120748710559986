import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import { Button } from "../shared/Button";

import HandyGolfAPI from "../../lib/HandyGolfAPI";

export const MobileSection = ({
  lessonDetails,
  unit,
  // loadLessonUnitData,
  updateLessonDetails,
  // userLessonData,
  // lesson,
  // currentSubUnit,
  className,
  unitIdx,
}) => {
  //   const [isOpen, setIsOpen] = useState(
  //     unit.lesson_unit_id === currentSubUnit.parent_lesson_unit_id
  //   );
  // const [selected, setSelected] = useState(currentSubUnit.lesson_unit_id);
  const {
    lesson,
    completedSubUnits,
    numUnits,
    sub,
    subNotes,
    unitCompletion,
    unitNotes,
    parentIndex,
  } = lessonDetails;
  // if (!userLessonData) {
  //   return <></>;
  // }
  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };

  // let baseClass = "accordion";
  // if (mobile) {
  //   baseClass += "-mobile";
  // }
  // console.log(baseClass);

  return (
    <>
      <div className={className}>
        <div
          className="accordion-mobile__title-box"
          //   onClick={() => {
          //     setIsOpen((prev) => !prev);
          //   }}
        >
          {unitCompletion[unitIdx].percent_complete === 100 ? (
            <i className="material-icons">check</i>
          ) : (
            <i
              className="material-icons"
              style={{ color: "#010D15", opacity: ".2" }}
            >
              check
            </i>
          )}

          <p>
            {unit.number}. {unit.name}
          </p>
          <i className="material-icons" style={{ opacity: "0" }}>
            chevron_right
          </i>
        </div>

        <div className="accordion-mobile__data-box">
          <div style={{ overflow: "hidden" }}>
            <LinearProgress
              color="success"
              variant="determinate"
              value={unitCompletion[unitIdx].percent_complete}
              size={100}
            />
            {unit.sub_units.map((sub_unit, idx) => {
              // console.log(
              //   sub_unit.lesson_unit_id,
              //   sub.lesson_unit_id
              // );
              return (
                <div key={idx} className="accordion-mobile__sub-unit">
                  <div
                    className={
                      sub_unit.lesson_unit_id === sub.lesson_unit_id
                        ? "accordion-mobile__sub-unit__data selected"
                        : "accordion-mobile__sub-unit__data"
                    }
                    onClick={() => {
                      updateLessonDetails(sub_unit.lesson_unit_id);
                    }}
                  >
                    {sub_unit.lesson_unit_id === sub.lesson_unit_id ? (
                      <i className="material-icons">pause</i>
                    ) : (
                      <i className="material-icons">play_circle_filled</i>
                    )}

                    <p>
                      {sub_unit.number} {sub_unit.name}
                    </p>
                    {unitNotes.find(
                      (thing) =>
                        thing.lesson_unit_id === sub_unit.lesson_unit_id
                    ) ? (
                      <>
                        {sub_unit.lesson_unit_id === sub.lesson_unit_id ? (
                          <strong
                            style={{
                              position: "absolute",
                              right: "40px",
                            }}
                          >
                            {convertSecondsToTimeText(45)}
                          </strong>
                        ) : (
                          <span
                            style={{
                              position: "absolute",
                              right: "40px",
                            }}
                          >
                            {convertSecondsToTimeText(45)}
                          </span>
                        )}

                        <i
                          className="material-icons"
                          style={{
                            fontSize: "8px",
                            color: "#0071BB",
                            position: "absolute",
                            right: "15px",
                            // marginLeft: "30px",
                          }}
                        >
                          brightness_1
                        </i>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="accordion-mobile__sub-unit__checkbox">
                    {completedSubUnits.includes(sub_unit.lesson_unit_id) ? (
                      <i className="material-icons">check</i>
                    ) : (
                      <i
                        className="material-icons"
                        style={{ color: "#010D15", opacity: ".2" }}
                      >
                        check
                      </i>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
