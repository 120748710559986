import React from "react";

// Components
import VideoMgmt from "../components/swing_trainer/VideoMgmt/VideoMgmt";
import PageMetaData from "../components/PageMetaData";

export default function AdminSwingTrainerCategoryMgmtPage() {
  return (
    <PageMetaData title="Video Management" seo="keywords for seo stuff">
      <div className="container">
        <div className="container__content">
          <VideoMgmt />
        </div>
      </div>
    </PageMetaData>
  );
}
