import React, { useState } from "react";

// CSS
import "../../../../LessonsPage.css";

// Libraries
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Rating from "@mui/material/Rating";
import StickyNote2SharpIcon from "@mui/icons-material/StickyNote2Sharp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Video Library
import ReactPlayer from "react-player";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

// Other
import nl2br from "react-nl2br";

export function NoteSection({
  lesson,
  current_lesson_unit,
  loadLessonUnitData,
  selected_tab,
  setSelectedTab,
  note_time_checked,
  setNoteTimeChecked,
  convertSecondsToTimeText,
  video_time,
  note_text,
  setNoteText,
  videoRef,
  setVideoPlaying,
}) {
  return (
    <div>
      <Tabs
        value={selected_tab}
        onChange={(e, new_value) => {
          setSelectedTab(new_value);
        }}
        aria-label="notes and additional resources tabs"
      >
        <Tab label="Notes" className="hg_lesson_tab" />
        <Tab label="Additional Resources" className="hg_lesson_tab" />
      </Tabs>
      <div className="tab_content_box">
        {selected_tab === 0 && (
          <>
            {/* @TODO This should be componentized */}
            <strong>Add a note</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Checkbox
              checked={note_time_checked}
              onChange={(e) => setNoteTimeChecked(e.target.checked)}
            />
            Add video timestamp{" "}
            <strong>{convertSecondsToTimeText(video_time)}</strong>
            <br />
            <TextField
              id="outlined-multiline-static"
              label=""
              multiline
              rows={4}
              style={{ width: "100%" }}
              value={note_text}
              onChange={(e) => setNoteText(e.target.value)}
            />
            <br />
            <div style={{ marginTop: 10, textAlign: "right" }}>
              <Button
                className="blue_rounded"
                variant="contained"
                onClick={() => {
                  HandyGolfAPI.sendRequest("lesson/addLessonUnitNote", {
                    lesson_id: lesson.lesson_id,
                    lesson_unit_id: current_lesson_unit.lesson_unit_id,
                    text: note_text,
                    video_timestamp_in_seconds: note_time_checked
                      ? video_time
                      : 0,
                  }).then((data) => {
                    loadLessonUnitData(current_lesson_unit.lesson_unit_id);
                  });
                  setNoteText("");
                }}
              >
                Add Note
              </Button>
            </div>
            <br />
            {current_lesson_unit.notes.map((note, idx) => {
              return (
                <div key={idx}>
                  <div style={{ width: "100%", display: "flex" }}>
                    {note.video_timestamp_in_seconds > 0 ? (
                      <div style={{ flex: 1 }}>
                        <Button
                          onClick={() => {
                            videoRef.current.seekTo(
                              note.video_timestamp_in_seconds,
                              "seconds"
                            );
                            setVideoPlaying(true);
                          }}
                          startIcon={<PlayCircleIcon />}
                        >
                          <strong>
                            {convertSecondsToTimeText(
                              note.video_timestamp_in_seconds
                            )}
                          </strong>
                        </Button>
                        <br />
                      </div>
                    ) : (
                      <div style={{ flex: 1 }}>
                        <StickyNote2SharpIcon className="lesson_unit_notes has_notes" />
                      </div>
                    )}
                    <div>
                      <IconButton
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure that you want to PERMANENTLY DELETE this note?"
                            )
                          ) {
                            HandyGolfAPI.sendRequest(
                              "lesson/removeLessonUnitNote",
                              {
                                user_lesson_unit_note_id:
                                  note.user_lesson_unit_note_id,
                              }
                            ).then((data) => {
                              loadLessonUnitData(
                                current_lesson_unit.lesson_unit_id
                              );
                            });
                          }
                        }}
                      >
                        <DeleteIcon className="lesson_unit_note_delete" />
                      </IconButton>
                    </div>
                  </div>

                  <Divider />
                  <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <Typography variant="body2">{nl2br(note.text)}</Typography>
                  </div>
                </div>
              );
            })}
          </>
        )}
        {selected_tab === 1 && (
          <>There are currently no additional resources for this lesson unit.</>
        )}
      </div>
    </div>
  );
}
