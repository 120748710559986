// Libraries
const HandyGolf = require('./HandyGolf');
const HandyGolfAPI = require('../lib/HandyGolfAPI');
// const Cookies = require('universal-cookie');

// Config
// const cookies = new Cookies();
// const personas = {
//     john: {
//         full_name: 'John Freeman',
//         role: 'FREE',
//     },
//     matt: {
//         full_name: 'Matt Subscriberton',
//         role: 'SUBSCRIBER',
//     },
//     dan: {
//         full_name: 'Dan Adminson',
//         role: 'ADMIN',
//     }
//
// }

// let is_user_authenticated = false;
// let authenticated_user_details = {};


class HandyGolfAuth {
    static appCallback = null;

    static async resetSessionCache() {
        return await HandyGolfAPI.getSession(true);
    }
    static async isUserAuthenticated() {
        const session = await HandyGolfAPI.getSession();
        if(session && session.user_id) {
            return(parseInt(session.user_id) > 0);
        } else {
            return false;
        }
    }
    static async isUserAdmin() {
        if(await HandyGolfAuth.isUserAuthenticated()) {
            const session = await HandyGolfAPI.getSession();
            return (parseInt(session.is_admin) === 1);
        } else {
            return false;
        }
    }
    static async isUserSubscribed() {
        if(await HandyGolfAuth.isUserAuthenticated()) {
            const session = await HandyGolfAPI.getSession();
            return (parseInt(session.is_subscribed) === 1);
        } else {
            return false;
        }
    }
    static async getAuthenticatedUser() {
        if(await HandyGolfAuth.isUserAuthenticated()) {
            const session = await HandyGolfAPI.getSession();
            return session.user;
        } else {
            return false;
        }
    }

    // static async loginAsPersona(hg_persona){
    //     // @todo Check for dev site and admin permissions
    //     if(hg_persona && personas[hg_persona]) {
    //         is_user_authenticated = true;
    //         authenticated_user_details = personas[hg_persona];
    //         cookies.set("HG_PERSONA", hg_persona, { path: '/' })
    //     }
    // }
    static async loginWithGoogleAccount(credential, user_info = null) {
        return await HandyGolfAPI.sendRequest(
            "user/performLogin",
            {
                auth_type: HandyGolfAPI.AUTH_TYPE_GOOGLE,
                credential: credential,
                user_info: user_info
            }
        )
            .then(data => {
                HandyGolfAPI.setApiToken(data.token);
                if(HandyGolfAuth.appCallback) {
                    HandyGolfAuth.appCallback();
                }
                return true;
            })
            .catch(error => {
                throw error;
            });
    }
    static async signUpWithEmailAccount(email, full_name, password) {
        return await HandyGolfAPI.sendRequest(
            "user/createUser",
            {
                auth_type: HandyGolfAPI.AUTH_TYPE_EMAIL,
                email: email,
                full_name: full_name,
                password: password,
            }
        )
            .then(data => {
                HandyGolfAPI.setApiToken(data.token);
                if(HandyGolfAuth.appCallback) {
                    HandyGolfAuth.appCallback();
                }
                return true;
            })
            .catch(error => {
                throw error;
            });
    }
    static async loginWithEmailAccount(email, password) {
        return await HandyGolfAPI.sendRequest(
            "user/performLogin",
            {
                auth_type: HandyGolfAPI.AUTH_TYPE_EMAIL,
                email: email,
                password: password,
            }
        )
            .then(data => {
                HandyGolfAPI.setApiToken(data.token);
                if(HandyGolfAuth.appCallback) {
                    HandyGolfAuth.appCallback();
                }
                return true;
            })
            .catch(error => {
                throw error;
            });
    }
    static async logout() {
        await HandyGolfAPI.sendRequest(
            "user/performLogout",
            {}
        );
        HandyGolfAPI.resetSession();
        if(HandyGolfAuth.appCallback) {
            HandyGolfAuth.appCallback();
        }
        return true;
    }
}

module.exports = HandyGolfAuth;