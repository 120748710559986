import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";
// MUI
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";
function translateType(type) {
  switch (type) {
    case 1:
      return "Drill";
    case 2:
      return "Training Session";
    default:
      return "Unknown";
  }
}

export function VideoView({ trainer_video_id }) {
  const [loaded, setLoaded] = useState(false);
  const [video_details, setVideoDetails] = useState([]);
  useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("trainer/getVideoDetails", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      if (mounted) {
        setVideoDetails(data);
      }
      setLoaded(true);
    });
    return () => {
      mounted = false;
    };
  }, [trainer_video_id]);
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Video Management
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/swing_trainer/video_mgmt/">
          <Button>Video List</Button>
        </Link>
        <Button disabled>Video Details</Button>
      </Breadcrumbs>
      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Video Details" />
        {!loaded && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {loaded && (
          <>
            <CardContent>
              <strong>Name:</strong> {video_details.name}
              <br />
              <strong>Type:</strong>{" "}
              {translateType(video_details.type)}
              <br />
              <strong>Description:</strong> {video_details.description}
              <br />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Link
                to={
                  "/admin/swing_trainer/video_mgmt/edit/" +
                  video_details.trainer_video_id
                }
              >
                <Button variant="contained" className="blue">
                  Edit Video
                </Button>
              </Link>
              <br />
            </CardActions>
          </>
        )}
      </Card>
    </div>
  );
}
