import React, { useState, useEffect, Children } from "react";

import { Carousel } from "./carousel";
import { FBCard } from "./card";

//mocks
import { Mocks } from "./mocks";

// import "./Feedback.scss";
export function Feedback() {
  return (
    <div className="feedback">
      <div className="feedback__title">
        <p>Testimonials</p>
      </div>
      <div className="feedback__title">
        <h1>Our Members Say</h1>
      </div>

      <div className="feedback__carouselWrapper">
        <Carousel>
          {Mocks.map((card) => {
            return (
              <FBCard
                key={card.id}
                photo={card.photo}
                name={card.name}
                testimony={card.testimony}
                skill={card.skill}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
