import React from "react";

// Components
import LessonMgmt from "../components/LessonMgmt";
import PageMetaData from "../components/PageMetaData";

function AdminLessonMgmtPage() {
  return (
    <PageMetaData title="Lesson Management" seo="keywords for seo stuff">
      <div className="container">
        <div className="container__content">
          <div className="body_content">
            <LessonMgmt />
          </div>
        </div>
      </div>
    </PageMetaData>
  );
}

export default AdminLessonMgmtPage;
