import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "./shared";
// import { mockprograms } from "./mockvideos";

//swiper stuff
import { Swiper, SwiperSlide } from "swiper/react";
import { register } from "swiper/element/bundle";

//this uses swiper elements, swiper modules is going to lose support soon.
register();
export const ProgramSlider = ({ programs, sectionName }) => {
  let navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const swiperRef = useRef(null);
  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      // slidesPerView: 4.1,
      // slidesPerGroup: 4,
      //   loop: true,
      //   loopFillGroupWithBlank: true,
      centeredSlides: false,
      //   slidesPerGroup: 1,
      //   loopFillGroupWithBlank: true,
      //   slidesPerGroupSkip: 1,
      grabCursor: true,
      spaceBetween: 10,
      breakpoints: {
        // 250: {
        //   slidesPerView: 3,
        //   slidesPerGroup: 3,
        // },
        // 560: {
        //   slidesPerView: 2.2,
        //   slidesPerGroup: 2,
        // },
        // 600: {
        //   slidesPerView: 2.4,
        //   slidesPerGroup: 2,
        // },
        // 700: {
        //   slidesPerView: 2.8,
        //   slidesPerGroup: 2,
        // },
        // 800: {
        //   slidesPerView: 3.2,
        //   slidesPerGroup: 3,
        // },
        // 900: {
        //   slidesPerView: 3.6,
        //   slidesPerGroup: 3,
        // },
        // 1000: {
        //   slidesPerView: 4,
        //   slidesPerGroup: 4,
        // },

        // 1080: {
        //   slidesPerView: 2,
        //   slidesPerGroup: 2,
        //   // spaceBetween: 10,
        // },
        // 1100: {
        //   slidesPerView: 2,
        //   slidesPerGroup: 2,
        // },
        // 1180: {
        //   slidesPerView: 2,
        //   slidesPerGroup: 2,
        // },
        // 1200: {
        //   slidesPerView: 2,
        //   slidesPerGroup: 2,
        // },
        1250: {
          slidesPerView: 1.5,
          slidesPerGroup: 1,
        },
        1300: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1400: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },

        1464: {
          slidesPerView: 2.3,
          slidesPerGroup: 2,
          // spaceBetween: 10,
        },
      },
      navigation: true,
      //   pagination: true,
      //   scrollbar: true,
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            
           z-index:2;
            color: white;
          }
         
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, [programs]);
  // if (!programs) {
  //   return <></>;
  // }
  // console.log(programs);
  return (
    // <div className="results-slider">
    //   <strong>
    //     <p>{sectionName}</p>
    //   </strong>

    <swiper-container
      ref={swiperRef}
      init="false"
      // slidesPerView={1}
      // centeredSlides={false}
      // slidesPerGroupSkip={1}
      // grabCursor={true}
      // keyboard={{
      //   enabled: true,
      // }}
      // breakpoints={{
      //   769: {
      //     slidesPerView: 2,
      //     slidesPerGroup: 2,
      //   },
      // }}
      // scrollbar={true}
      // navigation={true}
      // pagination={{
      //   clickable: true,
      // }}
      // modules={[Keyboard, Scrollbar, Navigation, Pagination]}
      // className="mySwiper"
    >
      {programs.map((program, idx) => {
        return (
          <swiper-slide key={idx}>
            <div
              className="programs-slider__card"
              onClick={() => {
                navigate(currentPath + "/" + program.trainer_program_id);
              }}
            >
              <div
                className="programs-slider__card__thumb"
                style={{ backgroundImage: `url(${program.thumbnail_url})` }}
              ></div>
              <div className="programs-slider__card__textbox">
                <div
                  className={
                    program.name.length < 18
                      ? "programs-slider__card__title"
                      : "programs-slider__card__title--small"
                  }
                >
                  <strong>{program.name}</strong>
                  {/* <strong>Gggggg gggg gg gggg g</strong> */}
                </div>
                <div style={{ marginBottom: "12px" }}>{program.subtitle}</div>
                {/* {item.description.length > 250 ?
    `${item.description.substring(0, 250)}...` : item.description
  } */}
                <div>
                  {program.description.length > 99
                    ? `${program.description.substring(0, 99)}...`
                    : program.description}
                </div>
              </div>
            </div>
          </swiper-slide>
        );
      })}
    </swiper-container>
    //   <div className="results-slider__end-shadow"></div>
    // </div>
  );
};
