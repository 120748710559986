import React, { useEffect, useState } from "react";
import { Button, search } from "../../components";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { AdminTable } from "../AdminTable";

export function PickLesson({
  setOpenPicker,
  setActivityToSubmit,
  activityType,
}) {
  const [listLoading, setListLoading] = useState(true);
  const [lessonList, setLessonList] = useState();
  const [selectedLesson, setSelectedLesson] = useState();
  const [parentUnits, setParentUnits] = useState();
  const [selectedParent, setSelectedParent] = useState();
  const [lessonUnits, setLessonUnits] = useState();
  const [subUnitsByParent, setSubUnitsByParent] = useState();

  const [selectedUnit, setSelectedUnit] = useState();

  const [editingLesson, setEditingLesson] = useState();
  const [editingUnit, setEditingUnit] = useState();
  const [editingSub, setEditingSub] = useState();

  const getLessonList = () => {
    HandyGolfAPI.sendRequest("lesson/lesson/list")
      .then((data) => {
        console.log("lessonlist", data);
        setLessonList(data);
        setListLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLessonUnits = () => {
    let currentLesson = lessonList.find(
      (lesson) => lesson.name === selectedLesson.name
    );

    HandyGolfAPI.sendRequest("lesson/lesson_unit/list")
      .then((data) => {
        console.log(data);
        let matchingUnits = data.filter(
          (unit) =>
            unit.lesson_id === currentLesson.lesson_id &&
            unit.parent_lesson_unit_id !== 0
        );
        let parentUnits = data.filter(
          (unit) =>
            unit.lesson_id === currentLesson.lesson_id &&
            unit.parent_lesson_unit_id === 0
        );
        console.log("parentunits", parentUnits);
        console.log("lessonunits", matchingUnits);
        setParentUnits(parentUnits);
        setLessonUnits(matchingUnits);
        console.log(parentUnits);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filterSubUnits = () => {
    // console.log(selectedParent);
    let currentParent = parentUnits.find(
      (unit) => unit.name === selectedParent.name
    );
    let filteredSubUnits = lessonUnits.filter(
      (unit) => unit.parent_lesson_unit_id === currentParent.lesson_unit_id
    );
    console.log(filteredSubUnits);
    setSubUnitsByParent(filteredSubUnits);
  };
  // Trigger when neither `selectedLesson` nor `selectedParent` are selected
  useEffect(() => {
    if (!selectedLesson && !selectedParent) {
      getLessonList();
    }
  }, [selectedLesson, selectedParent]);

  // Trigger when `selectedLesson` is selected but `selectedParent` is not
  useEffect(() => {
    if (selectedLesson && !selectedParent) {
      getLessonUnits();
    }
  }, [selectedLesson, selectedParent]);

  // Trigger when both `selectedLesson` and `selectedParent` are selected
  useEffect(() => {
    if (selectedLesson && selectedParent) {
      filterSubUnits();
    }
  }, [selectedLesson, selectedParent]);

  const lessonActivitySetter = (lesson) => {
    let lessonObj = {
      name: lesson.name,
      description: lesson.description,
      type: activityType,
      lesson_unit_id: lesson.lesson_unit_id,
      activity_lenth_in_seconds: lesson.video_length,
    };
    setActivityToSubmit(lessonObj);
  };
  return (
    <div style={{ width: "380px", position: "relative", height: "100%" }}>
      {lessonList && (
        <div className="admin-table-half">
          <div className="admin-info-half__title">
            Pick a Lesson
            <div
              className="admin-edit-item-button"
              onClick={() => {
                // setLessonForm(selectedLesson);
                setOpenPicker(false);
              }}
            >
              <i className="material-icons">back</i>
              <strong>Back</strong>
            </div>
          </div>

          <AdminTable
            tableData={lessonList}
            headers={["name"]}
            setSelectedItem={setSelectedLesson}
            setEditingItem={setEditingLesson}
          />
        </div>
      )}
      <div
        className={
          !editingLesson
            ? "admin-action-edit-screen"
            : "admin-action-edit-screen admin-action-edit-screen--open"
        }
      >
        {parentUnits && (
          <div className="admin-table-half">
            <div className="admin-info-half__title">
              Pick a Unit
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm(selectedLesson);
                  setEditingLesson(false);
                }}
              >
                <i className="material-icons">back</i>
                <strong>Back</strong>
              </div>
            </div>
            <AdminTable
              tableData={parentUnits}
              headers={["name"]}
              setSelectedItem={setSelectedParent}
              setEditingItem={setEditingUnit}
            />
          </div>
        )}
      </div>
      <div
        className={
          !editingUnit
            ? "admin-action-edit-screen"
            : "admin-action-edit-screen admin-action-edit-screen--open"
        }
      >
        {subUnitsByParent && (
          <div className="admin-table-half">
            <div className="admin-info-half__title">
              Pick a Sub Unit
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm(selectedLesson);
                  setEditingUnit(false);
                }}
              >
                <i className="material-icons">back</i>
                <strong>Back</strong>
              </div>
            </div>
            <AdminTable
              tableData={subUnitsByParent}
              headers={["name"]}
              setSelectedItem={setSelectedUnit}
              setEditingItem={setEditingSub}
            />
          </div>
        )}
      </div>
      <div
        className={
          !editingSub
            ? "admin-action-edit-screen"
            : "admin-action-edit-screen admin-action-edit-screen--open"
        }
      >
        {selectedUnit && (
          <div className="admin-info-half">
            <div className="admin-info-half__title">
              Selected Sub Unit
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm(selectedLesson);
                  setEditingSub(false);
                }}
              >
                <i className="material-icons">back</i>
                <strong>Back</strong>
              </div>
            </div>
            <div className="admin-info-half__infobox">
              <div className="admin-info-half__key-value">
                <label>Name:</label>
                <strong>{selectedUnit.name}</strong>
              </div>
            </div>
            <Button
              primary
              fluid
              onClick={() => {
                lessonActivitySetter(selectedUnit);
                setOpenPicker(false);
              }}
            >
              Set Chosen Unit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
