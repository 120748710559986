import React, { useState, useEffect } from "react";

// Handy Golf Libs
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAuth from "../lib/HandyGolfAuth";
import { useTheme } from "../providers";
// Materials
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "./shared";
import MButton from "@mui/material/Button";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import { useUser } from "../providers";
import { useUpload } from "../context/UploadContext";
import { AdminNavItem } from "./index";

// Google Libraries
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export function AdminNav() {
  const [theme] = useTheme();
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const { isVerified, verifyAdmin, uploads } = useUpload();
  // const [currentPath, setCurrentPath] = useState();
  const currentPath = useLocation().pathname;
  const [selectedNav, setSelectedNav] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  // const getPath = () => {
  //   setCurrentPath(useLocation.pathname);
  // };
  useEffect(() => {
    if (currentPath.includes("trainer")) {
      setSelectedNav("Trainer");
    } else if (currentPath.includes("fix")) {
      setSelectedNav("Fixes");
    } else if (currentPath.includes("coach")) {
      setSelectedNav("Coaches");
    } else if (currentPath.includes("lessons")) {
      setSelectedNav("Lessons");
    } else if (currentPath.includes("requests")) {
      setSelectedNav("Requests");
    } else if (currentPath.includes("position")) {
      setSelectedNav("Positions");
    } else if (currentPath.includes("admin/upload/dashboard")) {
      setSelectedNav("Uploads");
    } else if (currentPath.includes("admin/dashboard")) {
      setSelectedNav("AdminDash");
    }
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const [adminMode] = useUser();
  //   console.log();
  //   console.log(adminMode);

  //state for trainer subnav links
  //   const [trainerSelected, setTrainerSelected] = useState(false);

  const trainerSubNav = [
    {
      name: "Programs",
      url: "/admin/trainer/programs",
    },
    {
      name: "Equipment",
      url: "/admin/trainer/equipment",
    },
    {
      name: "Videos",
      url: "/admin/trainer/videos",
    },
    {
      name: "Categories",
      url: "/admin/trainer/categories",
    },
  ];
  const fixSubNav = [
    {
      name: "Faults",
      url: "/admin/fix/faults",
    },
    {
      name: "Categories",
      url: "/admin/fix/categories",
    },
  ];
  // console.log(currentPath);
  if (uploads) {
    console.log(uploads);
  }
  return (
    <>
      <div className="admin-navigation">
        {user.is_admin && (
          <>
            <div className="logo">
              <Link to="/admin/dashboard">
                {theme === 1 ? (
                  <img
                    src="/img/handy-golf-logo-dark-mode.svg"
                    className="handy-golf-logo-dark-mode"
                    alt="Handy Golf Logo"
                  />
                ) : (
                  <img
                    src="/img/handy-golf-logo.svg"
                    className="handy-golf-logo"
                    alt="Handy Golf Logo"
                  />
                )}
              </Link>
            </div>
            {selectedNav && (
              <>
                <AdminNavItem
                  title={"Trainer"}
                  subNav={trainerSubNav}
                  selectedNav={selectedNav}
                />
                <AdminNavItem
                  title={"Fixes"}
                  subNav={fixSubNav}
                  selectedNav={selectedNav}
                />
                <AdminNavItem
                  title={"Coaches"}
                  soloUrl={"/admin/coaches"}
                  selectedNav={selectedNav}
                />
                <AdminNavItem
                  title={"Lessons"}
                  soloUrl={"/admin/lessons"}
                  selectedNav={selectedNav}
                />
                <AdminNavItem
                  title={"Requests"}
                  soloUrl={"/admin/user/requests"}
                  selectedNav={selectedNav}
                />
                <AdminNavItem
                  title={"Positions"}
                  soloUrl={"/admin/analyzer/positions"}
                  selectedNav={selectedNav}
                />
                <AdminNavItem
                  title={"Uploads"}
                  soloUrl={"/admin/upload/dashboard"}
                  selectedNav={selectedNav}
                />
              </>
            )}

            <Link to="/dashboard">
              <Button
                primary
                onClick={() => {
                  enterAdminMode();
                }}
              >
                Leave Admin
              </Button>
            </Link>
          </>
        )}
      </div>
      <div className="admin-navigation-mobile">
        <MButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ color: "#707070", fontWeight: "normal" }}
        >
          <div className="admin-navigation__navlink">
            Admin Pages{" "}
            {open ? (
              <i className="material-icons open">chevron_right</i>
            ) : (
              <i className="material-icons">chevron_right</i>
            )}
          </div>
        </MButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <Link to="/admin/dashboard">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Dashboard
                </div>
              </Link>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/coaches">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Coaches
                </div>
              </NavLink>
            </div>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/lessons">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Lessons
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/trainer/programs">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Trainer Programs
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/trainer/videos">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Trainer Videos
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/trainer/categories">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Trainer Categories
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/trainer/equipment">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Trainer Equipment
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/fix/faults">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Fix Faults
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/fix/categories">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Fix Categories
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <NavLink to="/admin/user/requests">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  User Requests
                </div>
              </NavLink>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="admin-navigation__navlink">
              <Link to="/dashboard">
                <Button
                  primary
                  onClick={() => {
                    enterAdminMode();
                  }}
                >
                  Leave Admin
                </Button>
              </Link>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
