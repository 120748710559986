import React from "react";

import "./ComingSoonLayout.css";

export const ComingSoonLayout = () => {
  return (
    <div class="landing">
      <div className="landing-background">
        <div className="landing-content">
          <div className="info-and-image">
            <div className="info-half">
              <div className="info-half-content">
                <div className="logo-section">
                  <img
                    className="logo-img"
                    src="/img/comingsoon/handy-golf-logo-dark-mode.svg"
                  />
                </div>
                <div className="social-icons-section">
                  <a
                    href="https://www.instagram.com/handygolfapp/"
                    target="_blank"
                  >
                    <img
                      className="social-img link-img"
                      src="/img/comingsoon/instagram-icon.svg"
                    />
                  </a>
                  <a href="https://www.youtube.com/@HandyGolf" target="_blank">
                    <img
                      className="social-img link-img"
                      src="/img/comingsoon/youtube-icon.svg"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/people/Handy-Golf/61550247959488/"
                    target="_blank"
                  >
                    <img
                      className="social-img link-img"
                      src="/img/comingsoon/facebook-icon.svg"
                    />
                  </a>
                  <a href="https://x.com/handygolfapp" target="_blank">
                    <img
                      className="social-img link-img"
                      src="/img/comingsoon/x-icon.svg"
                    />
                  </a>
                  <a
                    href="https://linkedin.com/company/handy-golf"
                    target="_blank"
                  >
                    <img
                      className="social-img link-img"
                      src="/img/comingsoon/linkedin-icon.svg"
                    />
                  </a>
                </div>
                <div className="download-section">
                  <div className="download-buttons">
                    <img
                      className="download-img link-img"
                      src="/img/comingsoon/app-store-coming-soon-xl.png"
                    />
                    <img
                      className="download-img link-img"
                      src="/img/comingsoon/play-store-coming-soon-xl.png"
                    />
                  </div>
                  <div className="tagline">
                    <div className="tagline-text">
                      <div>Your Ultimate Golf Companion</div>
                      <div>from First Swing to Final Putt</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="image-half ross-not-centered">
              <div className="image-container">
                <img
                  className="golfer-img"
                  src="/img/comingsoon/golferscreen-full.png"
                />
              </div>
            </div>
            <div className="image-half ross-centered">
              <div className="image-container-centered">
                <img
                  className="golfer-img-centered"
                  src="/img/comingsoon/golferscreen-full-centered.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
