import React, { useEffect, useState } from "react";

import HandyGolfAPI from "../../../../../../lib/HandyGolfAPI";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export function List({ videos }) {
  // if (videos.length > 0){}
  //   console.log(videos);
  return (
    <div className="list">
      {videos.map((vid, idx) => {
        return (
          //   <Card key={idx}>
          //     <CardActionArea>
          //       <CardMedia
          //         component="img"
          //         height="140"
          //         width="200"
          //         image="/img/sample-course-vid-thumb.jpg"
          //         alt="PLACEHOLD"
          //       />
          //       <CardContent>
          //         <Typography gutterBottom variant="h5" component="div">
          //           {vid.name}
          //         </Typography>
          //       </CardContent>
          //     </CardActionArea>
          //   </Card>
          <div className="list__video" key={idx}>
            <img src={vid.thumbnail_url} />
            {/* <img src="/img/sample-course-vid-thumb.jpg" /> */}
            <p>{vid.name}</p>
          </div>
        );
      })}
    </div>
  );
}
