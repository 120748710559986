import React from "react";

// Components
import CategoryMgmt from "../components/swing_trainer/CategoryMgmt/CategoryMgmt";
import PageMetaData from "../components/PageMetaData";

export default function AdminSwingTrainerCategoryMgmtPage() {
  return (
    <PageMetaData title="Category Management" seo="keywords for seo stuff">
      <div className="container">
        <div className="container__content">
          <CategoryMgmt />
        </div>
      </div>
    </PageMetaData>
  );
}
