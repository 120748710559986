import React, { useEffect, useState, useContext, useRef } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Card,
  Container,
  AppTopBar,
  BottomNav,
  Player,
  Button,
} from "../../components";
import HourMinuteSelector from "./HourMinuteSelector";
import ProgramCalendar from "./ProgramCalendar";
import {
  StateContext,
  StateDispatchContext,
  CheckProgramsCacheContext,
} from "../../context/AppContext";

export function ActivityDetails() {
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkProgramsCache = useContext(CheckProgramsCacheContext);
  const navigate = useNavigate();

  //states
  const [openingPracticeMode, setOpeningPracticeMode] = useState(false);
  const { trainer_activity_id } = useParams();

  const videoRef = useRef(null);
  useEffect(() => {
    checkProgramsCache();
  }, [state.loading.programs_data, trainer_activity_id]);

  const isLoading = state.loading.programs_data;
  //    ||
  //   console.log(state.loading);
  if (isLoading) {
    return <>loading.....</>;
  }
  let program = state.programs_data.programs.find((program) =>
    program.trainer_sessions.find((session) =>
      session.trainer_activities.find(
        (activity) =>
          activity.trainer_activity_id === parseInt(trainer_activity_id)
      )
    )
  );
  let programIndex = state.programs_data.programs.findIndex(
    (el) => el.trainer_program_id === program.trainer_program_id
  );
  let session = program.trainer_sessions.find((session) =>
    session.trainer_activities.find(
      (activity) =>
        activity.trainer_activity_id === parseInt(trainer_activity_id)
    )
  );
  let activity = session.trainer_activities.find(
    (activity) => activity.trainer_activity_id === parseInt(trainer_activity_id)
  );
  let trainerProgramId = program.trainer_program_id;
  let trainerProgramIdString = trainerProgramId.toString();

  let userProgram = state.programs_data.user.find(
    (user) => user.trainer_program_id === trainerProgramId
  );
  let userSession = userProgram.trainer_sessions.find(
    (sesh) => sesh.trainer_session_id === session.trainer_session_id
  );
  let completedActivities = userSession.completed_trainer_activity_ids;
  let type = activity.type;
  {
    /* 1 - Drill Explanation
                                2 - Drill Practice 
                                3 - Guided Training 
                                4 - Lesson 
                                5 - Mirror Mode */
  }

  console.log(activity);
  console.log(userProgram);
  console.log(userSession);
  return (
    <>
      <AppTopBar
        breadcrumb
        pageTitle={activity.name}
        // pageTitle={"Activtyyay"}
        // trainer_video_id={trainer_video_id}
        // watchlistIds={watchlistIds}
        // favoriteIds={favoriteIds}
        // handleFavorite={handleFavorite}
        // handleWatchlist={handleWatchlist}
        path={"/trainer/programs/" + trainerProgramIdString}
        // favorite
        // addTo
      />
      <div className="container">
        <div className="container__content">
          <div className="pad-box">
            <div style={{ position: "relative" }}>
              {(type === 1 || type === 3) && (
                //DRILL EXPLANATION or GUIDED TRAINING
                <Player
                  videoRef={videoRef}
                  url={"/vid/adamscott.mp4"}
                  //   url={
                  //     video.video_url !== ""
                  //       ? video.video_url
                  //       : "/vid/adamscott.mp4"
                  //   }
                  isTrainer
                  practiceMode={false}
                  openingPracticeMode={false}
                  setOpeningPracticeMode={setOpeningPracticeMode}
                />
              )}

              {type === 2 && (
                //DRILL PRACTICE
                <Player
                  videoRef={videoRef}
                  url={"/vid/adamscott.mp4"}
                  //   url={
                  //     video.video_url !== ""
                  //       ? video.video_url
                  //       : "/vid/adamscott.mp4"
                  //   }
                  // light={"/vid/adam"}
                  // saveFeedback={saveFeedback}
                  // current_lesson_unit={current_lesson_unit}
                  // loadLessonUnitData={loadLessonUnitData}
                  // lesson={lesson}
                  // feedback_display={feedback_display}
                  // setFeedbackDisplay={setFeedbackDisplay}
                  // user_lesson_data={user_lesson_data}
                  // setVideoTime={setVideoTime}
                  // feedback_rating={feedback_rating}
                  // feedback_text={feedback_text}
                  isTrainer
                  practiceMode={true}
                  openingPracticeMode={openingPracticeMode}
                  setOpeningPracticeMode={setOpeningPracticeMode}
                  // userRepRange={userRepRange}
                />
              )}
              {type === 4 && (
                //lesson probably can just be trainer but with a lesson sub unit vid.
                <Player
                  videoRef={videoRef}
                  url={"/vid/adamscott.mp4"}
                  //   url={
                  //     video.video_url !== ""
                  //       ? video.video_url
                  //       : "/vid/adamscott.mp4"
                  //   }
                  isTrainer
                  practiceMode={false}
                  openingPracticeMode={false}
                  setOpeningPracticeMode={setOpeningPracticeMode}
                />
                // <Player
                //   videoRef={videoRef}

                //   url={
                //     sub.video_url !== ""
                //       ? sub.video_url
                //       : "/vid/adamscott.mp4"
                //   }

                //   lessonDetails={lessonDetails}
                //   updateLessonDetails={updateLessonDetails}
                //   dispatchHandler={dispatchHandler}
                //   saveFeedback={saveFeedback}
                //   feedback_display={feedback_display}
                //   setFeedbackDisplay={setFeedbackDisplay}
                //   setVideoTime={setVideoTime}
                //   feedback_rating={feedback_rating}
                //   feedback_text={feedback_text}
                //   prevSubUnitId={lessonDetails.prevSub}
                //   nextSubUnitId={lessonDetails.nextSub}
                // />
              )}
              {type === 5 && (
                //DRILL PRACTICE with default mirror mode?
                <Player
                  videoRef={videoRef}
                  url={"/vid/adamscott.mp4"}
                  //   url={
                  //     video.video_url !== ""
                  //       ? video.video_url
                  //       : "/vid/adamscott.mp4"
                  //   }
                  // light={"/vid/adam"}
                  // saveFeedback={saveFeedback}
                  // current_lesson_unit={current_lesson_unit}
                  // loadLessonUnitData={loadLessonUnitData}
                  // lesson={lesson}
                  // feedback_display={feedback_display}
                  // setFeedbackDisplay={setFeedbackDisplay}
                  // user_lesson_data={user_lesson_data}
                  // setVideoTime={setVideoTime}
                  // feedback_rating={feedback_rating}
                  // feedback_text={feedback_text}
                  defaultCompareMode={true}
                  isTrainer
                  practiceMode={true}
                  openingPracticeMode={openingPracticeMode}
                  setOpeningPracticeMode={setOpeningPracticeMode}
                  // userRepRange={userRepRange}
                />
              )}
            </div>
            <div style={{ margin: "2rem auto" }}>
              {!completedActivities.some((el) => el === trainer_activity_id) ? (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    HandyGolfAPI.sendRequest(
                      "trainer/program/markActivityAsComplete",
                      {
                        trainer_activity_id: parseInt(trainer_activity_id),
                      }
                    ).then((data) => {
                      let key = "programs_data";
                      let newProgramState = state.programs_data;
                      let newUser = data.user_programs;
                      let newCache = data.new_cache_time;
                      let newProgramObject = {
                        ...newProgramState,
                        user: newUser,
                        new_cache_time: newCache,
                      };
                      localStorage.setItem(
                        key,
                        JSON.stringify(newProgramObject)
                      );
                      dispatch({
                        type: "FETCH_SUCCESS",
                        payload: { key, data: newProgramObject },
                      });
                    });
                  }}
                >
                  Mark as Completed
                </Button>
              ) : (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    HandyGolfAPI.sendRequest(
                      "trainer/program/markActivityAsIncomplete",
                      {
                        trainer_activity_id: parseInt(trainer_activity_id),
                      }
                    ).then((data) => {
                      let key = "programs_data";
                      let newProgramState = state.programs_data;
                      let newUser = data.user_programs;
                      let newCache = data.new_cache_time;
                      let newProgramObject = {
                        ...newProgramState,
                        user: newUser,
                        new_cache_time: newCache,
                      };
                      localStorage.setItem(
                        key,
                        JSON.stringify(newProgramObject)
                      );
                      dispatch({
                        type: "FETCH_SUCCESS",
                        payload: { key, data: newProgramObject },
                      });
                    });
                  }}
                >
                  Mark as Incomplete
                </Button>
              )}
            </div>
            {!completedActivities.some((el) => el === trainer_activity_id) && (
              <div style={{ margin: "2rem auto" }}>
                <Button
                  primary
                  fluid
                  style={{
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    HandyGolfAPI.sendRequest("trainer/program/skipActivity", {
                      trainer_activity_id: parseInt(trainer_activity_id),
                    }).then((data) => {
                      let key = "programs_data";
                      let newProgramState = state.programs_data;
                      let newUser = data.user_programs;
                      let newCache = data.new_cache_time;
                      let newProgramObject = {
                        ...newProgramState,
                        user: newUser,
                        new_cache_time: newCache,
                      };
                      localStorage.setItem(
                        key,
                        JSON.stringify(newProgramObject)
                      );
                      dispatch({
                        type: "FETCH_SUCCESS",
                        payload: { key, data: newProgramObject },
                      });
                    });
                  }}
                >
                  Skip Activity
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="container__side-content">
          {/* <div
            style={{ display: "flex", flexDirection: "column", gap: "3rem" }}
          >
             <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.2rem",
              }}>
             <strong>Related Programs</strong>
             {map other programs}
              </div>

          </div> */}
        </div>
      </div>
      <BottomNav />
    </>
  );
}
