import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";

import { FileUploader } from "react-drag-drop-files";

import LinearProgress from "@mui/material/LinearProgress";

import { FileUpload } from "./sub/FileUpload";
const defaultBucket = {
  name: "handygolfwebsite-images",
  region: "us-east-2",
};
const defaultS3Keys = {
  accessKeyId: "",
  secretAccessKey: "",
};
//i want to have the option for each component location to have its default props, like bucket name, path, filenames, etc
export function S3Uploader() {
  const [error, setError] = useState();
  const [bucketInfo, setBucketInfo] = useState(defaultBucket);
  const [fileList, setFileList] = useState();
  const [hasCorrectKeys, setHasCorrectKeys] = useState();
  const [s3Keys, setS3Keys] = useState(defaultS3Keys);
  const [filePath, setFilePath] = useState({
    folder: "",
    name: "",
  });
  const [counter, setCounter] = useState(0);
  AWS.config.update({
    region: bucketInfo.region,
    credentials: {
      accessKeyId: sessionStorage.getItem("accessKeyId"),
      secretAccessKey: sessionStorage.getItem("secretAccessKey"),
    },
  });
  const s3 = new AWS.S3({
    params: { Bucket: bucketInfo.name },
    region: bucketInfo.region,
  });

  useEffect(() => {
    if (
      !sessionStorage.getItem("accessKeyId") ||
      !sessionStorage.getItem("secretAccessKey")
    ) {
      setError("CredentialsError");
      setS3Keys(defaultS3Keys);
    } else {
      setS3Keys({
        accessKeyId: sessionStorage.getItem("accessKeyId"),
        secretAccessKey: sessionStorage.getItem("secretAccessKey"),
      });
      setError();

      // console.log(s3Keys.accessKeyId);
      AWS.config.update({
        region: bucketInfo.region,
        credentials: {
          accessKeyId: sessionStorage.getItem("accessKeyId"),
          secretAccessKey: sessionStorage.getItem("secretAccessKey"),
        },
      });
      let params = {
        Bucket: bucketInfo.name,
      };
      s3.listObjectsV2(params, (err, data) => {
        if (err) {
          // console.log(err, err.stack);
          setError(err.code);
          setS3Keys(defaultS3Keys);
          setHasCorrectKeys(false);
        } else {
          // setFileList(data.Contents);
          setError();
          setHasCorrectKeys(true);
          setFileList(data.Contents);
          // console.log(data.Contents);
        }
      });
      // console.log(s3Keys.accessKeyId);
    }
  }, [counter]);
  // console.log(s3Keys);

  const handleKeyChange = (e) => {
    setError();
    setS3Keys({ ...s3Keys, [e.target.name]: e.target.value });
  };
  const storeKeys = (e) => {
    e.preventDefault();

    if (s3Keys.accessKeyId === "" || s3Keys.secretAccessKey === "") {
      sessionStorage.removeItem("accessKeyId");
      sessionStorage.removeItem("secretAccessKey");
      setError("CredentialsError");
    } else {
      sessionStorage.setItem("accessKeyId", s3Keys.accessKeyId);
      sessionStorage.setItem("secretAccessKey", s3Keys.secretAccessKey);
      AWS.config.update({
        region: bucketInfo.region,
        credentials: {
          accessKeyId: s3Keys.accessKeyId,
          secretAccessKey: s3Keys.secretAccessKey,
        },
      });
      let params = {
        Bucket: bucketInfo.name,
      };
      s3.listObjectsV2(params, (err, data) => {
        if (err) {
          // console.log(err, err.stack);
          setError(err.code);
          setS3Keys(defaultS3Keys);
          setHasCorrectKeys(false);
        } else {
          // setFileList(data.Contents);
          setError();
          setHasCorrectKeys(true);
          setFileList(data.Contents);
          // console.log(data.Contents);
        }
      });
      setCounter(counter + 1);
    }
  };

  const handleBucketInfoChange = (e) => {
    // setError(null);
    setBucketInfo({ ...bucketInfo, [e.target.name]: e.target.value });
  };
  const handleFilePathChange = (e) => {
    setFilePath({ ...filePath, [e.target.name]: e.target.value });
    // setFileName(e.target.value);
  };
  return (
    <div className="uploader">
      <h1>Upload a File</h1>
      {hasCorrectKeys ? <p>keys correct</p> : <p>keys false</p>}
      {!hasCorrectKeys && (
        <form className="uploader__subform">
          <div>
            <input
              name="accessKeyId"
              // type="password"
              placeholder="access key"
              value={s3Keys.accessKeyId}
              onChange={handleKeyChange}
            />
          </div>
          <div>
            <input
              name="secretAccessKey"
              // type="password"
              placeholder="secret access key"
              value={s3Keys.secretAccessKey}
              onChange={handleKeyChange}
            />
          </div>
          <button onClick={storeKeys}>Check Credentials</button>
        </form>
      )}
      {hasCorrectKeys && (
        <button
          onClick={() => {
            setHasCorrectKeys(false);
            sessionStorage.removeItem("accessKeyId");
            sessionStorage.removeItem("secretAccessKey");
            setS3Keys(defaultS3Keys);
            setError("CredentialsError");
          }}
        >
          delete credentials
        </button>
      )}

      {!error && <></>}
      {error === "CredentialsError" && (
        <p style={{ color: "red" }}>
          One or both of your key credentials are missing!
        </p>
      )}
      {error === "InvalidAccessKeyId" ||
        (error === "SignatureDoesNotMatch" && (
          <p style={{ color: "red" }}>
            One or both of your key credentials are invalid!
          </p>
        ))}

      <div>
        <form className="uploader__subform">
          <div>
            <input
              name="name"
              placeholder="bucket name"
              value={bucketInfo.name}
              onChange={handleBucketInfoChange}
            />
          </div>
          <div>
            <input
              name="region"
              placeholder="bucket region"
              value={bucketInfo.region}
              onChange={handleBucketInfoChange}
            />
          </div>
          <div>
            <input
              name="folder"
              placeholder="folder"
              value={filePath.folder}
              onChange={handleFilePathChange}
            />
          </div>
          <div>
            <input
              name="name"
              placeholder="file name"
              value={filePath.name}
              onChange={handleFilePathChange}
            />
          </div>
        </form>
        <FileUpload
          bucketInfo={bucketInfo}
          // fileName={fileName}
          filePath={filePath}
          setFilePath={setFilePath}
          s3Keys={s3Keys}
          setError={setError}
          fileList={fileList}
          setFileList={setFileList}
          setCounter={setCounter}
          counter={counter}
          // setFileName={setFileName}
        />
      </div>
    </div>
  );
}
