import React, { useState } from "react";

// CSS
import "../../../../LessonsPage.css";

// Libraries
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Rating from "@mui/material/Rating";
import StickyNote2SharpIcon from "@mui/icons-material/StickyNote2Sharp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Video Library
import ReactPlayer from "react-player";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

// Other
import nl2br from "react-nl2br";

export function VideoSection({
  lesson,
  current_lesson_unit,
  loadLessonUnitData,
  videoRef,
  video_playing,
  setVideoPlaying,
  video_levels,
  setVideoLevels,
  feedback_display,
  setFeedbackDisplay,
  setVideoTime,
  saveFeedback,
  feedback_rating,
  feedback_text,
}) {
  return (
    <div>
      <div className="unit_controls">
        {current_lesson_unit.previous_lesson_unit &&
          current_lesson_unit.previous_lesson_unit.number &&
          current_lesson_unit.previous_lesson_unit.number.trim().length > 0 && (
            <Button
              className="back_button white_button"
              startIcon={<ArrowLeftIcon />}
              onClick={(e) => {
                loadLessonUnitData(
                  current_lesson_unit.previous_lesson_unit.lesson_unit_id
                );
              }}
            >
              {current_lesson_unit.previous_lesson_unit.number}
            </Button>
          )}
        <div className="unit_title">
          {current_lesson_unit.parent_lesson_unit_number}.
          {current_lesson_unit.sort_order}: {current_lesson_unit.name}
        </div>
        {current_lesson_unit.next_lesson_unit &&
          current_lesson_unit.next_lesson_unit.number &&
          current_lesson_unit.next_lesson_unit.number.trim().length > 0 && (
            <Button
              className="back_button white_button"
              endIcon={<ArrowRightIcon />}
              onClick={(e) => {
                loadLessonUnitData(
                  current_lesson_unit.next_lesson_unit.lesson_unit_id
                );
              }}
            >
              {current_lesson_unit.next_lesson_unit.number}
            </Button>
          )}
      </div>
      {/* @TODO Add in Video URL Once Video Uploads are working */}
      {/*<ReactPlayer src="https://handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx.s3.us-east-2.amazonaws.com/e142b8cb-c632-42a4-a7f8-edebd06e6df1/hls/adamscott.m3u8" />*/}
      <div style={{ position: "relative" }}>
        <ReactPlayer
          ref={videoRef}
          url="https://handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx.s3.us-east-2.amazonaws.com/e142b8cb-c632-42a4-a7f8-edebd06e6df1/hls/adamscott.m3u8"
          preload="auto"
          controls={true}
          controlsList="nodownload"
          playing={video_playing}
          style={{ width: 550, height: 350, borderRadius: 20 }}
          onReady={(e) => {
            setVideoLevels(videoRef.current.getInternalPlayer("hls").levels);
          }}
          onPlay={(e) => {
            setVideoPlaying(true);
          }}
          onPause={(e) => {
            setVideoPlaying(false);
          }}
          onProgress={(e) => {
            if (video_playing && feedback_display) {
              setFeedbackDisplay(false);
            }
            setVideoTime(Math.floor(e.playedSeconds));
          }}
          onEnded={() => {
            setFeedbackDisplay(true);
            HandyGolfAPI.sendRequest("lesson/markLessonUnitAsComplete", {
              lesson_id: lesson.lesson_id,
              lesson_unit_id: current_lesson_unit.lesson_unit_id,
            }).then((data) => {
              loadLessonUnitData(current_lesson_unit.lesson_unit_id);
            });
          }}
        ></ReactPlayer>

        <div
          style={{
            textAlign: "center",
            position: "absolute",
            display: feedback_display ? "flex" : "none",
            justifyContent: "center",
            backgroundColor: "#000000cc",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            color: "#ffffff",
            fontWeight: "bold",
          }}
        >
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            Please tell us how you would rate this lesson:
            <br />
            <Rating
              className="lesson_feedback_stars"
              value={feedback_rating}
              onChange={(e, new_value) => {
                saveFeedback(new_value, null);
              }}
            />
            <br />
            <div>
              {feedback_rating > 0 && feedback_rating <= 3 ? (
                <>
                  What could we do better?
                  <br />
                </>
              ) : (
                <>
                  Do you have any additional comments?
                  <br />
                </>
              )}

              <TextField
                multiline
                rows={3}
                style={{
                  width: 300,
                  marginBottom: 20,
                  backgroundColor: "#ffffff",
                }}
                inputProps={{ maxLength: 300 }}
                value={feedback_text}
                onChange={(e) => {
                  saveFeedback(null, e.target.value);
                }}
              />
            </div>
            {current_lesson_unit.next_lesson_unit &&
              current_lesson_unit.next_lesson_unit.number &&
              current_lesson_unit.next_lesson_unit.number.trim().length > 0 && (
                <Button
                  variant="contained"
                  className="green"
                  startIcon={<ArrowRightIcon />}
                  onClick={(e) => {
                    loadLessonUnitData(
                      current_lesson_unit.next_lesson_unit.lesson_unit_id
                    );
                  }}
                >
                  Continue to Next Lesson
                </Button>
              )}
          </div>
        </div>
      </div>
      Bitrate Levels:
      {video_levels.map((video_level, i) => {
        return (
          <Button
            onClick={() => {
              videoRef.current.getInternalPlayer("hls").currentLevel = i;
            }}
            key={i}
          >
            {video_level.attrs.RESOLUTION}
          </Button>
        );
      })}
      <Typography variant="body2" gutterBottom>
        {current_lesson_unit.description}
      </Typography>
      <br />
    </div>
  );
}
