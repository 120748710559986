import React from 'react';

// CSS
import './DevStyleGuidePage.css';

// MUI
import Typography from "@mui/material/Typography";
import {NavLink} from "react-router-dom";
import {Divider, Box, Breadcrumbs} from "@mui/material";

import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'firstName',
        headerName: 'First name',
        width: 150,
        editable: true,
    },
    {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: true,
    },
    {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'fullName',
        headerName: 'Full name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
];

const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

function DevStyleGuidePage() {

    return (
        <div className="body_content">
            <Typography variant="h3" gutterBottom>Style Guide</Typography>

            <Typography variant="h6" gutterBottom>Colors</Typography>
            <div className="color_container">
                <div className="black square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Black<br />#010101ff<br />White Text</Typography>
                </div>
                <div className="jet square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Jet<br />#363636ff<br />White Text</Typography>
                </div>
                <div className="dim-gray square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Dim Gray<br />#707070ff<br />White Text</Typography>
                </div>
                <div className="white square_medium txt_center">
                    <Typography variant="body2" gutterBottom>White<br />#ffffffff<br />Black Text</Typography>
                </div>
                <div className="pakistan-green square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Pakistan Green<br />#134611ff<br />White Text</Typography>
                </div>
                <div className="dark-pastel-green square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Dark Pastel Green<br />#3ab549ff<br />White Text</Typography>
                </div>
                <div className="penn-blue square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Penn Blue<br />#091540ff<br />White Text</Typography>
                </div>
                <div className="azul square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Azul<br />#0071bbff<br />White Text</Typography>
                </div>
                <div className="alice-blue square_medium txt_center">
                    <Typography variant="body2" gutterBottom>Alice Blue<br />#e9edf4ff<br />Black Text</Typography>
                </div>
            </div>
            <Typography variant="subtitle2" gutterBottom>Color Usage:</Typography>
            <Typography variant="subtitle2" gutterBottom>The above colors should be used in the following ways:</Typography>
            <ul>
                <li><strong>Black:</strong> ...</li>
                <li><strong>Jet:</strong> ...</li>
                <li><strong>Dim Gray:</strong> ...</li>
                <li><strong>White:</strong> ...</li>
                <li><strong>Pakistan Green:</strong> ...</li>
                <li><strong>Dark Pastel Green:</strong> ...</li>
                <li><strong>Penn Blue:</strong> ...</li>
                <li><strong>Azul:</strong> ...</li>
                <li><strong>Alice Blue:</strong> ...</li>
            </ul>
            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Fonts</Typography>
            <ul>
                <li><Typography variant="h6" gutterBottom>Roboto - The quick brown fox jumps over the lazy dog</Typography></li>
            </ul>
            <Typography variant="subtitle2" gutterBottom>Font Usage:</Typography>
            <ul>
                <li><Typography variant="body2" gutterBottom><strong>Roboto:</strong> This is currently used everywhere on the site with a fallback to Helvetica, Arial, and then any sans-serif font.</Typography></li>
            </ul>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Main Menu Links</Typography>
            <div className="header_menu" style={{width:400, margin: "0 auto 0 0"}}>
                <div><a href="#" className="header_menu">Item 1</a></div>
                <div><a href="#" className="header_menu">Item 2</a></div>
                <div><a href="#" className="header_menu">Item 3</a></div>
                <div><a href="#" className="header_menu">Item 4</a></div>
                <div><a href="#" className="header_menu">Item 5</a></div>
            </div><br />
            <Typography variant="subtitle2" gutterBottom>Used on the main menu of the website.</Typography>
            <Typography variant="body2" gutterBottom>
                <ul>
                    <li><strong>Font:</strong> Roboto</li>
                    <li><strong>Size:</strong> 14px</li>
                    <li><strong>Text Color (Inactive):</strong> Dim Gray</li>
                    <li><strong>Text Color (Hover):</strong> Azul</li>
                    <li><strong>Text Color (Active):</strong> Azul</li>
                    <li><strong>Font Weight:</strong> Bold</li>
                    <li><strong>Padding:</strong> 8px x 16px</li>
                    <li><strong>Text Decoration (Inactive):</strong> None</li>
                    <li><strong>Text Decoration (Hover):</strong> 2px thick underline with 10px offset</li>
                    <li><strong>Text Decoration (Active):</strong> 2px thick underline with 10px offset</li>
                </ul>
            </Typography>
            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Footer Links</Typography>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Green Button</Typography>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Blue Button</Typography>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>White Button</Typography>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Green Form Box</Typography>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Carousel</Typography>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Breadcrumbs</Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Button>Root</Button>
                <Button>Next Level</Button>
                <Button disabled>Last Level</Button>
            </Breadcrumbs>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Data Grid</Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>

            <Divider sx={{marginBottom: 5}} />
            <Typography variant="h6" gutterBottom>Form</Typography>
        </div>
    );
}

export default DevStyleGuidePage;
