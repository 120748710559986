import * as React from "react";
import {
  VideoList,
  VideoView,
  VideoForm,
  DeleteVideoConfirm,
} from "./sub";

// MUI
import CircularProgress from "@mui/material/CircularProgress";

import { useParams, Navigate, Link } from "react-router-dom";

export default function VideoMgmt() {
  const { action_param = "list", id_param = 0 } = useParams();

  // New View Logic -- Based on Props
  let trainer_video_id = 0;
  if (action_param === "list") {
    return <VideoList />;
  }
  if (action_param === "add") {
    return <VideoForm mode="add" trainer_video_id={trainer_video_id} />;
  }
  // MJH
  if (action_param === "edit") {
    trainer_video_id = parseInt(id_param);
    if (trainer_video_id > 0) {
      return <VideoForm mode="edit" trainer_video_id={trainer_video_id} />;
    } else {
      return <div>ERROR 1.</div>;
    }
  }
  if (action_param === "view") {
    trainer_video_id = parseInt(id_param);
    console.log(trainer_video_id);
    if (trainer_video_id > 0) {
      return <VideoView trainer_video_id={trainer_video_id} />;
    } else {
      return <div>ERROR 2.</div>;
    }
  }
  if (action_param === "delete") {
    trainer_video_id = parseInt(id_param);
    console.log(trainer_video_id);
    if (trainer_video_id > 0) {
      return <DeleteVideoConfirm trainer_video_id={trainer_video_id} />;
    } else {
      return <div>ERROR 2.</div>;
    }
  }
  if (action_param === undefined) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Navigate to="/admin/swing_trainer/video_mgmt" />
    </div>
  );
}
