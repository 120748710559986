import * as React from "react";
import { Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import AdminPage from "../pages/AdminPage";
import AdminLessonMgmtPage from "../pages/AdminLessonMgmtPage";
import AdminSwingTrainerCategoryMgmtPage from "../pages/AdminSwingTrainerCategoryMgmtPage";
import AdminSwingTrainerTagMgmtPage from "../pages/AdminSwingTrainerTagMgmtPage";
import AdminSwingTrainerVideoMgmtPage from "../pages/AdminSwingTrainerVideoMgmtPage";
import ContactPage from "../pages/ContactPage";
import LessonsPage from "../pages/LessonsPage";
import DevDebuggerPage from "../pages/DevDebuggerPage";
import MySQLQueryRunnerPage from "../pages/MySQLQueryRunnerPage";
import DevStyleGuidePage from "../pages/DevStyleGuidePage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import StorePage from "../pages/StorePage";
import SupportPage from "../pages/SupportPage";
import SwingAnalyzerPage from "../pages/SwingAnalyzerPage";
import SwingDoctorPage from "../pages/SwingDoctorPage";
// import SwingTrainerPage from "../pages/SwingTrainerPage";
import { SwingTrainerPage } from "../pages/swing_trainer_page";
import { SwingTrainerVideoPage } from "../pages/swing_trainer_page";
import TermsOfServicePage from "../pages/TermsOfServicePage";
import ToolsPage from "../pages/ToolsPage";
import UserProfilePage from "../pages/UserProfilePage";
import UserSignUpPage from "../pages/UserSignUpPage";
import LessonDetailsPage from "../pages/lesson_details_page/LessonDetailsPage";

import {
  Dashboard,
  Lessons,
  Trainer,
  VideoPage,
  LessonDetails,
  Progress,
  SwingAnalyzer,
  TrainerVideoSearch,
  Programs,
  ProgramDetails,
  ActivityDetails,
} from "../app/";
// import { AdminDash, CoachMgmt, EquipmentMgmt } from "../admin";
//import page elements

export const freeUserRoutes = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/progress",
    element: <Progress />,
  },

  // {
  //   path: "/admin",
  //   element: <AdminDash />,
  // },
  // {
  //   path: "/admin/coaches",
  //   element: <CoachMgmt />,
  // },
  // {
  //   path: "/admin/equipment",
  //   element: <EquipmentMgmt />,
  // },

  // {
  //   path: "/admin/lesson_mgmt/:action_param?/:id_param?",
  //   element: <AdminLessonMgmtPage />,
  // },
  // {
  //   path: "/admin/swing_trainer/category_mgmt/:action_param?/:id_param?",
  //   element: <AdminSwingTrainerCategoryMgmtPage />,
  // },
  // {
  //   path: "/admin/swing_trainer/tag_mgmt/:action_param?/:id_param?",
  //   element: <AdminSwingTrainerTagMgmtPage />,
  // },
  // {
  //   path: "/admin/swing_trainer/video_mgmt/:action_param?/:id_param?",
  //   element: <AdminSwingTrainerVideoMgmtPage />,
  // },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/lessons/lesson_details/:lesson_id?",
    element: <LessonDetails />,
  },
  {
    path: "/lesson_details/:lesson_id?",
    element: <LessonDetailsPage />,
  },
  // {
  //   path: "/lessons",
  //   element: <LessonsPage />,
  // },
  {
    path: "/lessons",
    element: <Lessons />,
  },

  {
    path: "/dev/debug",
    element: <DevDebuggerPage />,
  },
  {
    path: "/dev/mysql",
    element: <MySQLQueryRunnerPage />,
  },
  {
    path: "/dev/style_guide",
    element: <DevStyleGuidePage />,
  },
  {
    path: "/privacy_policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/store",
    element: <StorePage />,
  },

  {
    path: "/support",
    element: <SupportPage />,
  },
  {
    path: "/swing_analyzer",
    element: <SwingAnalyzer />,
  },
  // {
  //   path: "/swing_analyzer",
  //   element: <SwingAnalyzerPage />,
  // },
  {
    path: "/swing_doctor",
    element: <SwingDoctorPage />,
  },
  {
    path: "/trainer",
    element: <Trainer />,
  },
  {
    path: "/trainer/programs",
    element: <Programs />,
  },
  {
    path: "/trainer/programs/:trainer_program_id?",
    element: <ProgramDetails />,
  },
  {
    path: "/trainer/activity/:trainer_activity_id?",
    element: <ActivityDetails />,
  },
  {
    path: "/trainer/drills",
    element: <TrainerVideoSearch />,
  },
  // {
  //   path: "/trainer/drills",
  //   element: <Drills />,
  // },
  {
    path: "/trainer/guided_trainings",
    element: <TrainerVideoSearch />,
  },
  // {
  //   path: "/trainer/guided_trainings",
  //   element: <GuidedTrainings />,
  // },

  {
    path: "/trainer/drills/:trainer_video_id?",
    element: <VideoPage />,
  },
  {
    path: "/trainer/guided_trainings/:trainer_video_id?",
    element: <VideoPage />,
  },

  {
    path: "/swing_trainer",
    element: <SwingTrainerPage />,
  },
  {
    path: "/swing_trainer/:trainer_video_id?",
    element: <SwingTrainerVideoPage />,
  },
  {
    path: "/terms_of_service",
    element: <TermsOfServicePage />,
  },
  {
    path: "/tools",
    element: <ToolsPage />,
  },
  {
    path: "/user/profile",
    element: <UserProfilePage />,
  },
  {
    path: "/user/profile",
    element: <UserProfilePage />,
  },
  {
    path: "/user/sign_up",
    element: <UserSignUpPage />,
  },
];
