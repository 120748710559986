import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export function Results({ videos, type }) {
  let navigate = useNavigate();
  // console.log("videosrecent:", videos);
  function LinearProgressWithLabel(props) {
    const value = props.value;
    return (
      <>
        <Typography
          variant="body2"
          className="txt-dark-pastel-green"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
        <LinearProgress variant="determinate" {...props} />
      </>
    );
  }
  if (!videos) {
    return <h4>loading...</h4>;
  }

  return (
    <div className="results">
      {/* <h1>Watch them Again</h1> */}
      {videos.map((video, idx) => {
        return (
          <div
            className="results__video"
            key={idx}
            onClick={() => {
              navigate("/swing_trainer/" + video.trainer_video_id);
            }}
          >
            {/* <div> */}
            <img src={video.thumbnail_url} />
            {/* <LinearProgressWithLabel
            className="greenbg"
            value={video.video_viewed_until_seconds}
          /> */}
            {/* </div> */}

            <div className="results__video results__video--info">
              <h5>{video.name}</h5>
              <p>{video.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
