// import logo from './logo.svg';
import "./scss";

import React from "react";
import { Amplify } from "aws-amplify";

// Handy Golf Libs
import HandyGolf from "./lib/HandyGolf";
import HandyGolfAuth from "./lib/HandyGolfAuth";

import { Layout } from "./layout";
import { ToastProvider, ThemeProvider, UserProvider } from "./providers";
import { StateProvider } from "./context/AppContext";
import { UploadProvider } from "./context/UploadContext";
// Materials
import { Button, Drawer, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

// Google Libraries
import ReactGA from "react-ga4";
import { Authenticator } from "@aws-amplify/ui-react";
import { GoogleOAuthProvider } from "@react-oauth/google";

// CSS
import "@aws-amplify/ui-react/styles.css";

// Amplify (I'm not sure that this is needed)
// import awsExports from "./aws-exports";
import { SwingTrainerVideoPage } from "./pages/swing_trainer_page/sub";

// Amplify.configure(awsExports);

// Configure Google Analytics
let analytics_id = "";
if (HandyGolf.getValue("analytics_id") !== "") {
  analytics_id = HandyGolf.getValue("analytics_id");
  ReactGA.initialize(analytics_id);
}

function ScrollToTop() {
  // Scroll to the top of the page if the navigation is changed.
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  // States
  const [state, setState] = React.useState({
    sidemenu: false,
  });
  const [is_authenticated, setIsAuthenticated] = React.useState(false);
  const [user_details, setUserDetails] = React.useState({});

  const loadUserDetails = () => {
    // console.log("loading user details");
    HandyGolfAuth.isUserAuthenticated().then((is_authenticated) => {
      setIsAuthenticated(is_authenticated);
      // console.log(is_authenticated);
      if (is_authenticated) {
        HandyGolfAuth.getAuthenticatedUser().then((user_details) => {
          // console.log("HEYY", user_details);
          setUserDetails(user_details);
        });
      } else {
        setUserDetails({});
      }
    });
  };

  React.useEffect(() => {
    HandyGolfAuth.appCallback = () => {
      loadUserDetails();
    };
    loadUserDetails();
    if (analytics_id) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Home",
      });
    }
  }, []);

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  return (
    <GoogleOAuthProvider clientId="668398659016-osa091djv0ccu0ac06jspbg12jggvd4r.apps.googleusercontent.com">
      <BrowserRouter>
        <UserProvider>
          <ThemeProvider>
            <StateProvider>
              <UploadProvider>
                <ToastProvider>
                  <ScrollToTop />
                  <Layout />
                </ToastProvider>
              </UploadProvider>
            </StateProvider>
          </ThemeProvider>
        </UserProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
  // return (
  //     <div>
  //       {/*<Authenticator>*/}
  //       {/*  {({ signOut, user }) => (*/}
  //       {/*      <main>*/}
  //       {/*        <h1>Hello {user.username}</h1>*/}
  //       {/*        <button onClick={signOut}>Sign out</button>*/}
  //       {/*      </main>*/}
  //       {/*  )}*/}
  //       {/*</Authenticator>*/}
  //         <div style={{width: 600, marginLeft:'auto', marginRight:'auto'}}>
  //             <LessonMgmt />
  //         </div>
  //       {/*<MySQLQueryRunnerForm />*/}
  //     </div>
  // );
}

export default App;
