import React, { useState, useEffect } from "react";

export function FBCard({ name, photo, testimony, skill }) {
  return (
    <div className="fbcard">
      <div className="fbcard__photo">
        <img src={photo} />
      </div>
      <div className="fbcard__text">
        <p>{testimony}</p>
        <strong>{name}</strong>
      </div>
    </div>
  );
}
