import React, { useEffect, useState, useContext, useRef } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Card,
  Container,
  AppTopBar,
  BottomNav,
  Player,
  Button,
} from "../../components";
import HourMinuteSelector from "./HourMinuteSelector";
import ProgramCalendar from "./ProgramCalendar";
import {
  StateContext,
  StateDispatchContext,
  CheckProgramsCacheContext,
} from "../../context/AppContext";
// import { Button } from "@mui/material";
const initialWeek = [
  {
    day: 0,
    name: "Sun",
    active: false,
  },
  {
    day: 1,
    name: "Mon",
    active: false,
  },
  {
    day: 2,
    name: "Tue",
    active: false,
  },
  {
    day: 3,
    name: "Wed",
    active: false,
  },
  {
    day: 4,
    name: "Thu",
    active: false,
  },
  {
    day: 5,
    name: "Fri",
    active: false,
  },
  {
    day: 6,
    name: "Sat",
    active: false,
  },
];

export function ProgramDetails() {
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkProgramsCache = useContext(CheckProgramsCacheContext);
  const navigate = useNavigate();

  const [currentScreen, setCurrentScreen] = useState(2);
  const [weekdays, setWeekdays] = useState(initialWeek);

  //hour pick widget
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("AM");
  const [wantsNotis, setWantsNotis] = useState(true);
  const [wantsSchedule, setWantsSchedule] = useState(true);

  const [currentSession, setCurrentSession] = useState(0);

  const { trainer_program_id } = useParams();

  const videoRef = useRef(null);
  useEffect(() => {
    checkProgramsCache();
  }, [state.loading.programs_data, trainer_program_id]);

  const isLoading = state.loading.programs_data;
  //    ||
  //   console.log(state.loading);
  if (isLoading) {
    return <>loading.....</>;
  }
  let program = state.programs_data.programs.find(
    (program) => program.trainer_program_id === parseInt(trainer_program_id)
  );
  let sessions = program.trainer_sessions;
  let inProgram = state.programs_data.user.some(
    (program) => program.trainer_program_id === parseInt(trainer_program_id)
  );
  console.log("User is in program:", inProgram);
  //   console.log(state.programs_data.user);

  const handleWeekdaySelect = (dayNumber) => {
    setWeekdays((prevWeek) =>
      prevWeek.map((day) =>
        day.day === dayNumber ? { ...day, active: !day.active } : day
      )
    );
  };

  const weekdayConverter = () => {
    let dayNumArray = [];
    weekdays.forEach((el) => {
      if (el.active) {
        dayNumArray.push(el.day);
      }
    });
    // console.log(dayNumArray);
    return dayNumArray;
  };

  const timeConverter = () => {
    let hour =
      selectedPeriod === "AM"
        ? `${selectedHour.toString().padStart(2, "0")}:`
        : `${(selectedHour + 12).toString()}:`;
    // console.log(hour);
    let minute = `${selectedMinute.toString().padStart(2, "0")}:`;
    let second = "00";
    let fullTime = hour + minute + second;
    return fullTime;
  };
  const getDaysWithActivity = () => {
    let userProgram = state.programs_data.user.find(
      (program) => program.trainer_program_id === parseInt(trainer_program_id)
    );
    return userProgram.days_with_activity;
  };

  const typeToString = (type) => {
    // 1 - Drill Explanation
    // 2 - Drill Practice
    // 3 - Guided Training
    // 4 - Lesson
    // 5 - Mirror Mode
    let string = "";
    if (type === 1) {
      string = "Drill Explanation";
    }
    if (type === 2) {
      string = "Drill Practice";
    }
    if (type === 3) {
      string = "Guided Training";
    }
    if (type === 4) {
      string = "Lesson";
    }
    if (type === 5) {
      string = "Mirror Mode";
    }
    return string;
  };
  const typeToIconString = (type) => {
    let string = "";

    if (type === 1) {
      string = "/img/backswing-green.svg";
    }
    if (type === 2) {
      string = "/img/golf-swing-green.svg";
    }
    if (type === 3) {
      string = "/img/club-and-tee.svg";
    }
    if (type === 4) {
      string = "/img/green-play.svg";
    }
    if (type === 5) {
      string = "/img/green-mirror.svg";
    }
    return string;
  };
  const filterActivities = (listType) => {
    let userProgram = state.programs_data.user.find(
      (el) => el.trainer_program_id === parseInt(trainer_program_id)
    );
    let userSession = userProgram.trainer_sessions.find(
      (el) =>
        el.trainer_session_id === sessions[currentSession].trainer_session_id
    );

    let currentCompletedActivities = sessions[
      currentSession
    ].trainer_activities.filter((activity) =>
      userSession.completed_trainer_activity_ids.some(
        (stringId) => parseInt(stringId) === activity.trainer_activity_id
      )
    );
    let currentIncompleteActivities = sessions[
      currentSession
    ].trainer_activities.filter(
      (activity) =>
        !userSession.completed_trainer_activity_ids.some(
          (stringId) => parseInt(stringId) === activity.trainer_activity_id
        )
    );
    if (listType === "complete") {
      return currentCompletedActivities;
    }
    if (listType === "incomplete") {
      return currentIncompleteActivities;
    }
  };

  const userProgramData = () => {
    let userProgram = state.programs_data.user.find(
      (el) => el.trainer_program_id === parseInt(trainer_program_id)
    );
    return userProgram;
  };

  // console.log(sessions[currentSession]);
  // console.log(userSession);
  // console.log(currentCompletedActivities);
  // console.log(currentIncompleteActivities);
  // console.log(program);
  // console.log(notificationTime);
  return (
    <>
      {!inProgram ? (
        <>
          <AppTopBar
            breadcrumb
            pageTitle={program.name}
            // pageTitle=
            // trainer_video_id={trainer_video_id}
            // watchlistIds={watchlistIds}
            // favoriteIds={favoriteIds}
            // handleFavorite={handleFavorite}
            // handleWatchlist={handleWatchlist}
            path={"/trainer/programs"}
            // favorite
            // addTo
          />
          <div className="container">
            <div className="container__content">
              <div className="pad-box">
                <div style={{ position: "relative" }}>
                  <Player
                    videoRef={videoRef}
                    url={"/vid/adamscott.mp4"}
                    // url={
                    //   video.video_url !== ""
                    //     ? video.video_url
                    //     : "/vid/adamscott.mp4"
                    // }
                    // light={"/vid/adam"}
                    // saveFeedback={saveFeedback}
                    // current_lesson_unit={current_lesson_unit}
                    // loadLessonUnitData={loadLessonUnitData}
                    // lesson={lesson}
                    // feedback_display={feedback_display}
                    // setFeedbackDisplay={setFeedbackDisplay}
                    // user_lesson_data={user_lesson_data}
                    // setVideoTime={setVideoTime}
                    // feedback_rating={feedback_rating}
                    // feedback_text={feedback_text}
                    isTrainer
                    // practiceMode={practiceMode}
                    // openingPracticeMode={openingPracticeMode}
                    // setOpeningPracticeMode={setOpeningPracticeMode}
                    // userRepRange={userRepRange}
                  />
                </div>
                <div>
                  <div style={{ display: "flex", gap: "3rem" }}>
                    <div>
                      <p>Sessions</p>
                      <h3>{sessions.length}</h3>
                    </div>
                    <div>
                      <p>Recommended Frequency</p>
                      <h3>{program.recommended_frequency}</h3>
                    </div>
                    <div>
                      <p>Session Time</p>
                      <h3>{program.session_length_in_minutes}</h3>
                    </div>
                  </div>
                  <p>
                    {program.description !== ""
                      ? program.description
                      : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2rem",
                  }}
                >
                  <div>
                    {/* PLACEHOLDER */}
                    {/* <img src="/img/0evx.jpg" /> */}
                    <div className="program-schedule-maker">
                      <div className="program-schedule-maker__switch-container">
                        <label>Schedule Practice</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={wantsSchedule}
                            onChange={(e) => {
                              setWantsSchedule(e.target.checked);
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="program-schedule-maker__divider"></div>
                      <div className="program-schedule-maker__weekdays-container">
                        <div className="tiny-label">
                          Select days to practice
                        </div>
                        <div className="program-schedule-maker__weekdays">
                          {weekdays.map((day, idx) => {
                            return (
                              <button
                                key={idx}
                                className={day.active ? "active" : "inactive"}
                                onClick={() => {
                                  handleWeekdaySelect(day.day);
                                }}
                              >
                                {day.name}
                              </button>
                            );
                          })}
                        </div>
                      </div>

                      <div className="program-schedule-maker__time-selector">
                        <HourMinuteSelector
                          // notificationTime={notificationTime}
                          // setNotificationTime={setNotificationTime}
                          selectedHour={selectedHour}
                          setSelectedHour={setSelectedHour}
                          selectedMinute={selectedMinute}
                          setSelectedMinute={setSelectedMinute}
                          selectedPeriod={selectedPeriod}
                          setSelectedPeriod={setSelectedPeriod}
                        />
                      </div>
                      <div className="program-schedule-maker__checkbox-container">
                        <input
                          style={{
                            accentColor: "#3ab549",
                            width: "20px",
                            height: "20px",
                            // margin: 0,
                          }}
                          type="checkbox"
                          checked={wantsNotis}
                          onChange={(e) => setWantsNotis(e.target.checked)}
                        />
                        <label>Remind me about this program</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Button
                      primary
                      onClick={() => {
                        weekdayConverter();
                      }}
                    >
                      TestTime
                    </Button> */}
                    <Button
                      primary
                      onClick={() => {
                        HandyGolfAPI.sendRequest(
                          "trainer/program/joinProgram",
                          {
                            trainer_program_id: trainer_program_id,
                            has_schedule: wantsSchedule ? 1 : 0,
                            schedule_weekdays: weekdayConverter(),
                            schedule_reminder: wantsNotis ? 1 : 0,
                            schedule_reminder_time: timeConverter(),
                          }
                        ).then((data) => {
                          let key = "programs_data";
                          let newProgramState = state.programs_data;
                          let newUser = data.user_programs;
                          let newCache = data.new_cache_time;
                          let newProgramObject = {
                            ...newProgramState,
                            user: newUser,
                            new_cache_time: newCache,
                          };
                          localStorage.setItem(
                            key,
                            JSON.stringify(newProgramObject)
                          );
                          dispatch({
                            type: "FETCH_SUCCESS",
                            payload: { key, data: newProgramObject },
                          });
                        });
                      }}
                    >
                      Join Program
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container__side-content">
              {/* <div
            style={{ display: "flex", flexDirection: "column", gap: "3rem" }}
          >
             <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.2rem",
              }}>
             <strong>Related Programs</strong>
             {map other programs}
              </div>

          </div> */}
            </div>
          </div>
          <BottomNav />
        </>
      ) : (
        <>
          <AppTopBar
            breadcrumb
            pageTitle={program.name}
            // pageTitle=
            // trainer_video_id={trainer_video_id}
            // watchlistIds={watchlistIds}
            // favoriteIds={favoriteIds}
            // handleFavorite={handleFavorite}
            // handleWatchlist={handleWatchlist}
            path={"/trainer/programs"}
            // favorite
            // addTo
          />
          <div className="container">
            <div className="container__content">
              <div className="pad-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto 2rem auto",
                  }}
                >
                  <Button
                    primary
                    style={
                      currentScreen === 1
                        ? {
                            backgroundColor: "transparent",
                            borderRadius: "0",
                            borderBottom: "2px solid var(--primary)",
                          }
                        : { backgroundColor: "transparent", borderRadius: "0" }
                    }
                    onClick={() => {
                      setCurrentScreen(1);
                    }}
                  >
                    About
                  </Button>
                  <Button
                    primary
                    style={
                      currentScreen === 2
                        ? {
                            backgroundColor: "transparent",
                            borderRadius: "0",
                            borderBottom: "2px solid var(--primary)",
                          }
                        : { backgroundColor: "transparent", borderRadius: "0" }
                    }
                    onClick={() => {
                      setCurrentScreen(2);
                    }}
                  >
                    Sessions
                  </Button>
                  <Button
                    primary
                    style={
                      currentScreen === 3
                        ? {
                            backgroundColor: "transparent",
                            borderRadius: "0",
                            borderBottom: "2px solid var(--primary)",
                          }
                        : { backgroundColor: "transparent", borderRadius: "0" }
                    }
                    onClick={() => {
                      setCurrentScreen(3);
                    }}
                  >
                    Preferences
                  </Button>
                </div>
                {currentScreen === 1 && (
                  <>
                    <div style={{ position: "relative" }}>
                      <Player
                        videoRef={videoRef}
                        url={"/vid/adamscott.mp4"}
                        // url={
                        //   video.video_url !== ""
                        //     ? video.video_url
                        //     : "/vid/adamscott.mp4"
                        // }
                        // light={"/vid/adam"}
                        // saveFeedback={saveFeedback}
                        // current_lesson_unit={current_lesson_unit}
                        // loadLessonUnitData={loadLessonUnitData}
                        // lesson={lesson}
                        // feedback_display={feedback_display}
                        // setFeedbackDisplay={setFeedbackDisplay}
                        // user_lesson_data={user_lesson_data}
                        // setVideoTime={setVideoTime}
                        // feedback_rating={feedback_rating}
                        // feedback_text={feedback_text}
                        isTrainer
                        // practiceMode={practiceMode}
                        // openingPracticeMode={openingPracticeMode}
                        // setOpeningPracticeMode={setOpeningPracticeMode}
                        // userRepRange={userRepRange}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "2rem",
                      }}
                    >
                      <div style={{ width: "550px" }}>
                        <div style={{ display: "flex", gap: "3rem" }}>
                          <div>
                            <p>Sessions</p>
                            <h3>{sessions.length}</h3>
                          </div>
                          <div>
                            <p>Recommended Frequency</p>
                            <h3>{program.recommended_frequency}</h3>
                          </div>
                          <div>
                            <p>Session Time</p>
                            <h3>{program.session_length_in_minutes}</h3>
                          </div>
                        </div>
                        <p>
                          {program.description !== ""
                            ? program.description
                            : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."}
                        </p>
                      </div>

                      <ProgramCalendar
                        getDaysWithActivity={getDaysWithActivity}
                      />
                    </div>
                  </>
                )}
                {currentScreen === 2 && (
                  <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="session-selector">
                        <div className="session-selector__nums">
                          {sessions.map((session, idx) => {
                            return (
                              <div
                                key={idx}
                                className={
                                  currentSession === idx
                                    ? "session-selector__num session-selector__num--selected"
                                    : "session-selector__num"
                                }
                                onClick={() => {
                                  setCurrentSession(idx);
                                }}
                              >
                                {session.session_number}
                              </div>
                            );
                          })}
                        </div>
                        {/* 1 - Drill Explanation
                                2 - Drill Practice 
                                3 - Guided Training 
                                4 - Lesson 
                                5 - Mirror Mode */}
                        {filterActivities("incomplete").length > 0 && (
                          <div className="session-selector__activities">
                            <div
                              style={{
                                fontSize: "14px",
                                opacity: ".8",
                                marginTop: "2rem",
                              }}
                            >
                              To do
                            </div>
                            {filterActivities("incomplete").map(
                              (activity, idx) => {
                                // let iconString = typeToIconString(
                                //   activity.type
                                // );
                                // console.log(iconString);
                                return (
                                  <div
                                    key={idx}
                                    className="session-selector__activity"
                                    onClick={() => {
                                      navigate(
                                        "/trainer/activity/" +
                                          activity.trainer_activity_id
                                      );
                                    }}
                                  >
                                    <div className="session-selector__activity__left">
                                      <span>
                                        <img
                                          src={typeToIconString(activity.type)}
                                        />
                                      </span>
                                    </div>
                                    <div className="session-selector__activity__middle">
                                      <div className="session-selector__activity__description">
                                        {typeToString(activity.type)} | 6 min |
                                        type: {activity.type}
                                      </div>
                                      <div className="session-selector__activity__title">
                                        {activity.name
                                          ? activity.name
                                          : "No Activity Name Here"}
                                      </div>
                                    </div>
                                    <div className="session-selector__activity__right">
                                      <span>
                                        <i className="material-icons">
                                          chevron_right
                                        </i>
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}

                        {filterActivities("complete").length > 0 && (
                          <div className="session-selector__activities">
                            <div
                              style={{
                                fontSize: "14px",
                                opacity: ".8",
                                marginTop: "2rem",
                              }}
                            >
                              Completed
                            </div>
                            {filterActivities("complete").map(
                              (activity, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className="session-selector__activity"
                                    onClick={() => {
                                      navigate(
                                        "/trainer/activity/" +
                                          activity.trainer_activity_id
                                      );
                                    }}
                                  >
                                    <div className="session-selector__activity__left">
                                      <span>
                                        <img
                                          src={typeToIconString(activity.type)}
                                        />
                                      </span>
                                    </div>
                                    <div className="session-selector__activity__middle">
                                      <div className="session-selector__activity__description">
                                        {typeToString(activity.type)} | 6 min |
                                        type: {activity.type}
                                      </div>
                                      <div className="session-selector__activity__title">
                                        {activity.name
                                          ? activity.name
                                          : "No Activity Name Here"}
                                      </div>
                                    </div>
                                    <div className="session-selector__activity__right">
                                      <span>
                                        <i className="material-icons">
                                          chevron_right
                                        </i>
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                      {/* <div>
                      {sessions.map((session, idx) => {
                        return (
                          <div key={idx}>
                            <h1>SESSION {session.session_number}</h1>
                            {session.trainer_activities.map((activity, idx) => {
                              return (
                                <div
                                  key={idx}
                                  style={{ backgroundColor: "#0B253C" }}
                                >
                                  <h3>{activity.name}</h3>
                                  <h4>
                                    {activity.type === 1
                                      ? "Drill"
                                      : "Guided Training"}
                                  </h4>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div> */}
                    </div>
                  </>
                )}
                {currentScreen === 3 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {/* PLACEHOLDER */}
                        {/* <img src="/img/0evx.jpg" /> */}
                        <div className="program-schedule-maker">
                          <div className="program-schedule-maker__switch-container">
                            <label>Schedule Practice</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={wantsSchedule}
                                onChange={(e) => {
                                  setWantsSchedule(e.target.checked);
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="program-schedule-maker__divider"></div>
                          <div className="program-schedule-maker__weekdays-container">
                            <div className="tiny-label">
                              Select days to practice
                            </div>
                            <div className="program-schedule-maker__weekdays">
                              {weekdays.map((day, idx) => {
                                return (
                                  <button
                                    key={idx}
                                    className={
                                      day.active ? "active" : "inactive"
                                    }
                                    onClick={() => {
                                      handleWeekdaySelect(day.day);
                                    }}
                                  >
                                    {day.name}
                                  </button>
                                );
                              })}
                            </div>
                          </div>

                          <div className="program-schedule-maker__time-selector">
                            <HourMinuteSelector
                              // userProgramData={userProgramData()}
                              // notificationTime={notificationTime}
                              // setNotificationTime={setNotificationTime}
                              selectedHour={selectedHour}
                              setSelectedHour={setSelectedHour}
                              selectedMinute={selectedMinute}
                              setSelectedMinute={setSelectedMinute}
                              selectedPeriod={selectedPeriod}
                              setSelectedPeriod={setSelectedPeriod}
                            />
                          </div>
                          <div className="program-schedule-maker__checkbox-container">
                            <input
                              style={{
                                accentColor: "#3ab549",
                                width: "20px",
                                height: "20px",
                                // margin: 0,
                              }}
                              type="checkbox"
                              checked={wantsNotis}
                              onChange={(e) => setWantsNotis(e.target.checked)}
                            />
                            <label>Remind me about this program</label>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "360px", margin: ".5rem 0" }}>
                        <Button
                          primary
                          fluid
                          onClick={() => {
                            HandyGolfAPI.sendRequest(
                              "trainer/program/editProgramSchedule",
                              {
                                trainer_program_id: trainer_program_id,
                                has_schedule: wantsSchedule ? 1 : 0,
                                schedule_weekdays: weekdayConverter(),
                                schedule_reminder: wantsNotis ? 1 : 0,
                                schedule_reminder_time: timeConverter(),
                              }
                            ).then((data) => {
                              let key = "programs_data";
                              let newProgramState = state.programs_data;
                              let newUser = data.user_programs;
                              let newCache = data.new_cache_time;
                              let newProgramObject = {
                                ...newProgramState,
                                user: newUser,
                                new_cache_time: newCache,
                              };
                              localStorage.setItem(
                                key,
                                JSON.stringify(newProgramObject)
                              );
                              dispatch({
                                type: "FETCH_SUCCESS",
                                payload: { key, data: newProgramObject },
                              });
                            });
                          }}
                        >
                          Edit Schedule
                        </Button>
                      </div>
                      <div style={{ width: "360px", margin: ".5rem 0" }}>
                        <Button
                          primary
                          fluid
                          onClick={() => {
                            HandyGolfAPI.sendRequest(
                              "trainer/program/restartProgram",
                              {
                                trainer_program_id: trainer_program_id,
                              }
                            ).then((data) => {
                              let key = "programs_data";
                              let newProgramState = state.programs_data;
                              let newUser = data.user_programs;
                              let newCache = data.new_cache_time;
                              let newProgramObject = {
                                ...newProgramState,
                                user: newUser,
                                new_cache_time: newCache,
                              };
                              localStorage.setItem(
                                key,
                                JSON.stringify(newProgramObject)
                              );
                              dispatch({
                                type: "FETCH_SUCCESS",
                                payload: { key, data: newProgramObject },
                              });
                            });
                          }}
                        >
                          Restart Program
                        </Button>
                      </div>
                      <div style={{ width: "360px", margin: ".5rem 0" }}>
                        <Button
                          secondary
                          fluid
                          onClick={() => {
                            HandyGolfAPI.sendRequest(
                              "trainer/program/leaveProgram",
                              {
                                trainer_program_id: trainer_program_id,
                              }
                            ).then((data) => {
                              let key = "programs_data";
                              let newProgramState = state.programs_data;
                              let newUser = data.user_programs;
                              let newCache = data.new_cache_time;
                              let newProgramObject = {
                                ...newProgramState,
                                user: newUser,
                                new_cache_time: newCache,
                              };
                              localStorage.setItem(
                                key,
                                JSON.stringify(newProgramObject)
                              );
                              dispatch({
                                type: "FETCH_SUCCESS",
                                payload: { key, data: newProgramObject },
                              });
                            });
                          }}
                        >
                          Leave Program
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="container__side-content"></div>
          </div>
          <BottomNav />
        </>
      )}
    </>
  );
}
