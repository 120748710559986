const React = require("react");
// const HandyGolfAPI = require("./HandyGolfAPI");

class HandyGolf {
  constructor() {
    throw new Error("The HandyGolf class should not be instantiated.");
  }

  static config_profiles = {
    localdev: {
      is_dev_env: true,
      landing_page: "main",
      api_hostname: "http://localhost:3001",
      analytics_id: "",
      s3_image_folder: "localdev",
      s3_image_url:
        "https://handygolfwebsite-images.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "handygolfwebsite-images",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "handy-golf-website-course-videos-source71e471f1-njgpzuslaldr",
      s3_transcoded_video_bucket_name: "handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx",
      s3_bucket_region: "us-east-2",
    },
    mattdev: {
      is_dev_env: true,
      landing_page: "main",
      api_hostname: "http://localhost:3001",
      analytics_id: "",
      s3_image_folder: "mattdev",
      s3_image_url:
        "https://handygolfwebsite-images.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "handygolfwebsite-images",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "handy-golf-website-course-videos-source71e471f1-njgpzuslaldr",
      s3_transcoded_video_bucket_name: "handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx",
      s3_bucket_region: "us-east-2",
    },
    natedev: {
      is_dev_env: true,
      landing_page: "main",
      api_hostname: "http://localhost:3001",
      analytics_id: "",
      s3_image_folder: "natedev",
      s3_image_url:
        "https://handygolfwebsite-images.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "handygolfwebsite-images",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "handy-golf-website-course-videos-source71e471f1-njgpzuslaldr",
      s3_transcoded_video_bucket_name: "handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx",
      s3_bucket_region: "us-east-2",
    },
    awsdev: {
      is_dev_env: true,
      landing_page: "main",
      api_hostname:
        "https://0gpdzoidl4.execute-api.us-east-2.amazonaws.com/dev",
      analytics_id: "",
      s3_image_folder: "dev",
      s3_image_url:
        "https://handygolfwebsite-images.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "handygolfwebsite-images",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "handy-golf-website-course-videos-source71e471f1-njgpzuslaldr",
      s3_transcoded_video_bucket_name: "handy-golf-website-course-vide-destination920a3c57-l2vaejzvknyx",
      s3_bucket_region: "us-east-2",
    },
    beta: {
      is_dev_env: false,
      landing_page: "comingsoon",
      api_hostname:
        "https://dbh4omceti.execute-api.us-east-2.amazonaws.com/beta",
      analytics_id: "",
      s3_image_folder: "beta",
      s3_image_url:
        "https://hgma-beta.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "hgma-beta",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "hgtv-beta-source71e471f1-9z2gmvmmzcxc",
      s3_transcoded_video_bucket_name: "hgtv-beta-destination920a3c57-afmv8umjd6ob",
      s3_bucket_region: "us-east-2",
    },
    staging: {
      is_dev_env: false,
      landing_page: "comingsoon",
      api_hostname:
        "https://viqa4non9b.execute-api.us-east-2.amazonaws.com/staging",
      analytics_id: "",
      s3_image_folder: "staging",
      s3_image_url:
        "https://hgma-prod.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "hgma-prod",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "hgtv-prod-source71e471f1-qsrshrerhwn5",
      s3_transcoded_video_bucket_name: "hgtv-prod-destination920a3c57-wrgdgw1offzz",
      s3_bucket_region: "us-east-2",
    },
    prod: {
      is_dev_env: false,
      landing_page: "comingsoon",
      api_hostname:
        "https://dbfzt2njsd.execute-api.us-east-2.amazonaws.com/prod",
      analytics_id: "G-MRXN3YB4HW",
      s3_image_folder: "prod",
      s3_image_url:
        "https://hgma-prod.s3.us-east-2.amazonaws.com/",
      s3_image_bucket_name: "hgma-prod",
      s3_image_bucket_region: "us-east-2",
      s3_source_video_bucket_name: "hgtv-prod-source71e471f1-qsrshrerhwn5",
      s3_transcoded_video_bucket_name: "hgtv-prod-destination920a3c57-wrgdgw1offzz",
      s3_bucket_region: "us-east-2",
    },
  };

  static getValue(key_name) {
    return HandyGolf.config_profiles[process.env.REACT_APP_HG_PROFILE][
      key_name
    ];
  }
}

if (
  !process.env.REACT_APP_HG_PROFILE ||
  !HandyGolf.config_profiles[process.env.REACT_APP_HG_PROFILE]
) {
  throw new Error("This app has not been properly configured.");
}

module.exports = HandyGolf;
