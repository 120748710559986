import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AdminTable, Uploader, setFormDefaults, AdminContainer } from "./index";
import { Button, AdminNav } from "../components";
import { useUser } from "../providers";

const initialCoachForm = {
  name: "",
  title: "",
  bio: "",
  facebook_url: "",
  instagram_url: "",
  youtube_url: "",
  website_url: "",
};
export function AdminCoaches() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    // child,
    // grand_child,
    id_param: parent_id,
    // id_param2: child_id,
    // id_param3: grandchild_id,
  } = useParams();

  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //coaches
  const [coachList, setCoachList] = useState();
  const [selectedCoach, setSelectedCoach] = useState();
  const [editingCoach, setEditingCoach] = useState();
  //forms
  const [coachForm, setCoachForm] = useState(initialCoachForm);
  const updateURL = (newPath) => {
    const currentPath = location.pathname;
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath, { replace: true });
    }
  };

  const getCoaches = () => {
    HandyGolfAPI.sendRequest("coach/list")
      .then((data) => {
        // console.log(data);
        setCoachList(data);
        //setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    if (!selectedCoach) {
      getCoaches();
    }
  }, [selectedCoach]);

  useEffect(() => {
    if (coachList) {
      if (parent_id) {
        let currentParent = coachList.find(
          (el) => el.coach_id === parseInt(parent_id)
        );
        console.log(currentParent);
        if (currentParent) {
          setSelectedCoach(currentParent);
          setEditingCoach(true);
        }
      }
    }
  }, [coachList]);

  const handleCoachFormChange = (e) => {
    setCoachForm({
      ...coachForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleCoachEditSubmit = (e) => {
    e.preventDefault();
    // console.log(coachForm);
    HandyGolfAPI.sendRequest("coach/update", {
      coach_id: selectedCoach.coach_id,
      coach: coachForm,
    })
      .then((data) => {
        console.log(data);
        setSelectedCoach(data.data);
        setEditing(false);
        setCoachForm(initialCoachForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCoachCreateSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("coach/create", {
      coach: coachForm,
    })
      .then((data) => {
        let newCoach = data.data;
        let coaches = coachList;
        coaches.push(newCoach);
        setCoachList(coaches);
        setCreating(false);
        setCoachForm(initialCoachForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelectCoach = (item) => {
    updateURL(`coach/${item.coach_id}`);
    setSelectedCoach(item);
  };
  const removeLastTwoSegments = () => {
    const currentPath = location.pathname;
    const segments = currentPath.split("/");

    if (segments.length > 3) {
      const newPath = segments.slice(0, -2).join("/");

      navigate(newPath, { replace: true });
    }
  };
  return (
    <>
      <AdminContainer>
        {coachList && (
          <div className="admin-table-half">
            <div className="admin-table-half__title">
              HandyGolf Coaches
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm();
                  setCreating(true);
                }}
              >
                <i className="material-icons">add</i>
                <strong>New Coach</strong>
              </div>
            </div>
            <AdminTable
              tableData={coachList}
              headers={["profile_picture_url", "name"]}
              // setSelectedItem={setSelectedCoach}
              setSelectedItem={handleSelectCoach}
              setEditingItem={setEditingCoach}
            />
          </div>
        )}
        <div
          className={
            !creating
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                setCreating(false);
                setCoachForm(initialCoachForm);
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h2>Create New Coach</h2>
              <form className="admin-form" onSubmit={handleCoachCreateSubmit}>
                <div>
                  <label>Coach Name</label>
                  <input
                    type="text"
                    name="name"
                    maxLength={50}
                    placeholder="name for new coach"
                    value={coachForm.name}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.name.length > 0 ? (
                      <div>{coachForm.name.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Coach Title</label>
                  <input
                    type="text"
                    name="title"
                    maxLength={50}
                    placeholder="title for new coach"
                    value={coachForm.title}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.title.length > 0 ? (
                      <div>{coachForm.title.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Coach Facebook</label>
                  <input
                    type="text"
                    name="facebook_url"
                    maxLength={400}
                    placeholder="paste facebook url"
                    value={coachForm.facebook_url}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.facebook_url.length > 0 ? (
                      <div>{coachForm.facebook_url.length}/400</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Coach Instagram</label>
                  <input
                    type="text"
                    name="instagram_url"
                    maxLength={400}
                    placeholder="paste instagram url"
                    value={coachForm.instagram_url}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.instagram_url.length > 0 ? (
                      <div>{coachForm.instagram_url.length}/400</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Coach YouTube</label>
                  <input
                    type="text"
                    name="youtube_url"
                    maxLength={400}
                    placeholder="paste youtube url"
                    value={coachForm.youtube_url}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.youtube_url.length > 0 ? (
                      <div>{coachForm.youtube_url.length}/400</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Coach Website</label>
                  <input
                    type="text"
                    name="website_url"
                    maxLength={400}
                    placeholder="paste website url"
                    value={coachForm.website_url}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.website_url.length > 0 ? (
                      <div>{coachForm.website_url.length}/400</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Coach Bio</label>
                  <textarea
                    rows="4"
                    name="bio"
                    maxLength={1000}
                    placeholder="name for new coach"
                    value={coachForm.bio}
                    onChange={handleCoachFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {coachForm.bio.length > 0 ? (
                      <div>{coachForm.bio.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <strong>
                    You can add profile picture on the coach edit screen after
                    the coach has been created
                  </strong>
                </div>
                <Button fluid primary>
                  Submit Coach
                </Button>
              </form>
            </div>
          </div>
        </div>
        {/* COACH PAGE */}
        <div
          className={
            !editingCoach
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingCoach(false);
                setSelectedCoach();
              }}
            >
              Close
            </Button>
          </div>
          {selectedCoach && (
            <>
              <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                <div className="admin-info-half" style={{ maxWidth: "460px" }}>
                  <div className="admin-info-half__title">
                    {selectedCoach.name}
                    {/* <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  setFormDefaults(
                                    selectedCoach,
                                    coachForm,
                                    setCoachForm
                                  );
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div> */}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Profile Picture
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploading(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    {!uploading && (
                      <img
                        src={
                          selectedCoach.profile_picture_url !== ""
                            ? selectedCoach.profile_picture_url
                            : "/img/no-profile-pic.png"
                        }
                      />
                    )}
                  </div>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__section-title">
                      Details
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setFormDefaults(
                            selectedCoach,
                            coachForm,
                            setCoachForm
                          );
                          setEditing(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Name:</label>
                      <strong>{selectedCoach.name}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Title:</label>
                      <strong>{selectedCoach.title}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Facebook:</label>
                      <strong>{selectedCoach.facebook_url}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Instagram:</label>
                      <strong>{selectedCoach.instagram_url}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>YouTube:</label>
                      <strong>{selectedCoach.youtube_url}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Website:</label>
                      <strong>{selectedCoach.website_url}</strong>
                    </div>
                    <div className="admin-info-half__description">
                      <label>Bio:</label>
                      <div>{selectedCoach.bio}</div>
                    </div>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "40px",
                    }}
                  >
                    <div
                      className="admin-edit-item-button"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you SURE you want to PERMANENTLY DELETE this Coach?"
                          )
                        ) {
                          HandyGolfAPI.sendRequest("coach/delete", {
                            coach_id: selectedCoach.coach_id,
                          }).then((data) => {
                            console.log(data);
                            setEditingCoach(false);
                            setSelectedCoach();
                          });
                        }
                      }}
                    >
                      <i className="material-icons">delete</i>
                      <strong>Delete</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  !editing
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEditing(false);
                      setCoachForm(initialCoachForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      Edit coach: <br></br>'{selectedCoach.name}'
                    </h2>
                    <form
                      className="admin-form"
                      onSubmit={handleCoachEditSubmit}
                    >
                      <div>
                        <label>Change Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={50}
                          placeholder={selectedCoach.name}
                          value={coachForm.name}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.name.length > 0 ? (
                            <div>{coachForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Change Title</label>
                        <input
                          type="text"
                          name="title"
                          maxLength={50}
                          placeholder={selectedCoach.title}
                          value={coachForm.title}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.title.length > 0 ? (
                            <div>{coachForm.title.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Change Facebook</label>
                        <input
                          type="text"
                          name="facebook_url"
                          maxLength={400}
                          placeholder={selectedCoach.facebook_url}
                          value={coachForm.facebook_url}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.facebook_url.length > 0 ? (
                            <div>{coachForm.facebook_url.length}/400</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Change Instagram</label>
                        <input
                          type="text"
                          name="instagram_url"
                          maxLength={400}
                          placeholder={selectedCoach.instagram_url}
                          value={coachForm.instagram_url}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.instagram_url.length > 0 ? (
                            <div>{coachForm.instagram_url.length}/400</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Change YouTube</label>
                        <input
                          type="text"
                          name="youtube_url"
                          maxLength={400}
                          placeholder={selectedCoach.youtube_url}
                          value={coachForm.youtube_url}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.youtube_url.length > 0 ? (
                            <div>{coachForm.youtube_url.length}/400</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Change Website</label>
                        <input
                          type="text"
                          name="website_url"
                          maxLength={400}
                          placeholder={selectedCoach.website_url}
                          value={coachForm.website_url}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.website_url.length > 0 ? (
                            <div>{coachForm.website_url.length}/400</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label>Change Bio</label>
                        <textarea
                          rows="4"
                          name="bio"
                          maxLength={1000}
                          placeholder={selectedCoach.bio}
                          value={coachForm.bio}
                          onChange={handleCoachFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {coachForm.bio.length > 0 ? (
                            <div>{coachForm.bio.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <Button fluid primary>
                        Submit Changes
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  !uploading
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploading(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploading && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {selectedCoach.profile_picture_url === "" ? (
                          <>
                            <h2>Add Profile Picture</h2>

                            <Uploader
                              folder={"coaches/"}
                              fileName={`coaches/coach_${selectedCoach.coach_id}`}
                              background={"/img/no-profile-pic.png"}
                              setUploading={setUploading}
                              setNew={setSelectedCoach}
                              id={selectedCoach.coach_id}
                              type={"coach"}
                            />
                          </>
                        ) : (
                          <>
                            <h2>Change Profile Picture</h2>

                            <Uploader
                              folder={"coaches/"}
                              fileName={`coaches/coach_${selectedCoach.coach_id}`}
                              background={selectedCoach.profile_picture_url}
                              setUploading={setUploading}
                              setNew={setSelectedCoach}
                              id={selectedCoach.coach_id}
                              type={"coach"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </AdminContainer>
    </>
  );
}
