import React, { useEffect, useState } from "react";

import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

export function MenuOptions({ setType, type }) {
  // this is a sep comonent just in case design wants to get fancy,
  //for now ill just make it two buttons
  return (
    <div className="menu_options">
      {type === 1 ? (
        <>
          <Button
            variant="contained"
            // className="blue"
            disabled
            onClick={() => {
              setType(1);
            }}
          >
            Drills
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button
            variant="contained"
            className="blue"
            onClick={() => {
              setType(2);
            }}
          >
            Training Sessions
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            className="blue"
            onClick={() => {
              setType(1);
            }}
          >
            Drills
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button
            variant="contained"
            // className="blue"
            disabled
            onClick={() => {
              setType(2);
            }}
          >
            Training Sessions
          </Button>
        </>
      )}
    </div>
  );
}
