import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";
// MUI
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";
function translateType(type) {
  switch (type) {
    case 1:
      return "Yes";
    case 0:
      return "No";
    default:
      return "Unknown";
  }
}

export function CategoryView({ trainer_category_id }) {
  const [loaded, setLoaded] = useState(false);
  const [category_details, setCategoryDetails] = useState([]);
  useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("trainer/getCategoryDetails", {
      trainer_category_id: trainer_category_id,
    }).then((data) => {
      if (mounted) {
        setCategoryDetails(data);
      }
      setLoaded(true);
    });
    return () => {
      mounted = false;
    };
  }, [trainer_category_id]);
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Category Management
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/swing_trainer/category_mgmt/">
          <Button>Category List</Button>
        </Link>
        <Button disabled>Category Details</Button>
      </Breadcrumbs>
      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Category Details" />
        {!loaded && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {loaded && (
          <>
            <CardContent>
              <strong>Name:</strong> {category_details.name}
              <br />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Link
                to={
                  "/admin/swing_trainer/category_mgmt/edit/" +
                  category_details.trainer_category_id
                }
              >
                <Button variant="contained" className="blue">
                  Edit Category
                </Button>
              </Link>
              <br />
            </CardActions>
          </>
        )}
      </Card>
    </div>
  );
}
