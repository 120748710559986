import React, { useState } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  SkipNext,
  VolumeUp,
  VolumeOff,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import Popover from "@mui/material/Popover";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { Button } from "../shared";

export const PracticeControls = ({
  controlRef,
  onPlayPause,
  playing,
  time,
  muted,
  volume,
  onMute,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  onSeek,
  played,
  currentTime,
  duration,
  toggleFullScreen,
  popoverOpen,
  togglePopover,
  toggleMirrorVideo,
  toggleMirrorCamera,
  mirror,
  loadLessonUnitData,
  current_lesson_unit,
  lesson,
  user_lesson_data,
  isTrainer,
  setCompareMode,
  repCount,
  setCount,
  video_playing,
  setTimer,
  setRange,
  repRange,
  compareMode,
  onPracticeSettingsChange,
  playbackRate,
  practiceEnded,
  setPracticeEnded,
  delayTimerOpen,
  endSetDelay,
  setDelayTimerOpen,
  setVideoPlaying,
}) => {
  let tempRepArray = [];
  for (let i = 0; i < repRange; i++) {
    tempRepArray.push(i);
  }

  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };
  if (delayTimerOpen && !video_playing) {
    let timeRemaining = endSetDelay;
    var timer = document.getElementById("practice-mode-controls__countdown");

    let timerId = setInterval(countddown, 1000);

    function countddown() {
      if (timeRemaining === -1) {
        clearTimeout(timerId);
        // doSomething();
        // if (!video_playing) {
        //   onPlayPause();this doesnt work because both mobile and full controls technically run at once
        // }
        setVideoPlaying(true);
        setDelayTimerOpen(false);
        console.log("uhoh");
      } else {
        console.log(timeRemaining);
        if (timeRemaining === 0) {
          timer.innerHTML = "GO";
          // setVideoPlaying(true);
          timeRemaining -= 1;
        } else {
          timer.innerHTML = timeRemaining;
          // + ' until set/ rep';
          timeRemaining -= 1;
        }
      }
    }
  }

  // const countdown = () => {
  //   if(timeRemaining <= 0){
  //     clearInterval(timerId)
  //     return "GO!"
  //   } else if(timeRemaining <= endSetDelay){
  //     let tempTimeRemaining = timeRemaining;
  //     timeRemaining -= 1;
  //     return tempTimeRemaining;
  //   }
  // }

  return (
    <>
      <div
        ref={controlRef}
        //   onMouseLeave={setPopoverOpen(false)}
        // style={{ objectFit: "contain" }}
      >
        {/* <div></div> */}
        <div
          style={{
            position: "absolute",
            zIndex: "-1",
            height: "100%",
            width: "100%",
            left: "0",
            bottom: "0",
          }}
          onClick={onPlayPause}
        ></div>
        {isTrainer && (
          <div
            className="video-controls__compare-button"
            style={{ position: "absolute", zIndex: "11" }}
          >
            <Button
              primary
              onClick={() => {
                setCompareMode((prev) => !prev);
              }}
            >
              Compare
            </Button>
            <Button
              primary
              // iconLeading="compare"
              onClick={() => {
                toggleMirrorVideo();
              }}
            >
              Flip Drill
            </Button>
            <Button
              primary
              // iconLeading="compare"
              onClick={() => {
                toggleMirrorCamera();
              }}
            >
              Flip Camera
            </Button>
            <Button
              primary
              onClick={() => {
                toggleFullScreen();
              }}
            >
              Fullscreen
            </Button>
          </div>
        )}
        <div
          className={
            popoverOpen
              ? "video-controls__popover"
              : "video-controls__popover video-controls__popover--closed"
          }
        >
          <div className="video-controls__popover__section video-controls__popover__section">
            <>Mark as Watched</>
            <i
              className="material-icons"
              style={{ color: "#010D15", opacity: ".2" }}
            >
              check
            </i>
          </div>
        </div>

        <div className="practice-mode-controls">
          {/* <div className="practice-mode-controls__section">
           
          </div> */}
          <div style={{ position: "relative", zIndex: "10", maxWidth: "30%" }}>
            <div>
              <select
                name="playbackRate"
                onChange={(e) => {
                  onPracticeSettingsChange(e);
                }}
              >
                <option value="none" selected disabled hidden>
                  Playback Rate
                </option>
                <option value={0.25}>0.25x</option>
                <option value={0.5}>0.5x</option>
                <option value={0.75}>0.75x</option>
                <option value={1}>1.0x</option>
                <option value={1.25}>1.25x</option>
                <option value={1.5}>1.50x</option>
              </select>
            </div>
            <div>
              <select
                name="repRange"
                onChange={(e) => {
                  onPracticeSettingsChange(e);
                }}
              >
                <option value="none" selected disabled hidden>
                  Number of Reps
                </option>
                <option value={1}>1 reps in a set</option>
                <option value={2}>2 reps in a set</option>
                <option value={3}>3 reps in a set</option>
                <option value={4}>4 reps in a set</option>
                <option value={5}>5 reps in a set{" (default)"}</option>
                <option value={6}>6 reps in a set</option>
                <option value={7}>7 reps in a set</option>
                <option value={8}>8 reps in a set</option>
                <option value={9}>9 reps in a set</option>
                <option value={10}>10 reps in a set</option>
              </select>
            </div>
            <div>
              <select
                name="setRange"
                onChange={(e) => {
                  onPracticeSettingsChange(e);
                }}
              >
                <option value="none" selected disabled hidden>
                  Number of Sets
                </option>
                <option value={1}>1 sets</option>
                <option value={2}>2 sets</option>
                <option value={3}>3 sets</option>
                <option value={4}>4 sets</option>
                <option value={5}>5 sets{" (default)"}</option>
                <option value={6}>6 sets</option>
                <option value={7}>7 sets</option>
                <option value={8}>8 sets</option>
                <option value={9}>9 sets</option>
                <option value={10}>10 sets</option>
              </select>
            </div>
            <div>
              <select
                name="endRepDelay"
                onChange={(e) => {
                  onPracticeSettingsChange(e);
                }}
              >
                <option value="none" selected disabled hidden>
                  Rep Delay
                </option>
                <option value={0}>0s{" (default)"} </option>
                <option value={1}>1s second rep delay</option>
                <option value={2}>2s rep delay</option>
                <option value={3}>3s rep delay</option>
                <option value={4}>4s rep delay</option>
                <option value={5}>5s rep delay</option>
                <option value={6}>6s rep delay</option>
                <option value={7}>7s rep delay</option>
                <option value={8}>8s rep delay</option>
                <option value={9}>9s rep delay</option>
                <option value={10}>10s rep delay</option>
              </select>
            </div>
            <div>
              <select
                name="endSetDelay"
                onChange={(e) => {
                  onPracticeSettingsChange(e);
                }}
              >
                <option value="none" selected disabled hidden>
                  Set Delay
                </option>
                <option value={0}>0s set delay</option>
                <option value={1}>1s set delay</option>
                <option value={2}>2s set delay</option>
                <option value={3}>3s set delay{" (default)"}</option>
                <option value={4}>4s set delay</option>
                <option value={5}>5s set delay</option>
                <option value={6}>6s set delay</option>
                <option value={7}>7s set delay</option>
                <option value={8}>8s set delay</option>
                <option value={9}>9s set delay</option>
                <option value={10}>10s set delay</option>
              </select>
            </div>
            <div>
              <select
                name="cameraPosition"
                onChange={(e) => {
                  onPracticeSettingsChange(e);
                }}
              >
                <option value="none" selected disabled hidden>
                  Camera Position
                </option>
                <option value={"alternating"}>Alternating</option>
                <option value="face-on">Face on</option>
                <option value="down-the-line">Down the line</option>
              </select>
            </div>
          </div>

          {!video_playing && (
            <div className="practice-mode-controls__middle-section">
              {repCount === 0 &&
                setCount === 1 &&
                setTimer === 0 &&
                !practiceEnded && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      // width: "60px",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      onPlayPause();
                    }}
                  >
                    <img src="/img/play-arrow-1.svg" />
                    <strong>Start Practice</strong>
                  </div>
                )}
              {repCount === 0 && setTimer === 0 && setCount !== 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "60px",
                    textAlign: "center",
                  }}
                  //maybe have a pause after every set option??
                  // onClick={() => {
                  //   onPlayPause();
                  // }}
                >
                  {/* <img src="/img/play-arrow-1.svg" />
                  <strong>Continue Practice</strong> */}
                  {/* {delayTimerOpen && (
                    <div
                      className="practice-mode-controls__countdown"
                      id="practice-mode-controls__countdown"
                    ></div>
                  )} */}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: "60px",
                  textAlign: "center",
                }}
                //maybe have a pause after every set option??
                // onClick={() => {
                //   onPlayPause();
                // }}
              >
                <div
                  className="practice-mode-controls__countdown"
                  id="practice-mode-controls__countdown"
                ></div>
              </div>
              {practiceEnded && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "60px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    repCount = 0;
                    setCount = 0;
                    setPracticeEnded(false);
                  }}
                >
                  <i className="material-icons" style={{ fontSize: "60px" }}>
                    replay
                  </i>
                  {/* <strong>Start Practice</strong> */}
                </div>
              )}
            </div>
          )}

          <div className="practice-mode-controls__bottom-section">
            <div className="practice-mode-controls__bottom-controls">
              <div className="practice-mode-controls__bottom-controls__section practice-mode-controls__bottom-controls__section--left">
                <div className="practice-mode-controls__rep-counter">
                  <div>{repCount}</div>
                </div>
              </div>
              <div className="practice-mode-controls__bottom-controls__section practice-mode-controls__bottom-controls__section--middle">
                <div className="practice-mode-controls__bottom-center-top">
                  <div>
                    Set {setCount} of {setRange}
                  </div>
                  <div className="practice-mode-controls__bottom-center-top__right">
                    <div
                    // onClick={() => {
                    //   togglePopover();
                    // }}
                    >
                      {/* {playbackRate}x speed */}
                    </div>
                    <div>{convertSecondsToTimeText(setTimer)}</div>
                  </div>
                </div>
                <div className="practice-mode-controls__set-tracker">
                  {tempRepArray.map((rep, idx) => {
                    // console.log(set, idx);
                    return (
                      <div
                        className={
                          rep >= repCount
                            ? "practice-mode-controls__set-marker"
                            : "practice-mode-controls__set-marker practice-mode-controls__set-marker--completed-set"
                        }
                        key={idx}
                      ></div>
                    );
                  })}
                </div>
              </div>
              <div className="practice-mode-controls__bottom-controls__section practice-mode-controls__bottom-controls__section--right">
                <div
                  className="practice-mode-controls__play-pause"
                  onClick={() => {
                    onPlayPause();
                    setPracticeEnded(false);
                    setDelayTimerOpen(false);
                  }}
                >
                  {playing ? (
                    <img src="/img/pause-icon.svg" />
                  ) : (
                    <img src="/img/play-arrow-1.svg" />
                  )}
                  {/* <img src="/img/pause-icon.svg" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
