import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "./shared";
import { mockvideos } from "./mockvideos";

//swiper stuff
import { Swiper, SwiperSlide } from "swiper/react";
import { register } from "swiper/element/bundle";

//this uses swiper elements, swiper modules is going to lose support soon.
register();
export const ResultsSlider = ({ videos, sectionName }) => {
  let navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const swiperRef = useRef(null);
  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      // slidesPerView: 4.1,
      // slidesPerGroup: 4,
      //   loop: true,
      //   loopFillGroupWithBlank: true,
      centeredSlides: false,
      //   slidesPerGroup: 1,
      //   loopFillGroupWithBlank: true,
      //   slidesPerGroupSkip: 1,
      grabCursor: true,
      spaceBetween: 10,
      breakpoints: {
        250: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        560: {
          slidesPerView: 2.2,
          slidesPerGroup: 2,
        },
        600: {
          slidesPerView: 2.4,
          slidesPerGroup: 2,
        },
        700: {
          slidesPerView: 2.8,
          slidesPerGroup: 2,
        },
        800: {
          slidesPerView: 3.2,
          slidesPerGroup: 3,
        },
        900: {
          slidesPerView: 3.6,
          slidesPerGroup: 3,
        },
        1000: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },

        1080: {
          slidesPerView: 2.5,
          slidesPerGroup: 2,
          // spaceBetween: 10,
        },
        1100: {
          slidesPerView: 2.6,
          slidesPerGroup: 2,
        },
        1180: {
          slidesPerView: 2.9,
          slidesPerGroup: 2,
        },
        1200: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1250: {
          slidesPerView: 3.2,
          slidesPerGroup: 3,
        },
        1300: {
          slidesPerView: 3.4,
          slidesPerGroup: 3,
        },
        1400: {
          slidesPerView: 3.8,
          slidesPerGroup: 3,
        },

        1464: {
          slidesPerView: 4.1,
          slidesPerGroup: 4,
          // spaceBetween: 10,
        },
      },
      navigation: true,
      //   pagination: true,
      //   scrollbar: true,
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            
           z-index:2;
            color: white;
          }
         
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, [videos]);
  // if (!videos) {
  //   return <></>;
  // }
  // console.log(videos);
  return (
    // <div className="results-slider">
    //   <strong>
    //     <p>{sectionName}</p>
    //   </strong>

    <swiper-container
      ref={swiperRef}
      init="false"
      // slidesPerView={1}
      // centeredSlides={false}
      // slidesPerGroupSkip={1}
      // grabCursor={true}
      // keyboard={{
      //   enabled: true,
      // }}
      // breakpoints={{
      //   769: {
      //     slidesPerView: 2,
      //     slidesPerGroup: 2,
      //   },
      // }}
      // scrollbar={true}
      // navigation={true}
      // pagination={{
      //   clickable: true,
      // }}
      // modules={[Keyboard, Scrollbar, Navigation, Pagination]}
      // className="mySwiper"
    >
      {videos.map((element, idx) => {
        return (
          <swiper-slide key={idx}>
            <div className="results-slider__thumbnail">
              <Card
                thumbnail
                // image={element.thumbnail_url}
                image={
                  element.thumbnail_url !== ""
                    ? element.thumbnail_url
                    : "/img/really-good-thumbnail.JPG"
                }
                //   key={idx}
                title={element.name}
                onClick={() => {
                  navigate(currentPath + "/" + element.trainer_video_id);
                }}
              />
            </div>
          </swiper-slide>
        );
      })}
    </swiper-container>
    //   <div className="results-slider__end-shadow"></div>
    // </div>
  );
};
