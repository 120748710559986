export const Mocks = [
  {
    id: 1,
    name: "john jacob",
    testimony:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    photo: "https://via.placeholder.com/400x400",
    skill: "Junior Player",
  },
  {
    id: 2,
    name: "jingle heimer",
    testimony:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ",
    photo: "https://via.placeholder.com/400x400",
    skill: "Advanced Player",
  },
  {
    id: 3,
    name: "Schmidt",
    testimony:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    photo: "https://via.placeholder.com/400x400",
    skill: "Terrible Player",
  },
  {
    id: 4,
    name: "his name",
    testimony:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ",
    photo: "https://via.placeholder.com/400x400",
    skill: "Elite Player",
  },
  {
    id: 5,
    name: "Was my name too",
    testimony:
      "Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ",
    photo: "https://via.placeholder.com/400x400",
    skill: "Conner Level",
  },
];
