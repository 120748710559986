import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/swiper.min.css";
// import "swiper/swiper-bundle.min.css";

const HourMinuteSelector = ({
  //   userProgramData,
  selectedHour,
  setSelectedHour,
  selectedMinute,
  setSelectedMinute,
  selectedPeriod,
  setSelectedPeriod,
}) => {
  //   const [selectedHour, setSelectedHour] = useState(0);
  //   const [selectedMinute, setSelectedMinute] = useState(0);
  //   const [selectedPeriod, setSelectedPeriod] = useState("AM");
  const hours = Array.from({ length: 12 }, (_, i) => i + 1); // 1 to 12 hours
  const minutes = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 minutes
  const periods = ["AM", "PM"];
  //   if (userProgramData) {
  //     console.log(userProgramData);
  //   }
  return (
    <div className="time-swiper">
      {/* Hour Selector */}
      <div className="time-swiper__center-bar-wrapper">
        <div className="time-swiper__center-bar-wrapper__bar"></div>
      </div>
      <Swiper
        direction="vertical"
        slidesPerView={3.5}
        centeredSlides={true}
        // freeMode={true}
        // freeModeSticky={true}
        onSlideChange={(swiper) => setSelectedHour(swiper.realIndex + 1)}
        loop={true}
        effect="coverflow"
        coverflowEffect={{
          rotate: -15,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
      >
        {hours.map((hour) => (
          <SwiperSlide key={hour}>
            <div style={{ textAlign: "center", fontSize: "19px" }}>{hour}</div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          zIndex: "1",
          //   marginInline: "19px",
        }}
      >
        <strong>:</strong>
      </div>
      {/* Minute Selector */}
      <Swiper
        direction="vertical"
        slidesPerView={3.5}
        centeredSlides={true}
        // freeMode={true}
        // freeModeSticky={true}
        // onSlideChange={(swiper) => console.log(swiper)}
        onSlideChange={(swiper) => setSelectedMinute(swiper.realIndex)}
        // onSlideChange={(swiper) => setSelectedMinute(swiper.activeIndex)}
        loop={true}
        effect="coverflow"
        coverflowEffect={{
          rotate: -15,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
      >
        {minutes.map((minute) => (
          <SwiperSlide key={minute}>
            <div style={{ textAlign: "center", fontSize: "19px" }}>
              {minute}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        direction="vertical"
        slidesPerView={3.35}
        centeredSlides={true}
        // loop={true}
        onSlideChange={(swiper) => setSelectedPeriod(periods[swiper.realIndex])}
        effect="coverflow"
        coverflowEffect={{
          rotate: -10,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
      >
        {periods.map((period) => (
          <SwiperSlide key={period}>
            <div style={{ textAlign: "center", fontSize: "19px" }}>
              {period}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div
        style={{
          marginLeft: "20px",
          display: "flex",
          alignItems: "center",
          fontSize: "24px",
        }}
      >
        {`${selectedHour.toString()}:${selectedMinute
          .toString()
          .padStart(2, "0")}`}
      </div> */}
    </div>
  );
};

export default HourMinuteSelector;
