import React from "react";
import { useUser } from "../providers";
import { AppLayout } from "./AppLayout";
import { SiteLayout } from "./SiteLayout";
import { AdminLayout } from "./AdminLayout";
import { ComingSoonLayout } from "./ComingSoonLayout";
import HandyGolf from "../lib/HandyGolf";

const Cookies = require("universal-cookie");
const cookies = new Cookies();

export const Layout = () => {
  const [user, setContextUser, isLoadingUser] = useUser();
  const landing_page = HandyGolf.getValue('landing_page');
  const bypasslp = ( cookies.get('HG_BYPASS_LP') === '813cf0c0-7a02-41ac-afca-d5f698df1a78' );
  if ((landing_page === 'main' || bypasslp) && isLoadingUser) {
    //real loading spinner
    return <p>loading. . . </p>;
  }

  return (
    <>
      { !bypasslp && landing_page === 'comingsoon' && <ComingSoonLayout />}
      { (landing_page === 'main' || bypasslp) && (user ? user.is_admin ? <AdminLayout /> : <AppLayout /> : <SiteLayout />)}
    </>
  );
};
