import React, { Component } from "react";
import HandyGolfAPI from '../lib/HandyGolfAPI';

function MySQLQueryRunnerForm() {
    const [hostname, setHostname] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [database, setDatabase] = React.useState("");
    const [query, setQuery] = React.useState("");
    const [result, setResult] = React.useState("");

    const runQuery = async () => {
        try {
            HandyGolfAPI.sendRequest(
                'dbq/runQuery',
                {
                    hostname: hostname,
                    username: username,
                    password: password,
                    database: database,
                    query: query,
                }
            )
            .then((data) => {
                console.log('Query call succeeded: ', data);
                setResult(JSON.stringify(data, null, "\t"));
            })
            // const restOperation = post({
            //     apiName: 'HandyGolfAPI',
            //     path: '/api/dbq/runQuery',
            //     options: {
            //         body: {
            //             hostname: hostname,
            //             username: username,
            //             password: password,
            //             database: database,
            //             query: query,
            //         }
            //     },
            //     credentials: ''
            // });
            // const response = await restOperation.response;
        } catch (error) {
            console.log('Query call failed: ', error);
        }
    }

    const handleHostnameChange = (e) => {
        setHostname(e.target.value);
    }
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handleDatabaseChange = (e) => {
        setDatabase(e.target.value);
    }
    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    }
    return(
        <div>
            Hostname: <input
                type="text"
                id="hostname"
                name="hostname"
                onChange={handleHostnameChange}
                value={hostname}
            /><br />
            Username: <input
                type="text"
                id="username"
                name="username"
                onChange={handleUsernameChange}
                value={username}
            /><br />
            Password: <input
                type="password"
                id="password"
                name="password"
                onChange={handlePasswordChange}
                value={password}
            /><br />
            Database: <input
                type="text"
                id="database"
                name="database"
                onChange={handleDatabaseChange}
                value={database}
            /><br />
            Query: <textarea
                id="query"
                name="query"
                onChange={handleQueryChange}
                value={query}
            /><br />
            <br />
            <button onClick={runQuery}>Test Run Query</button>
            <br />
            Result:
            <pre>{result}</pre>
        </div>
    );
}

export default MySQLQueryRunnerForm;