import React, { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { Button } from "../shared/Button";

import HandyGolfAPI from "../../lib/HandyGolfAPI";

export const Section = ({
  lessonDetails,
  unit,
  // loadLessonUnitData,
  updateLessonDetails,

  unitIdx,
}) => {
  const {
    lesson,
    completedSubUnits,
    numUnits,
    sub,
    subNotes,
    unitCompletion,
    unitNotes,
    parentIndex,
  } = lessonDetails;
  const [isOpen, setIsOpen] = useState(parentIndex === unitIdx);
  //if we want selecting to reset open, uncomment useeffect vv
  useEffect(() => {
    setIsOpen(parentIndex === unitIdx);
  }, [sub]);

  // if (!userLessonData) {
  //   return <></>;
  // }
  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };

  const changingBottomStyle =
    !isOpen &&
    unitCompletion[unitIdx].percent_complete !== 100 &&
    unitCompletion[unitIdx].percent_complete > 0
      ? { borderRadius: "10px 10px 0 0" }
      : {};
  return (
    <>
      <div className="accordion__unit" style={changingBottomStyle}>
        <div
          className="accordion__title-box"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          {unitCompletion[unitIdx].percent_complete === 100 ? (
            <i className="material-icons">check</i>
          ) : (
            <i
              className="material-icons"
              style={{ color: "#010D15", opacity: ".2" }}
            >
              check
            </i>
          )}

          <p>
            {unit.number}. {unit.name}
          </p>
          {isOpen ? (
            <i className="material-icons open">chevron_right</i>
          ) : (
            <i className="material-icons">chevron_right</i>
          )}
        </div>
        {!isOpen &&
          unitCompletion[unitIdx].percent_complete !== 100 &&
          unitCompletion[unitIdx].percent_complete > 0 && (
            <LinearProgress
              sx={{
                backgroundColor: "#363636",
                borderRadius: "0 0 4px 4px",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#3AB549",
                  // borderRadius: "0 0 4px 4px",
                },
              }}
              variant="determinate"
              value={unitCompletion[unitIdx].percent_complete}
              size={100}
            />
          )}
        {isOpen &&
          unitCompletion[unitIdx].percent_complete !== 100 &&
          unitCompletion[unitIdx].percent_complete > 0 && (
            <LinearProgress
              sx={{
                backgroundColor: "#363636",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#3AB549",
                },
              }}
              variant="determinate"
              value={unitCompletion[unitIdx].percent_complete}
              size={100}
            />
          )}
        <div
          className={
            !isOpen
              ? "accordion__data-box"
              : "accordion__data-box accordion__data-box--open"
          }
        >
          <div style={{ overflow: "hidden" }}>
            {unit.sub_units.map((sub_unit, idx) => {
              // console.log(userLessonData.sub_unit_notes_and_completion);
              // console.log(sub_unit);
              return (
                <div key={idx} className="accordion__sub-unit">
                  <div
                    className={
                      sub_unit.lesson_unit_id === sub.lesson_unit_id
                        ? "accordion__sub-unit__data selected"
                        : "accordion__sub-unit__data"
                    }
                    onClick={() => {
                      updateLessonDetails(sub_unit.lesson_unit_id);
                      console.log(sub_unit.lesson_unit_id);
                    }}
                  >
                    <p>
                      {sub_unit.number} {sub_unit.name}
                    </p>
                    {sub_unit.lesson_unit_id === sub.lesson_unit_id ? (
                      <strong
                        style={{
                          // fontSize: "8px",
                          // color: "#0071BB",
                          position: "absolute",
                          right: "40px",
                          // marginLeft: "30px",
                        }}
                      >
                        {convertSecondsToTimeText(
                          sub_unit.video_length !== 0
                            ? sub_unit.video_length
                            : 45
                        )}
                      </strong>
                    ) : (
                      <span
                        style={{
                          // fontSize: "8px",
                          // color: "#0071BB",
                          position: "absolute",
                          right: "40px",
                          // marginLeft: "30px",
                        }}
                      >
                        {convertSecondsToTimeText(
                          sub_unit.video_length !== 0
                            ? sub_unit.video_length
                            : 45
                        )}
                      </span>
                    )}
                    {unitNotes.find(
                      (thing) =>
                        thing.lesson_unit_id === sub_unit.lesson_unit_id
                    ) ? (
                      <>
                        <i
                          className="material-icons"
                          style={{
                            fontSize: "8px",
                            color: "#0071BB",
                            position: "absolute",
                            right: "15px",
                            // marginLeft: "30px",
                          }}
                        >
                          brightness_1
                        </i>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="accordion__sub-unit__checkbox">
                    {completedSubUnits.includes(sub_unit.lesson_unit_id) ? (
                      <i className="material-icons">check</i>
                    ) : (
                      <i
                        className="material-icons"
                        style={{ color: "#010D15", opacity: ".2" }}
                      >
                        check
                      </i>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
