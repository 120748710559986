import React, { useEffect, useState } from "react";
import { Button } from "./shared";

export const Search = ({
  // searchString,
  // setSearchString,
  handleSearchChange,
  onSearchSubmit,
}) => {
  return (
    <form className="search-bar" onSubmit={onSearchSubmit}>
      <i className="material-icons search-bar__icon">search</i>
      <input
        type="search"
        placeholder="Search anything..."
        onChange={handleSearchChange}
      />
      {/* <Button secondary>Cancel</Button> */}
    </form>
  );
};
