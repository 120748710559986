import React from 'react';


// Libraries
import HandyGolfAuth from "../lib/HandyGolfAuth";
import { GoogleOAuthProvider, GoogleLogin  } from '@react-oauth/google';

// MUI
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {Button, Divider, TextField} from "@mui/material";

// Nav
import { Link, Navigate } from "react-router-dom";
import AppleIcon from "@mui/icons-material/Apple";

function GoogleIcon() {
    return <div style={{height:18, width:18, marginRight:15}}><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg></div>;
}
function UserSignUpPage() {
    const [ loaded_auth, setLoadedAuth ] = React.useState(false)
    const [ is_authenticated, setIsAuthenticated ] = React.useState(false)
    const [ user_details, setUserDetails ] = React.useState({})
    const [ email, setEmail ] = React.useState('');
    const [ name, setName ] = React.useState('');
    const [ password, setPassword ] = React.useState('');
    const [ confirm_password, setConfirmPassword ] = React.useState('');

    const tmpGoogleLogin = React.useRef(null);

    if(!loaded_auth) {
        HandyGolfAuth.isUserAuthenticated().then((is_authenticated) => {
            setIsAuthenticated(is_authenticated);
            if(is_authenticated) {
                HandyGolfAuth.getAuthenticatedUser().then((user_details) => {
                    setUserDetails(user_details);
                    setLoadedAuth(true);
                });
            } else {
                setLoadedAuth(true)
            }
        })
    }

    return (
        <div className="body_content">
            <div style={{maxWidth:600, minWidth:300, marginLeft:"auto", marginRight:"auto"}}>
                {
                    !loaded_auth ? (
                        <CircularProgress />
                    ) : (
                        (is_authenticated && user_details && user_details.full_name) ? (
                            <Navigate to="/user/profile" />
                        ) : (
                            <>
                                <Typography variant="h5" gutterBottom>Account Creation</Typography>
                                <Button variant="outlined" className="google_sign_in" onClick={() => { tmpGoogleLogin.current.style.display='block'; }}><GoogleIcon />Create Account with Google</Button>
                                <div ref={tmpGoogleLogin} style={{display:'none'}}>
                                    <GoogleLogin
                                        text="signup_with"
                                        // useOneTap={true}
                                        width="400"
                                        auto_select={false}
                                        onSuccess={credentialResponse => {
                                            HandyGolfAuth.loginWithGoogleAccount(credentialResponse.credential)
                                                .then(async (data) => {
                                                    await HandyGolfAuth.resetSessionCache();
                                                    setIsAuthenticated(await HandyGolfAuth.isUserAuthenticated());
                                                    setUserDetails(await HandyGolfAuth.getAuthenticatedUser());
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                })
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                </div>
                                <Button variant="contained" className="apple_sign_in" onClick={() => { }}><AppleIcon style={{marginRight:10}} />Create Account with Apple</Button>
                                <Divider />
                                <Typography variant="h5" style={{marginTop:20}}>Create Account with Email and Password:</Typography><br />
                                <TextField label="Email" onChange={(e) => { setEmail(e.target.value)}} style={{width:'100%', marginBottom:20}} />
                                <TextField label="Name" onChange={(e) => { setName(e.target.value)}} style={{width:'100%', marginBottom:20}} /><br />
                                <TextField label="Password" type="password" onChange={(e) => { setPassword(e.target.value)}} style={{width:'100%', marginBottom:20}} /><br />
                                <TextField label="Confirm Password" type="password" onChange={(e) => { setConfirmPassword(e.target.value)}} style={{width:'100%', marginBottom:20}} /><br />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if(email.trim().length === 0) {
                                            alert('You must specify an email address.')
                                        } else if(name.trim().length === 0) {
                                            alert('You must specify a name.')
                                        } else if(password.trim().length < 8) {
                                            alert('Your password must be at least 8 characters long.')
                                        } else if(password.trim() !== confirm_password.trim()) {
                                            alert('Your passwords do not match.')
                                        } else {
                                            HandyGolfAuth.signUpWithEmailAccount(email, name, password)
                                                .then(async (data) => {
                                                    await HandyGolfAuth.resetSessionCache();
                                                    setIsAuthenticated(await HandyGolfAuth.isUserAuthenticated());
                                                    setUserDetails(await HandyGolfAuth.getAuthenticatedUser());
                                                })
                                                .catch(error => {
                                                    alert(error.message);
                                                })
                                        }
                                    }}
                                    style={{width:'100%'}}
                                >Create Email Based Account</Button><br />
                                {/*<Typography variant="h6" gutterBottom>Development Login as Persona:</Typography>*/}
                                {/*<Button variant="contained" onClick={() => {HandyGolfAuth.loginAsPersona('john').then(() => setLoadedAuth(false))}}>Log In as John Freeman</Button><br /><br />*/}
                                {/*<Button variant="contained" onClick={() => {HandyGolfAuth.loginAsPersona('matt').then(() => setLoadedAuth(false))}}>Log In as Matt Subscriberton</Button><br /><br />*/}
                                {/*<Button variant="contained" onClick={() => {HandyGolfAuth.loginAsPersona('dan').then(() => setLoadedAuth(false))}}>Log In as Dan Adminson</Button><br />*/}
                            </>
                        )
                    )
                }
            </div>
        </div>
    );
}

export default UserSignUpPage;
