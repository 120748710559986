import React, { useState, useEffect } from "react";

// Handy Golf Libs
// import HandyGolf from "../../lib/HandyGolf";
// import HandyGolfAuth from "../../lib/HandyGolfAuth";
// import { useTheme } from "../../providers";
// Materials
// import { Button, Drawer, Divider } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

// Google Libraries
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export function BottomNav({ lessonDetails }) {
  //   const [theme] = useTheme();

  //state for trainer subnav links
  const [trainerSelected, setTrainerSelected] = useState(false);

  if (lessonDetails) {
    // console.log(true);
    return (
      <div className="navigation__bottom">
        <div className="navigation__bottom__navlink">
          <NavLink
            to="/dashboard"
            onClick={() => {
              setTrainerSelected(false);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // justifyContent: "space-evenly",
              }}
            >
              <span>
                <img
                  className="nav-inactive"
                  src="/img/dog-ear-page-icon.svg"
                />

                <img
                  className="nav-active"
                  src="/img/dashboard-icon-green.svg"
                />
              </span>
              {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            </div>
          </NavLink>
        </div>
        <div className="navigation__bottom__navlink">
          <NavLink
            to="/dashboard"
            onClick={() => {
              setTrainerSelected(false);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // justifyContent: "space-evenly",
              }}
            >
              <span>
                <img className="nav-inactive" src="/img/share_icon.svg" />

                <img
                  className="nav-active"
                  src="/img/dashboard-icon-green.svg"
                />
              </span>
              {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            </div>
          </NavLink>
        </div>
        <div className="navigation__bottom__navlink">
          <NavLink
            to="/dashboard"
            onClick={() => {
              setTrainerSelected(false);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // justifyContent: "space-evenly",
              }}
            >
              <span>
                <img className="nav-inactive" src="/img/add_to_icon.svg" />

                <img
                  className="nav-active"
                  src="/img/dashboard-icon-green.svg"
                />
              </span>
              {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            </div>
          </NavLink>
        </div>
      </div>
    );
  }
  return (
    <div className="navigation__bottom">
      <div className="navigation__bottom__navlink">
        <NavLink
          to="/dashboard"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img
                className="nav-inactive"
                src="/img/dashboard-icon-grey.svg"
              />

              <img className="nav-active" src="/img/dashboard-icon-green.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Dashboard
          </div>
        </NavLink>
      </div>
      <div className="navigation__bottom__navlink">
        <NavLink
          to="/lessons"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img className="nav-inactive" src="/img/play-icon-grey.svg" />

              <img className="nav-active" src="/img/play-icon-green.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Lessons
          </div>
        </NavLink>
      </div>
      <div
        className="navigation__bottom__navlink"
        // onClick={() => {
        //   // setTrainerSelected((prev) => !prev);
        //   setTrainerSelected(true);
        // }}
      >
        <NavLink
          to="/trainer"
          onClick={() => {
            // setTrainerSelected((prev) => !prev);
            setTrainerSelected(true);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img className="nav-inactive" src="/img/driver-tee-grey.svg" />

              <img className="nav-active" src="/img/driver-tee-green.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Trainer
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: 'center',
              // justifyContent: "space-evenly",
            }}
          >
            Drills
          </div> */}
        </NavLink>
      </div>
      <div className="navigation__bottom__navlink">
        <NavLink
          to="/swing_analyzer"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // justifyContent: "space-evenly",
            }}
          >
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            <span>
              <img className="nav-inactive" src="/img/golf-swing-grey.svg" />

              <img
                className="nav-active"
                src="/img/golf-swing-green.svg"
                style={{ height: "40px", width: "32.7px" }}
              />
            </span>
            Analyzer
          </div>
        </NavLink>
      </div>
      <div className="navigation__bottom__navlink">
        <NavLink
          to="/progress"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img className="nav-inactive" src="/img/progress-icon-grey.svg" />

              <img className="nav-active" src="/img/progress-icon-grey.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Progress
          </div>
        </NavLink>
      </div>
    </div>
  );
}
