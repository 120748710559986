import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Container, AppTopBar, BottomNav } from "../components";
import { CheckLessonCacheContext, StateContext } from "../context/AppContext";

export function Lessons() {
  const state = useContext(StateContext);
  const checkLessonCache = useContext(CheckLessonCacheContext);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    checkLessonCache();

    return () => {
      mounted = false;
    };
  }, []);
  const isLoading = state.loading.lesson_list_data;
  if (isLoading) {
    // console.log("LOADING TIME");
    return <>loading.....</>;
  }
  // console.log(state.loading);
  let lessons = state.lesson_list_data.lesson_list;

  const image =
    "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg";
  const loremIpsum =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.";

  return (
    <>
      <AppTopBar />
      <div className="container">
        <div className="container__content">
          <div className="pad-box scroll-content-mobile">
            {/* <Container> */}
            <div className="card-container">
              {lessons.map((lesson, idx) => {
                return (
                  <Card
                    id={idx}
                    key={idx}
                    split
                    continue
                    title={lesson.name}
                    image={lesson.thumbnail_url ? lesson.thumbnail_url : image}
                    description={lesson.tagline}
                    //TODO update description to be tagline and tagline to be descripition
                    backInfo={lesson.description}
                    onClick={() => {
                      navigate("/lessons/lesson_details/" + lesson.lesson_id);
                    }}
                  />
                );
              })}
              <Card
                split
                start
                title="Simple Swing"
                image={image}
                description="Amazing golf drill that will truly help you hit driver straight"
                backInfo={loremIpsum}
              />
              <Card
                split
                continue
                title="Simple Swing"
                image={image}
                description="Amazing golf drill that will truly help you hit driver straight"
                backInfo={loremIpsum}
              />
              <Card
                split
                purchase
                title="Simple Swing"
                image={image}
                description="Amazing golf drill that will truly help you hit driver straight"
                backInfo={loremIpsum}
              />
            </div>
          </div>
        </div>
        <div className="container__collapse"></div>
      </div>
      {/* </Container> */}
      <BottomNav />
    </>
  );
}
