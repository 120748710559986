import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AdminTable, Uploader, setFormDefaults, AdminContainer } from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";

const initialCategoryForm = {
  name: "",
  //   parent_trainer_category_id: 0,
};

export function AdminFeedback() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    // child,
    // grand_child,
    id_param: parent_id,
    // id_param2: child_id,
    // id_param3: grandchild_id,
  } = useParams();
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();

  const [selectedLesson, setSelectedLesson] = useState();
  const updateURL = (newPath) => {
    const currentPath = location.pathname;
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath, { replace: true });
    }
  };

  const getLessonUnitFeedback = () => {
    HandyGolfAPI.sendRequest("user/lesson_unit_feedback/list", {}).then(
      (data) => {
        console.log(data);
      }
    );
  };
  const getTrainerVideoFeedback = () => {
    HandyGolfAPI.sendRequest("user/trainer_video_feedback/list", {}).then(
      (data) => {
        console.log(data);
      }
    );
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);

  useEffect(() => {
    if (!selectedLesson) {
      getLessonUnitFeedback();
      getTrainerVideoFeedback();
    }
  }, [selectedLesson]);

  return (
    <>
      <AdminContainer></AdminContainer>
    </>
  );
}
