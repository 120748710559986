import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Webcam from "react-webcam";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { Card, Container, AppTopBar, BottomNav, Player } from "../components";

// Video Library
import ReactPlayer from "react-player";
import { Button } from "@mui/material";

import {
  StateContext,
  StateDispatchContext,
  CheckCacheContext,
} from "../context/AppContext";

export function VideoPage() {
  const currentPath = useLocation().pathname;
  let navigate = useNavigate();
  // if (currentPath.includes("drills")) {
  //   console.log("yes");
  // } else {
  //   console.log("no");
  // }
  const currentType = currentPath.includes("drills") ? 1 : 2;
  const typeString = currentType === 1 ? "DRILL" : "GUIDED_TRAINING";
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkCache = useContext(CheckCacheContext);

  const [video_levels, setVideoLevels] = useState([]);
  const [video_playing, setVideoPlaying] = useState(false);
  const [video_time, setVideoTime] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [video, setVideo] = useState();
  const [feedback, setFeedback] = useState();
  //old lesson feedback states
  const [feedback_display, setFeedbackDisplay] = useState(false);
  const [feedback_rating, setFeedbackRating] = useState(0);
  const [feedback_text, setFeedbackText] = useState("");
  const [feedback_timeout, setFeedbackTimeout] = useState(null);
  const [rating, setRating] = useState();
  const [newFeedback, setNewFeedback] = useState("");
  const [addFeedbackOpen, setAddFeedbackOpen] = useState(false);
  const [mirror, setMirror] = useState(false);

  // const [videoList, setVideoList] = useState();
  // const [recommendedIds, setRecommendedIds] = useState();
  // const [favoriteIds, setFavoriteIds] = useState();
  // const [watchlistIds, setWatchlistIds] = useState();
  // const [recentIds, setRecentIds] = useState();

  // const [containedVideoList, setContainedVideoList] = useState();
  // const [recommendedVideos, setRecommendedVideos] = useState();

  const [practiceMode, setPracticeMode] = useState(false);
  const [openingPracticeMode, setOpeningPracticeMode] = useState(false);

  //practice mode value states:
  // const [userRepRange, setUserRepRange] = useState();

  const { trainer_video_id } = useParams();

  const videoRef = useRef(null);
  const webcamRef = useRef(null);
  // let video = {};
  useEffect(() => {
    checkCache();
    // fetchData("DRILL_trainer_videos", "trainer/video/getAllVideosByType");

    // console.log("1st console in useeffect");
    // console.log("isloadingstatus:", isLoading);
    // if (!isLoading) {
    //   console.log(state);
    //   console.log(videoList, otherTypeVideoList);
    //   videoSetter();
    // }

    HandyGolfAPI.sendRequest("trainer/getUserVideoFeedback", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      setFeedback(data);
    });
  }, [currentPath]);
  const isLoading =
    state.loading.DRILL_trainer_videos ||
    state.loading.DRILL_user_video_ids ||
    state.loading.DRILL_categories ||
    state.loading.GUIDED_TRAINING_trainer_videos ||
    state.loading.GUIDED_TRAINING_user_video_ids ||
    state.loading.GUIDED_TRAINING_categories;

  if (isLoading) {
    console.log("LOADING TIME");
    return <>loading.....</>;
  }
  let categories =
    currentType === 1
      ? state.DRILL_categories.categories
      : state.GUIDED_TRAINING_categories.categories;
  let recommendedIds =
    currentType === 1
      ? state.DRILL_user_video_ids.recommended_video_ids
      : state.GUIDED_TRAINING_user_video_ids.recommended_video_ids;
  let favoriteIds =
    currentType === 1
      ? state.DRILL_user_video_ids.favorite_video_ids
      : state.GUIDED_TRAINING_user_video_ids.favorite_video_ids;
  let watchlistIds =
    currentType === 1
      ? state.DRILL_user_video_ids.watchlist_video_ids
      : state.GUIDED_TRAINING_user_video_ids.watchlist_video_ids;
  let recentIds =
    currentType === 1
      ? state.DRILL_user_video_ids.recent_video_ids
      : state.GUIDED_TRAINING_user_video_ids.recent_video_ids;
  let videoList =
    currentType === 1
      ? state.DRILL_trainer_videos.trainer_videos
      : state.GUIDED_TRAINING_trainer_videos.trainer_videos;
  let otherTypeVideoList =
    currentType === 2
      ? state.DRILL_trainer_videos.trainer_videos
      : state.GUIDED_TRAINING_trainer_videos.trainer_videos;
  let pageVideoIdx = videoList.findIndex(
    (vid) => vid.trainer_video_id == trainer_video_id
  );
  let video = videoList[pageVideoIdx];

  let containedVideoList =
    currentType === 1
      ? otherTypeVideoList.filter((drill) =>
          video.guided_training_trainer_video_ids.includes(
            drill.trainer_video_id
          )
        )
      : otherTypeVideoList.filter((guided_training) =>
          video.drill_trainer_video_ids.includes(
            guided_training.trainer_video_id
          )
        );
  let recommendedVideos = videoList.filter((sameTypeVid) =>
    //just using first 3 recommendeds for now
    recommendedIds.slice(0, 3).includes(sameTypeVid.trainer_video_id)
  );

  // const saveFeedback = (new_rating, new_text) => {
  //   // Build Payload
  //   let payload = {
  //     lesson_id: lesson.lesson_id,
  //     lesson_unit_id: current_lesson_unit.lesson_unit_id,
  //     rating: new_rating !== null ? new_rating : feedback_rating,
  //     feedback_text:
  //       new_text !== null && new_text !== undefined
  //         ? new_text.trim()
  //         : feedback_text.trim(),
  //   };

  //   // Set Timeout
  //   clearTimeout(feedback_timeout);
  //   setFeedbackTimeout(
  //     setTimeout(() => {
  //       HandyGolfAPI.sendRequest(
  //         "lesson/saveUserLessonUnitFeedback",
  //         payload
  //       ).then((data) => {
  //         // Do Nothing on Success
  //       });
  //     }, 1000)
  //   );

  //   // Set States
  //   if (new_rating !== null) {
  //     setFeedbackRating(new_rating);
  //   }
  //   if (new_text !== null && new_text !== undefined) {
  //     setFeedbackText(new_text);
  //   }
  // };

  const handleFavorite = () => {
    if (favoriteIds.includes(Number(trainer_video_id))) {
      HandyGolfAPI.sendRequest("trainer/video/removeFavorite", {
        type: typeString,
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        // console.log(data);
        // setFavoriteIds(data.favorite_video_ids);
        let key = `${typeString}_user_video_ids`;
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: data },
        });
        localStorage.setItem(
          `${typeString}_user_video_ids`,
          JSON.stringify(data)
        );
      });
    } else {
      HandyGolfAPI.sendRequest("trainer/video/addFavorite", {
        type: typeString,
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        // console.log(data);
        // setFavoriteIds(data.favorite_video_ids);
        let key = `${typeString}_user_video_ids`;
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: data },
        });
        localStorage.setItem(
          `${typeString}_user_video_ids`,
          JSON.stringify(data)
        );
      });
    }
  };
  const handleWatchlist = () => {
    if (watchlistIds.includes(Number(trainer_video_id))) {
      HandyGolfAPI.sendRequest("trainer/video/removeFromWatchlist", {
        type: typeString,
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        // console.log(data);
        // setWatchlistIds(data.watchlist_video_ids);

        let key = `${typeString}_user_video_ids`;
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: data },
        });
        localStorage.setItem(
          `${typeString}_user_video_ids`,
          JSON.stringify(data)
        );
      });
    } else {
      HandyGolfAPI.sendRequest("trainer/video/addToWatchlist", {
        type: typeString,
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        // console.log(data);
        // setWatchlistIds(data.watchlist_video_ids);
        let key = `${typeString}_user_video_ids`;
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: data },
        });
        localStorage.setItem(
          `${typeString}_user_video_ids`,
          JSON.stringify(data)
        );
      });
    }
  };
  const handleMarkAsWatched = () => {
    if (recentIds.includes(Number(trainer_video_id))) {
      HandyGolfAPI.sendRequest("trainer/video/markAsNotWatched", {
        type: typeString,
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        // console.log(data);
        // setRecentIds(data.recently_watched_video_ids);
        let key = `${typeString}_user_video_ids`;
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: data },
        });
        localStorage.setItem(
          `${typeString}_user_video_ids`,
          JSON.stringify(data)
        );
      });
    } else {
      HandyGolfAPI.sendRequest("trainer/video/markAsWatched", {
        type: typeString,
        trainer_video_id: trainer_video_id,
      }).then((data) => {
        // console.log(data);
        // setRecentIds(data.recently_watched_video_ids);
        let key = `${typeString}_user_video_ids`;
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: data },
        });
        localStorage.setItem(
          `${typeString}_user_video_ids`,
          JSON.stringify(data)
        );
      });
    }
  };

  return (
    <>
      <AppTopBar
        breadcrumb
        pageTitle={video.name}
        // pageTitle=
        trainer_video_id={trainer_video_id}
        watchlistIds={watchlistIds}
        favoriteIds={favoriteIds}
        handleFavorite={handleFavorite}
        handleWatchlist={handleWatchlist}
        path={"/trainer/drills"}
        favorite
        addTo
      />
      <div className="container">
        <div className="container__content">
          <div className="pad-box">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                disabled={!practiceMode}
                onClick={() => {
                  setPracticeMode(false);
                }}
              >
                explanation
              </button>
              <button
                disabled={practiceMode}
                onClick={() => {
                  setPracticeMode(true);
                  setOpeningPracticeMode(true);
                }}
              >
                practice
              </button>
            </div>
            <div style={{ position: "relative" }}>
              <Player
                videoRef={videoRef}
                // url={video.video_url}
                url={
                  video.video_url !== ""
                    ? video.video_url
                    : "/vid/adamscott.mp4"
                }
                // light={"/vid/adam"}
                // saveFeedback={saveFeedback}
                // current_lesson_unit={current_lesson_unit}
                // loadLessonUnitData={loadLessonUnitData}
                // lesson={lesson}
                // feedback_display={feedback_display}
                // setFeedbackDisplay={setFeedbackDisplay}
                // user_lesson_data={user_lesson_data}
                // setVideoTime={setVideoTime}
                // feedback_rating={feedback_rating}
                // feedback_text={feedback_text}
                isTrainer
                practiceMode={practiceMode}
                openingPracticeMode={openingPracticeMode}
                setOpeningPracticeMode={setOpeningPracticeMode}
                // userRepRange={userRepRange}
              />
            </div>

            <div>
              <div style={{ display: "flex", gap: "3rem" }}>
                <div>
                  <p>Practice Time</p>
                  <h3>{Math.ceil(video.video_length / 60)} minutes</h3>
                </div>
                <div>
                  <p>Equipment</p>
                  {video.trainer_equipment_names.length > 0 ? (
                    <h3>
                      {video.trainer_equipment_names.map((equip, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {equip + ", "}
                          </React.Fragment>
                        );
                      })}
                    </h3>
                  ) : (
                    <h3>-</h3>
                  )}
                </div>
              </div>

              <p>
                {video.description !== ""
                  ? video.description
                  : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."}
              </p>
            </div>
            {/* {video.type === 1 ? } */}
            {containedVideoList.length > 0 && (
              <div
                className="container__color-layer"
                style={{
                  // padding: "2rem 1rem",
                  padding: ".5rem 1rem 2rem 1rem",
                  // display: "flex",
                  // flexWrap: "wrap",
                  // rowGap: "2rem",
                }}
              >
                {video.type === 1 ? (
                  <h3>Guided Drill Version</h3>
                ) : (
                  <h3>What's in this training?</h3>
                )}
                <div className="card-container">
                  {containedVideoList.map((vid, idx) => {
                    return (
                      <div
                        // style={{ width: "300px" }}
                        key={idx}
                      >
                        <Card
                          squareThumbInfo
                          // image={element.thumbnail_url}
                          image={
                            vid.thumbnail_url !== ""
                              ? vid.thumbnail_url
                              : "/img/really-good-thumbnail.JPG"
                          }
                          //   key={idx}
                          title={vid.name}
                          description={
                            vid.description !== ""
                              ? vid.description
                              : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed."
                          }
                          onClick={() => {
                            if (video.type === 1) {
                              console.log("clicked");
                              navigate(
                                "/trainer/guided_trainings/" +
                                  vid.trainer_video_id
                              );
                            } else if (video.type === 2) {
                              navigate(
                                "/trainer/drills/" + vid.trainer_video_id
                              );
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container__side-content">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "3rem" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.2rem",
              }}
            >
              <strong>Recommended for you</strong>
              {recommendedVideos.map((vid, idx) => {
                return (
                  <div key={idx}>
                    <Card
                      squareThumbInfo
                      // image={element.thumbnail_url}
                      image={
                        vid.thumbnail_url !== ""
                          ? vid.thumbnail_url
                          : "/img/really-good-thumbnail.JPG"
                      }
                      //   key={idx}
                      title={vid.name}
                      description={
                        vid.description !== ""
                          ? vid.description
                          : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed."
                      }
                      onClick={() => {
                        if (video.type === 1) {
                          // console.log("hihihi");
                          // console.log(video.trainer_video_id);
                          navigate("/trainer/drills/" + vid.trainer_video_id);
                        } else if (video.type === 2) {
                          console.log("hihihi");
                          // console.log(video.trainer_video_id);
                          navigate(
                            "/trainer/guided_trainings/" + vid.trainer_video_id
                          );
                        }
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.2rem",
              }}
            >
              <strong>Related</strong>
              {recommendedVideos.map((vid, idx) => {
                return (
                  <div key={idx}>
                    {/* <Link
                      to={
                        vid.type === 1
                          ? `/trainer/drills/${vid.trainer_vid_id}`
                          : `/trainer/guided_trainings/${vid.trainer_vid_id}`
                      }
                    > */}
                    <Card
                      squareThumbInfo
                      // image={element.thumbnail_url}
                      image={
                        vid.thumbnail_url !== ""
                          ? vid.thumbnail_url
                          : "/img/really-good-thumbnail.JPG"
                      }
                      //   key={idx}
                      title={vid.name}
                      description={
                        vid.description !== ""
                          ? vid.description
                          : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed."
                      }
                      onClick={() => {
                        if (video.type === 1) {
                          // console.log("hihihi");
                          // console.log(video.trainer_video_id);
                          navigate("/trainer/drills/" + vid.trainer_video_id);
                        } else if (video.type === 2) {
                          // console.log("hihihi");
                          // console.log(video.trainer_video_id);
                          navigate(
                            "/trainer/guided_trainings/" + vid.trainer_video_id
                          );
                        }
                      }}
                    />
                    {/* </Link> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
