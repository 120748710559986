import React from 'react';

// MUI
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

// Router
import { NavLink } from "react-router-dom";

function ToolsPage() {

    return (
        <div className="body_content">
            <Typography variant="h3" gutterBottom>Tools</Typography>
            <NavLink to="/swing_analyzer"><Button variant="contained">Swing Analyzer</Button></NavLink><br /><br />

            <NavLink to="/swing_doctor"><Button variant="contained">Swing Doctor</Button></NavLink><br /><br />

            <NavLink to="/swing_trainer"><Button variant="contained">Swing Trainer</Button></NavLink>
        </div>
    );
}

export default ToolsPage;
