import React, { useState, useEffect } from "react";

// Handy Golf Libs
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAuth from "../lib/HandyGolfAuth";
import { useTheme } from "../providers";
// Materials
import { Drawer, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button } from "./shared";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import { useUser } from "../providers";

// Google Libraries
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export function AppNav() {
  const [theme] = useTheme();
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  // const [adminMode] = useUser();
  console.log();
  console.log(adminMode);

  //state for trainer subnav links
  const [trainerSelected, setTrainerSelected] = useState(false);

  return (
    <div className="navigation__side">
      <div className="logo">
        <Link to="/dashboard">
          {theme === 1 ? (
            <img
              src="/img/handy-golf-logo-dark-mode.svg"
              className="handy-golf-logo-dark-mode"
              alt="Handy Golf Logo"
            />
          ) : (
            <img
              src="/img/handy-golf-logo.svg"
              className="handy-golf-logo"
              alt="Handy Golf Logo"
            />
          )}
        </Link>
      </div>
      <div className="navigation__side__navlink">
        <NavLink
          to="/dashboard"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img
                className="nav-inactive"
                src="/img/dashboard-icon-grey.svg"
              />

              <img className="nav-active" src="/img/dashboard-icon-green.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Dashboard
          </div>
        </NavLink>
      </div>
      <div className="navigation__side__navlink">
        <NavLink
          to="/lessons"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img className="nav-inactive" src="/img/play-icon-grey.svg" />

              <img className="nav-active" src="/img/play-icon-green.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Lessons
          </div>
        </NavLink>
      </div>
      <div
        className="navigation__side__navlink"
        // onClick={() => {
        //   // setTrainerSelected((prev) => !prev);
        //   setTrainerSelected(true);
        // }}
      >
        <NavLink
          to="/trainer"
          onClick={() => {
            setTrainerSelected((prev) => !prev);
            // setTrainerSelected(true);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              // justifyContent: "space-evenly",
            }}
            className="side-nav-expand"
          >
            <span>
              <img className="nav-inactive" src="/img/driver-tee-grey.svg" />

              <img className="nav-active" src="/img/driver-tee-green.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Trainer
            {trainerSelected ? (
              <i style={{ marginLeft: "20px" }} className="material-icons open">
                expand_less
              </i>
            ) : (
              <i style={{ marginLeft: "20px" }} className="material-icons">
                expand_less
              </i>
            )}
          </div>

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: '16px',
              // justifyContent: "space-evenly",
            }}
          >
            Drills
          </div> */}
        </NavLink>
      </div>
      {/* {trainerSelected ? ( */}
      <div
        className={
          !trainerSelected
            ? "trainer-sub-nav"
            : "trainer-sub-nav trainer-sub-nav--open"
        }
        // "trainer-sub-nav--open"
      >
        <div
          // style={{
          //   overflow: "hidden",
          //   display: "flex",
          //   flexDirection: "column",
          //   gap: "2rem",
          //   marginTop: "2rem",
          // }}
          className={
            !trainerSelected
              ? "trainer-sub-nav__gap"
              : "trainer-sub-nav__gap trainer-sub-nav__gap--open"
          }
        >
          <NavLink to="/trainer/programs">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "70px",
                fontSize: "16px",
              }}
            >
              Programs
            </div>
          </NavLink>
          <NavLink to="/trainer/guided_trainings">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "70px",
                fontSize: "16px",
              }}
            >
              Guided Trainings
            </div>
          </NavLink>
          <NavLink to="/trainer/drills">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "70px",
                fontSize: "16px",
              }}
            >
              Drills
            </div>
          </NavLink>
        </div>
      </div>
      {/* // ) : ( // <div className="trainer-sub-nav"></div>
      // )} */}
      <div className="navigation__side__navlink">
        <NavLink
          to="/swing_analyzer"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              // justifyContent: "space-evenly",
            }}
          >
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            <span>
              <img className="nav-inactive" src="/img/golf-swing-grey.svg" />

              <img className="nav-active" src="/img/golf-swing-green.svg" />
            </span>
            Analyzer
          </div>
        </NavLink>
      </div>
      <div className="navigation__side__navlink">
        <NavLink
          to="/progress"
          onClick={() => {
            setTrainerSelected(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              // justifyContent: "space-evenly",
            }}
          >
            <span>
              <img className="nav-inactive" src="/img/progress-icon-grey.svg" />

              <img className="nav-active" src="/img/progress-icon-grey.svg" />
            </span>
            {/* <i style={{ marginRight: "20px" }} className="material-icons">
              border_clear
            </i> */}
            Progress
          </div>
        </NavLink>
      </div>
      {user.is_admin ? (
        <>
          <div className="navigation__side__navlink">
            <Link to="/admin/dashboard">
              <Button
                primary
                onClick={() => {
                  enterAdminMode();
                }}
              >
                Go to Admin
              </Button>
            </Link>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <div
        style={{
          width: "200px",
          // margin: "auto",
          position: "absolute",
          left: "100px",
          bottom: "150px",
        }}
      >
        <Button secondary fluid iconLeading="shopping_cart">
          HandyGolf Store
        </Button>
      </div> */}
    </div>
  );
}
