import React, { createContext, useReducer, useEffect, useMemo } from "react";
import HandyGolfAPI from "../lib/HandyGolfAPI";

export const StateContext = createContext(null);
export const StateDispatchContext = createContext(null);
export const CheckCacheContext = createContext(null);
export const CheckLessonCacheContext = createContext(null);
export const CheckProgramsCacheContext = createContext(null);

//USED TO BE ARRAY, CHECK LATER 29 JULY 12;47
const initialState = {
  DRILL_trainer_videos: {
    new_cache_time: null,
    trainer_videos: [],
  },

  GUIDED_TRAINING_trainer_videos: {
    new_cache_time: null,
    trainer_videos: [],
  },

  DRILL_user_video_ids: {},
  GUIDED_TRAINING_user_video_ids: {},
  DRILL_categories: [],
  GUIDED_TRAINING_categories: [],
  lesson_list_data: [],
  lesson_data: [],
  programs_data: {},
  // current_lesson_data: {},
  // current_lesson:{},
  // cacheLoaded: false,
  // loading states being set automatically to true makes it so that there is always loading while components check for state. it should be possible however with optimization to set each to false initially and only set to true if need be, allowing for faster load.
  loading: {
    DRILL_trainer_videos: true,
    GUIDED_TRAINING_trainer_videos: true,
    DRILL_user_video_ids: true,
    GUIDED_TRAINING_user_video_ids: true,
    DRILL_categories: true,
    GUIDED_TRAINING_categories: true,
    lesson_list_data: true,
    lesson_data: true,
    programs_data: true,
    // current_lesson_data: true,
  },
  error: {
    DRILL_trainer_videos: null,
    GUIDED_TRAINING_trainer_videos: null,
    DRILL_user_video_ids: null,
    GUIDED_TRAINING_user_video_ids: null,
    DRILL_categories: null,
    GUIDED_TRAINING_categories: null,
    lesson_list_data: null,
    lesson_data: null,
    programs_data: null,
    // current_lesson_data: null,
  },
};
// const initialLessonState = {};

export function StateProvider({ children }) {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const trainerKeysAndEndpoints = [
    {
      key: "DRILL_trainer_videos",
      endpoint: "trainer/video/getAllVideosByType",
    },
    {
      key: "DRILL_categories",
      endpoint: "trainer/category/getAllCategories",
    },
    {
      key: "DRILL_user_video_ids",
      endpoint: "trainer/video/getAllUserSpecificVideoIds",
    },
    {
      key: "GUIDED_TRAINING_trainer_videos",
      endpoint: "trainer/video/getAllVideosByType",
    },
    {
      key: "GUIDED_TRAINING_categories",
      endpoint: "trainer/category/getAllCategories",
    },
    {
      key: "GUIDED_TRAINING_user_video_ids",
      endpoint: "trainer/video/getAllUserSpecificVideoIds",
    },
  ];

  const checkLessonCache = async (lesson_id) => {
    if (!lesson_id) {
      let key = "lesson_data";
      dispatch({ type: "SET_LOADING", payload: { key } });
    }

    if (lesson_id) {
      let key = "lesson_data";
      let localLessonsArray = JSON.parse(localStorage.getItem(key));
      console.log("incheckcache");
      if (!localLessonsArray) {
        await fetchLessonData(key, lesson_id);
      } else {
        const lessonInLocal = localLessonsArray.find(
          (lesson) => lesson.lesson_id === lesson_id
        );

        if (!lessonInLocal) {
          await fetchLessonData(key, lesson_id);
        } else {
          let localCacheTime = lessonInLocal.new_cache_time
            ? lessonInLocal.new_cache_time
            : 0;
          let newTime = new Date().getTime() / 1000;
          let difference = newTime - localCacheTime;
          if (difference > 604800) {
            await fetchLessonData(key, lesson_id);
          } else {
            console.log("incheckcache still");
            dispatch({
              type: "FETCH_SUCCESS",
              payload: { key, data: localLessonsArray },
            });
            console.log("incheckcache still still");
          }
        }
      }
    } else {
      let key = "lesson_list_data";
      let localData = JSON.parse(localStorage.getItem(key));
      if (!localData) {
        await fetchData(key, "lesson/lesson/getLessonList");
      } else {
        let localCacheTime = localData.new_cache_time
          ? localData.new_cache_time
          : 0;
        let newTime = new Date().getTime() / 1000;
        let difference = newTime - localCacheTime;
        if (difference > 604800) {
          await fetchData(key, "lesson/lesson/getLessonList");
        } else {
          dispatch({
            type: "FETCH_SUCCESS",
            payload: { key, data: localData },
          });
        }
      }
    }
  };

  const fetchLessonData = async (key, id) => {
    // dispatch({ type: "SET_LOADING", payload: { key } });
    try {
      const result = await fetchLessonDataAndStore(key, id);
      // console.log(result);
      dispatch({
        type: "FETCH_SUCCESS",
        payload: { key, data: result },
      });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: { key, error: error.message } });
    }
  };
  const fetchData = async (key, endpoint) => {
    dispatch({ type: "SET_LOADING", payload: { key } });
    try {
      // Wait for fetchDataAndStore to complete
      const result = await fetchDataAndStore(key, endpoint);
      // setData(result);
      // console.log("FETCH RESULT", result);
      dispatch({
        type: "FETCH_SUCCESS",
        payload: { key, data: result },
      });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: { key, error: error.message } });
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const fetchLessonDataAndStore = async (key, id) => {
    // console.log(id);
    try {
      const [lesson, user] = await Promise.all([
        HandyGolfAPI.sendRequest("lesson/lesson/getLessonData", {
          lesson_id: id,
        }),
        HandyGolfAPI.sendRequest("lesson/lesson/getAllUserData", {
          lesson_id: id,
        }),
      ]);

      if (!lesson || !user) {
        throw new Error("response was not ok");
      }

      const data1 = await lesson;
      const data2 = await user;
      const lessonObject = {
        lesson_id: id,
        new_cache_time: data1.new_cache_time,
        lesson_data: data1.lesson_data[0],
        user_data: data2,
      };
      let localLessonsArray = JSON.parse(localStorage.getItem(key));
      // console.log("THIS ISNT WORKING:", localLessonsArray);
      if (!localLessonsArray) {
        let tempArr = [];
        tempArr.push(lessonObject);
        localStorage.setItem(key, JSON.stringify(tempArr));
        //XXXXXX
        return tempArr;
      } else {
        // let tempArr = localLessonsArray;

        const lessonInLocal = localLessonsArray.find(
          (lesson) => lesson.lesson_id === id
        );
        if (!lessonInLocal) {
          let tempArr = localLessonsArray;
          tempArr.push(lessonObject);

          // console.log(tempArr);
          localStorage.setItem(key, JSON.stringify(tempArr));
          return tempArr;
        }

        return localLessonsArray;
        // console.log("lesson already exists:", lessonInLocal);
      }

      // return lessonObject;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      throw error; // Rethrow the error after logging it
    }
  };

  const checkProgramsCache = async () => {
    let key = "programs_data";
    let localProgramsObject = JSON.parse(localStorage.getItem(key));
    if (!localProgramsObject) {
      await fetchProgramsData(key);
    } else {
      let localCacheTime = localProgramsObject.new_cache_time
        ? localProgramsObject.new_cache_time
        : 0;
      let newTime = new Date().getTime() / 1000;
      let difference = newTime - localCacheTime;
      console.log(difference);
      if (difference > 604800) {
        await fetchProgramsData(key);
      } else {
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { key, data: localProgramsObject },
        });
      }
    }
  };

  const fetchProgramsData = async (key) => {
    try {
      const result = await fetchProgramsDataAndStore(key);
      dispatch({
        type: "FETCH_SUCCESS",
        payload: { key, data: result },
      });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: { key, error: error.message } });
    }
  };

  const fetchProgramsDataAndStore = async (key) => {
    try {
      const [programs, userPrograms, coaches] = await Promise.all([
        HandyGolfAPI.sendRequest("trainer/program/getAllPrograms"),
        HandyGolfAPI.sendRequest("trainer/program/getUserPrograms"),
        HandyGolfAPI.sendRequest("coach/getAllCoaches"),
      ]);
      if (!programs || !userPrograms || !coaches) {
        throw new Error("response was not ok");
      }
      const data1 = await programs.trainer_programs;
      const data2 = await userPrograms.user_programs;
      const data3 = await coaches.coaches;
      const time = await programs.new_cache_time;

      const programsObject = {
        programs: data1,
        user: data2,
        coaches: data3,
        new_cache_time: time,
      };
      // const programsObject = {
      //   programs: data1.trainer_programs,
      //   new_cache_time: data1.new_cache.time,
      //   user: data2.user_programs,
      //   coaches: data3.coaches,
      // };

      // let localProgramsObject = JSON.parse(localStorage.getItem(key))
      localStorage.setItem(key, JSON.stringify(programsObject));
      return programsObject;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      throw error; // Rethrow the error after logging it
    }
  };
  const checkCache = async () => {
    for (let i = 0; i < trainerKeysAndEndpoints.length; i++) {
      let key = trainerKeysAndEndpoints[i].key;
      let endpoint = trainerKeysAndEndpoints[i].endpoint;
      let localData = JSON.parse(localStorage.getItem(key));

      if (!localData) {
        await fetchData(key, endpoint);
      } else {
        let localCacheTime = localData.new_cache_time
          ? localData.new_cache_time
          : 0;
        let newTime = new Date().getTime() / 1000;
        let difference = newTime - localCacheTime;
        if (difference > 28800) {
          await fetchData(key, endpoint);
        } else {
          dispatch({ type: "SET_LOADING", payload: { key } });
          dispatch({
            type: "FETCH_SUCCESS",
            payload: { key, data: localData },
          });
        }
      }
    }
  };

  const fetchDataAndStore = async (key, endpoint) => {
    try {
      if (key.substring(0, 1) === "D" || key.substring(0, 1) === "G") {
        // console.log("before await in checkcache");
        const response = await HandyGolfAPI.sendRequest(endpoint, {
          type: key.substring(0, 1) === "D" ? "DRILL" : "GUIDED_TRAINING",
        });
        // console.log(response);
        if (!response) {
          throw new Error("network response was not ok");
        }
        const data = await response;
        localStorage.setItem(key, JSON.stringify(data));
        // console.log(
        //   "fetched API & stored in localStorage as:",
        //   key,
        //   ":",
        //   data
        // );
        return data;
      } else {
        // console.log("insidethe lesson one");
        // console.log("before await in checkcache");
        const response = await HandyGolfAPI.sendRequest(endpoint);
        // console.log(response);
        if (!response) {
          throw new Error("network response was not ok");
        }
        const data = await response;
        localStorage.setItem(key, JSON.stringify(data));
        // console.log(
        //   "fetched API & stored in localStorage as:",
        //   key,
        //   ":",
        //   data
        // );
        return data;
      }
    } catch (error) {
      console.error("Failed to fetch data from API:", error);
      throw error;
    }
    // } else {
    //   return localData;
    // }
  };

  // const currentLessonHandler = (current_lesson_unit_id) => {
  //   if (!current_lesson_unit_id) {
  //   }
  // };
  //memoize might not make a difference because of how your set state atm
  const memoizedState = useMemo(() => state, [state]);
  const memoizedDispatch = useMemo(() => dispatch, []);
  const memoizedCheckCache = useMemo(() => checkCache, []);
  // const memoizedCheckLessonCache = useMemo(() => checkLessonCache, []);

  return (
    <StateContext.Provider value={memoizedState}>
      <StateDispatchContext.Provider value={memoizedDispatch}>
        <CheckCacheContext.Provider value={memoizedCheckCache}>
          <CheckLessonCacheContext.Provider value={checkLessonCache}>
            <CheckProgramsCacheContext.Provider value={checkProgramsCache}>
              {children}
            </CheckProgramsCacheContext.Provider>
          </CheckLessonCacheContext.Provider>
        </CheckCacheContext.Provider>
      </StateDispatchContext.Provider>
    </StateContext.Provider>
  );
}

const stateReducer = (state, action) => {
  // const { type, payload } = action;
  switch (action.type) {
    case "FETCH_SUCCESS":
      // console.log(action);
      return {
        ...state,
        [action.payload.key]: action.payload.data,
        loading: { ...state.loading, [action.payload.key]: false },
        error: { ...state.error, [action.payload.key]: null },
      };
    case "SET_CURRENT_LESSON_DATA":
      return {
        ...state,
        [action.payload.key]: action.payload.data,
        loading: { ...state.loading, [action.payload.key]: false },
        error: { ...state.error, [action.payload.key]: null },
      };
    case "FETCH_ERROR":
      return {
        ...state,
        DRILL_trainer_videos: null,
        loading: false,
        error: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: { ...state.loading, [action.payload.key]: true },
      };
    // default: {
    //   throw Error("Unknown action: " + type);
    // }
    default:
      console.log("youre in the default");
      return state;
  }
};
