import React from "react";

// CSS
import "../LessonsPage.css";

// Libraries
import HandyGolfAPI from "../../lib/HandyGolfAPI";

// Components
import LessonMgmt from "../../components/LessonMgmt";
import PageMetaData from "../../components/PageMetaData";
import { LessonDisplay } from "./LessonDisplay";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Rating from "@mui/material/Rating";
import StickyNote2SharpIcon from "@mui/icons-material/StickyNote2Sharp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Video Library
import ReactPlayer from "react-player";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

// Other
import nl2br from "react-nl2br";

function LessonDetailsPage() {
  const { lesson_id = 0 } = useParams();
  return (
    <>
      <PageMetaData title="Lesson Details" seo="keywords for seo stuff" />
      <div className="green_body">
        <div className="body_content">
          <LessonDisplay lesson_id={lesson_id} />
        </div>
      </div>
    </>
  );
}

export default LessonDetailsPage;
