import React, { useEffect, useState, useContext } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  AppTopBar,
  BottomNav,
  ProgramSlider,
  Button,
} from "../../components";
import {
  StateContext,
  StateDispatchContext,
  CheckProgramsCacheContext,
} from "../../context/AppContext";

export function Programs() {
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkProgramsCache = useContext(CheckProgramsCacheContext);

  const [categorySelected, setCategorySelected] = useState();
  // const [categories, setCategories] = useState();
  const [childCategories, setChildCategories] = useState();

  //   const loadPrograms = () => {
  //     HandyGolfAPI.sendRequest("trainer/program/getAllPrograms")
  //       .then((data) => {
  //         console.log("NEW_SUCCESS:");
  //         console.log(data);
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   };

  useEffect(() => {
    //   loadPrograms();
    checkProgramsCache();
  }, [
    // searchResults, categorySelected, typeString
    state.loading.programs_data,
  ]);
  const isLoading = state.loading.programs_data;
  // state.loading.DRILL_trainer_videos ||
  // state.loading.DRILL_user_video_ids ||
  // state.loading.DRILL_categories ||
  // state.loading.GUIDED_TRAINING_trainer_videos ||
  // state.loading.GUIDED_TRAINING_user_video_ids ||
  // state.loading.GUIDED_TRAINING_categories;
  // console.log(state.loading);
  if (isLoading) {
    return <>loading.....</>;
  }
  let categories = [
    { name: "fixes" },
    { name: "swing" },
    { name: "fixes" },
    { name: "swing" },
    { name: "fixes" },
    { name: "swing" },
  ];
  let coaches = state.programs_data.coaches;
  let programs = state.programs_data.programs;

  return (
    <>
      <AppTopBar
        search
        filter
        breadcrumb
        // handleSearchChange={handleSearchChange}
        // onSearchSubmit={onSearchSubmit}
      />
      <div className="container">
        <div className="container__content">
          <div className="pad-box scroll-content-mobile">
            {categories && (
              <div className="button-slider">
                {categories.map((category, idx) => {
                  return (
                    <Button
                      key={idx}
                      primary
                      style={
                        categorySelected !== idx
                          ? {
                              background: "transparent",
                              border: "1px solid grey",
                            }
                          : {}
                      }
                      // onClick={() => {
                      //   if (idx === categorySelected) {
                      //     setCategorySelected();
                      //   } else {
                      //     setCategorySelected(idx);

                      //     filterUpdate(idx, category.child_categories);
                      //   }
                      // }}
                    >
                      {category.name}
                    </Button>
                  );
                })}
              </div>
            )}
            {coaches &&
              coaches.map((coach, idx) => {
                return (
                  <div className="results-slider" key={idx}>
                    <strong>
                      <p>{coach.name}</p>
                    </strong>
                    <ProgramSlider programs={programs} />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="container__collapse"></div>
      </div>
      <BottomNav />
    </>
  );
}
