import * as React from "react";
import { useState } from "react";

// CSS
import "../../../../LessonsPage.css";

// Libraries
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StickyNote2SharpIcon from "@mui/icons-material/StickyNote2Sharp";

export function UnitSection({
  lesson,
  expanded_lesson_unit,
  user_lesson_data,
  current_lesson_unit,
  setExpandedLessonUnit,
  loadLessonUnitData,
}) {
  //   const [current_lesson_unit, setCurrentLessonUnit] = useState({});
  //   const [expanded_lesson_unit, setExpandedLessonUnit] = useState();
  console.log(lesson);
  return (
    <div className="lesson_units">
      <br />
      {lesson.lesson_units.map((lesson_unit) => {
        return (
          <div className="lesson_unit" key={lesson_unit.lesson_unit_id}>
            <Accordion
              key={lesson_unit.lesson_unit_id}
              expanded={expanded_lesson_unit === "panel" + lesson_unit.number}
              className={
                parseInt(
                  user_lesson_data &&
                    user_lesson_data.unit_completion_totals &&
                    user_lesson_data.unit_completion_totals[
                      lesson_unit.lesson_unit_id
                    ]
                    ? user_lesson_data.unit_completion_totals[
                        lesson_unit.lesson_unit_id
                      ].percent_complete
                    : 0
                ) === 100
                  ? "completed_unit"
                  : "partial"
              }
              onChange={(event, newExpanded) => {
                setExpandedLessonUnit(
                  newExpanded ? "panel" + lesson_unit.number : ""
                );
              }}
            >
              <AccordionSummary
                key={lesson_unit.lesson_unit_id}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel" + lesson_unit.number + "-content"}
                id={"panel" + lesson_unit.number + "-header"}
              >
                {parseInt(
                  user_lesson_data &&
                    user_lesson_data.unit_completion_totals &&
                    user_lesson_data.unit_completion_totals[
                      lesson_unit.lesson_unit_id
                    ]
                    ? user_lesson_data.unit_completion_totals[
                        lesson_unit.lesson_unit_id
                      ].percent_complete
                    : 0
                ) === 100 && (
                  <>
                    <CheckIcon className="completed_check" />{" "}
                    {lesson_unit.number}. {lesson_unit.name}
                  </>
                )}
                {parseInt(
                  user_lesson_data &&
                    user_lesson_data.unit_completion_totals &&
                    user_lesson_data.unit_completion_totals[
                      lesson_unit.lesson_unit_id
                    ]
                    ? user_lesson_data.unit_completion_totals[
                        lesson_unit.lesson_unit_id
                      ].percent_complete
                    : 0
                ) < 100 &&
                  parseInt(
                    user_lesson_data &&
                      user_lesson_data.unit_completion_totals &&
                      user_lesson_data.unit_completion_totals[
                        lesson_unit.lesson_unit_id
                      ]
                      ? user_lesson_data.unit_completion_totals[
                          lesson_unit.lesson_unit_id
                        ].percent_complete
                      : 0
                  ) > 0 && (
                    <>
                      {lesson_unit.number}. {lesson_unit.name}
                      <div
                        className="unit_progress_border_complete"
                        style={{
                          width:
                            user_lesson_data.unit_completion_totals[
                              lesson_unit.lesson_unit_id
                            ].percent_complete + "%",
                        }}
                      ></div>
                    </>
                  )}
                {parseInt(
                  user_lesson_data &&
                    user_lesson_data.unit_completion_totals &&
                    user_lesson_data.unit_completion_totals[
                      lesson_unit.lesson_unit_id
                    ]
                    ? user_lesson_data.unit_completion_totals[
                        lesson_unit.lesson_unit_id
                      ].percent_complete
                    : 0
                ) === 0 && (
                  <>
                    {lesson_unit.number}. {lesson_unit.name}
                  </>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {lesson_unit.sub_units.map((sub_unit, idx) => {
                  return (
                    <div className="lesson_unit_selection" key={idx}>
                      <a
                        href="#"
                        className={
                          "lesson_unit_selection" +
                          (sub_unit.lesson_unit_id ===
                          current_lesson_unit.lesson_unit_id
                            ? " active"
                            : "")
                        }
                        onClick={() => {
                          loadLessonUnitData(sub_unit.lesson_unit_id);
                        }}
                      >
                        {sub_unit.number}: {sub_unit.name}
                      </a>
                      {user_lesson_data.sub_unit_notes_and_completion[
                        sub_unit.lesson_unit_id
                      ].note_count > 0 ? (
                        <>
                          <div
                            style={{
                              textAlign: "right",
                              marginRight: "2px",
                            }}
                          >
                            {
                              user_lesson_data.sub_unit_notes_and_completion[
                                sub_unit.lesson_unit_id
                              ].note_count
                            }
                          </div>
                          <div>
                            <StickyNote2SharpIcon className="lesson_unit_notes has_notes" />
                          </div>
                        </>
                      ) : (
                        <div>
                          <StickyNote2SharpIcon className="lesson_unit_notes" />
                        </div>
                      )}
                      <div>
                        <Checkbox
                          size="small"
                          className="dark_pastel_green"
                          checked={
                            user_lesson_data &&
                            user_lesson_data.sub_unit_notes_and_completion &&
                            user_lesson_data.sub_unit_notes_and_completion[
                              sub_unit.lesson_unit_id
                            ] &&
                            user_lesson_data.sub_unit_notes_and_completion[
                              sub_unit.lesson_unit_id
                            ].unit_completed
                              ? true
                              : false
                          }
                          onClick={(event) => {
                            HandyGolfAPI.sendRequest(
                              "lesson/" +
                                (event.target.checked
                                  ? "markLessonUnitAsComplete"
                                  : "markLessonUnitAsIncomplete"),
                              {
                                lesson_id: lesson.lesson_id,
                                lesson_unit_id: sub_unit.lesson_unit_id,
                              }
                            ).then((data) => {
                              loadLessonUnitData(
                                current_lesson_unit.lesson_unit_id
                              );
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}
