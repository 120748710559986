import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";
// MUI

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

export function DeleteTagConfirm({ trainer_tag_id }) {
  const [loaded, setLoaded] = useState(false);
  const [tag_details, setTagDetails] = useState([]);
  const [deleted, setDeleted] = useState(false);
  useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("trainer/tag/find", {
      trainer_tag_id: trainer_tag_id,
    }).then((data) => {
      if (mounted) {
        setTagDetails(data);
      }
      setLoaded(true);
    });
    return () => {
      mounted = false;
    };
  }, [trainer_tag_id]);

  function handleDeletion() {
    HandyGolfAPI.sendRequest("trainer/tag/delete", {
      trainer_tag_id: trainer_tag_id,
    }).then((data) => {
      console.log("SUCCESS:");
      console.log(data);
      setDeleted(true);
    });
  }
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Tag Management
      </Typography>

      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Delete Tag" />
        {!loaded && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {loaded && (
          <>
            {!deleted && (
              <>
                <CardContent>
                  <strong>Are you sure you want to delete</strong>{" "}
                  <i>{tag_details.name}</i>
                  <strong> tag?</strong>
                  <br />
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Link to={"/admin/swing_trainer/tag_mgmt"}>
                    <Button variant="contained" className="blue">
                      Go Back
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    className="blue"
                    onClick={handleDeletion}
                  >
                    Delete
                  </Button>

                  <br />
                </CardActions>
              </>
            )}

            {deleted && (
              <>
                <CardContent>
                  <strong>Tag has been deleted.</strong> <br />
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Link to={"/admin/swing_trainer/tag_mgmt"}>
                    <Button variant="contained" className="blue">
                      Go Back
                    </Button>
                  </Link>

                  <br />
                </CardActions>
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
}
