import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";
// MUI

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

export function DeleteVideoConfirm({ trainer_video_id }) {
  const [loaded, setLoaded] = useState(false);
  const [video_details, setVideoDetails] = useState([]);
  const [deleted, setDeleted] = useState(false);
  useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("trainer/getVideoDetails", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      if (mounted) {
        setVideoDetails(data);
      }
      setLoaded(true);
    });
    return () => {
      mounted = false;
    };
  }, [trainer_video_id]);

  function handleDeletion() {
    HandyGolfAPI.sendRequest("trainer/deleteVideo", {
      trainer_video_id: trainer_video_id,
    }).then((data) => {
      console.log("SUCCESS:");
      console.log(data);
      setDeleted(true);
    });
  }
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Video Management
      </Typography>

      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Delete Video" />
        {!loaded && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {loaded && (
          <>
            {!deleted && (
              <>
                <CardContent>
                  <strong>Are you sure you want to delete</strong>{" "}
                  <i>{video_details.name}</i>
                  <strong> video?</strong>
                  <br />
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Link to={"/admin/swing_trainer/video_mgmt"}>
                    <Button variant="contained" className="blue">
                      Go Back
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    className="blue"
                    onClick={handleDeletion}
                  >
                    Delete
                  </Button>

                  <br />
                </CardActions>
              </>
            )}

            {deleted && (
              <>
                <CardContent>
                  <strong>Video has been deleted.</strong> <br />
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Link to={"/admin/swing_trainer/video_mgmt"}>
                    <Button variant="contained" className="blue">
                      Go Back
                    </Button>
                  </Link>

                  <br />
                </CardActions>
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
}
