import {
  Button,
  Input,
  TextField,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import HandyGolfAPI from "../../../../../../lib/HandyGolfAPI";

//mui
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

export function VideoListSearch({ setSearchResults, type }) {
  const [inputValue, setInputValue] = useState("");
  const [filters, setFilters] = useState([]);
  const [tags, setTags] = useState();
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    HandyGolfAPI.sendRequest("trainer/getTags").then((data) => {
      // console.log(data);
      setTags(data);
    });
  }, []);

  const searchVideos = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/searchVideos", {
      type: type,
      search: inputValue,
      trainer_tag_ids: filters,
    }).then((data) => {
      console.log("SEARCHRESULTS:", data);
      setSearchResults(data);
    });
  };

  const changeFilters = (e) => {
    if (e.target.checked) {
      setFilters([...filters, e.target.value]);
    } else {
      setFilters(filters.filter((element) => e.target.value !== element));
    }

    // console.log(filters);
  };

  const changeSearch = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="search">
      <form onSubmit={searchVideos} style={{ display: "flex" }}>
        <input value={inputValue} onChange={changeSearch} />

        <button>search</button>
        <MenuIcon
          sx={{ color: "#0071bd", fontSize: 30 }}
          onClick={() => {
            setFilterOpen(!filterOpen);
          }}
        />
        {filterOpen ? (
          <>
            {/* <select name="filters"> */}
            {tags.map((tag, idx) => {
              return (
                <div key={idx}>
                  <input
                    type="checkbox"
                    key={idx}
                    value={tag.trainer_tag_id}
                    onChange={changeFilters}
                  />
                  <label>{tag.name}</label>
                </div>
              );
            })}
            {/* <option value="1">wrist</option>
            <option value="2">backswing</option> */}
            {/* </select> */}
          </>
        ) : (
          <></>
        )}
      </form>

      <br></br>
      {/* <Chip label="Wrist" onDelete={handleDelete} />
      <Chip label="Chipping" onDelete={handleDelete} /> */}
    </div>
  );
}
