import React, { useContext, useState, useEffect } from "react";
import HandyGolfAuth from "../../lib/HandyGolfAuth";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}
export function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [isLoadingUser, setIsLoadingUser] = useState();
  const [adminMode, setAdminMode] = useState(false);

  useEffect(() => {
    setIsLoadingUser(true);
    HandyGolfAuth.appCallback = () => {
      setContextUser();
    };
    setContextUser();
  }, []);
  function setContextUser() {
    HandyGolfAuth.getAuthenticatedUser().then((data) => {
      setUser(data);
      setIsLoadingUser(false);
    });
  }
  function enterAdminMode() {
    if (user.is_admin) {
      setAdminMode((prev) => !prev);
    }
  }
  return (
    <UserContext.Provider
      value={[user, setContextUser, isLoadingUser, adminMode, enterAdminMode]}
    >
      {children}
    </UserContext.Provider>
  );
}
