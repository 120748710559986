import * as React from "react";
import { Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import AdminPage from "../pages/AdminPage";
import AdminLessonMgmtPage from "../pages/AdminLessonMgmtPage";
import AdminSwingTrainerCategoryMgmtPage from "../pages/AdminSwingTrainerCategoryMgmtPage";
import ContactPage from "../pages/ContactPage";
import LessonsPage from "../pages/LessonsPage";
import DevDebuggerPage from "../pages/DevDebuggerPage";
import MySQLQueryRunnerPage from "../pages/MySQLQueryRunnerPage";
import DevStyleGuidePage from "../pages/DevStyleGuidePage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import StorePage from "../pages/StorePage";
import SupportPage from "../pages/SupportPage";
import SwingAnalyzerPage from "../pages/SwingAnalyzerPage";
import SwingDoctorPage from "../pages/SwingDoctorPage";
import { SwingTrainerPage } from "../pages/swing_trainer_page";
import { SwingTrainerVideoPage } from "../pages/swing_trainer_page";
import TermsOfServicePage from "../pages/TermsOfServicePage";
import ToolsPage from "../pages/ToolsPage";
import UserProfilePage from "../pages/UserProfilePage";
import UserSignUpPage from "../pages/UserSignUpPage";
import LessonDetailsPage from "../pages/lesson_details_page/LessonDetailsPage";

//import page elements

export const unAuthedRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/admin",
    element: <AdminPage />,
  },
  {
    path: "/admin/lesson_mgmt/:action_param?/:id_param?",
    element: <AdminLessonMgmtPage />,
  },
  {
    path: "/admin/swing_trainer/:category_mgmt/:action_param?/:id_param?",
    element: <AdminSwingTrainerCategoryMgmtPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/lesson_details/:lesson_id?",
    element: <LessonDetailsPage />,
  },
  {
    path: "/lessons",
    element: <LessonsPage />,
  },
  {
    path: "/dev/debug",
    element: <DevDebuggerPage />,
  },
  {
    path: "/dev/mysql",
    element: <MySQLQueryRunnerPage />,
  },
  {
    path: "/dev/style_guide",
    element: <DevStyleGuidePage />,
  },
  {
    path: "/privacy_policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/store",
    element: <StorePage />,
  },
  {
    path: "/support",
    element: <SupportPage />,
  },
  {
    path: "/swing_analyzer",
    element: <SwingAnalyzerPage />,
  },
  {
    path: "/swing_doctor",
    element: <SwingDoctorPage />,
  },
  {
    path: "/swing_trainer",
    element: <SwingTrainerPage />,
  },
  {
    path: "/swing_trainer/:trainer_video_id?",
    element: <SwingTrainerVideoPage />,
  },
  {
    path: "/terms_of_service",
    element: <TermsOfServicePage />,
  },
  {
    path: "/tools",
    element: <ToolsPage />,
  },
  {
    path: "/user/profile",
    element: <UserProfilePage />,
  },
  {
    path: "/user/profile",
    element: <UserProfilePage />,
  },
  {
    path: "/user/sign_up",
    element: <UserSignUpPage />,
  },
];
