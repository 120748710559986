import React, { useState } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  SkipNext,
  VolumeUp,
  VolumeOff,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import Popover from "@mui/material/Popover";
import { Button } from "../shared";

export const Controls = ({
  controlRef,
  onPlayPause,
  playing,
  time,
  muted,
  volume,
  onMute,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  onSeek,
  played,
  currentTime,
  duration,
  toggleFullScreen,
  popoverOpen,
  togglePopover,
  toggleMirrorVideo,
  mirror,
  loadLessonUnitData,
  dispatchHandler,
  // currentSubUnit,
  // lesson,
  // userLessonData,
  isTrainer,
  setCompareMode,
  // prevSub,
  // nextSub,
  lessonDetails,
  updateLessonDetails,
}) => {
  // const {
  //   lesson,
  //   // completedSubUnits,
  //   numUnits,
  //   // sub,
  //   subNotes,
  //   unitCompletion,
  //   unitNotes,
  //   // prevSub,
  //   // nextSub,
  // } = lessonDetails;
  let completedSubUnits = lessonDetails
    ? lessonDetails.completedSubUnits
    : null;
  let sub = lessonDetails ? lessonDetails.sub : null;
  let prevSub = lessonDetails ? lessonDetails.prevSub : null;
  let nextSub = lessonDetails ? lessonDetails.nextSub : null;
  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };
  // console.log(currentSubUnit.lesson_unit_id);
  // console.log(
  //   userLessonData.sub_unit_notes_and_completion[
  //     currentSubUnit.lesson_unit_id
  //   ].unit_completed
  // );
  // console.log(completedSubUnits);
  return (
    <>
      <div
        ref={controlRef}
        //   onMouseLeave={setPopoverOpen(false)}
      >
        {/* <div></div> */}
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            height: "100%",
            width: "100%",
            left: "0",
            bottom: "0",
          }}
          onClick={onPlayPause}
        ></div>
        {isTrainer && (
          <div className="video-controls__compare-button">
            <Button
              primary
              iconLeading="compare"
              onClick={() => {
                setCompareMode((prev) => !prev);
              }}
            >
              Compare
            </Button>
          </div>
        )}
        {!isTrainer && (
          <div
            className={
              popoverOpen
                ? "video-controls__popover"
                : "video-controls__popover video-controls__popover--closed"
            }
          >
            {!completedSubUnits.includes(sub.lesson_unit_id) ? (
              <div
                className="video-controls__popover__section video-controls__popover__section--top"
                // "markLessonUnitAsIncomplete"
                onClick={() => {
                  HandyGolfAPI.sendRequest("lesson/lesson_unit/markAsWatched", {
                    lesson_unit_id: sub.lesson_unit_id,
                  }).then((data) => {
                    // console.log(data);
                    dispatchHandler(data);
                  });
                }}
              >
                <>Mark as Watched</>
                <i
                  className="material-icons"
                  style={{ color: "#010D15", opacity: ".2" }}
                >
                  check
                </i>
              </div>
            ) : (
              <div
                className="video-controls__popover__section video-controls__popover__section--top"
                // "markLessonUnitAsIncomplete"
                onClick={() => {
                  HandyGolfAPI.sendRequest(
                    "lesson/lesson_unit/markAsNotWatched",
                    {
                      lesson_unit_id: sub.lesson_unit_id,
                    }
                  ).then((data) => {
                    // loadLessonUnitData(sub.lesson_unit_id, data);
                    dispatchHandler(data);
                  });
                }}
              >
                <>Mark as Not Watched</>
                <i className="material-icons">check</i>
              </div>
            )}

            <div
              className="video-controls__popover__section video-controls__popover__section--bottom"
              onClick={() => {
                toggleMirrorVideo();
              }}
            >
              <>Flip Video</>
              <img
                className={
                  mirror
                    ? "video-controls__flip-icon video-controls__flip-icon--flipped"
                    : "video-controls__flip-icon"
                }
                src={
                  mirror
                    ? "/img/flip_vertical_icon_gold.svg"
                    : "/img/flip_vertical_icon.svg"
                }
              />
            </div>
          </div>
        )}

        <div className="video-controls__slider">
          <Slider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeek}
            sx={{
              color: "#fff",
              height: 4,
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
                transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                "&::before": {
                  boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
                },
                "&.Mui-active": {
                  width: 20,
                  height: 20,
                },
              },
              "& .MuiSlider-rail": {
                color: "black",
                opacity: 0.6,
              },
            }}
          />

          <div className="video-controls__bottom">
            <div className="video-controls__bottom__left">
              {!isTrainer && (
                <span
                  className="control-icon"
                  onClick={(e) => {
                    updateLessonDetails(prevSub);
                  }}
                >
                  <img src="/img/skip_backward_fill_icon.svg" />
                </span>
              )}

              <span className="control-icon" onClick={onPlayPause}>
                {playing ? (
                  <img src="/img/pause-icon.svg" />
                ) : (
                  <img src="/img/play-arrow-1.svg" />
                )}
              </span>
              {!isTrainer && (
                <span
                  className="control-icon"
                  onClick={(e) => {
                    updateLessonDetails(nextSub);
                  }}
                >
                  <img src="/img/skip_forward_fill_icon.svg" />
                </span>
              )}

              <span className="control-icon" onClick={onMute}>
                {muted ? (
                  <img src="/img/volume_mute_icon.svg" />
                ) : (
                  <img src="/img/volume_up_icon.svg" />
                )}
              </span>

              <Slider
                onChange={onVolumeChangeHandler}
                value={volume * 100}
                onChangeCommitted={onVolumeSeekUp}
                //   value={played * 100}
                // onChange={onSeek}
                sx={{
                  color: "#fff",
                  height: 4,
                  width: 75,
                  "& .MuiSlider-thumb": {
                    width: 16,
                    height: 16,
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&::before": {
                      boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
                    },
                    "&.Mui-active": {
                      width: 20,
                      height: 20,
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
              />

              <span style={{ alignItems: "center" }}>
                {convertSecondsToTimeText(Math.floor(currentTime))}/
                {convertSecondsToTimeText(Math.floor(duration))}
              </span>
            </div>
            <div className="video-controls__bottom__right">
              {!isTrainer && (
                <span className="control-icon">
                  <img
                    src="/img/expand_circle_dots_icon.svg"
                    onClick={togglePopover}
                  />
                </span>
              )}

              <span className="control-icon">
                <img src="/img/cast_icon.svg" />
              </span>

              <span className="control-icon" onClick={toggleFullScreen}>
                <img src="/img/fullscreen.svg" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
