import React, { useEffect, useState, useRef } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AdminTable,
  Uploader,
  setFormDefaults,
  AdminContainer,
  VideoDragDrop,
} from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";
import { useUpload } from "../context/UploadContext";
import { Player } from "../components";
import ReactPlayer from "react-player";

const initialVideoForm = { name: "", description: "", type: 1 };
const initialPracticeVideoForm = { view: "" };
export function AdminVideos() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const { isVerified, verifyAdmin } = useUpload();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    // child,
    // grand_child,
    id_param: parent_id,
    // id_param2: child_id,
    // id_param3: grandchild_id,
  } = useParams();

  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();

  const [changingCats, setChangingCats] = useState();
  const [hasChangedCats, setHasChangedCats] = useState(false);
  const [changingEquip, setChangingEquip] = useState();
  const [hasChangedEquip, setHasChangedEquip] = useState(false);
  const [viewingVid, setViewingVid] = useState(false);
  //uploading states
  const [uploadingThumb, setUploadingThumb] = useState();
  const [uploadingVideo, setUploadingVideo] = useState();
  const [uploadingPracticeVideo, setUploadingPracticeVideo] = useState();
  const [changingPractice, setChangingPractice] = useState();
  //specifically for updating existing practiceVideo
  const [updatingPracticeFields, setUpdatingPracticeFields] = useState();
  const [updatingPracticeVideo, setUpdatingPracticeVideo] = useState();

  //videos
  const [videoList, setVideoList] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [editingVideo, setEditingVideo] = useState();
  //categories
  const [allCategories, setAllCategories] = useState();
  const [childCategories, setChildCategories] = useState();
  const [parentCategories, setParentCategories] = useState();
  // const [videoCategories, setVideoCategories] = useState();
  const [originalCatArray, setOriginalCatArray] = useState([]);
  const [categoryIdArray, setCategoryIdArray] = useState([]);
  //equipment
  const [allEquipment, setAllEquipment] = useState();
  const [equipmentIdArray, setEquipmentIdArray] = useState([]);
  const [originalEquipIdArray, setOriginalEquipIdArray] = useState([]);
  //practice videos
  const [practiceVideos, setPracticeVideos] = useState([]);
  const [selectedPractice, setSelectedPractice] = useState();
  //videoUrl
  const [videoUrl, setVideoUrl] = useState();
  //forms
  const [viewOtherSelected, setViewOtherSelected] = useState(false);
  const [videoForm, setVideoForm] = useState(initialVideoForm);
  const [practiceVideoForm, setPracticeVideoForm] = useState(
    initialPracticeVideoForm
  );
  //confusing but videoFileForm is for the actual video, the videoForm is for the drill/guidedTraining
  const [videoFileForm, setVideoFileForm] = useState();
  const [selectedField, setSelectedField] = useState();
  //search fields
  const [searchString, setSearchString] = useState("");
  const [searchStringArray, setSearchStringArray] = useState([]);
  const [searchFilterVideos, setSearchFilterVideos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  //videostuff
  const [video_time, setVideoTime] = useState(0);
  const videoRef = useRef(null);

  const updateURL = (newPath) => {
    const currentPath = location.pathname;
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath, { replace: true });
    }
  };

  const getVideos = () => {
    HandyGolfAPI.sendRequest("trainer/video/list")
      .then((data) => {
        // console.log(data);
        setVideoList(data);
        setSearchResults(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCategories = () => {
    HandyGolfAPI.sendRequest("trainer/category/list")
      .then((data) => {
        let parentCats = data.filter(
          (cat) => cat.parent_trainer_category_id === 0
        );
        let childCats = data.filter(
          (cat) => cat.parent_trainer_category_id !== 0
        );
        setChildCategories(childCats);
        setParentCategories(parentCats);
        // setAllCategories(data);
        console.log(data);
        console.log(parentCats);
        console.log(childCats);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCurrentVideoCategories = () => {
    HandyGolfAPI.sendRequest("trainer/video/getCategoryIds", {
      trainer_video_id: selectedVideo.trainer_video_id,
    })
      .then((data) => {
        console.log(data);
        // setVideoCategories(data);
        // let tempArray = [];
        // data.forEach((cat) => tempArray.push(cat.trainer_category_id));
        setOriginalCatArray(data.trainer_category_ids); //this array is just to check if the categoryIdArray has been changed, and allow revert if we undo changes
        setCategoryIdArray(data.trainer_category_ids);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSelectedVideoUrl = () => {
    HandyGolfAPI.sendRequest("video/find", {
      video_id: selectedVideo.video_id,
    }).then((data) => {
      console.log(data);
      setVideoUrl(data.video_url);
    });
  };
  //DRILL ONLY
  const getAllEquipment = () => {
    HandyGolfAPI.sendRequest("trainer/equipment/list")
      .then((data) => {
        setAllEquipment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCurrentPracticeVideos = () => {
    setPracticeVideos([]);
    HandyGolfAPI.sendRequest("trainer/video/getPracticeVideos", {
      trainer_video_id: selectedVideo.trainer_video_id,
    })
      .then((data) => {
        let practices = data.practice_videos;
        Promise.all(
          practices.map((practice) =>
            HandyGolfAPI.sendRequest("video/find", {
              video_id: practice.video_id,
            }).then((data) => {
              return {
                ...practice,
                url: data.video_url || "",
              };
            })
          )
        ).then((videos) => {
          setPracticeVideos(videos);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCurrentVideoEquipment = () => {
    HandyGolfAPI.sendRequest("trainer/video/getEquipmentIds", {
      trainer_video_id: selectedVideo.trainer_video_id,
    })
      .then((data) => {
        console.log(data);
        setEquipmentIdArray(data.trainer_equipment_ids);
        setOriginalEquipIdArray(data.trainer_equipment_ids);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    getAllCategories();
    getAllEquipment();
  }, []);
  useEffect(() => {
    if (!selectedVideo) {
      getVideos();
    } else {
      if (selectedVideo.type === 1) {
        getCurrentVideoEquipment();
        getCurrentPracticeVideos();
      }

      if (selectedVideo.video_id !== 0) {
        getSelectedVideoUrl();
      }
      // setDefaultVideoFileForm()
      getCurrentVideoCategories();
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (videoList) {
      if (parent_id) {
        let currentParent = videoList.find(
          (el) => el.trainer_video_id === parseInt(parent_id)
        );
        console.log(currentParent);
        if (currentParent) {
          setSelectedVideo(currentParent);
          setEditingVideo(true);
        }
      }
    }
  }, [videoList]);

  const searchStringCheck = (title, searchTerm) => {
    let titleSplit = title.split(" ");
    // let allSearchTerms = titleSplit.concat(catNames, equipNames);
    let allSearchTerms = titleSplit;
    for (let i = 0; i < allSearchTerms.length; i++) {
      if (allSearchTerms[i].includes(searchTerm)) {
        return true;
      }
    }
    return false;
  };
  const searchResultSetter = () => {
    // console.log(searchResults);
    console.log(searchString);

    let splitStringArray = searchString.split(" ");
    let properCapsStringArray = splitStringArray.map(
      (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    );

    if (properCapsStringArray.length > 0) {
      properCapsStringArray = properCapsStringArray.filter(
        (term) => term.length > 1
      );
      let mergedArray = [];

      properCapsStringArray.forEach((term) => {
        const singleSearchTermVideoArray = videoList.filter((video) =>
          // video.trainer_category_names.includes(term)
          searchStringCheck(
            video.name,
            // video.trainer_category_names,
            // video.trainer_equipment_names,
            term
          )
        );

        mergedArray = [...mergedArray, ...singleSearchTermVideoArray];
        // setSearchResults(...searchResults, ...singleSearchTermVideoArray);
      });
      const uniqueArrayNoDuplicates = [...new Set(mergedArray)];

      setSearchResults(uniqueArrayNoDuplicates);
    }
  };
  let searchTimer = null;

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
    // if (searchString.length > 1) {

    if (searchTimer == null) {
      searchTimer = setTimeout(() => {
        if (e.target.value === "") {
          setSearchResults(videoList);
        } else {
          searchResultSetter();
        }

        // console.log("searched times");
      }, 300);
    }
  };
  const onSearchSubmit = (e) => {
    e.preventDefault();
    // console.log(searchStringArray)
    clearTimeout(searchTimer);
    if (searchString === "") {
      //   console.log("empty string");
      setSearchResults(videoList);
    } else {
      searchResultSetter();
    }
  };
  const handleVideoFormChange = (e) => {
    setVideoForm({
      ...videoForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleVideoEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/video/update", {
      trainer_video_id: selectedVideo.trainer_video_id,
      trainer_video: videoForm,
    })
      .then((data) => {
        console.log(data);
        setSelectedVideo(data.data);
        setEditing(false);
        setVideoForm(initialVideoForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVideoCreateSubmit = (e) => {
    e.preventDefault();

    HandyGolfAPI.sendRequest("trainer/video/create", {
      trainer_video: videoForm,
    })
      .then((data) => {
        console.log(data);
        let newVideo = data.data;
        let videos = videoList;
        videos.push(newVideo);
        setVideoList(videos);
      })
      .catch((err) => {
        console.log(err);
      });
    setCreating(false);
    setVideoForm(initialVideoForm);
  };

  const handleCategoryChange = (e) => {
    let tempArray = [...categoryIdArray];
    let newId = parseInt(e.target.value);
    const index = tempArray.indexOf(newId);
    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(newId);
    }
    setCategoryIdArray(tempArray);
    if (!hasChangedCats) {
      setHasChangedCats(true);
    }
  };
  const handleEquipmentChange = (e) => {
    let tempArray = [...equipmentIdArray];
    let newId = parseInt(e.target.value);
    const index = tempArray.indexOf(newId);
    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(newId);
    }
    setEquipmentIdArray(tempArray);
    if (!hasChangedEquip) {
      setHasChangedEquip(true);
    }
  };
  const catIdConverter = (id) => {
    // let tempArr = [...childCategories];
    let tempArr = childCategories;
    let convertedCat = tempArr.find((cat) => cat.trainer_category_id === id);
    return convertedCat.name;
  };
  const equipIdConverter = (id) => {
    let tempArr = allEquipment;
    let convertedEquip = tempArr.find(
      (equip) => equip.trainer_equipment_id === id
    );
    return convertedEquip ? convertedEquip.name : "(deleted equipment)";
  };

  const videoTypeConverter = (type) => {
    if (type === 0) {
      return "No Type Selected";
    }
    if (type === 1) {
      return "Drill";
    }
    if (type === 2) {
      return "Guided Training";
    }

    if (type === "No Type Selected") {
      return 0;
    }
    if (type === "Drill") {
      return 1;
    }
    if (type === "Guided Training") {
      return 2;
    }
  };
  console.log("practiceVids", practiceVideos);
  const updatePracticeVideos = (data) => {
    let newVidData = data;
    let tempVids = practiceVideos;
    let oldVidIndex = tempVids.findIndex(
      (vid) => vid.video_id === newVidData.video_id
    );
    let oldVid = tempVids[oldVidIndex];
    let newVid = {
      ...newVidData,
      url: oldVid.url,
    };
    tempVids[oldVidIndex] = newVid;
    setPracticeVideos(tempVids);
    setUpdatingPracticeFields(false);
    setChangingPractice(false);
    setUploadingPracticeVideo(false);
  };
  const addNewPracticeVideo = (data, url) => {
    let newVidData = data;
    let tempVids = practiceVideos;

    let newVid = {
      ...newVidData,
      url: url,
    };
    tempVids.push(newVid);
    setPracticeVideos(tempVids);
    setUpdatingPracticeFields(false);
    setChangingPractice(false);
    setUploadingPracticeVideo(false);
  };

  const handleSelectVideo = (item) => {
    updateURL(`trainer_video/${item.trainer_video_id}`);
    setSelectedVideo(item);
  };
  const removeLastTwoSegments = () => {
    const currentPath = location.pathname;
    const segments = currentPath.split("/");

    if (segments.length > 3) {
      const newPath = segments.slice(0, -2).join("/");

      navigate(newPath, { replace: true });
    }
  };
  // console.log(videoList.forEach((el) => console.log(el.name, el.video_id)));
  console.log(videoUrl);
  return (
    <>
      <AdminContainer>
        {searchResults && (
          <div className="admin-table-half">
            <div className="admin-table-half__title">
              Trainer Videos
              <div
                className="admin-edit-item-button"
                onClick={() => {
                  // setLessonForm();
                  setCreating(true);
                }}
              >
                <i className="material-icons">add</i>
                <strong>New Video</strong>
              </div>
            </div>
            <Search
              handleSearchChange={handleSearchChange}
              onSearchSubmit={onSearchSubmit}
            />
            <br></br>
            <AdminTable
              tableData={searchResults}
              headers={["name", "type"]}
              // setSelectedItem={setSelectedVideo}
              setSelectedItem={handleSelectVideo}
              setEditingItem={setEditingVideo}
              typeConverter={videoTypeConverter}
            />
          </div>
        )}
        <div
          className={
            !creating
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                setCreating(false);
                setVideoForm(initialVideoForm);
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h2>Add New Video</h2>
              <form className="admin-form" onSubmit={handleVideoCreateSubmit}>
                <div>
                  <label>New Video Name</label>
                  <input
                    type="text"
                    name="name"
                    maxLength={50}
                    placeholder="name for new video"
                    value={videoForm.name}
                    onChange={handleVideoFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {videoForm.name.length > 0 ? (
                      <div>{videoForm.name.length}/50</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Video Description</label>
                  <textarea
                    rows="4"
                    name="description"
                    maxLength={250}
                    placeholder="description for new video"
                    value={videoForm.description}
                    onChange={handleVideoFormChange}
                  />
                  <div className="admin-form__character-counter">
                    {videoForm.description.length > 0 ? (
                      <div>{videoForm.description.length}/250</div>
                    ) : (
                      <div style={{ opacity: "0" }}>0/0</div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Select Video Type</label>
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <div className="admin-radio-button">
                      <input
                        type="radio"
                        name="type"
                        id="drill"
                        value="drill"
                        checked={videoForm.type === 1}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const numericValue =
                            value === "drill"
                              ? 1
                              : value === "guided training"
                              ? 2
                              : value;
                          setVideoForm({
                            ...videoForm,
                            [name]: numericValue,
                          });
                        }}
                      />
                      <label>Drill</label>
                    </div>
                    <div className="admin-radio-button">
                      <input
                        type="radio"
                        name="type"
                        id="guided training"
                        value="guided training"
                        checked={videoForm.type === 2}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const numericValue =
                            value === "drill"
                              ? 1
                              : value === "guided training"
                              ? 2
                              : value;
                          setVideoForm({
                            ...videoForm,
                            [name]: numericValue,
                          });
                        }}
                      />
                      <label>Guided Training</label>
                    </div>
                  </div>
                </div>
                <Button fluid primary>
                  Submit Video
                </Button>
              </form>
            </div>
          </div>
        </div>
        {/* VIDEO PAGE */}
        <div
          className={
            !editingVideo
              ? "admin-action-edit-screen"
              : "admin-action-edit-screen admin-action-edit-screen--open"
          }
        >
          <div className="admin-action-view__edit-button">
            <Button
              primary
              iconLeading="expand_more"
              onClick={() => {
                removeLastTwoSegments();
                setEditingVideo(false);
                setSelectedVideo();
              }}
            >
              Close
            </Button>
          </div>
          {selectedVideo && (
            <>
              <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                <div className="admin-info-half" style={{ maxWidth: "460px" }}>
                  <div className="admin-info-half__title">
                    {selectedVideo.name}
                    {/* <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div> */}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Video
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingVideo(true);
                          verifyAdmin();
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    {selectedVideo.video_id !== 0 && videoUrl ? (
                      <div style={{ position: "relative" }}>
                        <div className="admin-player-wrapper">
                          <ReactPlayer
                            className="admin-react-player"
                            width="100%"
                            height="100%"
                            // light={
                            //   selectedVideo.thumbnail_url !== ""
                            //     ? selectedVideo.thumbnail_url
                            //     : "/img/thumbnail-absent.png"
                            // }
                            url={videoUrl}
                            controls={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <>There is no video file</>
                    )}
                  </div>
                  <div className="admin-info-half__thumbnailbox">
                    <div className="admin-info-half__section-title">
                      Thumbnail
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setUploadingThumb(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>

                    {!uploadingThumb && (
                      <img
                        src={
                          selectedVideo.thumbnail_url !== ""
                            ? selectedVideo.thumbnail_url
                            : "/img/thumbnail-absent.png"
                        }
                      />
                    )}
                  </div>
                  <div className="admin-info-half__infobox">
                    <div className="admin-info-half__section-title">
                      Details
                      <div
                        className="admin-edit-item-button"
                        onClick={() => {
                          setFormDefaults(
                            selectedVideo,
                            videoForm,
                            setVideoForm
                          );
                          setEditing(true);
                        }}
                      >
                        <i className="material-icons">edit</i>
                        <strong>Edit</strong>
                      </div>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Video Name:</label>
                      <strong>{selectedVideo.name}</strong>
                    </div>
                    <div className="admin-info-half__key-value">
                      <label>Video Type:</label>
                      <strong>
                        {selectedVideo.type === 1 ? "Drill" : "Guided Training"}
                      </strong>
                    </div>
                    <div className="admin-info-half__description">
                      <label>Description:</label>
                      <div>
                        {selectedVideo.description !== ""
                          ? selectedVideo.description
                          : "Lorum ipsum lorum ipsum flo"}
                      </div>
                    </div>
                  </div>
                  {selectedVideo.type === 1 && (
                    <div className="admin-sub-video-section">
                      <div className="admin-sub-video-section__title">
                        Practice Videos
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setUpdatingPracticeFields(true);
                            setSelectedField("");
                            setUploadingPracticeVideo(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Practice Video</strong>
                        </div>
                      </div>
                      {practiceVideos.length > 0 ? (
                        <div className="admin-sub-video-section-list">
                          {practiceVideos.map((video, idx) => {
                            return (
                              <div key={idx}>
                                <div className="admin-info-half__thumbnailbox">
                                  <div className="admin-info-half__section-title">
                                    {video.view !== ""
                                      ? video.view
                                      : "No View Selected"}
                                    <div
                                      className="admin-edit-item-button"
                                      onClick={() => {
                                        setSelectedPractice(video);
                                        setPracticeVideoForm({
                                          // ...practiceVideoForm,
                                          view: video.view,
                                        });
                                        // if (
                                        //   video.view !== "FACE_ON" &&
                                        //   video.view !== "DTL"
                                        // ) {
                                        //   setViewOtherSelected(true);
                                        // } else {
                                        //   setViewOtherSelected(false);
                                        // }
                                        if (
                                          video.view === "FACE_ON" ||
                                          video.view === "DTL"
                                        ) {
                                          setSelectedField(video.view);
                                        } else {
                                          setSelectedField("other");
                                        }
                                        setChangingPractice(true);
                                      }}
                                    >
                                      <i className="material-icons">edit</i>
                                      <strong>Edit</strong>
                                    </div>
                                  </div>
                                  {video.url !== "" ? (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <div className="admin-player-wrapper">
                                        <ReactPlayer
                                          className="admin-react-player"
                                          width="100%"
                                          height="100%"
                                          // light={
                                          //   selectedVideo.thumbnail_url !== ""
                                          //     ? selectedVideo.thumbnail_url
                                          //     : "/img/thumbnail-absent.png"
                                          // }
                                          url={video.url}
                                          controls={true}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>There is no video file</>
                                  )}
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      height: "24px",
                                    }}
                                  >
                                    <div
                                      className="admin-edit-item-button"
                                      style={{ paddingBottom: "0" }}
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Are you SURE you want to PERMANENTLY DELETE this Practice Video?"
                                          )
                                        ) {
                                          HandyGolfAPI.sendRequest(
                                            "trainer/video/deletePracticeVideo",
                                            {
                                              trainer_video_id:
                                                selectedVideo.trainer_video_id,
                                              video_id: video.video_id,
                                            }
                                          ).then((data) => {
                                            console.log(data);
                                            // setEditingVideo(false);
                                            // setSelectedVideo();
                                          });
                                        }
                                      }}
                                    >
                                      <i className="material-icons">delete</i>
                                      <strong>Delete</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <>No Practice Videos</>
                      )}
                    </div>
                  )}

                  {categoryIdArray && (
                    <div className="admin-video-cats-wrapper">
                      <div className="admin-info-half__section-title">
                        Categories
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setChangingCats(true);
                          }}
                        >
                          <i className="material-icons">edit</i>
                          <strong>Edit</strong>
                        </div>
                      </div>
                      {categoryIdArray.length > 0 ? (
                        <ul>
                          {categoryIdArray.map((catId, idx) => {
                            return <li key={idx}>{catIdConverter(catId)}</li>;
                          })}
                        </ul>
                      ) : (
                        <div>This video has no categories, add some!</div>
                      )}
                    </div>
                  )}
                  {equipmentIdArray && selectedVideo.type === 1 && (
                    <div className="admin-video-cats-wrapper">
                      <div className="admin-info-half__section-title">
                        Equipment
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setChangingEquip(true);
                          }}
                        >
                          <i className="material-icons">edit</i>
                          <strong>Edit</strong>
                        </div>
                      </div>
                      {equipmentIdArray.length > 0 ? (
                        <ul>
                          {equipmentIdArray.map((equipId, idx) => {
                            return (
                              <li key={idx}>{equipIdConverter(equipId)}</li>
                            );
                          })}
                        </ul>
                      ) : (
                        <div>This video has no equipment, add some!</div>
                      )}
                    </div>
                  )}

                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "40px",
                    }}
                  >
                    <div
                      className="admin-edit-item-button"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you SURE you want to PERMANENTLY DELETE this Coach?"
                          )
                        ) {
                          HandyGolfAPI.sendRequest("trainer/video/delete", {
                            trainer_video_id: selectedVideo.trainer_video_id,
                          }).then((data) => {
                            console.log(data);
                            setEditingVideo(false);
                            setSelectedVideo();
                          });
                        }
                      }}
                    >
                      <i className="material-icons">delete</i>
                      <strong>Delete</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  !uploadingThumb
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingThumb(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingThumb && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {selectedVideo.thumbnail_url === "" ? (
                          <>
                            <h2>Add Thumbnail</h2>
                            <Uploader
                              folder={"trainer/thumbnails/"}
                              fileName={`trainer/thumbnails/trainer_video_${selectedVideo.trainer_video_id}`}
                              background={"/img/thumbnail-absent.png"}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedVideo}
                              id={selectedVideo.trainer_video_id}
                              type={"trainer_thumbnail"}
                            />
                          </>
                        ) : (
                          <>
                            <h2>Change Thumbnail</h2>

                            <Uploader
                              folder={"trainer/thumbnails/"}
                              fileName={`trainer/thumbnails/trainer_video_${selectedVideo.trainer_video_id}`}
                              background={"/img/thumbnail-absent.png"}
                              setUploading={setUploadingThumb}
                              setNew={setSelectedVideo}
                              id={selectedVideo.trainer_video_id}
                              type={"trainer_thumbnail"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div
                className={
                  !uploadingPracticeVideo
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      // setCategoryIdArray(originalCatArray);
                      setUploadingPracticeVideo(false);
                      setPracticeVideoForm(initialPracticeVideoForm);
                      setUpdatingPracticeFields(false);
                      setUpdatingPracticeVideo(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                  {/* <div> */}

                  <div
                    className="admin-info-half"
                    style={{
                      maxWidth: "460px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <h2>New Practice Video</h2>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        backgroundColor: "var(--cardBackground)",
                        padding: "1rem",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="admin-info-half__infobox"
                        style={{ backgroundColor: "var(--secondary)" }}
                      >
                        {/* <div className="admin-info-half__section-title">
                          <>Practice View Angle</>
                        </div> */}
                        <div className="admin-info-half__key-value">
                          <label>Current View:</label>
                          <div>
                            {practiceVideoForm.view === ""
                              ? "None selected"
                              : practiceVideoForm.view}
                          </div>
                        </div>
                      </div>
                      {!updatingPracticeFields && (
                        <Button
                          primary
                          fluid
                          onClick={() => {
                            setUpdatingPracticeFields(true);
                          }}
                        >
                          Change Fields
                        </Button>
                      )}

                      {updatingPracticeFields && (
                        <div className="admin-form">
                          <div>
                            <select
                              name="view"
                              value={selectedField}
                              onChange={(e) => {
                                setSelectedField(e.target.value);
                                if (e.target.value === "other") {
                                  setPracticeVideoForm({
                                    ...practiceVideoForm,
                                    view: "",
                                  });
                                  setViewOtherSelected(true);
                                } else {
                                  setViewOtherSelected(false);
                                  setPracticeVideoForm({
                                    ...practiceVideoForm,
                                    view: e.target.value,
                                  });
                                }
                              }}
                              style={{
                                height: "50px",
                                fontSize: "20px",
                                width: "100%",
                              }}
                            >
                              <option value="" disabled hidden>
                                Please Select View
                              </option>
                              <option value="FACE_ON">Face On</option>
                              <option value="DTL">Down the Line</option>
                              <option value="other">
                                Other (please specify)
                              </option>
                            </select>
                          </div>
                          {viewOtherSelected && (
                            <>
                              <input
                                type="text"
                                name="view"
                                maxLength={50}
                                placeholder="please specify different view"
                                value={practiceVideoForm.view}
                                onChange={(e) => {
                                  setPracticeVideoForm({
                                    ...practiceVideoForm,
                                    view: e.target.value,
                                  });
                                }}
                              />
                              <div className="admin-form__character-counter">
                                {practiceVideoForm.view.length > 0 ? (
                                  <div>{practiceVideoForm.view.length}/50</div>
                                ) : (
                                  <div style={{ opacity: "0" }}>0/0</div>
                                )}
                              </div>
                            </>
                          )}
                          {updatingPracticeFields && (
                            <>
                              <Button
                                secondary
                                fluid
                                onClick={() => {
                                  // setUpdatingPracticeFields(false);
                                  // setPracticeVideoForm({
                                  //   view: selectedPractice.view,
                                  // });
                                  setUploadingPracticeVideo(false);
                                  setPracticeVideoForm(
                                    initialPracticeVideoForm
                                  );
                                  setUpdatingPracticeFields(false);
                                  setUpdatingPracticeVideo(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                          {practiceVideoForm.view !== "" && (
                            <>
                              <Button
                                primary
                                fluid
                                onClick={() => {
                                  setUpdatingPracticeFields(false);
                                  setUpdatingPracticeVideo(true);
                                  // setPracticeVideoForm({
                                  //   view: selectedPractice.view,
                                  // });
                                }}
                              >
                                On to Video Upload
                              </Button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        backgroundColor: "var(--cardBackground)",
                        padding: "1rem",
                        borderRadius: "5px",
                      }}
                    >
                      {!updatingPracticeVideo && (
                        <>
                          <h2 style={{ textAlign: "center" }}>
                            Finilize Camera View First
                          </h2>
                          <Button
                            primary
                            fluid
                            disabled
                            onClick={() => {
                              setUpdatingPracticeVideo(true);
                            }}
                          >
                            Upload Video
                          </Button>
                        </>
                      )}

                      {updatingPracticeVideo && (
                        <>
                          {practiceVideoForm.view === "" ? (
                            <h2 style={{ textAlign: "center" }}>
                              Please select a view in the form above
                            </h2>
                          ) : (
                            <>
                              <Uploader
                                folder={"video/"}
                                fileName={`video/${practiceVideoForm.view}_view_drill_${selectedVideo.trainer_video_id}`}
                                formFields={practiceVideoForm}
                                background={"/img/thumbnail-absent.png"}
                                setUploading={setUploadingPracticeVideo}
                                // setNew={setSelectedVideo}
                                setNew={addNewPracticeVideo}
                                relatedObject={selectedVideo}
                                id={selectedVideo.trainer_video_id}
                                type={"create_trainer_practice_video"}
                              />
                              {practiceVideoForm.view === "" ? (
                                <></>
                              ) : (
                                <>
                                  <div
                                    className="admin-info-half__infobox"
                                    style={{
                                      backgroundColor: "var(--secondary)",
                                    }}
                                  >
                                    <div className="admin-info-half__section-title">
                                      Camera View
                                    </div>
                                    <div className="admin-info-half__key-value">
                                      <label>View:</label>
                                      <div>{practiceVideoForm.view}</div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* {updatingPracticeVideo && (
                                <Button
                                  secondary
                                  fluid
                                  onClick={() => {
                                    setUpdatingPracticeVideo(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              )} */}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <Button
                      secondary
                      fluid
                      onClick={() => {
                        // setUpdatingPracticeFields(false);
                        // setPracticeVideoForm({
                        //   view: selectedPractice.view,
                        // });
                        setUploadingPracticeVideo(false);
                        setPracticeVideoForm(initialPracticeVideoForm);
                        setUpdatingPracticeFields(false);
                        setUpdatingPracticeVideo(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div
                className={
                  !changingPractice
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      // setCategoryIdArray(originalCatArray);
                      setChangingPractice(false);
                      setSelectedPractice();
                      setPracticeVideoForm(initialPracticeVideoForm);
                      setUpdatingPracticeFields(false);
                      setUpdatingPracticeVideo(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {selectedPractice && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      {/* <div> */}

                      <div
                        className="admin-info-half"
                        style={{
                          maxWidth: "460px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <h2>Changing Practice Video</h2>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            backgroundColor: "var(--cardBackground)",
                            padding: "1rem",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            className="admin-info-half__infobox"
                            style={{ backgroundColor: "var(--secondary)" }}
                          >
                            <div className="admin-info-half__section-title">
                              <>Practice View Angle</>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Current View:</label>
                              <div>{practiceVideoForm.view}</div>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Original View:</label>
                              <div>{selectedPractice.view}</div>
                            </div>
                          </div>
                          {!updatingPracticeFields && (
                            <Button
                              primary
                              fluid
                              onClick={() => {
                                setUpdatingPracticeFields(true);
                              }}
                            >
                              Change Fields
                            </Button>
                          )}

                          {updatingPracticeFields && (
                            <div className="admin-form">
                              <div>
                                <select
                                  name="view"
                                  value={selectedField}
                                  onChange={(e) => {
                                    setSelectedField(e.target.value);
                                    if (e.target.value === "other") {
                                      setPracticeVideoForm({
                                        ...practiceVideoForm,
                                        view: "",
                                      });
                                      setViewOtherSelected(true);
                                    } else {
                                      setViewOtherSelected(false);
                                      setPracticeVideoForm({
                                        ...practiceVideoForm,
                                        view: e.target.value,
                                      });
                                    }
                                  }}
                                  style={{
                                    height: "50px",
                                    fontSize: "20px",
                                    width: "100%",
                                  }}
                                >
                                  <option
                                    value="FACE_ON"
                                    // selected={practiceVideoForm.view === "FACE_ON"}
                                  >
                                    Face On
                                  </option>
                                  <option
                                    value="DTL"
                                    // selected={practiceVideoForm.view === "DTL"}
                                  >
                                    Down the Line
                                  </option>
                                  <option value="other">
                                    Other (please specify)
                                  </option>
                                </select>
                              </div>
                              {viewOtherSelected && (
                                <>
                                  <input
                                    type="text"
                                    name="view"
                                    maxLength={50}
                                    placeholder="please specify different view"
                                    value={practiceVideoForm.view}
                                    onChange={(e) => {
                                      setPracticeVideoForm({
                                        ...practiceVideoForm,
                                        view: e.target.value,
                                      });
                                    }}
                                  />
                                  <div className="admin-form__character-counter">
                                    {practiceVideoForm.view.length > 0 ? (
                                      <div>
                                        {practiceVideoForm.view.length}/50
                                      </div>
                                    ) : (
                                      <div style={{ opacity: "0" }}>0/0</div>
                                    )}
                                  </div>
                                </>
                              )}
                              {updatingPracticeFields && (
                                <>
                                  <Button
                                    secondary
                                    fluid
                                    onClick={() => {
                                      setUpdatingPracticeFields(false);
                                      setPracticeVideoForm({
                                        view: selectedPractice.view,
                                      });
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                              )}
                              {selectedPractice.view !==
                                practiceVideoForm.view && (
                                <>
                                  <Button
                                    secondary
                                    fluid
                                    onClick={() => {
                                      setUpdatingPracticeFields(false);
                                      setUpdatingPracticeVideo(true);
                                      // setPracticeVideoForm({
                                      //   view: selectedPractice.view,
                                      // });
                                    }}
                                  >
                                    Keep Changes & Change Video
                                  </Button>
                                  <Button
                                    fluid
                                    primary
                                    onClick={() => {
                                      // e.preventDefault();
                                      HandyGolfAPI.sendRequest(
                                        "trainer/video/updatePracticeVideo",
                                        {
                                          trainer_video_id:
                                            selectedVideo.trainer_video_id,
                                          video_id: selectedPractice.video_id,
                                          practice_video: {
                                            view: practiceVideoForm.view,
                                          },
                                        }
                                      ).then((data) => {
                                        // console.log(data.data);
                                        updatePracticeVideos(data.data);
                                        // let newVidData = data.data;
                                        // let tempVids = practiceVideos;
                                        // let oldVidIndex = tempVids.findIndex(
                                        //   (vid) =>
                                        //     vid.video_id === newVidData.video_id
                                        // );
                                        // let oldVid = tempVids[oldVidIndex];
                                        // let newVid = {
                                        //   ...newVidData,
                                        //   url: oldVid.url,
                                        // };
                                        // tempVids[oldVidIndex] = newVid;
                                        // setPracticeVideos(tempVids);
                                        // setUpdatingPracticeFields(false);
                                        // setChangingPractice(false);
                                      });
                                    }}
                                  >
                                    Submit New View
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            backgroundColor: "var(--cardBackground)",
                            padding: "1rem",
                            borderRadius: "5px",
                          }}
                        >
                          {!updatingPracticeVideo && (
                            <Button
                              primary
                              fluid
                              onClick={() => {
                                setUpdatingPracticeVideo(true);
                              }}
                            >
                              Change Video
                            </Button>
                          )}

                          {updatingPracticeVideo && (
                            <>
                              {updatingPracticeFields ? (
                                <h2 style={{ textAlign: "center" }}>
                                  Finalize fields before uploading.
                                </h2>
                              ) : (
                                <>
                                  <Uploader
                                    folder={"video/"}
                                    fileName={`video/${practiceVideoForm.view}_view_drill_${selectedPractice.trainer_video_id}`}
                                    formFields={practiceVideoForm}
                                    background={"/img/thumbnail-absent.png"}
                                    setUploading={setChangingPractice}
                                    setNew={updatePracticeVideos}
                                    relatedObject={selectedPractice}
                                    id={selectedPractice.trainer_video_id}
                                    type={"update_trainer_practice_video"}
                                  />
                                  {practiceVideoForm.view ===
                                  selectedPractice.view ? (
                                    <></>
                                  ) : (
                                    <>
                                      <div
                                        className="admin-info-half__infobox"
                                        style={{
                                          backgroundColor: "var(--secondary)",
                                        }}
                                      >
                                        <div className="admin-info-half__section-title">
                                          Camera View WILL Change on Upload
                                        </div>
                                        <div className="admin-info-half__key-value">
                                          <label>View:</label>
                                          <div>{practiceVideoForm.view}</div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {updatingPracticeVideo && (
                                    <Button
                                      secondary
                                      fluid
                                      onClick={() => {
                                        setUpdatingPracticeVideo(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </>
                )}
              </div>

              <div
                className={
                  !uploadingVideo
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setUploadingVideo(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                {uploadingVideo && isVerified && (
                  <>
                    <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                      <div
                        className="admin-info-half"
                        style={{ maxWidth: "460px" }}
                      >
                        {selectedVideo.video_id === 0 ? (
                          <>
                            <h2>Add Video</h2>

                            {/* <Uploader
                              folder={"video/"}
                              fileName={`video/trainer_video_${selectedVideo.trainer_video_id}`}
                              background={"/img/thumbnail-absent.png"}
                              setUploading={setUploadingVideo}
                              setNew={setSelectedVideo}
                              relatedObject={selectedVideo}
                              id={selectedVideo.trainer_video_id}
                              type={"trainer_video"}
                            /> */}
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="/img/thumbnail-absent.png"
                                className="dragdrop-under-content"
                              />
                              <div className="dragdrop-overlay">
                                <VideoDragDrop
                                  type={"trainer_video"}
                                  object={selectedVideo}
                                  setUploading={setUploadingVideo}
                                  // handleFileChange={handleFileChange}
                                  // hasDropped={hasDropped}
                                  // duplicateFound={duplicateFound}
                                  // uppingToBucket={uppingToBucket}
                                  // progress={progress}
                                  // uploadFile={uploadFile}
                                  // setDuplicateFound={setDuplicateFound}
                                  // setUploading={setUploading}
                                  // type={type}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <h2>Change Video</h2>

                            {/* <Uploader
                            folder={"video/"}
                            fileName={`video/trainer_video_${selectedVideo.trainer_video_id}`}
                            background={"/img/thumbnail-absent.png"}
                            setUploading={setUploadingVideo}
                            setNew={setSelectedVideo}
                            relatedObject={selectedVideo}
                            id={selectedVideo.trainer_video_id}
                            type={"trainer_video"}
                          /> */}
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="/img/thumbnail-absent.png"
                                className="dragdrop-under-content"
                              />
                              <div className="dragdrop-overlay">
                                <VideoDragDrop
                                  type={"trainer_video"}
                                  object={selectedVideo}
                                  setUploading={setUploadingVideo}
                                  // handleFileChange={handleFileChange}
                                  // hasDropped={hasDropped}
                                  // duplicateFound={duplicateFound}
                                  // uppingToBucket={uppingToBucket}
                                  // progress={progress}
                                  // uploadFile={uploadFile}
                                  // setDuplicateFound={setDuplicateFound}
                                  // setUploading={setUploading}
                                  // type={type}
                                />
                              </div>
                            </div>
                          </>
                          // <>
                          //   <h2>Change Video</h2>

                          //   <Uploader
                          //     folder={"video/"}
                          //     fileName={`video/trainer_video_${selectedVideo.trainer_video_id}`}
                          //     background={"/img/thumbnail-absent.png"}
                          //     setUploading={setUploadingVideo}
                          //     setNew={setSelectedVideo}
                          //     relatedObject={selectedVideo}
                          //     id={selectedVideo.trainer_video_id}
                          //     type={"trainer_video"}
                          //   />
                          // </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className={
                  !changingCats
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setCategoryIdArray(originalCatArray);
                      setChangingCats(false);
                      setHasChangedCats(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      minWidth: "340px",
                    }}
                  >
                    <h2>Edit Video Categories</h2>
                    {parentCategories && childCategories && categoryIdArray && (
                      <>
                        <div className="admin-video-cats-wrapper">
                          <div className="admin-video-cats-wrapper__title">
                            Update Categories
                          </div>
                          <div className="admin-video-cats">
                            {parentCategories.map((parent, idxA) => {
                              return (
                                <div
                                  key={idxA}
                                  className="admin-video-cats__cat-boxes"
                                >
                                  <div
                                    key={idxA}
                                    className="admin-video-cats__parent"
                                  >
                                    {parent.name}
                                  </div>
                                  <div className="admin-video-cats__child">
                                    {childCategories.map((child, idxB) => {
                                      return child.parent_trainer_category_id ===
                                        parent.trainer_category_id ? (
                                        <div
                                          key={idxB}
                                          // style={{ width: "auto" }}
                                          className="admin-video-cats__child__child-box"
                                        >
                                          {child.name}

                                          <input
                                            type="checkbox"
                                            value={child.trainer_category_id}
                                            checked={categoryIdArray.includes(
                                              child.trainer_category_id
                                            )}
                                            onChange={handleCategoryChange}
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          <Button
                            fluid
                            secondary
                            onClick={() => {
                              setCategoryIdArray(originalCatArray);
                              setChangingCats(false);
                              setHasChangedCats(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        {hasChangedCats && (
                          <>
                            <div style={{ marginTop: "1rem" }}>
                              <Button
                                fluid
                                primary
                                onClick={() => {
                                  HandyGolfAPI.sendRequest(
                                    "trainer/video/saveCategories",
                                    {
                                      trainer_video_id:
                                        selectedVideo.trainer_video_id,
                                      trainer_category_ids: categoryIdArray,
                                    }
                                  ).then((data) => {
                                    console.log(data);
                                    setOriginalCatArray(categoryIdArray);
                                    setChangingCats(false);
                                    setHasChangedCats(false);
                                  });
                                }}
                              >
                                Submit Categories
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  !changingEquip
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEquipmentIdArray(originalEquipIdArray);
                      setChangingEquip(false);
                      setHasChangedEquip(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      minWidth: "340px",
                    }}
                  >
                    <h2>Edit Video Equipment</h2>
                    {allEquipment && equipmentIdArray && (
                      <>
                        <div className="admin-video-cats-wrapper">
                          <div className="admin-video-cats-wrapper__title">
                            Update Equipment
                          </div>
                          <div className="admin-video-cats">
                            {allEquipment.map((equip, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="admin-video-cats__child__child-box"
                                >
                                  {equip.name}
                                  <input
                                    type="checkbox"
                                    value={equip.trainer_equipment_id}
                                    checked={equipmentIdArray.includes(
                                      equip.trainer_equipment_id
                                    )}
                                    onChange={handleEquipmentChange}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          <Button
                            fluid
                            secondary
                            onClick={() => {
                              setEquipmentIdArray(originalEquipIdArray);
                              setChangingEquip(false);
                              setHasChangedEquip(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        {hasChangedEquip && (
                          <>
                            <div style={{ marginTop: "1rem" }}>
                              <Button
                                fluid
                                primary
                                onClick={() => {
                                  HandyGolfAPI.sendRequest(
                                    "trainer/video/saveEquipment",
                                    {
                                      trainer_video_id:
                                        selectedVideo.trainer_video_id,
                                      trainer_equipment_ids: equipmentIdArray,
                                    }
                                  ).then((data) => {
                                    console.log(data);
                                    setOriginalEquipIdArray(equipmentIdArray);
                                    setChangingEquip(false);
                                    setHasChangedEquip(false);
                                  });
                                }}
                              >
                                Submit Equipment
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  !editing
                    ? "admin-action-edit-screen"
                    : "admin-action-edit-screen admin-action-edit-screen--open"
                }
              >
                <div className="admin-action-view__edit-button">
                  <Button
                    primary
                    iconLeading="expand_more"
                    onClick={() => {
                      setEditing(false);
                      setVideoForm(initialVideoForm);
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <h2>
                      Edit Video: <br></br>'{selectedVideo.name}'
                    </h2>
                    <form
                      className="admin-form"
                      onSubmit={handleVideoEditSubmit}
                    >
                      <div>
                        <label>Change Video Name</label>
                        <input
                          type="text"
                          name="name"
                          maxLength={50}
                          placeholder={selectedVideo.name}
                          value={videoForm.name}
                          onChange={handleVideoFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {videoForm.name.length > 0 ? (
                            <div>{videoForm.name.length}/50</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>Select Video Type</label>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="type"
                              id="drill"
                              value="drill"
                              checked={videoForm.type === 1}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "drill"
                                    ? 1
                                    : value === "guided training"
                                    ? 2
                                    : value;
                                setVideoForm({
                                  ...videoForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Drill</label>
                          </div>
                          <div className="admin-radio-button">
                            <input
                              type="radio"
                              name="type"
                              id="guided training"
                              value="guided training"
                              checked={videoForm.type === 2}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const numericValue =
                                  value === "drill"
                                    ? 1
                                    : value === "guided training"
                                    ? 2
                                    : value;
                                setVideoForm({
                                  ...videoForm,
                                  [name]: numericValue,
                                });
                              }}
                            />
                            <label>Guided Training</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>Change Video Description</label>
                        <textarea
                          rows="4"
                          name="description"
                          maxLength={250}
                          placeholder={selectedVideo.description}
                          value={videoForm.description}
                          onChange={handleVideoFormChange}
                        />
                        <div className="admin-form__character-counter">
                          {videoForm.description.length > 0 ? (
                            <div>{videoForm.description.length}/250</div>
                          ) : (
                            <div style={{ opacity: "0" }}>0/0</div>
                          )}
                        </div>
                      </div>
                      <Button fluid primary>
                        Submit Changes
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </AdminContainer>
    </>
  );
}
