import React, { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { Button } from "../shared/Button";
import { Height } from "@mui/icons-material";
import { Section } from "./Section";
import { MobileSection } from "./MobileSection";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

export const Accordion = ({
  mobile,
  // lessonUnits,
  lessonDetails,
  // lesson,
  expanded_lesson_unit,
  userLessonData,
  currentSubUnit,
  // setExpandedLessonUnit,
  updateLessonDetails,
  // loadLessonUnitData,
  parentLessonId,
}) => {
  const [sliderPosition, setSliderPosition] = useState(0);
  // const [startingSlide, setStartingSlide] = useState();
  const [loading, setLoading] = useState(false);
  const {
    lesson,
    completedSubUnits,
    numUnits,
    sub,
    subNotes,
    unitCompletion,
    unitNotes,
  } = lessonDetails;
  useEffect(() => {
    if (!loading) {
      for (let i = 0; i < lessonDetails.lesson.lesson_units.length; i++) {
        if (
          lessonDetails.lesson.lesson_units[i].lesson_unit_id === parentLessonId
        ) {
          goToSlide(i);
        }
      }
    }
  }, [sub.lesson_unit_id]);

  if (!sub) {
    return <></>;
  }
  // console.log(parentLessonId);
  // console.log(lessonDetails);
  const goToSlide = (idx) => {
    document.querySelector(".swiper").swiper.slideTo(idx, 500, false);
  };
  // console.log(lessonDetails.lesson.lesson_units);
  // console.log(lessonDetails.lesson.lesson_units);
  if (mobile) {
    return (
      <div className="accordion-swiper">
        {/* <div className="accordion-mobile">
          {lessonDetails.lesson.lesson_units.map((unit, idx) => {
            return (
              <MobileSection
                className={`accordion-mobile__unit ${
                  idx === sliderPosition ? "active" : ""
                }
              ${idx < sliderPosition ? "prev" : ""}
              ${idx > sliderPosition ? "next" : ""}`}
                id={`accordion-mobile__unit__num` + idx}
                unit={unit}
                key={idx}
                lesson={lesson}
                expanded_lesson_unit={expanded_lesson_unit}
                userLessonData={userLessonData}
                sub={sub}
                // setExpandedLessonUnit={setExpandedLessonUnit}
                loadLessonUnitData={loadLessonUnitData}
              />
            );
          })}
        </div> */}
        <Swiper
          onAfterInit={() => {
            setLoading(false);
          }}
          slidesPerView={1.1}
          centeredSlides={true}
          spaceBetween={12}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          observer={true}
          observeSlideChildren={true}
        >
          {lessonDetails.lesson.lesson_units.map((unit, idx) => {
            return (
              <SwiperSlide key={idx}>
                <MobileSection
                  className={`accordion-mobile__unit ${
                    idx === sliderPosition ? "active" : ""
                  }
                  ${idx < sliderPosition ? "prev" : ""}
                  ${idx > sliderPosition ? "next" : ""}`}
                  // className={"accordion-mobile__unit"}
                  lessonDetails={lessonDetails}
                  id={`accordion-mobile__unit__num` + idx}
                  unit={unit}
                  unitIdx={idx}
                  // lesson={lessonDetails.lesson}
                  // expanded_lesson_unit={expanded_lesson_unit}
                  // userLessonData={userLessonData}
                  // sub={sub}
                  // setExpandedLessonUnit={setExpandedLessonUnit}
                  updateLessonDetails={updateLessonDetails}
                  // loadLessonUnitData={loadLessonUnitData}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }
  // console.log(parentLessonId);
  return (
    <>
      <div className="accordion">
        {lessonDetails.lesson.lesson_units.map((unit, idx) => {
          return (
            <Section
              lessonDetails={lessonDetails}
              unit={unit}
              key={idx}
              unitIdx={idx}
              // lesson={lessonDetails.lesson}
              // expanded_lesson_unit={expanded_lesson_unit}
              // userLessonData={userLessonData}
              // sub={sub}
              // setExpandedLessonUnit={setExpandedLessonUnit}
              updateLessonDetails={updateLessonDetails}
              // loadLessonUnitData={loadLessonUnitData}
              // parentLessonId={parentLessonId}
            />
          );
        })}
      </div>
    </>
  );
};
