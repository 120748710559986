import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useUpload } from "../../context/UploadContext";
import { Button } from "../../components";
import LinearProgress from "@mui/material/LinearProgress";

const fileTypes = ["JPG", "PNG", "JPEG", "MP4", "MOV"];
//DragDrop is separate just in case it becomes unweildy. in might end up being simple enough to just be in the file upload form.
export function VideoDragDrop({
  type,
  object,
  setUploading,
  // handleFileChange,
  // duplicateFound,
  // uppingToBucket,
  // hasDropped,
  // progress,
  // uploadFile,
  // setDuplicateFound,
  // setUploading,
}) {
  const [hasDropped, setHasDropped] = useState();
  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   console.log(file);
  //   setFile(file);
  // };
  const { isVerified, verifyAdmin, uploads, uploadVideo } = useUpload();

  const handleFileChange = (file) => {
    setHasDropped(true);
    uploadVideo(file, type, object);
    setUploading(false);
  };

  const dropperMarkup = (
    <div className="drop-zone-content">
      {hasDropped ? (
        <></>
      ) : (
        <>
          <div className="icon-text-button">
            <i className="material-icons">file_upload</i>
            <strong style={{ textDecoration: "underline" }}>
              Upload/Drop File Here
            </strong>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <FileUploader
        onDrop={(file) => {
          console.log(file);
          // const video = document.createElement("VIDEO");
          // video.src = file.preview;
          // video.addEventListener("loadedmetadata", () => {
          //   console.log(video.duration);
          // });
        }}
        children={dropperMarkup}
        // label="Upload File"
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
        // classes="drop-area"
        classes="drop-zone"
      />
    </>
  );
}
