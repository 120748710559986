import React from 'react';

// Components
import PageMetaData from "../components/PageMetaData";

// MUI
import Typography from "@mui/material/Typography";

function AboutPage() {
    return (
        <div className="body_content">
            <PageMetaData
                title="About Us"
                seo="seo keywords for about us"
            />
            <Typography variant="h3" gutterBottom>About HandyGolf</Typography>
        </div>
    );
}

export default AboutPage;
