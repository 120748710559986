import * as React from "react";
import { useState } from "react";
import { useRoutes } from "react-router-dom";
import { adminRoutes } from "./Admin";
import { freeUserRoutes } from "./FreeUser";
import { unAuthedRoutes } from "./UnAuthed";
import { subscribedUserRoutes } from "./SubscribedUser";

import { useUser } from "../providers";
export function AppRoutes() {
  const [user] = useUser();
  // console.log(user);
  let routes = [];

  if (user) {
    // console.log(user);
    if (user.is_admin) {
      // console.log(user);
      routes = adminRoutes;
    } else {
      routes = freeUserRoutes;
    }
  } else {
    routes = unAuthedRoutes;
  }

  //maybe common routes or public routes eventually will be the default
  const element = useRoutes([...routes]);
  return <>{element}</>;
}
