import * as React from "react";
import { useEffect, useState } from "react";
import HandyGolfAPI from "../../../../../lib/HandyGolfAPI";
import HandyGolfMessages from "../../../../../lib/HandyGolfMessages";

// MUI
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import FindInPageTwoToneIcon from "@mui/icons-material/FindInPageTwoTone";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

export function TagList() {
  const [loaded, setLoaded] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const trainers_columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: "100",
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      width: "100",
      renderCell: (params) => {
        return (
          <div>
            <Link
              to={"/admin/swing_trainer/tag_mgmt/view/" + params.row.trainer_tag_id}
            >
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="View"
              >
                <FindInPageTwoToneIcon />
              </IconButton>
            </Link>
            <Link
              to={"/admin/swing_trainer/tag_mgmt/edit/" + params.row.trainer_tag_id}
            >
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="Edit"
              >
                <EditTwoToneIcon />
              </IconButton>
            </Link>
            <Link
              to={"/admin/swing_trainer/tag_mgmt/delete/" + params.row.trainer_tag_id}
            >
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="Delete"
              >
                <DeleteTwoToneIcon />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    let mounted = true;
    let translatedArray = [];
    HandyGolfAPI.sendRequest("trainer/tag/list", {})
      .then((data) => {
        console.log(data);
        if (mounted) {
          setTrainers(data);
        }
        setLoaded(true);
      })
      .catch((err) => {
        HandyGolfMessages.reportMessage(HandyGolfMessages.ERROR, err.message);
      });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Tag Management
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Button disabled>Tag List</Button>
      </Breadcrumbs>
      <div style={{ textAlign: "center", marginBottom: 10 }}>
        <Link to="/admin/swing_trainer/tag_mgmt/add/">
          <Button
            variant="contained"
            className="blue"
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add Tag
          </Button>
        </Link>
        <br />
      </div>
      {!loaded && <CircularProgress />}
      {loaded && (
        <DataGrid
          rows={trainers}
          // rows={() => {}}
          columns={trainers_columns}
          getRowId={(row) => row.trainer_tag_id}
          disableRowSelectionOnClick
          pageSizeOptions={[10]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "odd_row" : "even_row"
          }
        ></DataGrid>
      )}
    </div>
  );
}
