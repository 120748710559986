import React from 'react';

// Libraries
import HandyGolf from '../lib/HandyGolf';

// Components
import PageMetaData from '../components/PageMetaData'
import MySQLQueryRunnerForm from "../components/MySQLQueryRunnerForm";

// MUI
import Typography from "@mui/material/Typography";

// Router
import {Navigate} from "react-router-dom";

function MySQLQueryRunnerPage() {
    return (
        <>
            {
                HandyGolf.getValue('is_dev_env') ? (
                    <PageMetaData
                        title="MySQL Query Runner"
                        seo="keywords for seo stuff"
                        // DO NOT INDEX
                    >
                        <div className="body_content">
                            <Typography variant="h3" gutterBottom>MySQL Query Runner</Typography>
                            <MySQLQueryRunnerForm />
                        </div>
                    </PageMetaData>
                ) : (
                    <>
                        <Navigate to="/" />
                    </>
                )
            }
        </>
    );
}

export default MySQLQueryRunnerPage;
