import React from "react";

// Components
import TagMgmt from "../components/swing_trainer/TagMgmt/TagMgmt";
import PageMetaData from "../components/PageMetaData";

export default function AdminSwingTrainerTagMgmtPage() {
  return (
    <PageMetaData title="Tag Management" seo="keywords for seo stuff">
      <div className="container">
        <div className="container__content">
          <TagMgmt />
        </div>
      </div>
    </PageMetaData>
  );
}
