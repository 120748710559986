import React, { useEffect, useState } from "react";
import {
  VideoListCategories,
  VideoListSearch,
  List,
  ContinueWatching,
  RecommendedVideos,
  Rewatch,
  Results,
} from "./sub";

import HandyGolfAPI from "../../../../lib/HandyGolfAPI";
import { Favorites } from "./sub/favorites/Favorites";

// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";

export function VideoList({ type }) {
  const [loaded, setLoaded] = useState(false);
  const [categories, setCategories] = useState();
  const [unFinishedVideos, setUnFinishedVideos] = useState();
  const [recommendedVideos, setRecommendedVideos] = useState();
  const [favorites, setFavorites] = useState();
  const [rewatchVideos, setRewatchVideos] = useState();
  const [searchResults, setSearchResults] = useState();
  const [searchObj, setSearchObj] = useState({
    search: "",
    tags: [1],
  });
  // const [favoriteIds, setFavoriteIds] = useState();
  useEffect(() => {
    // HandyGolfAPI.sendRequest("trainer/getUserActivity").then((data) => {
    //   //   console.log("SUCCESS:");
    //   //   console.log(data);
    //   setUserActivity(data);
    //   setLoaded(true);
    // });
    HandyGolfAPI.sendRequest("trainer/getUserContinueWatching", {
      type: type,
    }).then((data) => {
      // console.log(data);
      setUnFinishedVideos(data);
    });
    HandyGolfAPI.sendRequest("trainer/getUserRecommendations", {
      type: type,
    }).then((data) => {
      // console.log(data);
      setRecommendedVideos(data);
    });
    HandyGolfAPI.sendRequest("trainer/getUserFavorites", {
      type: type,
    }).then((data) => {
      // console.log("favorites:", data);
      setFavorites(data);
    });
    HandyGolfAPI.sendRequest("trainer/getUserRecentlyViewed", {
      type: type,
    }).then((data) => {
      // console.log(data);
      setRewatchVideos(data);
    });

    HandyGolfAPI.sendRequest("trainer/getVideosByCategory", {
      type: type,
    }).then((data) => {
      // console.log(`type ${type} videos:`, data);
      setCategories(data);
      setLoaded(true);
    });
    // let favoriteIds = [];
  }, [type, searchResults]);

  // console.log(searchResults);
  return (
    <div className="video-list">
      {loaded ? (
        <>
          <ContinueWatching
            videos={unFinishedVideos}
            favorites={favorites}
            type={type}
          />
          <RecommendedVideos
            videos={recommendedVideos}
            favorites={favorites}
            type={type}
          />
          <div className="video-list__fav-and-rewatch">
            <Favorites videos={favorites} favorites={favorites} type={type} />
            <Rewatch videos={rewatchVideos} favorites={favorites} type={type} />
          </div>

          <VideoListSearch type={type} setSearchResults={setSearchResults} />
          {searchResults && (
            <button
              onClick={() => {
                setSearchResults(null);
              }}
            >
              clear search
            </button>
          )}
          <div className="video-list__category-types">
            {type === 1 ? <h1>Drills</h1> : <h1>Training Sessions</h1>}
            <div>
              {!searchResults ? (
                <VideoListCategories categories={categories} />
              ) : (
                <Results
                  videos={searchResults}
                  favorites={favorites}
                  type={type}
                />
              )}

              {/* <List /> */}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
