import React, { useEffect, useState } from "react";

import HandyGolfAPI from "../../lib/HandyGolfAPI";

import { Calendar, MenuOptions, VideoList } from "./sub";

// MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { DataGrid } from "@mui/x-data-grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

export function SwingTrainerPage() {
  const [isNewUser, setIsNewUser] = useState(false);
  const [userActivity, setUserActivity] = useState();
  const [loaded, setLoaded] = useState(false);
  const [type, setType] = useState(1);
  useEffect(() => {
    HandyGolfAPI.sendRequest("trainer/getUserActivity").then((data) => {
      //   console.log("SUCCESS:");
      //   console.log(data);
      setUserActivity(data);
      setLoaded(true);
    });
  }, []);

  return (
    <div className="container">
      <div className="swing-trainer">
        {/* temp just to see with and without new user with mocks */}

        {loaded ? (
          <>
            {isNewUser && (
              <>
                <button
                  onClick={() => {
                    setIsNewUser(false);
                  }}
                >
                  not new user
                </button>
                <div className="swing-trainer__first-time">
                  <h1>First time? Not to worry</h1>
                  <Card sx={{ maxWidth: 600 }}>
                    {/* <CardHeader title="Swing Trainer Description" /> */}
                    <CardContent>
                      Welcome to the Swing Trainer. This tool can help you to
                      hone your swing skills through training sessions and
                      drills and can even help you to achieve your goals with
                      reminders and notifications. Watch this brief overview
                      video to see how you can get the most out of the swing
                      trainer.
                      {/* <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/me5gjIUe1Ks?si=Av8OVoXpuI8Ilhji"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe> */}
                    </CardContent>
                  </Card>
                </div>
              </>
            )}
            {!isNewUser && (
              <>
                <button
                  onClick={() => {
                    setIsNewUser(true);
                  }}
                >
                  set new user
                </button>
                <Calendar userActivity={userActivity} />
                <div>
                  <MenuOptions setType={setType} type={type} />
                </div>
                <div className="swing-trainer__list-body">
                  <VideoList type={type} />
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
