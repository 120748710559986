import React, { useState } from "react";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  SkipNext,
  VolumeUp,
  VolumeOff,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import Popover from "@mui/material/Popover";

export const MobileControls = ({
  mobileControlRef,
  onPlayPause,
  playing,
  time,
  muted,
  volume,
  onMute,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  onSeek,
  played,
  currentTime,
  duration,
  toggleFullScreen,
  popoverOpen,
  togglePopover,
  toggleMirrorVideo,
  mirror,
  dispatchHandler,

  // currentSubUnit,
  // prevSubUnitId,
  // nextSubUnitId,
  // lesson,
  // userLessonData,
  doubleClickBackward,
  doubleClickForward,
  isTrainer,
  setCompareMode,
  lessonDetails,
  updateLessonDetails,
}) => {
  let completedSubUnits = lessonDetails
    ? lessonDetails.completedSubUnits
    : null;
  let sub = lessonDetails ? lessonDetails.sub : null;
  let prevSub = lessonDetails ? lessonDetails.prevSub : null;
  let nextSub = lessonDetails ? lessonDetails.nextSub : null;
  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };
  let clickTimerLeft = null;

  function touchStartLeft() {
    if (clickTimerLeft == null) {
      clickTimerLeft = setTimeout(function () {
        clickTimerLeft = null;
        if (mobileControlRef.current.className === "video-controls-mobile") {
          mobileControlRef.current.className =
            "video-controls-mobile video-controls-mobile--open";
        } else if (
          mobileControlRef.current.className ===
          "video-controls-mobile video-controls-mobile--open"
        ) {
          mobileControlRef.current.className = "video-controls-mobile";
        }
        console.log("singleleft");
      }, 300);
    } else {
      clearTimeout(clickTimerLeft);
      clickTimerLeft = null;
      console.log("doubleleft");
      doubleClickBackward();
    }
  }
  let clickTimerRight = null;

  function touchStartRight() {
    if (clickTimerRight == null) {
      clickTimerRight = setTimeout(function () {
        clickTimerRight = null;
        if (mobileControlRef.current.className === "video-controls-mobile") {
          mobileControlRef.current.className =
            "video-controls-mobile video-controls-mobile--open";
        } else if (
          mobileControlRef.current.className ===
          "video-controls-mobile video-controls-mobile--open"
        ) {
          mobileControlRef.current.className = "video-controls-mobile";
          // console.log("closed");
        }
        console.log("singleright");
      }, 300);
    } else {
      clearTimeout(clickTimerRight);
      clickTimerRight = null;
      console.log("doubleright");
      doubleClickForward();
      if (
        mobileControlRef.current.className ===
        "video-controls-mobile video-controls-mobile--open"
      ) {
        mobileControlRef.current.className =
          "video-controls-mobile video-controls-mobile--open";
        // console.log("closed");
      }
    }
  }
  // console.log(sub.lesson_unit_id);
  // console.log(
  //   userLessonData.sub_unit_notes_and_completion[
  //     sub.lesson_unit_id
  //   ].unit_completed
  // );
  // console.log(mobileControlRef.className);
  return (
    <>
      <div
        // className="video-controls-mobile"
        ref={mobileControlRef}
        //   onMouseLeave={setPopoverOpen(false)}
      >
        {/* <div></div> */}
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            height: "100%",
            width: "100%",
            left: "0",
            bottom: "0",
            display: "flex",
          }}

          // onClick={onPlayPause}
        >
          <div
            style={{ width: "50%", height: "100%", zIndex: "1" }}
            // onTouchStart={touchStartLeft}
            onClick={touchStartLeft}

            // onDoubleClick={doubleClickBackward}
          ></div>
          <div
            style={{ width: "50%", height: "100%", zIndex: "1" }}
            // onDoubleClick={doubleClickForward}
            // onTouchStart={touchStartRight}
            onClick={touchStartRight}
          ></div>
        </div>
        {!isTrainer && (
          <div
            className={
              popoverOpen
                ? "video-controls-mobile__popover"
                : "video-controls-mobile__popover video-controls-mobile__popover--closed"
            }
          >
            {!completedSubUnits.includes(sub.lesson_unit_id) ? (
              <div
                className="video-controls-mobile__popover__section video-controls-mobile__popover__section--top"
                // "markLessonUnitAsIncomplete"
                onClick={() => {
                  HandyGolfAPI.sendRequest("lesson/lesson_unit/markAsWatched", {
                    lesson_unit_id: sub.lesson_unit_id,
                  }).then((data) => {
                    // console.log(data);
                    dispatchHandler(data);
                  });
                }}
              >
                <>Mark as Watched</>
                <i
                  className="material-icons"
                  style={{ color: "#010D15", opacity: ".2" }}
                >
                  check
                </i>
              </div>
            ) : (
              <div
                className="video-controls-mobile__popover__section video-controls-mobile__popover__section--top"
                // "markLessonUnitAsIncomplete"
                onClick={() => {
                  HandyGolfAPI.sendRequest(
                    "lesson/lesson_unit/markAsNotWatched",
                    {
                      lesson_unit_id: sub.lesson_unit_id,
                    }
                  ).then((data) => {
                    // loadLessonUnitData(sub.lesson_unit_id, data);
                    dispatchHandler(data);
                  });
                }}
              >
                <>Mark as Not Watched</>
                <i className="material-icons">check</i>
              </div>
            )}

            <div
              className="video-controls-mobile__popover__section video-controls-mobile__popover__section--bottom"
              onClick={toggleMirrorVideo}
            >
              <>Flip Video</>
              <img
                className={
                  mirror
                    ? "video-controls-mobile__flip-icon video-controls-mobile__flip-icon--flipped"
                    : "video-controls-mobile__flip-icon"
                }
                src={
                  mirror
                    ? "/img/flip_vertical_icon_gold.svg"
                    : "/img/flip_vertical_icon.svg"
                }
              />
            </div>
          </div>
        )}

        {/* <div className="video-controls-mobile__slider"> */}

        {/* <div className="video-controls-mobile__bottom"> */}
        <div className="video-controls-mobile__top">
          <span className="control-icon">
            <img src="/img/cast_icon.svg" />
          </span>
        </div>
        <div className="video-controls-mobile__middle">
          {!isTrainer && (
            <span
              className="control-icon"
              onClick={(e) => {
                updateLessonDetails(prevSub);
              }}
            >
              <img src="/img/skip_backward_fill_icon.svg" />
            </span>
          )}

          <span className="control-icon" onClick={onPlayPause}>
            {playing ? (
              <img src="/img/pause-icon.svg" />
            ) : (
              <img src="/img/play-arrow-1.svg" />
            )}
          </span>

          {!isTrainer && (
            <span
              className="control-icon"
              onClick={(e) => {
                updateLessonDetails(nextSub);
              }}
            >
              <img src="/img/skip_forward_fill_icon.svg" />
            </span>
          )}
        </div>
        <div className="video-controls-mobile__bottom">
          <div className="video-controls-mobile__bottom-controls">
            {/* {!isTrainer && ( */}
            <span className="control-icon">
              <img
                src="/img/expand_circle_dots_icon.svg"
                onClick={togglePopover}
              />
            </span>
            {/* )} */}

            <span className="control-icon" onClick={toggleFullScreen}>
              <img src="/img/fullscreen.svg" />
            </span>
          </div>
          <div className="video-controls-mobile__slider">
            <Slider
              min={0}
              max={100}
              value={played * 100}
              onChange={onSeek}
              sx={{
                color: "#fff",
                height: 4,
                padding: 0,
                "& .MuiSlider-thumb": {
                  width: 13,
                  height: 13,
                  transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                  "&::before": {
                    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible": {
                    boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
                  },
                  "&.Mui-active": {
                    width: 20,
                    height: 20,
                  },
                },
                "& .MuiSlider-rail": {
                  color: "black",
                  opacity: 0.6,
                },
                "& MuiSlider-root": {
                  padding: 0,
                },
              }}
            />
          </div>
        </div>
        {/* </div> */}

        {/* </div> */}
      </div>
    </>
  );
};
