import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AdminTable, Uploader, setFormDefaults, AdminContainer } from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";

const initialPositionListForm = { club: "", view: "", position_number: "" };
const initialPositionForm = {
  name: "",
  text: "",
  sort_order: 0,
  club: "",
  view: "",
  position_number: "",
};

export function AdminPositionImg() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    parent,
    // child,
    // grand_child,
    id_param: parent_id,
    // id_param2: child_id,
    // id_param3: grandchild_id,
  } = useParams();
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();

  //positions
  const [allPositions, setAllPositions] = useState();
  const [allPositionIds, setAllPositionIds] = useState();
  const [filteredPositions, setFilteredPositions] = useState();
  const [filteredIds, setFilteredIds] = useState();
  const [selectedPosition, setSelectedPosition] = useState();
  const [editingPosition, setEditingPosition] = useState();
  const [readyForImgs, setReadyForImgs] = useState();
  const [formChangeCount, setFormChangeCount] = useState(0);

  //uploading
  const [uploadingPositionImage, setUploadingPositionImage] = useState();
  //forms
  const [positionListForm, setPositionListForm] = useState(
    initialPositionListForm
  );
  const [positionForm, setPositionForm] = useState(initialPositionForm);
  const updateURL = (newPath) => {
    const currentPath = location.pathname;
    if (!currentPath.endsWith(newPath)) {
      const fullPath = `${currentPath}/${newPath}`;
      navigate(fullPath);
    }
  };

  const getPositions = () => {
    HandyGolfAPI.sendRequest("analyzer/position_image/list")
      .then((data) => {
        console.log("api", data);
        initialPositionIdsArray(data);
        setAllPositions(data);
        if (!filteredPositions || !filteredIds) {
          setFormChangeCount(formChangeCount + 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initialPositionIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) =>
      idsArray.push(element.analyzer_position_image_id)
    );
    setAllPositionIds(idsArray);
  };
  const handleFilterPositions = () => {
    let idsArray = [];
    let newPositions = allPositions.filter(
      (position) =>
        position.club === positionListForm.club &&
        position.view === positionListForm.view &&
        position.position_number === parseInt(positionListForm.position_number)
    );
    newPositions.forEach((position) =>
      idsArray.push(position.analyzer_position_image_id)
    );

    setFilteredIds(idsArray);
    setFilteredPositions(newPositions);
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    if (readyForImgs) {
      getPositions();
    }
  }, [readyForImgs]);
  useEffect(() => {
    if (
      positionListForm.club !== "" &&
      positionListForm.view !== "" &&
      positionListForm.position_number !== ""
    ) {
      setReadyForImgs(true);
    } else {
      setReadyForImgs(false);
    }
  }, [positionListForm]);
  useEffect(() => {
    if (
      positionListForm.club !== "" &&
      positionListForm.view !== "" &&
      positionListForm.position_number !== ""
    ) {
      if (allPositions && allPositionIds) {
        handleFilterPositions();
      }

      // filterIds();
    }
  }, [formChangeCount]);

  useEffect(() => {
    console.log("hi");
    if (parent_id) {
      HandyGolfAPI.sendRequest("analyzer/position_image/find", {
        analyzer_position_image_id: parseInt(parent_id),
      })
        .then((data) => {
          console.log("api", data);
          setSelectedPosition(data);
          setEditingPosition(data);
          setPositionListForm({
            club: data.club,
            view: data.view,
            position_number: data.position_number,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [adminMode]);
  useEffect(() => {
    if (!parent_id) {
      setEditingPosition();
      setSelectedPosition();
    } else {
      HandyGolfAPI.sendRequest("analyzer/position_image/find", {
        analyzer_position_image_id: parseInt(parent_id),
      })
        .then((data) => {
          // console.log("api", data);
          setSelectedPosition(data);
          setEditingPosition(data);
          setPositionListForm({
            club: data.club,
            view: data.view,
            position_number: data.position_number,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [parent_id]);

  const handlePositionOrderChange = (direction, positionId) => {
    let tempIdsArray = filteredIds;
    let tempPositions = filteredPositions;
    let currentIndex = filteredIds.findIndex((id) => id === positionId);
    let idElement = filteredIds[currentIndex];
    let positionElement = filteredPositions[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempPositions.splice(currentIndex, 1);
      tempPositions.splice(currentIndex - 1, 0, positionElement);
      setFilteredPositions(tempPositions);
      setFilteredIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempPositions.splice(currentIndex, 1);
      tempPositions.splice(currentIndex + 1, 0, positionElement);
      setFilteredPositions(tempPositions);
      setFilteredIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };

  const handlePositionFormChange = (e) => {
    setPositionForm({ ...positionForm, [e.target.name]: e.target.value });
  };
  const handlePositionCreateSubmit = (e) => {
    e.preventDefault();
    let defaultSortOrder = filteredPositions.length + 1;
    console.log(defaultSortOrder);
    HandyGolfAPI.sendRequest("analyzer/position_image/create", {
      analyzer_position_image: {
        club: positionListForm.club,
        view: positionListForm.view,
        position_number: positionListForm.position_number,
        name: positionForm.name,
        text: positionForm.text,
        sort_order: defaultSortOrder,
      },
    })
      .then((data) => {
        let newPosition = data.data;
        let positions = filteredPositions;
        let positionIds = filteredIds;
        positions.push(newPosition);
        positionIds.push(newPosition.analyzer_position_image_id);
        setFilteredPositions(positions);
        setFilteredIds(positionIds);
        setCreating(false);
        setPositionForm(initialPositionForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePositionUpdateSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("analyzer/position_image/update", {
      analyzer_position_image_id: selectedPosition.analyzer_position_image_id,
      analyzer_position_image: positionForm,
    })
      .then((data) => {
        setSelectedPosition(data.data);
        setEditing(false);
        setPositionForm(initialPositionForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectChange = (e) => {
    setPositionListForm({
      ...positionListForm,
      [e.target.name]: e.target.value,
    });
    setFormChangeCount(formChangeCount + 1);
  };

  const handleListChanger = (data) => {
    setReadyForImgs(false);
    let tempClub = positionListForm.club;
    let tempView = positionListForm.view;
    let tempPosition = positionListForm.position_number;
    setPositionListForm(initialPositionListForm);
    setFilteredPositions(null);
    setFilteredIds(null);

    setPositionListForm({
      club: tempClub,
      view: tempView,
      position_number: tempPosition,
    });

    setSelectedPosition(data);
  };

  const handleSelectPosition = (item) => {
    updateURL(`position/${item.analyzer_position_image_id}`);
    setSelectedPosition(item);
  };
  const removeLastTwoSegments = () => {
    const currentPath = location.pathname;
    const segments = currentPath.split("/");

    if (segments.length > 3) {
      const newPath = segments.slice(0, -2).join("/");

      navigate(newPath);
    }
  };

  return (
    <>
      <AdminContainer>
        <div className="admin-action-edit-screen admin-action-edit-screen--open">
          <div className="admin-overflow-scroll">
            <div className="admin-table-half">
              {positionListForm.club === "" && (
                <div className="admin-table-half__title">Select Club</div>
              )}
              {positionListForm.club !== "" && positionListForm.view === "" && (
                <div className="admin-table-half__title">Select View</div>
              )}
              {positionListForm.club !== "" &&
                positionListForm.view !== "" &&
                positionListForm.position_number === "" && (
                  <div className="admin-table-half__title">
                    Select Position Number
                  </div>
                )}

              <div className="card-container" style={{ marginBottom: "1rem" }}>
                <div
                  className="admin-info-half__infobox"
                  style={{ padding: ".25rem" }}
                >
                  <select
                    value={positionListForm.club}
                    style={{
                      height: "50px",
                      fontSize: "20px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    name="club"
                    onChange={handleSelectChange}
                  >
                    <option value="" disabled hidden>
                      Please Select Club
                    </option>
                    <option value="DRIVER">Driver</option>
                    <option value="IRON">Iron</option>
                  </select>
                </div>
                <div
                  className="admin-info-half__infobox"
                  style={{ padding: ".25rem" }}
                >
                  <select
                    value={positionListForm.view}
                    style={{
                      height: "50px",
                      fontSize: "20px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    name="view"
                    onChange={handleSelectChange}
                  >
                    <option value="" disabled hidden>
                      Please Select View
                    </option>
                    <option value="DOWNTHELINE">Down the Line</option>
                    <option value="FACEON">Face On</option>
                  </select>
                </div>
                <div
                  className="admin-info-half__infobox"
                  style={{ padding: ".25rem" }}
                >
                  <select
                    value={positionListForm.position_number}
                    style={{
                      height: "50px",
                      fontSize: "20px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    name="position_number"
                    onChange={handleSelectChange}
                  >
                    <option value="" disabled hidden>
                      Please Select Position
                    </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four</option>
                    <option value="5">Five</option>
                    <option value="6">Six</option>
                  </select>
                </div>
              </div>
              {filteredPositions && filteredIds && (
                <>
                  <div className="admin-table-half__title">
                    <div
                      className="admin-edit-item-button"
                      onClick={() => {
                        // setLessonForm();
                        setCreating(true);

                        // HandyGolfAPI.sendRequest(
                        //   "analyzer/position_image/create",
                        //   {analyzer_position_image:{
                        //     club:positionListForm.club,
                        //     view:positionListForm.view,
                        //     position_number:positionListForm.position_number,
                        //     name:
                        //   }}
                        // );
                      }}
                    >
                      <i className="material-icons">add</i>
                      <strong>New Position</strong>
                    </div>
                  </div>
                  <AdminTable
                    tableData={
                      filteredPositions
                      // handleFilterPositions()
                      // positionListForm.club,
                      // positionListForm.view,
                      // parseInt(positionListForm.position_number)
                    }
                    tableIds={filteredIds}
                    handleOrderChange={handlePositionOrderChange}
                    headers={
                      openSorting
                        ? ["", "sort_order", "image_url", "name"]
                        : ["image_url", "name", "text"]
                    }
                    // headers={["image_url", "name"]}
                    setSortChanging={setSortChanging}
                    idType={"analyzer_position_image_id"}
                    setSelectedItem={handleSelectPosition}
                    setEditingItem={setEditingPosition}
                  />
                  {openSorting ? (
                    <>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "40px",
                        }}
                      >
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setOpenSorting(false);
                          }}
                          style={{ right: "170px" }}
                        >
                          <strong>Cancel</strong>
                        </div>
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            HandyGolfAPI.sendRequest(
                              "analyzer/position_image/reorderForClubViewAndPositionNumber",
                              {
                                club: positionListForm.club,
                                view: positionListForm.view,
                                position_number:
                                  positionListForm.position_number,

                                analyzer_position_image_ids: filteredIds,
                              }
                            ).then((data) => {
                              setPositionListForm(initialPositionListForm);
                              let tempClub = positionListForm.club;
                              let tempView = positionListForm.view;
                              let tempPosition =
                                positionListForm.position_number;

                              getPositions();

                              setPositionListForm({
                                club: tempClub,
                                view: tempView,
                                position_number: tempPosition,
                              });
                              handleFilterPositions();
                              setOpenSorting(false);
                            });
                          }}
                        >
                          <i className="material-icons">swap_vert</i>
                          <strong>Save Sort Order</strong>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "40px",
                        }}
                      >
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setOpenSorting(true);
                          }}
                        >
                          <i className="material-icons">swap_vert</i>
                          <strong>Set Sort Order</strong>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={
              !creating
                ? "admin-action-edit-screen"
                : "admin-action-edit-screen admin-action-edit-screen--open"
            }
          >
            <div className="admin-action-view__edit-button">
              <Button
                primary
                iconLeading="expand_more"
                onClick={() => {
                  setCreating(false);
                }}
              >
                Close
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <h2>Create New Position</h2>
                <form
                  className="admin-form"
                  onSubmit={handlePositionCreateSubmit}
                >
                  <div>
                    <label>Club (cannot change here)</label>
                    <input
                      type="text"
                      name="club"
                      value={positionListForm.club}
                      disabled
                    />
                  </div>
                  <div>
                    <label>View (cannot change here)</label>
                    <input
                      type="text"
                      name="view"
                      value={positionListForm.view}
                      disabled
                    />
                  </div>
                  <div>
                    <label>Position No. (cannot change here)</label>
                    <input
                      type="number"
                      name="position_number"
                      value={positionListForm.position_number}
                      disabled
                    />
                  </div>
                  <div>
                    <label>Position Name</label>
                    <input
                      type="text"
                      name="name"
                      maxLength={100}
                      value={positionForm.name}
                      placeholder="position name here"
                      onChange={handlePositionFormChange}
                    />
                    <div className="admin-form__character-counter">
                      {positionForm.name.length > 0 ? (
                        <div>{positionForm.name.length}/100</div>
                      ) : (
                        <div style={{ opacity: "0" }}>0/0</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label>Position Text</label>
                    <textarea
                      rows="4"
                      name="text"
                      maxLength={200}
                      placeholder="Text info of new position"
                      value={positionForm.text}
                      onChange={handlePositionFormChange}
                    />
                    <div className="admin-form__character-counter">
                      {positionForm.text.length > 0 ? (
                        <div>{positionForm.text.length}/200</div>
                      ) : (
                        <div style={{ opacity: "0" }}>0/0</div>
                      )}
                    </div>
                  </div>
                  <Button fluid primary>
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div
            className={
              !editingPosition
                ? "admin-action-edit-screen"
                : "admin-action-edit-screen admin-action-edit-screen--open"
            }
          >
            <div className="admin-action-view__edit-button">
              <Button
                primary
                iconLeading="expand_more"
                onClick={() => {
                  removeLastTwoSegments();
                  setEditingPosition(false);
                  setSelectedPosition();
                }}
              >
                Close
              </Button>
            </div>
            {selectedPosition && (
              <>
                <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                  <div
                    className="admin-info-half"
                    style={{ maxWidth: "460px" }}
                  >
                    <div className="admin-info-half__title">
                      {selectedPosition.name}
                      {/* <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div> */}
                    </div>
                    <div className="admin-info-half__thumbnailbox">
                      <div className="admin-info-half__section-title">
                        Image
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setUploadingPositionImage(true);
                          }}
                        >
                          <i className="material-icons">edit</i>
                          <strong>Edit</strong>
                        </div>
                      </div>
                      {!uploadingPositionImage && (
                        <img
                          src={
                            selectedPosition.image_url !== ""
                              ? selectedPosition.image_url
                              : "/img/thumbnail-absent.png"
                          }
                        />
                      )}
                    </div>
                    <div className="admin-info-half__infobox">
                      <div className="admin-info-half__section-title">
                        Details
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            setFormDefaults(
                              selectedPosition,
                              positionForm,
                              setPositionForm
                            );
                            setEditing(true);
                          }}
                        >
                          <i className="material-icons">edit</i>
                          <strong>Edit</strong>
                        </div>
                      </div>
                      <div className="admin-info-half__key-value">
                        <label>Position Name:</label>
                        <strong>{selectedPosition.name}</strong>
                      </div>
                      <div className="admin-info-half__description">
                        <label>Text:</label>
                        <div>
                          {selectedPosition.text !== ""
                            ? selectedPosition.text
                            : "Lorum ipsum lorum ipsum flo"}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "24px",
                      }}
                    >
                      <div
                        className="admin-edit-item-button"
                        style={{ paddingBottom: "0" }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you SURE you want to PERMANENTLY DELETE this position image?"
                            )
                          ) {
                            HandyGolfAPI.sendRequest(
                              "analyzer/position_image/delete",
                              {
                                analyzer_position_image_id:
                                  selectedPosition.analyzer_position_image_id,
                              }
                            ).then((data) => {
                              console.log(data);
                              removeLastTwoSegments();
                              setEditingPosition(false);
                              setSelectedPosition();
                            });
                          }
                        }}
                      >
                        <i className="material-icons">delete</i>
                        <strong>Delete</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    !uploadingPositionImage
                      ? "admin-action-edit-screen"
                      : "admin-action-edit-screen admin-action-edit-screen--open"
                  }
                >
                  <div className="admin-action-view__edit-button">
                    <Button
                      primary
                      iconLeading="expand_more"
                      onClick={() => {
                        setUploadingPositionImage(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                  {uploadingPositionImage && (
                    <>
                      <div className="admin-overflow-scroll admin-overflow-scroll--centered">
                        <div
                          className="admin-info-half"
                          style={{ maxWidth: "460px" }}
                        >
                          {selectedPosition.image_url === "" ? (
                            <>
                              <h2>Add Image</h2>

                              <Uploader
                                folder={"analyzer_position_images/"}
                                fileName={`analyzer_position_images/analyzer_position_${selectedPosition.analyzer_position_image_id}`}
                                background={"/img/thumbnail-absent.png"}
                                setUploading={setUploadingPositionImage}
                                setNew={handleListChanger}
                                id={selectedPosition.analyzer_position_image_id}
                                type={"analyzer_position_image"}
                              />
                            </>
                          ) : (
                            <>
                              <h2>Change Image</h2>

                              <Uploader
                                folder={"analyzer_position_images/"}
                                fileName={`analyzer_position_images/analyzer_position_${selectedPosition.analyzer_position_image_id}`}
                                background={selectedPosition.image_url}
                                setUploading={setUploadingPositionImage}
                                setNew={handleListChanger}
                                id={selectedPosition.analyzer_position_image_id}
                                type={"analyzer_position_image"}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={
                    !editing
                      ? "admin-action-edit-screen"
                      : "admin-action-edit-screen admin-action-edit-screen--open"
                  }
                >
                  <div className="admin-action-view__edit-button">
                    <Button
                      primary
                      iconLeading="expand_more"
                      onClick={() => {
                        setEditing(false);
                        setPositionForm(initialPositionForm);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <h2>Edit Position</h2>
                      <form
                        className="admin-form"
                        onSubmit={handlePositionUpdateSubmit}
                      >
                        {/* <div>
                    <label>Club (cannot change here)</label>
                    <input
                      type="text"
                      name="club"
                      value={positionListForm.club}
                      disabled
                    />
                  </div>
                  <div>
                    <label>View (cannot change here)</label>
                    <input
                      type="text"
                      name="view"
                      value={positionListForm.view}
                      disabled
                    />
                  </div>
                  <div>
                    <label>Position No. (cannot change here)</label>
                    <input
                      type="number"
                      name="position_number"
                      value={positionListForm.position_number}
                      disabled
                    />
                  </div> */}
                        <div>
                          <label>Position Name</label>
                          <input
                            type="text"
                            name="name"
                            maxLength={100}
                            value={positionForm.name}
                            placeholder={positionForm.name}
                            onChange={handlePositionFormChange}
                          />
                          <div className="admin-form__character-counter">
                            {positionForm.name.length > 0 ? (
                              <div>{positionForm.name.length}/100</div>
                            ) : (
                              <div style={{ opacity: "0" }}>0/0</div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label>Position Text</label>
                          <textarea
                            rows="4"
                            name="text"
                            maxLength={200}
                            placeholder={positionForm.text}
                            value={positionForm.text}
                            onChange={handlePositionFormChange}
                          />
                          <div className="admin-form__character-counter">
                            {positionForm.text.length > 0 ? (
                              <div>{positionForm.text.length}/200</div>
                            ) : (
                              <div style={{ opacity: "0" }}>0/0</div>
                            )}
                          </div>
                        </div>
                        <Button fluid primary>
                          Submit
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </AdminContainer>
    </>
  );
}
