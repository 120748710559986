import React, { useEffect } from "react";

// Components
import LessonMgmt from "./LessonMgmt";

function PageMetaData({ title, seo, children }) {
  useEffect(() => {
    document.title = "HandyGolf - " + title;
    // Set SEO Meta keywords, etc...
  }, []);
  return children;
}

export default PageMetaData;
