import React from 'react';

// MUI
import Typography from "@mui/material/Typography";

function ContactPage() {
    return (
        <div className="body_content">
            <Typography variant="h3" gutterBottom>Contact</Typography>
        </div>
    );
}

export default ContactPage;
