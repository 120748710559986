import * as React from "react";
import {
  CategoryList,
  CategoryView,
  CategoryForm,
  DeleteCategoryConfirm,
} from "./sub";

// MUI
import CircularProgress from "@mui/material/CircularProgress";

import { useParams, Navigate, Link } from "react-router-dom";

export default function CategoryMgmt() {
  const { action_param = "list", id_param = 0 } = useParams();

  // New View Logic -- Based on Props
  let trainer_category_id = 0;
  if (action_param === "list") {
    return <CategoryList />;
  }
  if (action_param === "add") {
    return <CategoryForm mode="add" trainer_category_id={trainer_category_id} />;
  }
  // MJH
  if (action_param === "edit") {
    trainer_category_id = parseInt(id_param);
    if (trainer_category_id > 0) {
      return <CategoryForm mode="edit" trainer_category_id={trainer_category_id} />;
    } else {
      return <div>ERROR 1.</div>;
    }
  }
  if (action_param === "view") {
    trainer_category_id = parseInt(id_param);
    console.log(trainer_category_id);
    if (trainer_category_id > 0) {
      return <CategoryView trainer_category_id={trainer_category_id} />;
    } else {
      return <div>ERROR 2.</div>;
    }
  }
  if (action_param === "delete") {
    trainer_category_id = parseInt(id_param);
    console.log(trainer_category_id);
    if (trainer_category_id > 0) {
      return <DeleteCategoryConfirm trainer_category_id={trainer_category_id} />;
    } else {
      return <div>ERROR 2.</div>;
    }
  }
  if (action_param === undefined) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Navigate to="/admin/swing_trainer/category_mgmt" />
    </div>
  );
}
