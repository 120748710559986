import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button } from "../../components";
import LinearProgress from "@mui/material/LinearProgress";

const fileTypes = ["JPG", "PNG", "JPEG", "MP4", "MOV"];
//DragDrop is separate just in case it becomes unweildy. in might end up being simple enough to just be in the file upload form.
export function DragDrop({
  handleFileChange,
  duplicateFound,
  uppingToBucket,
  hasDropped,
  progress,
  uploadFile,
  setDuplicateFound,
  setUploading,
}) {
  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   console.log(file);
  //   setFile(file);
  // };
  const dropperMarkup = (
    <div className="drop-zone-content">
      {hasDropped ? (
        <></>
      ) : (
        <>
          <div className="icon-text-button">
            <i className="material-icons">file_upload</i>
            <strong style={{ textDecoration: "underline" }}>
              Upload/Drop File Here
            </strong>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      {duplicateFound ? (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // pirosition: "relative",
              // zIndex: "1",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              UPLOADING WILL OVERWRITE EXISTING FILE
            </div>
            <br></br>

            <Button
              secondary
              fluid
              style={{
                // backgroundColor: "#DC3545",
                position: "relative",
                zIndex: "2",
              }}
              onClick={() => {
                setUploading(false);
              }}
            >
              Cancel
            </Button>
            <br></br>
            <Button
              secondary
              fluid
              style={{
                backgroundColor: "#DC3545",
                position: "relative",
                zIndex: "2",
              }}
              onClick={() => {
                setDuplicateFound(false);
                uploadFile();
              }}
            >
              Click to Overwrite File
            </Button>
          </div>
        </>
      ) : (
        <>
          {uppingToBucket ? (
            <>
              {progress ? (
                <>
                  <div className="progress-golfballs-container">
                    <strong>
                      {progress !== 100
                        ? `Uploading Progress ${progress}%`
                        : "Uploaded Successfully!"}
                    </strong>
                    <div className="progress-golfballs">
                      <img
                        className={
                          progress < 0
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 10
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 20
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 30
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 40
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 50
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 60
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 70
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 80
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                      <img
                        className={
                          progress < 90
                            ? "golfball-of-progress"
                            : "golfball-of-progress golfball-of-progress--lit"
                        }
                        src={"/img/golfball.png"}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>waiting...</>
              )}
            </>
          ) : (
            <FileUploader
              onDrop={(file) => {
                console.log(file);
                // const video = document.createElement("VIDEO");
                // video.src = file.preview;
                // video.addEventListener("loadedmetadata", () => {
                //   console.log(video.duration);
                // });
              }}
              children={dropperMarkup}
              // label="Upload File"
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              // classes="drop-area"
              classes="drop-zone"
            />
          )}
        </>
      )}
    </>
  );
}
