import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Webcam from "react-webcam";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { useUser } from "../providers";
import {
  Card,
  Container,
  AppTopBar,
  BottomNav,
  Player,
  AdminNav,
  Button,
} from "../components";

import {
  StateContext,
  StateDispatchContext,
  CheckCacheContext,
} from "../context/AppContext";
import { AdminContainer } from "./index";

export function AdminDash() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  let navigate = useNavigate();
  const image =
    "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg";
  return (
    <>
      <AdminContainer>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "2rem 0",
            // overflowY: "scroll",
          }}
        >
          <div
            style={{
              width: "80%",
              // whiteSpace: "nowrap",
              // textAlign: "center",
            }}
          >
            <div className="card-container">
              <div className="admin-dash-card">
                <strong>Trainer</strong>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/trainer/programs");
                  }}
                >
                  Programs
                </div>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/trainer/equipment");
                  }}
                >
                  Equipment
                </div>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/trainer/videos");
                  }}
                >
                  Videos
                </div>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/trainer/categories");
                  }}
                >
                  Categories
                </div>
              </div>
              <div className="admin-dash-card">
                <strong>Fixes</strong>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/fix/faults");
                  }}
                >
                  Faults
                </div>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/fix/categories");
                  }}
                >
                  Categories
                </div>
              </div>
              <div className="admin-dash-card">
                <strong>Coaches</strong>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/coaches");
                  }}
                >
                  Coaches
                </div>
              </div>
              <div className="admin-dash-card">
                <strong>Lessons</strong>
                <div
                  className="admin-dash-card__link"
                  onClick={() => {
                    navigate("/admin/lessons");
                  }}
                >
                  Lessons
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminContainer>
    </>
  );
}
