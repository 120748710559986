class HandyGolfMessages {
    constructor() {
        throw new Error("The messages class should not be instantiated.")
    }
    static messages = [];

    static SUCCESS = 'success';
    static INFO = 'info';
    static WARNING = 'warning';
    static ERROR = 'error';

    static reportMessage(type, message) {
        HandyGolfMessages.messages.push({
            type: type,
            message: message
        });
    }
    static getMessagesForDisplay() {
        let ret_messages = HandyGolfMessages.messages;
        HandyGolfMessages.messages = [];
        return ret_messages;
    }
}

module.exports = HandyGolfMessages;