import React from "react";
import { AdminNav } from "../components";

export function AdminContainer({ children }) {
  return (
    <div className="admin-area-wrapper">
      <div className="admin-nav-wrapper">
        <AdminNav />
      </div>
      {/* <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box"> */}
      <div className="admin-area-body">
        <div className="admin-action-container">
          <div className="admin-action">
            <div className="admin-action-view">{children}</div>
          </div>
        </div>
      </div>
      {/* </div>
        </div>
      </div> */}
    </div>
  );
}
