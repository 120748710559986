import React, { useEffect, useState, useRef, useContext } from "react";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import {
  Accordion,
  Card,
  Container,
  Button,
  AppTopBar,
  BottomNav,
  Player,
} from "../components";
import {
  StateContext,
  StateDispatchContext,
  CheckLessonCacheContext,
} from "../context/AppContext";
// Video Library
import ReactPlayer from "react-player";

// Navigation
import { useParams, useNavigate, Link, json } from "react-router-dom";
//mui
import CircularProgress from "@mui/material/CircularProgress";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Checkbox from "@mui/material/Checkbox";

export function LessonDetails() {
  const { lesson_id = 0 } = useParams();
  //contexts
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  const checkLessonCache = useContext(CheckLessonCacheContext);
  //NEWSTATES, MAY PUT INTO CONTEXT
  // console.log(lesson_id);
  //old lesson display states
  const [currentLesson, setCurrentLesson] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const [lessonDetails, setLessonDetails] = useState();
  // const [lesson, setLesson] = useState({});
  // const [currentSubUnit, setCurrentLessonUnit] = useState({});
  // const [expanded_lesson_unit, setExpandedLessonUnit] = useState();
  const [loaded_lesson_unit_id, setLoadedLessonUnitId] = useState(0);
  const [note_text, setNoteText] = useState("");
  const [note_time_checked, setNoteTimeChecked] = useState(true);
  const [noteBeingEdited, setNoteBeingEdited] = useState(-1);
  const [editedNoteText, setEditedNoteText] = useState("");
  const [selected_tab, setSelectedTab] = useState(0);
  // const [userLessonData, setUserLessonData] = useState({});
  //old lesson feedback states
  const [feedback_display, setFeedbackDisplay] = useState(false);
  const [feedback_rating, setFeedbackRating] = useState(0);
  const [feedback_text, setFeedbackText] = useState("");
  const [feedback_timeout, setFeedbackTimeout] = useState(null);

  // const [stateLoaded, setStateLoaded] = useState(false);

  // VIDEO STATE
  const [video, setVideo] = useState();
  const [video_levels, setVideoLevels] = useState([]);
  const [video_playing, setVideoPlaying] = useState(false);
  const [video_time, setVideoTime] = useState(0);

  //new note state
  const [isNoteFocused, setIsNoteFocused] = useState(false);
  // Refs
  const videoRef = useRef(null);
  let navigate = useNavigate();
  // STALLING FUNCTION--DELETE FOR PROD
  async function stall(stallTime = 3000) {
    await new Promise((resolve) => setTimeout(resolve, stallTime));
  }

  const loadLessonUnitData = async (new_unit_id, dataToCache) => {
    let key = "lesson_data";
    // let lessonIndex = state.lesson_data.findIndex(
    //   (lesson) => lesson.lesson_id === parseInt(lesson_id)
    // );

    // if (dataToCache) {
    //   let newState = state.lesson_data;
    //   let updatedLesson = {
    //     ...lessonInfo,
    //     user_data: dataToCache,
    //   };
    //   newState[lessonIndex] = updatedLesson;

    //   console.log(newState);
    //   localStorage.setItem(key, JSON.stringify(newState));
    //   dispatch({
    //     type: "FETCH_SUCCESS",
    //     payload: { key, data: newState },
    //   });
    // } else {
    //   let newState = state.lesson_data;

    //   console.log(lessonIndex);
    //   // console.log(lessonInfo);

    //   let updatedLesson = {
    //     ...lessonInfo,
    //     user_data: {
    //       ...userLessonData,
    //       latest_lesson_unit_id: new_unit_id,
    //     },
    //   };
    //   newState[lessonIndex] = updatedLesson;

    //   console.log(newState);
    //   localStorage.setItem(key, JSON.stringify(newState));
    //   dispatch({
    //     type: "FETCH_SUCCESS",
    //     payload: { key, data: newState },
    //   });
    // }
  };
  //check local storage
  const isLocalStorageEnabled = () => {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, null);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };
  const initialLessonState = (lessonData) => {
    //getting lesson & user info
    let currentLessonIdx = lessonData.findIndex(
      (lesson) => parseInt(lesson_id) === lesson.lesson_id
    );
    let lessonInfo = lessonData.find(
      (lesson) => lesson.lesson_id === parseInt(lesson_id)
    );
    console.log(lessonData[currentLessonIdx].lesson_data);

    let lesson = lessonData[currentLessonIdx].lesson_data;

    let userLessonData = lessonInfo.user_data;
    // current sub unit will be the latest by default
    let latestSubUnitId = userLessonData.latest_lesson_unit_id;

    let lessonUnit = lesson.lesson_units.find((lesson_unit) =>
      lesson_unit.sub_units.some(
        (sub_unit) => sub_unit.lesson_unit_id === latestSubUnitId
      )
    );
    let startingUnitIndex = lesson.lesson_units.findIndex((lesson_unit) =>
      lesson_unit.sub_units.some(
        (sub_unit) => sub_unit.lesson_unit_id === latestSubUnitId
      )
    );

    let currentSubUnit = lessonUnit.sub_units.find(
      (sub_unit) => sub_unit.lesson_unit_id === latestSubUnitId
    );
    //initially the user_data wasnt going into the state properly so i broke it into its usable pieces, ill probably change that later
    let subUnitNotes = userLessonData.user_lesson_unit_notes.find(
      (sub_unit) => sub_unit.lesson_unit_id === latestSubUnitId
    )
      ? userLessonData.user_lesson_unit_notes.find(
          (sub_unit) => sub_unit.lesson_unit_id === latestSubUnitId
        ).notes
      : null;
    //getting the location of the user at all times as well as their next moves
    let currentLessonUnitIndex = lesson.lesson_units.findIndex(
      (unit) => unit === lessonUnit
    );
    let prevLessonUnitIndex =
      currentLessonUnitIndex === 0 ? 0 : currentLessonUnitIndex - 1;
    let nextLessonUnitIndex =
      lesson.lesson_units.length - 1 === currentLessonUnitIndex
        ? 0
        : currentLessonUnitIndex + 1;
    let currentSubUnitIndex = lessonUnit.sub_units.findIndex(
      (sub_unit) => sub_unit.lesson_unit_id === latestSubUnitId
    );
    let prevSubUnitIndex =
      currentSubUnitIndex === 0 ? -1 : currentSubUnitIndex - 1;
    let nextSubUnitIndex =
      lessonUnit.sub_units.length - 1 === currentSubUnitIndex
        ? -1
        : currentSubUnitIndex + 1;
    let prevSubUnitId =
      prevSubUnitIndex === -1
        ? currentLessonUnitIndex !== 0
          ? lesson.lesson_units[prevLessonUnitIndex].sub_units[
              lesson.lesson_units[prevLessonUnitIndex].sub_units.length - 1
            ].lesson_unit_id
          : lesson.lesson_units[0].sub_units[0].lesson_unit_id
        : lesson.lesson_units[currentLessonUnitIndex].sub_units[
            prevSubUnitIndex
          ].lesson_unit_id;
    let nextSubUnitId =
      nextSubUnitIndex === -1
        ? lesson.lesson_units[nextLessonUnitIndex].sub_units[0].lesson_unit_id
        : lesson.lesson_units[currentLessonUnitIndex].sub_units[
            nextSubUnitIndex
          ].lesson_unit_id;

    let numUnits = lesson.lesson_units.length;

    let initialDetailsObject = {
      lessonIndex: currentLessonIdx,
      lesson: lesson,

      unit: lessonUnit,
      sub: currentSubUnit,
      parentIndex: startingUnitIndex,
      numUnits: numUnits,
      unitNotes: userLessonData.user_lesson_unit_notes,
      subNotes: subUnitNotes,
      unitCompletion: userLessonData.lesson_unit_completion,
      completedSubUnits: userLessonData.watched_lesson_unit_ids,
      prevSub: prevSubUnitId,
      nextSub: nextSubUnitId,
      // user: userLessonData,
    };
    return initialDetailsObject;
  };
  useEffect(() => {
    // let mounted = true;
    // console.log("useeffect triggered");

    checkLessonCache(parseInt(lesson_id));

    const updateLesson = async () => {
      const result = await initialLessonState(state.lesson_data);

      setLessonDetails(result);

      // console.log("Data fetched and set:", result);
    };
    if (!state.loading.lesson_data) {
      updateLesson();
    }

    // return () => {
    //   mounted = false;
    // };
  }, [
    state.loading.lesson_data,
    // state.lesson_data,
    lesson_id,
    // parseInt(lesson_id)
  ]);
  console.log("after useEffect");
  const isLoading = state.loading.lesson_data;

  if (isLoading || !lessonDetails) {
    console.log("LOADING TIME");
    return <>loading.....</>;
  }
  const {
    lessonIndex,

    lesson,
    completedSubUnits,
    numUnits,
    parentIndex,
    sub,
    subNotes,
    unitCompletion,
    unitNotes,
  } = lessonDetails;
  // console.log(lesson);
  const updateLessonDetails = (newId) => {
    let currentLessonIdx = state.lesson_data.findIndex(
      (lesson) => parseInt(lesson_id) === lesson.lesson_id
    );
    let newLesson = state.lesson_data[currentLessonIdx].lesson_data;
    let newUserLessonData = state.lesson_data[currentLessonIdx].user_data;
    let newUnit = newLesson.lesson_units.find((lesson_unit) =>
      lesson_unit.sub_units.some(
        (sub_unit) => sub_unit.lesson_unit_id === newId
      )
    );

    let newSubUnit = newUnit.sub_units.find(
      (sub_unit) => sub_unit.lesson_unit_id === newId
    );

    let newSubUnitNotes = newUserLessonData.user_lesson_unit_notes.find(
      (sub_unit) => sub_unit.lesson_unit_id === newId
    )
      ? newUserLessonData.user_lesson_unit_notes.find(
          (sub_unit) => sub_unit.lesson_unit_id === newId
        ).notes
      : null;
    let newUnitIndex = lesson.lesson_units.findIndex((lesson_unit) =>
      lesson_unit.sub_units.some(
        (sub_unit) => sub_unit.lesson_unit_id === newId
      )
    );
    let prevLessonUnitIndex = newUnitIndex === 0 ? 0 : newUnitIndex - 1;
    let nextLessonUnitIndex =
      lesson.lesson_units.length - 1 === newUnitIndex ? 0 : newUnitIndex + 1;
    let currentSubUnitIndex = newUnit.sub_units.findIndex(
      (sub_unit) => sub_unit.lesson_unit_id === newId
    );
    let prevSubUnitIndex =
      currentSubUnitIndex === 0 ? -1 : currentSubUnitIndex - 1;
    let nextSubUnitIndex =
      newUnit.sub_units.length - 1 === currentSubUnitIndex
        ? -1
        : currentSubUnitIndex + 1;
    let prevSubUnitId =
      prevSubUnitIndex === -1
        ? newUnitIndex !== 0
          ? lesson.lesson_units[prevLessonUnitIndex].sub_units[
              lesson.lesson_units[prevLessonUnitIndex].sub_units.length - 1
            ].lesson_unit_id
          : lesson.lesson_units[0].sub_units[0].lesson_unit_id
        : lesson.lesson_units[newUnitIndex].sub_units[prevSubUnitIndex]
            .lesson_unit_id;
    let nextSubUnitId =
      nextSubUnitIndex === -1
        ? lesson.lesson_units[nextLessonUnitIndex].sub_units[0].lesson_unit_id
        : lesson.lesson_units[newUnitIndex].sub_units[nextSubUnitIndex]
            .lesson_unit_id;

    // let numUnits = lesson.lesson_units.length;
    setLessonDetails({
      ...lessonDetails,
      // lesson:
      // lessonIndex: currentLessonIdx,
      unit: newUnit,
      sub: newSubUnit,
      parentIndex: newUnitIndex,
      unitNotes: newUserLessonData.user_lesson_unit_notes,
      subNotes: newSubUnitNotes,
      // numUnits: numUnits,
      unitCompletion: newUserLessonData.lesson_unit_completion,
      completedSubUnits: newUserLessonData.watched_lesson_unit_ids,
      prevSub: prevSubUnitId,
      nextSub: nextSubUnitId,
    });
  };

  const dispatchHandler = (data) => {
    let key = "lesson_data";
    dispatch({
      type: "SET_LOADING",
      payload: { key },
    });

    let newState = state.lesson_data;
    let tempLesson = newState[lessonIndex];

    let updatedLesson = {
      ...tempLesson,
      user_data: data,
    };
    newState[lessonDetails.lessonIndex] = updatedLesson;

    localStorage.setItem(key, JSON.stringify(newState));

    const updateLesson = async () => {
      const result = await initialLessonState(newState);

      setLessonDetails(result);

      // console.log("Data fetched and set:", result);
    };
    if (!state.loading.lesson_data) {
      updateLesson();
    }
    dispatch({
      type: "FETCH_SUCCESS",
      payload: { key, data: newState },
    });
  };

  const convertSecondsToTimeText = (seconds) => {
    let hours = "";
    let minutes = "";
    if (seconds >= 3600) {
      hours = (Math.floor(seconds / 3600) + ":").padStart(2, "0");
      seconds = seconds - Math.floor(seconds / 3600) * 3600;
    }

    if (seconds >= 60) {
      if (hours !== "") {
        minutes = (Math.floor(seconds / 60) + ":").padStart(3, "0");
      } else {
        minutes = (Math.floor(seconds / 60) + ":").padStart(2, "0");
      }
      seconds = seconds - Math.floor(seconds / 60) * 60;
    } else {
      if (hours !== "") {
        minutes = "00:";
      } else {
        minutes = "0:";
      }
    }
    return hours + minutes + seconds.toString().padStart(2, "0");
  };

  const changeEditedNote = (noteId) => {
    if (noteBeingEdited === noteId) {
      //-1 being default, no notes open
      setNoteBeingEdited(-1);
    }
    if (noteId !== noteBeingEdited) {
      setNoteBeingEdited(noteId);
    }
    setEditedNoteText("");
  };

  const saveFeedback = (new_rating, new_text) => {
    // Build Payload
    console.log(sub.lesson_unit_id);
    console.log(new_rating);
    let payload = {
      // lesson_id: lesson.lesson_id,
      lesson_unit_id: sub.lesson_unit_id,
      rating: new_rating !== null ? new_rating : feedback_rating,
      feedback_text:
        new_text !== null && new_text !== undefined
          ? new_text.trim()
          : feedback_text.trim(),
    };

    // Set Timeout
    clearTimeout(feedback_timeout);
    setFeedbackTimeout(
      setTimeout(() => {
        HandyGolfAPI.sendRequest(
          "lesson/lesson_unit/saveFeedback",
          payload
        ).then((data) => {
          // Do Nothing on Success
        });
      }, 1000)
    );

    // Set States
    if (new_rating !== null) {
      setFeedbackRating(new_rating);
    }
    if (new_text !== null && new_text !== undefined) {
      setFeedbackText(new_text);
    }
  };

  //note state stuff
  const noteFocusSetter = (e) => {
    if (e.type === "focus") {
      setIsNoteFocused(true);
    } else if (e.type === "blur") {
      setIsNoteFocused(false);
    }
  };

  return (
    <>
      <AppTopBar
        breadcrumb
        pageTitle={lesson.name}
        path={"/lessons"}
        // favorite
        // addTo
      />

      {!(
        // (
        //   lesson &&
        //   lesson.lesson_id > 0 &&
        //   currentSubUnit &&
        //   currentSubUnit.lesson_unit_id
        // )
        lessonDetails
      ) && <CircularProgress />}
      {
        // lesson &&
        //   lesson.lesson_id > 0 &&
        //   currentSubUnit &&
        //   currentSubUnit.lesson_unit_id
        lessonDetails && (
          <div className="container">
            <div className="container__content">
              {/* <img src="https://placehold.jp/3d4070/ffffff/1020x880.png" /> */}
              <div className="video-pad-box">
                <div style={{ position: "relative" }}>
                  <Player
                    videoRef={videoRef}
                    // url={lesson.lesson_units[0].sub_units[0].video}
                    url={
                      sub.video_url !== ""
                        ? sub.video_url
                        : "/vid/adamscott.mp4"
                    }
                    // light={video.thumbnail_url}
                    lessonDetails={lessonDetails}
                    updateLessonDetails={updateLessonDetails}
                    dispatchHandler={dispatchHandler}
                    saveFeedback={saveFeedback}
                    // currentSubUnit={sub}
                    // loadLessonUnitData={loadLessonUnitData}
                    // lesson={lesson}
                    feedback_display={feedback_display}
                    setFeedbackDisplay={setFeedbackDisplay}
                    // userLessonData={userLessonData}
                    setVideoTime={setVideoTime}
                    feedback_rating={feedback_rating}
                    feedback_text={feedback_text}
                    prevSubUnitId={lessonDetails.prevSub}
                    nextSubUnitId={lessonDetails.nextSub}
                  />
                </div>
              </div>
              {/* </div> */}
              <div className="pad-box scroll-content-mobile">
                <div className="container__bottom-content">
                  <div className="only-on-mobile">
                    <div
                      className="mobile-lesson-subunit-description"
                      style={{ padding: "15px", fontSize: "14px" }}
                    >
                      Sub unit description - 200 Lorem ipsum dolor sit amet,
                      consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                      invidunt ut labore et dolore magna aliquyam erat, sed diam
                    </div>
                    <Accordion
                      mobile
                      // lessonUnits={lessonUnits}
                      lessonDetails={lessonDetails}
                      // lesson={lesson}
                      // expanded_lesson_unit={expanded_lesson_unit}
                      // userLessonData={userLessonData}
                      // currentSubUnit={currentSubUnit}
                      // setExpandedLessonUnit={setExpandedLessonUnit}
                      updateLessonDetails={updateLessonDetails}
                      // loadLessonUnitData={loadLessonUnitData}
                      // parentLessonId={parentLessonId}
                    />
                  </div>
                  <div className="not-on-mobile">
                    <Accordion
                      lessonDetails={lessonDetails}
                      updateLessonDetails={updateLessonDetails}
                      // lessonUnits={lessonUnits}
                      // lesson={lesson}
                      // expanded_lesson_unit={expanded_lesson_unit}
                      // userLessonData={userLessonData}
                      // currentSubUnit={currentSubUnit}
                      // setExpandedLessonUnit={setExpandedLessonUnit}
                      // loadLessonUnitData={loadLessonUnitData}
                      // parentLessonId={parentLessonId}
                    />
                  </div>
                </div>

                <div className="note-section">
                  <div className="note-section__add-note">
                    <textarea
                      onFocus={noteFocusSetter}
                      // onBlur={noteFocusSetter}
                      className="note-section__text-area"
                      placeholder="Add a note"
                      value={note_text}
                      onChange={(e) => setNoteText(e.target.value)}
                    ></textarea>
                    <div
                      className={
                        isNoteFocused
                          ? "note-section__divider--focused"
                          : "note-section__divider"
                      }
                      // style={{ width: "100%", border: "1px solid white" }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "20px 0",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "14px",
                        }}
                      >
                        <input
                          style={{
                            accentColor: "#3ab549",
                            width: "20px",
                            height: "20px",
                            // margin: 0,
                          }}
                          type="checkbox"
                          checked={note_time_checked}
                          onChange={(e) => setNoteTimeChecked(e.target.checked)}
                        />
                        {/* <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#3AB549",
                            },
                          }}
                        /> */}
                        <label>Add video timestamp</label>
                        {isNoteFocused && (
                          <strong>
                            {convertSecondsToTimeText(video_time)}
                          </strong>
                        )}
                      </div>
                      {isNoteFocused && (
                        <div>
                          {/* {note_text === "" ? (
                            <></>
                          ) : ( */}
                          <Button
                            secondary
                            style={{ background: "transparent" }}
                            onClick={() => {
                              setNoteText("");

                              setIsNoteFocused(false);
                            }}
                          >
                            Cancel
                          </Button>
                          {/*  )} */}
                          <Button
                            primary
                            onClick={() => {
                              HandyGolfAPI.sendRequest(
                                "lesson/lesson_unit/addNote",
                                {
                                  // lesson_id: lesson.lesson_id,
                                  lesson_unit_id: sub.lesson_unit_id,
                                  text: note_text,
                                  video_timestamp_in_seconds: note_time_checked
                                    ? video_time
                                    : 0,
                                }
                              ).then((data) => {
                                dispatchHandler(data);
                              });
                              setNoteText("");
                            }}
                          >
                            Add Note
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  {subNotes &&
                    subNotes.length > 0 &&
                    subNotes.map((note, idx) => {
                      return (
                        <div
                          className="note-section__note"
                          key={idx}
                          // style={{
                          //   display: "flex",
                          //   flexDirection: "column",
                          //   padding: "0 2rem",
                          //   margin: "2rem 0",
                          //   gap: "10px",
                          // }}
                        >
                          {noteBeingEdited === idx ? (
                            <textarea
                              className="note-section__text-area"
                              // style={{
                              //   color: "white",
                              //   backgroundColor: "#011523",
                              //   border: "none",
                              //   resize: "none",
                              //   boxShadow: "none",
                              //   outline: "none",
                              // }}
                              placeholder={note.text}
                              value={editedNoteText}
                              autoFocus
                              onChange={(e) =>
                                setEditedNoteText(e.target.value)
                              }
                              // onBlur={() => {
                              //   setNoteBeingEdited(-1);
                              // }}
                            ></textarea>
                          ) : (
                            <p
                              onClick={() => {
                                changeEditedNote(idx);

                                setIsNoteFocused(false);
                              }}
                              style={{ margin: "0 0 0 0" }}
                            >
                              {note.text}
                            </p>
                          )}
                          {noteBeingEdited === idx && (
                            <div className="note-section__divider--focused"></div>
                          )}

                          <div
                            className={
                              noteBeingEdited === idx
                                ? "note-section__note__bottom note-section__note__bottom--editing"
                                : "note-section__note__bottom"
                            }
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   gap: "22px",
                            //   margin: "16px 0 0 0",
                            // }}
                          >
                            {noteBeingEdited !== idx && (
                              <>
                                {note.video_timestamp_in_seconds > 0 ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      gap: "6px",
                                    }}
                                  >
                                    <i
                                      className="material-icons"
                                      style={{ color: "#3ab549" }}
                                      onClick={() => {
                                        videoRef.current.seekTo(
                                          note.video_timestamp_in_seconds,
                                          "seconds"
                                        );
                                        setVideoPlaying(true);
                                      }}
                                    >
                                      play_circle_filled
                                    </i>
                                    <strong>
                                      {convertSecondsToTimeText(
                                        note.video_timestamp_in_seconds
                                      )}
                                    </strong>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <i
                                  className="material-icons"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    changeEditedNote(idx);
                                    setIsNoteFocused(false);
                                  }}
                                >
                                  create
                                </i>
                              </>
                            )}
                            <i
                              className="material-icons"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure that you want to PERMANENTLY DELETE this note?"
                                  )
                                ) {
                                  HandyGolfAPI.sendRequest(
                                    "lesson/lesson_unit/deleteNote",
                                    {
                                      user_lesson_unit_note_id:
                                        note.user_lesson_unit_note_id,
                                    }
                                  ).then((data) => {
                                    dispatchHandler(data);
                                  });
                                }
                              }}
                            >
                              delete
                            </i>
                            {noteBeingEdited === idx && (
                              <div>
                                <Button
                                  secondary
                                  style={{ background: "transparent" }}
                                  onClick={() => {
                                    console.log("CANCEL NOTE");
                                    setEditedNoteText("");
                                    setNoteBeingEdited(-1);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  primary
                                  onClick={() => {
                                    console.log(editedNoteText);
                                    HandyGolfAPI.sendRequest(
                                      "lesson/lesson_unit/editNote",
                                      {
                                        text: editedNoteText,
                                        user_lesson_unit_note_id:
                                          note.user_lesson_unit_note_id,
                                      }
                                    ).then((data) => {
                                      dispatchHandler(data);
                                    });
                                    setEditedNoteText("");
                                    setNoteBeingEdited(-1);
                                  }}
                                >
                                  Save Edit
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/*  */}
            </div>
            <div className="container__side-content">
              <div className="pad-box">
                <Accordion
                  // lesson_units={lesson.lesson_units}
                  // lesson={lesson}
                  lessonDetails={lessonDetails}
                  // expanded_lesson_unit={expanded_lesson_unit}
                  // userLessonData={userLessonData}
                  // currentSubUnit={currentSubUnit}
                  // setExpandedLessonUnit={setExpandedLessonUnit}
                  updateLessonDetails={updateLessonDetails}
                  // loadLessonUnitData={loadLessonUnitData}
                />
              </div>
            </div>
          </div>
        )
      }
      <BottomNav lessonDetails />
    </>
  );
}
