import React, { useEffect, useState, useMemo } from "react";
import { AdminTableItem } from "./index";
import { Button } from "../components";

export function AdminTable({
  tableData,
  tableIds,
  handleOrderChange,
  headers,
  setSortChanging,
  idType,
  setSelectedItem,
  setEditingItem,
  typeConverter,
}) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = sortConfig.direction === "asc" ? "desc" : "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const sortedData = useMemo(() => {
    if (sortConfig.key) {
      return [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return tableData;
  }, [tableData, sortConfig]);

  const headerConverter = (header) => {
    if (header.includes("name")) {
      if (header.includes("user")) {
        return "User";
      } else {
        return "Name";
      }
    } else if (header.includes("type")) {
      return "Type";
    } else if (header.includes("description")) {
      return "Description";
    } else if (header.includes("message")) {
      return "Message";
    } else if (header.includes("sent_on")) {
      return "Date";
    } else if (header.includes("subtitle")) {
      return "Subtitle";
    } else if (header.includes("session")) {
      return "Session";
    } else if (header.includes("type")) {
      return "Type";
    } else if (header.includes("picture")) {
      return "Picture";
    } else if (header.includes("thumbnail")) {
      return "Thumbnail";
    } else {
      return header;
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[date.getUTCMonth()];
    const day = date.getUTCDate();
    return `${month} ${day}`;
  };

  return (
    <div>
      <div className="admin-table-wrapper">
        <table className="admin-table">
          <thead>
            <tr>
              {headers.map((header, idx) => {
                return (
                  <th
                    key={idx}
                    onClick={() => handleSort(header)}
                    style={
                      header === ""
                        ? {
                            cursor: "pointer",
                            width: "100px",
                            paddingLeft: "0",
                          }
                        : { cursor: "pointer" }
                    }
                  >
                    {/* {handleHeaderStrings(header)} */}
                    {headerConverter(header)}
                    <span
                      style={
                        sortConfig.key === header
                          ? { opacity: "1" }
                          : { opacity: "0" }
                      }
                    >
                      {sortConfig.direction === "asc" ? " ▼" : " ▲"}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, rowIdx) => {
              return (
                <tr key={rowIdx}>
                  {headers.map((header, itemIdx) => {
                    return (
                      <AdminTableItem
                        key={itemIdx}
                        header={header}
                        item={item}
                        setSortChanging={setSortChanging}
                        handleOrderChange={handleOrderChange}
                        tableIds={tableIds}
                        idType={idType}
                        setSelectedItem={setSelectedItem}
                        setEditingItem={setEditingItem}
                        typeConverter={typeConverter}
                        formatDate={formatDate}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        {/* purely aesthetic now, bottom bar */}
      </div>
    </div>
  );
}
