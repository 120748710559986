import React, { useState, useEffect } from "react";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { Card, Button } from "../components";
import { LinearProgress } from "@mui/material";
import { AppTopBar, Footer, BottomNav } from "../components";

export function Progress() {
  const image =
    "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg";

  return (
    <>
      <AppTopBar />
      <div className="container">
        <div className="container__content">
          <div className="card-container">
            <Card
              bordered
              purchase
              smallTitle="Recommended for you"
              title="Drill Name"
              image={image}
              description="There was a todo here, not anymore"
            />
          </div>
          {/* <Card wide /> */}
        </div>
        <div className="container__collapse"></div>
      </div>
      {/* <Footer /> */}
      <BottomNav />
    </>
  );
}
