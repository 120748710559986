import React from "react";

// Libraries
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import HandyGolfMessages from "../lib/HandyGolfMessages";

// MUI
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowCircleUpTwoToneIcon from "@mui/icons-material/ArrowCircleUpTwoTone";
import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import FindInPageTwoToneIcon from "@mui/icons-material/FindInPageTwoTone";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Rating from "@mui/material/Rating";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Navigation
import { useParams, Navigate, Link } from "react-router-dom";

// Other
import nl2br from "react-nl2br";

// @todo Find a better place for constants and translation methods
const LESSON_TYPE_FREE = 1;
const LESSON_TYPE_SUBSCRIPTION = 2;
function translateLessonType(type) {
  switch (type) {
    case LESSON_TYPE_FREE:
      return "Free";
    case LESSON_TYPE_SUBSCRIPTION:
      return "Subscription";
    default:
      return "Unknown";
  }
}

// Components
// Lesson List
function LessonList() {
  const [loaded, setLoaded] = React.useState(false);
  const [lesson_list, setLessonList] = React.useState([]);
  const lesson_list_columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: "100",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: "200",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: "50",
      width: "50",
      renderCell: (params) => {
        return <div>{translateLessonType(params.row.type)}</div>;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      width: "100",
      renderCell: (params) => {
        return (
          <div>
            <Link to={"/admin/lesson_mgmt/view/" + params.row.lesson_id}>
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="View Lesson"
              >
                <FindInPageTwoToneIcon />
              </IconButton>
            </Link>
            <Link to={"/admin/lesson_mgmt/edit/" + params.row.lesson_id}>
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="Edit Lesson"
              >
                <EditTwoToneIcon />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
  ];
  React.useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("lesson/getLessons", {})
      .then((data) => {
        if (mounted) {
          setLessonList(data);
        }
        setLoaded(true);
      })
      .catch((err) => {
        HandyGolfMessages.reportMessage(HandyGolfMessages.ERROR, err.message);
      });
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Lesson Management
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Button disabled>Lesson List</Button>
      </Breadcrumbs>
      <div style={{ textAlign: "center", marginBottom: 10 }}>
        <Link to="/admin/lesson_mgmt/add/">
          <Button
            variant="contained"
            className="blue"
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add lesson
          </Button>
        </Link>
        <br />
      </div>
      {!loaded && <CircularProgress />}
      {loaded && (
        <DataGrid
          rows={lesson_list}
          columns={lesson_list_columns}
          getRowId={(row) => row.lesson_id}
          disableRowSelectionOnClick
          pageSizeOptions={[10]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "odd_row" : "even_row"
          }
        ></DataGrid>
      )}
    </div>
  );
}
function LessonView({ lesson_id }) {
  const [loaded, setLoaded] = React.useState(false);
  const [lesson_details, setLessonDetails] = React.useState([]);
  React.useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("lesson/getLessonDetails", {
      lesson_id: lesson_id,
    }).then((data) => {
      if (mounted) {
        setLessonDetails(data);
      }
      setLoaded(true);
    });
    return () => {
      mounted = false;
    };
  }, [lesson_id]);
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Lesson Management
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/lesson_mgmt/">
          <Button>Lesson List</Button>
        </Link>
        <Button disabled>Lesson Details</Button>
      </Breadcrumbs>
      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Lesson Details" />
        {!loaded && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {loaded && (
          <>
            <CardContent>
              <strong>Name:</strong> {lesson_details.name}
              <br />
              <strong>Tagline:</strong> {lesson_details.tagline}
              <br />
              <strong>Description:</strong> {lesson_details.description}
              <br />
              <strong>Type:</strong> {translateLessonType(lesson_details.type)}
              <br />
              {/* @todo Add Average Rating */}
              {/*<strong>Average Rating:</strong> <Rating defaultValue={3} disabled />*/}
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Link to={"/admin/lesson_mgmt/edit/" + lesson_details.lesson_id}>
                <Button variant="contained" className="blue">
                  Edit Lesson
                </Button>
              </Link>
              <br />
            </CardActions>
          </>
        )}
      </Card>
      <br />

      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Lesson Units" />
        <CardContent>
          <LessonUnitList lesson_id={lesson_id} parent_lesson_unit_id={0} />
        </CardContent>
      </Card>
    </div>
  );
}
function LessonForm({ mode, lesson_id }) {
  const [loaded, setLoaded] = React.useState(false);
  const [redirect, setRedirect] = React.useState("");
  React.useEffect(() => {
    let mounted = true;
    if (lesson_id > 0) {
      HandyGolfAPI.sendRequest("lesson/getLessonDetails", {
        lesson_id: lesson_id,
      }).then((data) => {
        if (mounted) {
          setName(data.name);
          setTagline(data.tagline);
          setDescription(data.description);
          setType(data.type);
        }
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
    return () => {
      mounted = false;
    };
  }, [mode, lesson_id]);
  const [name, setName] = React.useState("");
  const [tagline, setTagline] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [type, setType] = React.useState("");
  const saveLessonDetails = () => {
    let method = "addLesson";
    if (lesson_id > 0) {
      method = "editLesson";
    }
    HandyGolfAPI.sendRequest("lesson/" + method, {
      lesson_id: lesson_id,
      name: name,
      tagline: tagline,
      description: description,
      type: type,
    }).then((data) => {
      setRedirect("/admin/lesson_mgmt/view/" + data.lesson_id);
    });
  };
  return (
    <div>
      {redirect !== "" && <Navigate to={redirect} />}
      <Typography variant="h4" gutterBottom>
        Lesson Management
      </Typography>
      {mode === "edit" ? (
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/admin/lesson_mgmt/">
            <Button>Lesson List</Button>
          </Link>
          <Link to={"/admin/lesson_mgmt/view/" + lesson_id}>
            <Button>Lesson Details</Button>
          </Link>
          <Button disabled>Edit Lesson</Button>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/admin/lesson_mgmt/">
            <Button>Lesson List</Button>
          </Link>
          <Button disabled>Add Lesson</Button>
        </Breadcrumbs>
      )}
      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Lesson Details" />
        {loaded ? (
          <>
            <CardContent>
              <FormControl>
                <TextField
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ width: 320 }}
                />
                <br />
                <br />
                <TextField
                  label="Tagline"
                  variant="outlined"
                  name="tagline"
                  value={tagline}
                  onChange={(e) => setTagline(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                  sx={{ width: 320 }}
                />
                <br />
                <br />
                <TextField
                  label="Description"
                  variant="outlined"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ width: 320 }}
                />
                <br />
                <br />
                <FormLabel id="lesson-type-group-label">Lesson Type</FormLabel>
                <RadioGroup
                  aria-labelledby="type"
                  defaultValue={LESSON_TYPE_FREE}
                  name="type"
                  value={type}
                >
                  <FormControlLabel
                    value={LESSON_TYPE_FREE}
                    control={
                      <Radio
                        onChange={(e, value) => {
                          if (e.target.checked) {
                            setType(e.target.value);
                          }
                        }}
                      />
                    }
                    label="Free"
                  />
                  <FormControlLabel
                    value={LESSON_TYPE_SUBSCRIPTION}
                    control={
                      <Radio
                        onChange={(e, value) => {
                          if (e.target.checked) {
                            setType(e.target.value);
                          }
                        }}
                      />
                    }
                    label="Subscription"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                className="blue"
                onClick={saveLessonDetails}
              >
                Save
              </Button>
              <Link to="/admin/lesson_mgmt/">
                <Button variant="contained" className="blue">
                  Cancel
                </Button>
              </Link>
            </CardActions>
          </>
        ) : (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
      </Card>
    </div>
  );
}

function SortOrderDialog(props) {
  const { lesson_unit_list, open, onClose, onSave } = props;
  const [my_sort_order_list, setMySortOrderList] = React.useState([]);

  React.useEffect(() => {
    console.log(lesson_unit_list);
    setMySortOrderList(lesson_unit_list);
  }, [lesson_unit_list]);

  const moveUp = (index) => {
    index = parseInt(index);
    let new_sort_order_list = [];
    for (let i in my_sort_order_list) {
      i = parseInt(i);
      if (i + 1 === index) {
        new_sort_order_list.push(my_sort_order_list[i + 1]);
        new_sort_order_list.push(my_sort_order_list[i]);
      } else if (i === index) {
        // Skip (Handled on previous iteration)
      } else {
        new_sort_order_list.push(my_sort_order_list[i]);
      }
    }
    setMySortOrderList(new_sort_order_list);
  };
  const moveDown = (index) => {
    index = parseInt(index);
    let new_sort_order_list = [];
    for (let i in my_sort_order_list) {
      i = parseInt(i);
      if (i === index) {
        // Skip (Handled on next iteration)
      } else if (i - 1 === index) {
        new_sort_order_list.push(my_sort_order_list[i]);
        new_sort_order_list.push(my_sort_order_list[i - 1]);
      } else {
        new_sort_order_list.push(my_sort_order_list[i]);
      }
    }
    setMySortOrderList(new_sort_order_list);
  };

  return (
    <Dialog
      onClose={() => {
        setMySortOrderList(lesson_unit_list);
        onClose();
      }}
      open={open}
    >
      <DialogTitle>Modify Sort Order</DialogTitle>
      <div style={{ width: 400, padding: "10px 20px" }}>
        {my_sort_order_list &&
          my_sort_order_list.map((item, index) => {
            return (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>{item.name}</div>
                <div
                  style={{
                    flex: 1,
                    minWidth: 50,
                    maxWidth: 50,
                    textAlign: "right",
                  }}
                >
                  {index > 0 && (
                    <IconButton
                      onClick={() => {
                        moveUp(index);
                      }}
                    >
                      <ArrowCircleUpTwoToneIcon className="green_icon" />
                    </IconButton>
                  )}
                </div>
                <div
                  style={{
                    flex: 1,
                    minWidth: 50,
                    maxWidth: 50,
                    textAlign: "right",
                  }}
                >
                  {index < my_sort_order_list.length - 1 && (
                    <IconButton
                      onClick={() => {
                        moveDown(index);
                      }}
                    >
                      <ArrowCircleDownTwoToneIcon className="green_icon" />
                    </IconButton>
                  )}
                </div>
              </div>
            );
          })}
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            className="blue"
            onClick={() => {
              onSave(my_sort_order_list);
              onClose();
            }}
          >
            Save Sort Order
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
function LessonUnitList({ lesson_id, parent_lesson_unit_id = 0 }) {
  const [lesson_unit_list, setLessonUnitList] = React.useState();
  const [sort_order_dialog_open, setSortOrderDialogOpen] =
    React.useState(false);
  const lesson_unit_list_columns = [
    {
      field: "sort_order",
      headerName: "#",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <>
            {params.row.parent_lesson_unit_id > 0 ? (
              <>{params.row.child_full_number}</>
            ) : (
              <>{params.row.sort_order}</>
            )}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "",
      headerName: "Average Rating",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <Rating value={params.row.average_rating} disabled={true} />;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 0.5,

      renderCell: (params) => {
        return (
          <div>
            <Link
              to={
                "/admin/lesson_mgmt/view_unit/" +
                lesson_id +
                "|" +
                params.row.lesson_unit_id +
                "/"
              }
            >
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="View Lesson Unit"
              >
                <FindInPageTwoToneIcon />
              </IconButton>
            </Link>
            <Link
              to={
                "/admin/lesson_mgmt/edit_unit/" +
                lesson_id +
                "|" +
                params.row.lesson_unit_id +
                "|" +
                parent_lesson_unit_id
              }
            >
              <IconButton
                color="primary"
                className="greenicon"
                aria-label="Edit Lesson Unit"
              >
                <EditTwoToneIcon />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
  ];
  const loadLessonUnits = () => {
    HandyGolfAPI.sendRequest("lesson/getLessonUnits", {
      lesson_id: lesson_id,
      parent_lesson_unit_id: parent_lesson_unit_id,
    }).then((data) => {
      setLessonUnitList(data);
    });
  };
  React.useEffect(() => {
    loadLessonUnits();
  }, [lesson_id, parent_lesson_unit_id]);
  return (
    <div>
      {lesson_unit_list && (
        <SortOrderDialog
          open={sort_order_dialog_open}
          lesson_unit_list={lesson_unit_list}
          onClose={() => {
            setSortOrderDialogOpen(false);
          }}
          onSave={(data) => {
            let lesson_unit_id_list = data.map((item) => item.lesson_unit_id);
            // Send Data to API
            HandyGolfAPI.sendRequest("lesson/saveLessonUnitSortOrder", {
              lesson_id: lesson_id,
              parent_lesson_unit_id: parent_lesson_unit_id,
              lesson_unit_id_list: lesson_unit_id_list,
            }).then((data) => {
              loadLessonUnits();
            });
            // Close Dialog
            setSortOrderDialogOpen(false);
          }}
        />
      )}

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Link
            to={
              "/admin/lesson_mgmt/add_unit/" +
              lesson_id +
              "|0|" +
              parent_lesson_unit_id
            }
          >
            <Button variant="contained" className="blue">
              Add Lesson Unit
            </Button>
          </Link>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          <Button
            variant="contained"
            className="blue"
            onClick={() => {
              setSortOrderDialogOpen(true);
            }}
          >
            Modify Sort Order
          </Button>
        </div>
      </div>
      {!lesson_unit_list && <CircularProgress />}
      {lesson_unit_list && (
        <DataGrid
          rows={lesson_unit_list}
          columns={lesson_unit_list_columns}
          getRowId={(row) => row.lesson_unit_id}
          disableRowSelectionOnClick
          pageSizeOptions={[10]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "odd_row" : "even_row"
          }
        ></DataGrid>
      )}
    </div>
  );
}
function LessonUnitView({ lesson_id, lesson_unit_id }) {
  const [loaded, setLoaded] = React.useState(false);
  const [lesson_unit_details, setLessonUnitDetails] = React.useState([]);
  React.useEffect(() => {
    let mounted = true;
    HandyGolfAPI.sendRequest("lesson/getLessonUnitDetails", {
      lesson_unit_id: lesson_unit_id,
    }).then((data) => {
      if (mounted) {
        setLessonUnitDetails(data);
      }
      setLoaded(true);
    });
    return () => {
      mounted = false;
    };
  }, [lesson_id, lesson_unit_id]);
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Lesson Management
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/lesson_mgmt/">
          <Button>Lesson List</Button>
        </Link>
        <Link to={"/admin/lesson_mgmt/view/" + lesson_id}>
          <Button>Lesson Details</Button>
        </Link>
        {loaded && Number(lesson_unit_details.parent_lesson_unit_id) > 0 && (
          <Link
            to={
              "/admin/lesson_mgmt/view_unit/" +
              lesson_id +
              "|" +
              lesson_unit_details.parent_lesson_unit_id +
              "/"
            }
          >
            <Button>Parent Lesson Unit Details</Button>
          </Link>
        )}
        <Button disabled>Lesson Unit Details</Button>
      </Breadcrumbs>
      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Lesson Unit Details" />
        {!loaded && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {loaded && (
          <>
            <CardContent>
              <strong>#:</strong>{" "}
              {lesson_unit_details.parent_lesson_unit_id > 0
                ? lesson_unit_details.child_full_number
                : lesson_unit_details.sort_order}
              <br />
              <strong>Name:</strong> {lesson_unit_details.name}
              <br />
              <strong>Description:</strong> {lesson_unit_details.description}
              <br />
              <strong>Text:</strong> {nl2br(lesson_unit_details.text)}
              <br />
              <strong>Unit Type:</strong> {lesson_unit_details.unit_type}
              <br />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Link
                to={
                  "/admin/lesson_mgmt/edit_unit/" +
                  lesson_id +
                  "|" +
                  lesson_unit_id +
                  "|" +
                  lesson_unit_details.parent_lesson_unit_id
                }
              >
                <Button variant="contained" className="blue">
                  Edit Lesson Unit
                </Button>
                <br />
              </Link>
            </CardActions>
          </>
        )}
      </Card>

      {loaded && Number(lesson_unit_details.parent_lesson_unit_id) === 0 && (
        <>
          <br />

          <Card sx={{ maxWidth: 550 }}>
            <CardHeader title="Sub Units" />
            <CardContent>
              <LessonUnitList
                lesson_id={lesson_id}
                parent_lesson_unit_id={lesson_unit_id}
              />
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
function LessonUnitForm({
  mode,
  lesson_id,
  parent_lesson_unit_id,
  lesson_unit_id,
}) {
  const [loaded, setLoaded] = React.useState(false);
  const [redirect, setRedirect] = React.useState();
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [text, setText] = React.useState("");
  const [unit_type, setUnitType] = React.useState(0);
  const [sort_order, setSortOrder] = React.useState(0);

  React.useEffect(() => {
    let mounted = true;
    if (mode === "edit") {
      console.log(lesson_unit_id);
      HandyGolfAPI.sendRequest("lesson/getLessonUnitDetails", {
        lesson_unit_id: lesson_unit_id,
      }).then((data) => {
        setLoaded(true);
        if (mounted) {
          setName(data.name);
          setDescription(data.description);
          setText(data.text);
          setUnitType(data.unit_type);
          setSortOrder(data.sort_order);
        }
      });
    } else {
      setLoaded(true);
    }
    return () => {
      mounted = false;
    };
  }, [mode, lesson_id, parent_lesson_unit_id, lesson_unit_id]);
  const saveLessonUnitDetails = () => {
    let method = "addLessonUnit";
    if (mode === "edit" && lesson_unit_id > 0) {
      method = "editLessonUnit";
    }
    let api_params = {
      lesson_id: lesson_id,
      lesson_unit_id: lesson_unit_id,
      parent_lesson_unit_id: parent_lesson_unit_id,
      name: name,
      description: description,
      text: text,
      unit_type: unit_type,
      sort_order: sort_order,
    };
    HandyGolfAPI.sendRequest("lesson/" + method, api_params).then((data) => {
      setRedirect(
        "/admin/lesson_mgmt/view_unit/" +
          lesson_id +
          "|" +
          data.lesson_unit_id +
          "/"
      );
    });
  };
  return (
    <div>
      {redirect && <Navigate to={redirect} />}
      <Typography variant="h4" gutterBottom>
        Lesson Management
      </Typography>
      {mode === "edit" ? (
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/admin/lesson_mgmt/">
            <Button>Lesson List</Button>
          </Link>
          <Link to={"/admin/lesson_mgmt/view/" + lesson_id}>
            <Button>Lesson Details</Button>
          </Link>
          <Link
            to={
              "/admin/lesson_mgmt/view_unit/" +
              lesson_id +
              "|" +
              lesson_unit_id +
              "/"
            }
          >
            <Button>Lesson Unit Details</Button>
          </Link>
          <Button disabled>Edit Lesson Unit</Button>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/admin/lesson_mgmt/">
            <Button>Lesson List</Button>
          </Link>
          <Link to={"/admin/lesson_mgmt/view/" + lesson_id}>
            <Button>Lesson Details</Button>
          </Link>
          <Button disabled>Add Lesson Unit</Button>
        </Breadcrumbs>
      )}
      <Card sx={{ maxWidth: 550 }}>
        <CardHeader title="Lesson Unit Details" />
        {loaded === false ? (
          <CardContent>
            <div>{name}</div>
            <CircularProgress />
          </CardContent>
        ) : (
          <>
            <CardContent>
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ width: 320 }}
              />
              <br />
              <br />
              <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ width: 320 }}
              />
              <br />
              <br />
              <TextField
                label="Text"
                variant="outlined"
                name="text"
                multiline
                maxRows={4}
                value={text}
                onChange={(e) => setText(e.target.value)}
                sx={{ width: 320 }}
              />
              <br />
              <br />
              <InputLabel id="unit_type-select-label">Unit Type</InputLabel>
              <Select
                labelId="unit_type-select-label"
                value={unit_type}
                onChange={(e) => setUnitType(e.target.value)}
                sx={{ width: 300 }}
              >
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={1}>Normal</MenuItem>
                <MenuItem value={2}>Drill</MenuItem>
              </Select>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                className="blue"
                onClick={saveLessonUnitDetails}
              >
                Save
              </Button>
              {mode === "add" && parent_lesson_unit_id === 0 && (
                <Link to={"/admin/lesson_mgmt/view/" + lesson_id}>
                  <Button variant="contained" className="blue">
                    Cancel
                  </Button>
                </Link>
              )}
              {mode === "add" && parent_lesson_unit_id > 0 && (
                <Link
                  to={
                    "/admin/lesson_mgmt/view_unit/" +
                    lesson_id +
                    "|" +
                    parent_lesson_unit_id +
                    "/"
                  }
                >
                  <Button variant="contained" className="blue">
                    Cancel
                  </Button>
                </Link>
              )}
              {mode === "edit" && (
                <Link
                  to={
                    "/admin/lesson_mgmt/view_unit/" +
                    lesson_id +
                    "|" +
                    lesson_unit_id +
                    "/"
                  }
                >
                  <Button variant="contained" className="blue">
                    Cancel
                  </Button>
                </Link>
              )}
            </CardActions>
          </>
        )}
      </Card>
    </div>
  );
}

export default function LessonMgmt() {
  const { action_param = "list", id_param = 0 } = useParams();

  // New View Logic -- Based on Props
  let lesson_id = 0;
  let lesson_unit_id = 0;
  let parent_lesson_unit_id = 0;
  if (action_param === "list") {
    return <LessonList />;
  }
  if (action_param === "add") {
    return <LessonForm mode="add" />;
  }
  // MJH
  if (action_param === "edit") {
    lesson_id = parseInt(id_param);
    if (lesson_id > 0) {
      return <LessonForm mode="edit" lesson_id={lesson_id} />;
    } else {
      return <div>ERROR 1.</div>;
    }
  }
  if (action_param === "view") {
    lesson_id = parseInt(id_param);
    if (lesson_id > 0) {
      return <LessonView lesson_id={lesson_id} />;
    } else {
      return <div>ERROR 2.</div>;
    }
  }
  if (action_param === "add_unit") {
    const id_parts = ("" + id_param).split("|");
    lesson_id = parseInt(id_parts[0]);
    lesson_unit_id = parseInt(id_parts[1]);
    parent_lesson_unit_id = parseInt(id_parts[2]);
    if (lesson_id > 0) {
      return (
        <LessonUnitForm
          mode="add"
          lesson_id={lesson_id}
          lesson_unit_id={lesson_unit_id}
          parent_lesson_unit_id={parent_lesson_unit_id}
        />
      );
    } else {
      return <div>ERROR 3.</div>;
    }
  }
  if (action_param === "edit_unit") {
    const id_parts = ("" + id_param).split("|");
    lesson_id = parseInt(id_parts[0]);
    lesson_unit_id = parseInt(id_parts[1]);
    parent_lesson_unit_id = parseInt(id_parts[2]);
    if (lesson_id > 0) {
      return (
        <LessonUnitForm
          mode="edit"
          lesson_id={lesson_id}
          lesson_unit_id={lesson_unit_id}
          parent_lesson_unit_id={parent_lesson_unit_id}
        />
      );
    } else {
      return <div>ERROR 3.</div>;
    }
  }
  if (action_param === "view_unit") {
    const id_parts = ("" + id_param).split("|");
    lesson_id = parseInt(id_parts[0]);
    lesson_unit_id = parseInt(id_parts[1]);
    if (lesson_id > 0) {
      return (
        <LessonUnitView lesson_id={lesson_id} lesson_unit_id={lesson_unit_id} />
      );
    } else {
      return <div>ERROR 4.</div>;
    }
  }
  if (action_param === undefined) {
    return <CircularProgress />;
  }
  // Redirect
  return (
    <div>
      <Navigate to="/admin/lesson_mgmt" />
    </div>
  );
}
